<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <SelectRadioButonsList
      id="clientPopup"
      service="client"
      :list-query="query"
      :search-fields="searchFields"
      message="You can only select clients that are in the same group(s) as you."
    >
      <SelectRadioButonsOption
        slot="item"
        slot-scope="{ item }"
        name="client"
        :label="fullName(item)"
        :item="item"
        :has-info="true"
        :selected="selectedItem && selectedItem._id === item._id"
        @selected="itemSelected"
        @show-info="item => (showingInfoPopupFor = item)"
      />
    </SelectRadioButonsList>

    <f7-popup
      id="infoPopup"
      ref="infoPopup"
      :opened="!!showingInfoPopupFor"
      @popup:closed="showingInfoPopupFor = null"
    >
      <f7-page v-if="showingInfoPopupFor">
        <f7-navbar :title="fullName(showingInfoPopupFor)">
          <f7-nav-right>
            <f7-link @click="closeInfoPopup">
              Close
            </f7-link>
          </f7-nav-right>
        </f7-navbar>

        <f7-block v-if="showingInfoPopupFor">
          <div
            v-if="
              showingInfoPopupFor.summary ||
                showingInfoPopupFor.interventions ||
                showingInfoPopupFor.notes
            "
          >
            <div
              v-if="showingInfoPopupFor.summary"
              class="mb-9"
            >
              <h3 class="text-align-center mb-0">
                Summary
              </h3>
              <p v-html="cleanHTML(showingInfoPopupFor.summary)" />
            </div>

            <div
              v-if="showingInfoPopupFor.interventions"
              class="mb-9"
            >
              <h3 class="text-align-center mb-0">
                Interventions
              </h3>
              <p v-html="cleanHTML(showingInfoPopupFor.interventions)" />
            </div>

            <div
              v-if="showingInfoPopupFor.notes"
              class="mb-9"
            >
              <h3 class="text-align-center mb-0">
                Notes
              </h3>
              <p v-html="cleanHTML(showingInfoPopupFor.notes)" />
            </div>
          </div>
          <p v-else>
            No description to display.
          </p>
        </f7-block>
      </f7-page>
    </f7-popup>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import SelectRadioButonsList from '@/components/SelectRadioButonsList';
import SelectRadioButonsOption from '@/components/SelectRadioButonsOption';
import DOMPurify from 'dompurify';

export default {
  components: {
    SelectRadioButonsList,
    SelectRadioButonsOption,
  },
  props: {
    selectedItem: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showingInfoPopupFor: null,
      searchFields: ['firstName', 'lastName'],
    };
  },
  computed: {
    ...mapGetters(['currentGroup']),
    query() {
      if (!this.currentGroup) return {};

      const query = {
        _id: { $in: this.currentGroup.clients },
        $sort: { firstName: 1 },
      };

      return query;
    },
  },
  methods: {
    fullName(item) {
      return `${item.firstName} ${item.lastName}`;
    },
    itemSelected(item) {
      this.$emit('selected', item);
    },
    closeInfoPopup() {
      this.$refs.infoPopup.close();
    },
    cleanHTML(html) {
      return DOMPurify.sanitize(html);
    },
  },
};
</script>

<style scoped>
#infoPopup {
  z-index: 12604;
}
</style>
