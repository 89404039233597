<template>
  <div
    :id="id"
    class="inner-tab-page-content page-content"
    :class="{ 'inner-tab-page-content--active': isActive }"
  >
    <slot v-if="isActive" />
  </div>
</template>

<script>
  export default {
    props: {
      id: {
        type: String,
        required: true,
      },
      title: {
        type: String,
        default: 'Tab',
      },
      icon: {
        type: String,
        required: false,
        default: null,
      },
    },
    data() {
      return {
        isActive: false,
      };
    },
    watch: {
      isActive(newVal, oldVal) {
        if (newVal !== oldVal && newVal === true) {
          this.$emit('selected');
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .inner-tab-page-content {
    overflow: hidden;
    padding-top: 38px !important;
    padding-bottom: 0 !important;
    display: none;

    @media (min-width: $large_screen_width) {
      height: calc(100vh - 56px);
    }

    &--active {
      display: block;
    }
  }

  .inner-tab-page-content .infinite-scroll-page {
    margin-bottom: 56px;
    margin-top: 10px;
    padding-top: 0 !important;
  }
</style>
