import { roleKeys } from '../roles';

const {
  limited,
  regular,
  social,
  team,
  staff,
  clinician,
  groupAdmin,
  orgAdmin,
  superAdmin,
} = roleKeys;

const commonRoles = [
  social,
  team,
  staff,
  clinician,
  groupAdmin,
  orgAdmin,
  superAdmin,
];

const supportingRoles = [limited, regular];
/**
 * The different notifications generated from the app.
 *
 * @type {Object}
 */
export const notificationTypes = {
  PostAddedToGroup: {
    text: 'New post added to group',
    event: 'posts.created',
    roles: [...commonRoles, ...supportingRoles],
  },
  ClientTaggedInPost: {
    text: 'Your linked person has been tagged in a post',
    event: 'tags.created',
    roles: [...supportingRoles],
  },
  NewMessageReceived: {
    text: 'New message received',
    event: 'messages.created',
    roles: [...commonRoles, ...supportingRoles],
  },
  PostLiked: {
    text: 'Your post has been liked',
    event: 'likes.created',
    roles: [...commonRoles],
  },
  PostCommentedOn: {
    text: 'Your post has been commented on',
    event: 'comments.created',
    roles: [...commonRoles],
  },
  PostAlsoCommentedOn: {
    text: 'Someone else has commented on a post',
    event: 'comments.created',
    roles: [...commonRoles, ...supportingRoles],
  },
  GroupMembersCountChanged: {
    text: 'Member added to a group',
    event: 'groups.updated',
    roles: [...commonRoles],
  },
};
