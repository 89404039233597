<template>
  <FeedSheetModal
    id="likesSheetModal"
    service="likes"
    icon="like-fill.svg"
    empty-message=""
    :style="{ 'margin-bottom': marginBottomZero ? 0 : '6px' }"
  >
    <div
      slot="list-items"
      slot-scope="{ items }"
    >
      <Like
        v-for="item in items"
        :key="item._id"
        :user-id="item.createdBy"
      />
    </div>
  </FeedSheetModal>
</template>

<script>
  import Like from '@/components/socialFeed/Like';
  import FeedSheetModal from '@/components/socialFeed/FeedSheetModal';
  export default {
    components: {
      Like,
      FeedSheetModal,
    },
    props: {
      marginBottomZero: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  #numOfLikes {
    color: gray;
    font-size: 0.9em;
    font-weight: 450;
  }
  .likes-list {
    overflow: auto;
    height: auto;
    min-height: 36vh;
    max-height: 55vh;
  }
</style>
