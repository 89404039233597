<template>
  <div class="w-100">
    <li
      v-if="displayAsButton"
      class="input-button"
    >
      <f7-link
        class="item-link item-content"
        @click="openPopup"
      >
        <div class="item-media">
          <img
            src="@/assets/images/icons/location-orange.svg"
            class="image-icon"
          >
        </div>
        <div
          class="item-inner"
          :class="{ 'form-error': error }"
        >
          <div
            v-if="value"
            class="item-title"
          >
            {{ value.name }}
          </div>
          <div
            v-else
            class="item-title"
          >
            Add Location
            <BaseLoadingIcon
              v-if="geolocationSearchingAndFetchingDetails"
              :size="20"
            />
          </div>
        </div>
      </f7-link>
    </li>

    <f7-row
      v-else
      class="input-field"
    >
      <f7-label :class="{ 'form-error': error }">
        Location <span class="required-field"> *</span>
      </f7-label>
      <f7-link
        class="item-link item-content w-100"
        @click="openPopup"
      >
        <div class="item-inner">
          <div
            v-if="value"
            class="item-title"
          >
            {{ value.name }}
          </div>
          <div
            v-else
            class="item-title"
          >
            Add Location
          </div>
        </div>
      </f7-link>
      <!-- <f7-list-item  class="location-error">test</f7-list-item> -->
      <f7-list-item
        v-if="errorMessage"
        class="location-error"
      >
        {{
          errorMessage
        }}
      </f7-list-item>
    </f7-row>

    <f7-popup
      ref="popup"
      class="above-all"
      @popup:closed="cancel"
    >
      <f7-page>
        <f7-navbar>
          <f7-nav-left>
            <f7-link @click="cancel">
              <f7-icon f7="close" />
            </f7-link>
            <BaseGPSButton
              ref="gpsButton"
              v-model="geolocationCoordinates"
              :search-on-mounted="true"
              @searchingChange="status => (geolocationSearching = status)"
            />
          </f7-nav-left>
          <f7-nav-title class="text-center">
            {{ title }}
          </f7-nav-title>
          <f7-nav-right>
            <f7-button
              v-if="placeSelected !== null"
              class="btn-clear"
              @click="clear"
            >
              Clear
            </f7-button>
          </f7-nav-right>
        </f7-navbar>

        <f7-searchbar
          ref="searchbar"
          v-model="searchText"
          @input="updateSearchText"
        />

        <f7-list class="no-hairlines-md results-list">
          <!-- Suggested & Nearby Places -->
          <f7-list-item
            v-for="place in places"
            :key="place.place_id"
            class="results-list__result"
          >
            <label class="item-radio">
              <input
                type="radio"
                name="place"
                :value="place"
                :checked="
                  placeSelected && place.place_id === placeSelected.place_id
                "
                @input="placeSelected = place"
              >
              <i class="icon icon-radio" />
              <div class="item-title">{{ place.name }}</div>
            </label>
          </f7-list-item>

          <!-- No results found -->
          <f7-list-item v-if="places.length === 0">
            No Results found
          </f7-list-item>
        </f7-list>
      </f7-page>
    </f7-popup>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import showToast from '@/mixins/showToast';
import BaseGPSButton from '@/components/BaseGPSButton';
import BaseLoadingIcon from '@/components/BaseLoadingIcon';
import FeathersClient from '@/api/feathers-client';

export default {
  components: {
    BaseGPSButton,
    BaseLoadingIcon,
  },
  mixins: [showToast],
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: 'Select Location',
    },
    displayAsButton: {
      type: Boolean,
      default: false,
    },
    autoSelectNearest: {
      type: Boolean,
      default: false,
    },
    clearOnConfirm: {
      type: Boolean,
      default: false,
    },
    limitRadius: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
    },
  },
  data() {
    return {
      placeSelected: this.value,
      placesSuggested: [],

      geolocationSearching: false,
      geolocationCoordinates: null,
      searchText: '',
    };
  },
  computed: {
    places() {
      const items = this.placesSuggested;

      // Add selected item to beginning of list if not already included
      if (this.placeSelected) {
        const itemsIncludesSelected = items
          .map(item => item.place_id)
          .includes(this.placeSelected.place_id);

        if (!itemsIncludesSelected && this.searchText === '') {
          items.unshift(this.placeSelected);
        } else if (!itemsIncludesSelected) {
          items.push(this.placeSelected);
        }
      }

      return items;
    },
    geolocationCoordinatesSerialized() {
      if (!this.geolocationCoordinates) return;

      return `${this.geolocationCoordinates.latitude.toString()},${this.geolocationCoordinates.longitude.toString()}`;
    },
    geolocationSearchingAndFetchingDetails() {
      return (
        this.geolocationSearching ||
        (this.placeSelected && !this.placeSelected.title)
      );
    },
    geocodeQuery() {
      const query = {
        limitRadius: this.limitRadius,
      };

      if (this.geolocationCoordinatesSerialized) {
        query.latlng = this.geolocationCoordinatesSerialized;
      }

      if (this.searchText) {
        query.text = this.searchText;
      }

      return query;
    },
  },
  watch: {
    async geolocationCoordinates() {
      this.placesSuggested = await FeathersClient.service('geocode').find({
        query: {
          latlng: this.geolocationCoordinatesSerialized,
        },
      });

      if (!this.placeSelected && this.placesSuggested.length > 0) {
        [this.placeSelected] = this.placesSuggested;
        this.$emit('input', this.placeSelected);
      }
    },
    placeSelected(newVal, oldVal) {
      const valueCreated = !oldVal;
      const valueUpdated =
        newVal && oldVal && newVal.place_id !== oldVal.place_id;

      if (valueUpdated || valueCreated) {
        this.confirm();
      }
    },
  },
  created() {
    this.searchDebounced = debounce(this.search, 300);
  },
  methods: {
    async search() {
      this.placesSuggested = await FeathersClient.service('geocode').find({
        query: this.geocodeQuery,
      });
    },
    resetSearch() {
      this.searchText = '';
      this.$refs.searchbar.disable();
      this.placesSuggested = [];
    },
    closePopup() {
      this.$refs.popup.close();
    },
    openPopup() {
      this.search();
      this.$refs.popup.open();
    },

    // User Actions
    async confirm() {
      this.placeSelected = await FeathersClient.service('geocode').get(
        this.placeSelected.place_id
      );

      this.resetSearch();
      this.closePopup();
      this.$emit('input', this.placeSelected);

      if (this.clearOnConfirm) {
        this.placeSelected = this.value;
      }
    },
    cancel() {
      this.resetSearch();
      this.closePopup();
      this.placeSelected = this.value;
    },
    clear() {
      this.resetSearch();
      this.closePopup();
      this.placeSelected = null;
      this.$emit('input', null);
    },
    updateSearchText(event) {
      this.searchText = event.target.value.trim();

      this.searchDebounced();
    },
  },
};
</script>

<style lang="scss">
.item-inner {
  &:after {
    display: none !important;
  }
}

.results-list {
  overflow: hidden;
  margin-top: 0 !important;

  .results-list__result {
    margin-left: 16px;
    margin-right: 16px;

    border-bottom: 1px solid color(gray);
    font-size: 0.85em;

    .item-radio {
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;

      padding-top: 16px;
      padding-bottom: 16px;

      .icon-radio {
        margin-left: 13px;
      }

      .item-title {
        white-space: pre-wrap;
      }
    }
  }
}

.input-button {
  background: color(white);
  padding: 0;
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 0.9em;

  @media (max-width: 355px) {
    margin-top: 0.4em;
  }

  .item-inner {
    .item-title {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      white-space: normal;
    }
  }

  .item-content {
    width: 100%;
    font-weight: bold;
    font-size: 0.9em;
    padding-left: 7px !important;

    @media (max-width: 355px) {
      font-size: 0.8em;
    }

    .item-media {
      min-width: 0 !important;

      & + .item-inner {
        margin-left: 12px;
      }
    }
  }
}

.btn-clear {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1em;
  font-weight: bold;
  color: color(red) !important;

  @media (max-width: 360px) {
    font-size: 0.55em;
  }
}

.required-field {
  font-size: 1.1em;
  margin-left: 3px;
}

.w-100 {
  width: 100%;
}

.above-all {
  z-index: 12601;
}
.form-error {
  color: #ef4422 !important;
}

.location-error {
  color: color(red);
  .item-content {
    min-height: auto;
    .item-inner {
      text-align: center;
      padding: 0;
      min-height: auto;
      height: auto;
    }
  }
}
</style>
