<template>
  <div
    v-if="$can('create', 'events')"
    class="fab fab-right-bottom add-event"
  >
    <f7-link @click="createEvent">
      <f7-icon f7="add" />
    </f7-link>
  </div>
</template>
<script>
  import moment from 'moment';
  export default {
    props: {
      selectedDate: {
        default: null,
        type: Object,
      },
    },
    computed: {
      dateForCreate() {
        return this.selectedDate || moment();
      },
    },
    methods: {
      async createEvent() {
        const currentTime = moment(this.selectedDate || new Date());
        const remainder = 15 - (currentTime.minute() % 15);

        const start = moment(currentTime)
          .add(remainder, 'minutes')
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss[Z]');

        const end = moment(currentTime)
          .add(remainder + 60, 'minutes')
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss[Z]');

        const eventDefaults = {
          start,
          end,
        };

        await this.$store.dispatch('setmodifyItemData', eventDefaults);
        this.$f7router.navigate('/app/EventForm/');
      },
    },
  };
</script>
<style scoped lang="scss">
  .add-event {
    margin-right: 10px;
    position: fixed;
    .options-button {
      color: color(white);
      background-color: #296486;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
</style>
