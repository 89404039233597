import { mapActions, mapGetters, mapState } from 'vuex';
import { uniq } from 'lodash';
import feathersClient from '@/api/feathers-client';

export default {
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('users', {
      findUsersInStore: 'find',
    }),
    ...mapGetters('notifications', {
      findNotificationsInStore: 'find',
    }),
    notificationsQuery() {
      return {
        $sort: {
          createdAt: -1,
        },
        subscriber: this.user._id,
        label: {
          $ne: null,
        },
      };
    },
    notifications() {
      const { data } = this.findNotificationsInStore({
        paginate: false,
        query: this.notificationsQuery,
      });

      return data;
    },

    destinationLink() {
      if (this.parent && 
          this.parent.modelName === 'groups' && 
          this.notification.ref.kind === 'countChange') {
        return '/app/info/';
      } else if (this.parent && this.parent.modelName === 'groups') {
        return `/app/destinationPost/${this.notification.ref._id}`;
      } else if (
        this.parent &&
        this.parent.modelName === 'conversations' &&
        typeof this.navigateDelayed === 'function'
      ) {
        return this.navigateDelayed('/app/conversations/');
      } else if (this.parentServiceName === 'posts') {
        return `/app/destinationPost/${this.parentServiceId}`;
      } else if (this.notification.label === 'NewMessageReceived') {
        return `/app/conversations/${this.parentServiceId}/`;
      } else if (this.parent) {
        return `/app/destinationPost/${this.parent._id}`;
      }
      return '';
    },
    navigateDelayed(route) {
      // Workaround to support navigation immediately after app is launched from notification link
      setTimeout(() => {
        this.$f7.views.main.router.navigate(route);
      }, 1000);
    },
    parentServiceName() {
      return `${this.notification.parent.kind.toLowerCase()}s`;
    },
    parentServiceId() {
      return `${this.notification.parent._id}`;
    },
    siblingServiceName() {
      return `${this.notification.ref.kind.toLowerCase()}s`;
    },
    formattedTimestamp() {
      const timestamp = this.notification.sentAt || this.notification.updatedAt;
      return this.formatDateTime(timestamp);
    },
    icon() {
      switch (this.siblingServiceName) {
        case 'comments':
          return 'chats';
        case 'likes':
          return 'heart';
        case 'posts':
          return 'chat';
        case 'tags':
          return 'chat';
        default:
          return 'social_rss_fill';
      }
    },
    text() {
      if (
        this.parentServiceName === 'comments' &&
        this.siblingServiceName === 'likes'
      ) {
        return `${this.names} liked your comment`;
      }
      const actionType = this.siblingServiceName === 'comments' ? 'commented on' : 'liked';
      if (this.isParentCreator) {
        return `${this.names} ${actionType} your post`;
      }
      if (this.parentServiceName === 'groups') {
        return 'A  post was added to the group';
      }
      return `${this.names} also ${actionType} a post`;
    },
    names() {
      const names = this.siblingUsers.map(r => `${r.firstName} ${r.lastName}`);
      return names.length === 1
        ? names[0]
        : `${names.slice(0, -1).join(', ')} and ${names.slice(-1)}`;
    },
    parentUser() {
      return this.$store.getters[`${this.parentServiceName}/get`](
        this.notification.parent._id
      );
    },
    parent() {
      const parent = this.$store.getters[`${this.parentServiceName}/get`](
        this.notification.parent._id
      );
      // Check if the parent is top level or references another model
      // For example, a like that references a comment that in turn references a post
      if (!parent || !parent.ref || !parent.ref._id) {
        return parent;
      }
      return this.$store.getters['posts/get'](parent.ref._id);
    },
    isParentCreator() {
      if (!this.parent) return;
      return this.parent.createdBy === this.user._id;
    },
    siblingRecords() {
      return this.$store.getters[`${this.siblingServiceName}/find`]({
        query: this.siblingRecordQuery,
      }).data;
    },
    siblingUsers() {
      return this.findUsersInStore({ query: this.siblingUsersQuery }).data;
    },
    siblingRecordQuery() {
      return {
        'ref._id': this.notification.parent._id,
        $limit: 3,
        $sort: {
          createdAt: -1,
        },
        createdBy: {
          $ne: this.user._id,
        },
      };
    },
    siblingUsersQuery() {
      const userIds = uniq(this.siblingRecords.map(r => r.createdBy));
      return {
        _id: {
          $in: userIds,
        },
      };
    },
  },
  methods: {
    ...mapActions('users', {
      findUsers: 'find',
    }),
    viewNotification() {
      feathersClient
        .service('notifications')
        .patch(this.notification._id, { read: true });
      this.$f7.views.main.router.navigate(this.destinationLink);
    },
  },
};
