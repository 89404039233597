<template>
  <f7-list-item
    :class="['login-list-item', { 'login-list-item--disabled': isDisabled }]"
    :title="storedAccount.email"
    swipeout
    @click="handleClick"
    @swipeout:deleted="deleteAccount"
  >
    <f7-icon
      slot="media"
      icon="demo-list-icon"
    >
      <svg
        viewBox="0,0,50,50"
        class="avatar"
        height="45"
        width="45"
      >
        <g>
          <circle
            :fill="color"
            cx="25"
            cy="25"
            r="25"
          />
          <text
            fill="#fff"
            font-size="20"
            text-anchor="middle"
            x="25"
            y="33"
          >
            {{ initial }}
          </text>
        </g>
      </svg>
    </f7-icon>
    <f7-swipeout-actions
      v-if="showDeleteAction"
      right
    >
      <f7-swipeout-button
        delete
        confirm-text="Are you sure you want to forget this account?"
      >
        Delete
      </f7-swipeout-button>
    </f7-swipeout-actions>
  </f7-list-item>
</template>
<script>
  import { mapState, mapActions, mapGetters } from 'vuex';
  import { sample } from 'lodash';

  export default {
    props: {
      storedAccount: {
        type: Object,
        required: true,
        validator: (account) => ['email', 'tokenId', 'expiration'].every((key) => key in account),
      },
      showActions: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      ...mapState('auth', {
        currentUser: 'user',
      }),
      ...mapGetters('accounts', {
        isAccountExpired: 'isExpired',
      }),
      initial() {
        return this.storedAccount.email.charAt(0).toUpperCase();
      },
      color() {
        const colors = ['#EC6B70', '#379DCB', '#6ABCEA', '#B7506A', '#FAAB19'];
        return sample(colors);
      },
      showDeleteAction() {
        return (
          this.isExpired || this.showActions || this.currentUser.email === this.storedAccount.email
        );
      },
      isDisabled() {
        return this.currentUser && this.currentUser.email === this.storedAccount.email;
      },
      isExpired() {
        return this.isAccountExpired(this.storedAccount);
      },
    },
    methods: {
      ...mapActions('accounts', {
        removeStoredAccount: 'remove',
      }),
      deleteAccount() {
        try {
          this.removeStoredAccount(this.storedAccount.email);
        } catch (err) {
          this.$f7.dialog.alert(
            'If the problem persists, contact support.',
            'Error removing account'
          );
        }
      },
      handleClick(e) {
        if (this.isDisabled) return;
        e.preventDefault();
        this.$emit('selected', {
          email: this.storedAccount.email,
          tokenId: this.storedAccount.tokenId,
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  .login-list-item {
    transition: all 0.2s ease-in;
    list-style-type: none;
    font-size: 13px;
    font-weight: bold;

    .icon {
      display: flex !important;
      justify-content: center;
      width: auto;
    }

    .avatar {
      width: 100%;
      height: auto;
      font-weight: normal;
      max-width: 53px;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }
</style>
<style>
  li.swipeout.login-list-item.login-list-item--disabled > div:first-child {
    opacity: 0.5 !important;
  }
</style>
