<template>
  <f7-swiper-slide>
    <div class="slide">
      <div
        v-if="$slots.image"
        class="slide__image"
      >
        <slot name="image" />
      </div>
      <h1
        class="slide__title"
        v-text="title"
      />
      <div class="slide__content">
        <slot />
      </div>
    </div>
  </f7-swiper-slide>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.slide {
  padding: 0 3em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-weight: 300;
  font-size: clamp(0.75rem, calc(1.3vw + 0.5rem), 1.25rem);

  &__image ::v-deep img {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 50%;
    height: auto;
  }

  ::v-deep p {
    margin-bottom: 2.5em;
  }

  &__title {
    font-size: 2em;
    font-weight: inherit;
  }

  &__content {
    font-size: 1.25em;
    letter-spacing: 0.5px;
    text-align: center;
    line-height: 1.2;
  }
}
</style>
