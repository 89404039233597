import store from '@/store';
import { range } from 'lodash';

export const findAllPages = async (
  service,
  query = {},
  limit = 1000,
  qid = null
) => {
  const findAction = `${service}/find`;
  const serviceParams = {
    query: {
      ...query,
      $limit: limit,
    },
  };

  if (qid) {
    serviceParams.qid = qid;
  }

  const { data, total } = await store.dispatch(findAction, serviceParams);

  let resultsData = data;

  const pageCount = Math.ceil(total / limit);

  if (pageCount > 1) {
    const pageNumbers = range(1, pageCount);

    const requests = pageNumbers.map(pageNumber => {
      const pageParams = {
        query: {
          ...query,
          $skip: pageNumber * limit,
          $limit: limit,
        },
      };
      if (qid) {
        pageParams.qid = qid;
      }

      return store.dispatch(findAction, pageParams);
    });

    const additionalResults = await Promise.all(requests);

    resultsData = [
      ...resultsData,
      ...additionalResults.map(result => result.data).flat(),
    ];
  }

  return { data: resultsData, total };
};
