<template>
  <div class="calendar-view-container">
    <div class="no-padding">
      <div class="title-section">
        <h3 v-text="currentMonthTitle" />
        <div class="control-bar">
          <span @click="previousMonth"><img
            src="@/assets/images/icons/left-arrow.svg"
            class="image-icon"
          ></span>
          <span @click="nextMonth"><img
            src="@/assets/images/icons/right-arrow.svg"
            class="image-icon"
          ></span>
        </div>
      </div>
      <div id="calendar-inline-container" />
    </div>
    <div class="event-list-wrapper">
      <f7-list
        v-if="eventsOnSelectedDate.length > 0"
        media-list
        class="event-list"
      >
        <Event
          v-for="event in eventsOnSelectedDate"
          :key="event._id"
          :event="event"
        />
      </f7-list>
      <div
        v-else-if="!loading"
        class="empty-state"
      >
        <h2>{{ noEventsOnSelectedDateNotice }}</h2>
      </div>
      <div
        v-else
        class="display-flex justify-content-center align-items-center mt-35"
      >
        <f7-preloader />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import { findAllPages } from '@/helpers/pagination';

import Event from '@/components/event/Event';
export default {
  components: {
    Event,
  },
  data() {
    return {
      calendar: null,
    };
  },
  computed: {
    ...mapGetters('events', {
      findEventsInStore: 'find',
    }),
    ...mapGetters(['currentGroup', 'loading']),
    qid() {
      return this.currentGroup
        ? `eventsCalendar-${this.currentGroup._id}`
        : 'initEventsCalendar';
    },
    eventsQuery() {
      return {
        group: this.currentGroup._id,
        $sort: { start: 1 },
      };
    },
    selectedDateCurrentTime() {
      const date = this.selectedDate.format('YYYY-MM-DD');
      const time = moment().format('HH:mm:ss');

      return `${date}T${time}Z`;
    },
    noEventsOnSelectedDateNotice() {
      if (this.eventsOnSelectedDate.length > 0) return '';

      const todaySelected = this.selectedDate.isSame(moment(), 'day');

      return todaySelected
        ? 'No events today'
        : `No events on ${this.selectedDate.format('MMMM Do YYYY')}`;
    },
    currentMonthTitle() {
      return this.startOfCurrentMonth.format('MMMM YYYY');
    },
    selectedDate() {
      if (!this.calendar) return moment();

      // Get current date & time, timezone-naive
      const date = moment(this.calendar.value[0]).format('YYYY-MM-DD');
      const time = moment().format('HH:mm:ss');
      const selectedDate = moment(`${date}T${time}Z`);

      // Round to nearest 15 minutes.
      const roundTime = 15 - (selectedDate.minute() % 15);
      selectedDate.add(roundTime, 'minutes');
      this.$emit('selected-date', selectedDate);

      return selectedDate;
    },
    eventsInCalendar() {
      if (!this.currentGroup) return [];

      const { data } = this.findEventsInStore({
        paginate: false,
        query: this.eventsQuery,
        qid: this.qid,
      });

      return data;
    },
    startOfCurrentMonth() {
      if (!this.calendar) return moment().startOf('month');

      const datetime = moment(
        `${this.calendar.currentMonth + 1} ${this.calendar.currentYear}`,
        'M YYYY'
      ).startOf('month');

      return datetime;
    },
    eventsOnSelectedDate() {
      if (!this.calendar) return [];

      return this.eventsInCalendar.filter(event => {
        return moment(event.start).isSame(this.selectedDate, 'day');
      });
    },
    eventsInCalendarSerialized() {
      return this.eventsInCalendar.map(
        event =>
          new Date(
            moment(event.start).format('YYYY'),
            moment(event.start).format('M') - 1,
            moment(event.start).format('DD')
          )
      );
    },
  },
  watch: {
    currentGroup: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.fetchEvents();
        }
      },
    },
    eventsInCalendarSerialized() {
      this.updateCalendarEvents();
    },
  },
  mounted() {
    this.initCalendar();
  },
  beforeDestroy() {
    this.calendar.destroy('#calendar-inline-container');
  },
  methods: {
    initCalendar() {
      this.calendar = this.$f7.calendar.create({
        containerEl: '#calendar-inline-container',
        toolbar: false,
        value: [new Date()],
        firstDay: 0,
        events: this.eventsInCalendarSerialized,
      });

      this.calendar.on('monthYearChangeStart', () => {
        this.$store.commit('addLoadingAction', 'events-calendar-view');
      });

      this.calendar.on('monthYearChangeEnd', this.fetchEvents);
    },
    async fetchEvents() {
      this.$store.commit('addLoadingAction', 'events-calendar-view');

      const monthStart = this.startOfCurrentMonth.format('YYYY-MM-DD');
      let monthEnd = this.startOfCurrentMonth.clone();
      monthEnd = monthEnd.endOf('month').format('YYYY-MM-DD');

      await findAllPages(
        'events',
        {
          ...this.eventsQuery,
          start: {
            $gte: monthStart,
            $lte: monthEnd,
          },
        },
        50,
        this.qid
      );

      this.$store.commit('removeLoadingAction', 'events-calendar-view');
    },
    async fetchUpcoming() {
      this.$store.commit('addLoadingAction', 'events-calendar-view');
      await findAllPages('events', this.eventsQuery, 50, this.qid);
      this.$store.commit('removeLoadingAction', 'events-calendar-view');
    },
    updateCalendarEvents() {
      this.calendar.params.events = this.eventsInCalendarSerialized;
      this.calendar.update();
    },
    nextMonth() {
      this.calendar.nextMonth(700);
    },
    previousMonth() {
      this.calendar.prevMonth(700);
    },
  },
};
</script>
<style scoped lang="scss">
.calendar-view-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .title-section {
    display: flex;
    justify-content: space-between;
    background-color: color(white);

    h3 {
      margin-left: 1em;
      margin-top: 0.5em;
    }
    .control-bar {
      display: flex;
      align-items: center;
      span {
        height: 35px;
        width: 35px;
        margin-right: 0.8em;
        display: inline-block;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      img {
        height: 17px;
        width: 17px;
      }
    }
  }

  #calendar-inline-container {
    background-color: color(white);
    margin-top: -0.5em;
  }

  .event-list-wrapper {
    flex-grow: 1;
    overflow: auto;

    .event-list {
      list-style: none;
      margin-top: 0;
    }
  }
}

.empty-state {
  display: flex;
  justify-content: center;
  align-items: start;

  h2 {
    @media (max-width: 360px) {
      font-size: 1.2em;
    }
  }
}
</style>
