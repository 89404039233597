<template>
  <div class="card-content">
    <Avatar :user-id="comment.createdBy" />
    <div class="wrapper">
      <div
        class="main-content"
        :class="{ isFocused: isEditing }"
      >
        <textarea
          v-if="isEditing"
          v-model="message"
          class="edit-text"
          placeholder="What's on your mind?"
          autofocus
        />
        <div
          v-else
          class="text"
        >
          <span
            class="user-name"
            @click="openUserProfile(author._id)"
          >
            <UserDisplayName :user-id="comment.createdBy" />
          </span>
          <SanitizedText
            :text="comment.text"
            class="message-body"
          />
        </div>
        <!-- Only the user who create the comment can edit it -->

        <div
          v-if="isEditing"
          class="actionbar"
        >
          <f7-link
            id="btn-update"
            :class="{ isDisabled: !message.trim().length }"
            @click="updateComment"
          >
            Save
          </f7-link>
          <f7-link
            id="btn-delete"
            @click="deleteComment"
          >
            Delete
          </f7-link>
          <f7-link
            id="btn-cancel"
            @click="cancel"
          >
            Cancel
          </f7-link>
        </div>
        <div
          v-else
          class="actionbar"
        >
          <f7-button
            v-if="$can('update', comment)"
            class="editComment"
            @click="isEditing = !isEditing"
          >
            Edit
          </f7-button>
          <f7-button
            v-else
            class="likeComment"
            @click="likeComment"
          >
            <span v-if="isLiked">Unlike</span>
            <span v-else>Like</span>
          </f7-button>
          <f7-link
            v-if="comment.createdBy !== user._id"
            class="reportComment"
            :href="`/app/reportItem/comment:${comment._id}/`"
          >
            Report
          </f7-link>
          <span class="date"> {{ formatDateTime(comment.createdAt) }}</span>
        </div>
        <div
          v-if="likes.total && !isEditing"
          class="comment-likes-count"
        >
          <f7-icon f7="heart_fill" /> {{ likes.total }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { openUserProfile } from '@/mixins/helperFunctions';
import datesMixin from '@/mixins/dates';
import Avatar from '@/components/Avatar';
import showToastMixin from '@/mixins/showToast';
import { findAllPages } from '@/helpers/pagination';
import SanitizedText from '@/components/SanitizedText';
import UserDisplayName from '@/components/UserDisplayName';

export default {
  components: {
    Avatar,
    SanitizedText,
    UserDisplayName,
  },
  mixins: [datesMixin, showToastMixin, openUserProfile],
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      message: this.comment.text,
      isEditing: false,
      canEditComment: null,
      likeActionPending: false,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('likes', { findLikesInStore: 'find' }),
    ...mapGetters('users', { getUserInStore: 'get' }),
    author() {
      return this.getUserInStore(this.comment.createdBy);
    },
    isLikedQuery() {
      return {
        'ref._id': this.comment._id,
      };
    },
    isLiked() {
      return (
        this.findLikesInStore({
          query: {
            'ref._id': this.comment._id,
            createdBy: this.user._id,
            $limit: 0,
          },
        }).total > 0
      );
    },
    likes() {
      return this.findLikesInStore({
        paginate: false,
        query: this.isLikedQuery,
      });
    },
  },
  created() {
    findAllPages('likes', this.isLikedQuery);
  },
  methods: {
    ...mapActions('likes', {
      findLikes: 'find',
      createLike: 'create',
      removeLikes: 'remove',
    }),
    ...mapActions('comments', {
      patchComment: 'patch',
      removeComment: 'remove',
    }),
    likeComment() {
      if (this.likeActionPending) return;
      this.likeActionPending = true;
      if (this.isLiked) {
        return this.removeLikes([
          null,
          {
            query: {
              'ref._id': this.comment._id,
              createdBy: this.user._id,
            },
          },
        ])
          .then(() => {
            this.likeActionPending = false;
          })
          .catch(() => {
            this.showToast('Error removing like');
            this.likeActionPending = false;
          });
      } else {
        return this.createLike([
          {
            ref: {
              kind: 'Comment',
              _id: this.comment._id,
            },
          },
        ])
          .then(() => {
            this.likeActionPending = false;
          })
          .catch(() => {
            this.showToast('Error liking comment');
            this.likeActionPending = false;
          });
      }
    },
    updateComment() {
      if (this.message.trim().length === 0) return;
      this.patchComment([
        this.comment._id,
        {
          text: this.message,
        },
      ])
        .then(() => {
          this.showToast('Comment updated');
          this.isEditing = !this.isEditing;
        })
        .catch(() => {
          this.showToast('Error updating comment');
        });
    },
    deleteComment() {
      this.removeComment([this.comment._id]).catch(() => {
        this.showToast('Error deleting comment');
      });
    },
    cancel() {
      if (this.message.trim().length === 0) {
        this.message = this.comment.text;
      }
      this.isEditing = !this.isEditing;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.5em;
  img {
    border: 1px solid transparent;
    border-radius: 100px;
    height: 50px;
    width: 50px;
  }
}
.wrapper {
  width: 100%;
}
.main-content {
  background-color: darken(color(bg-color), 3%);
  border: 1px solid transparent;
  margin-left: 0.3em;
  border-radius: 10px;
  padding-left: 0.5em;
  padding-right: 0.5em;
  position: relative;
  .text {
    margin-top: 0em;
    .message-body {
      margin-top: 0.3em;
      color: lighten(color(text-color), 20%);
      width: 100%;
      word-break: break-word;
    }
  }
  .edit-text {
    padding-top: 3px;
    width: 100%;
    min-height: 4.5em;
  }
  .user-name {
    font-size: 1em;
    font-weight: bold;
    color: color(text-color);
  }
}
.actionbar {
  font-size: 0.8em;
  color: color(blue);
  margin-top: 0.7em;
  margin-bottom: 0.7em;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > * {
    font-size: 0.9em;
    height: auto;
    line-height: 1;
    min-width: auto;
    padding: 0 6px;
  }

  > a,
  > button {
    text-transform: uppercase;
    color: color(blue);
  }

  .date {
    color: lighten(color(text-color), 35%);
  }

  .reportComment {
    color: color(red);
  }
}

.comment-likes-count {
  position: absolute;
  bottom: -0.5rem;
  right: 0.5rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-color: #eee;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.5em;
  font-size: 0.7em;
  padding: 0.2em 0.4em;
  i {
    font-size: 0.7em;
    color: #ec6b70;
  }
}

#btn-update {
  margin-right: 0.6em;
}
#btn-cancel {
  color: color(light-blue);
}
#btn-delete {
  float: right;
  margin-right: 1em;
}
.isFocused {
  border: 1px solid color(blue);
  padding: 0.5em;
}
.isDisabled {
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}
</style>
