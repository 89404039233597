<template>
  <f7-page>
    <div class="blurBgImage" />
  </f7-page>
</template>
<script>
  import logoutMixin from '@/mixins/logout';
  export default {
    mixins: [logoutMixin],
    mounted() {
      this.showNoGroupError();
    },
    methods: {
      showNoGroupError() {
        const title = 'Group membership is required';
        const text =
          'You are currently not in any groups. Please contact your program administrator.';
        this.$f7.dialog.alert(text, title, () => {
          this.logout();
        });
      },
    },
  };
</script>
<style>
  .blurBgImage {
    top: 0;
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-image: url('../assets/images/blurBgImage.jpg');
    background-size: cover;
  }
</style>
