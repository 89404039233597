import feathersClient, { makeServicePlugin, BaseModel } from '../../api/feathers-client';

class Behavior extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = 'Behavior';

  static setupInstance(data, { store, models }) {
    data.createdByData = new models.api.User(data.createdByData);
    data.targetBehaviorData = new models.api.Targetbehavior(data.targetBehaviorData);
    data.clientData = new models.api.Client(data.clientData);
    return data;
  }
}
const servicePath = 'v2/behaviors';
const servicePlugin = makeServicePlugin({
  Model: Behavior,
  service: feathersClient.service(servicePath),
  servicePath,
  addOnUpsert: true,
  multi: true,
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
