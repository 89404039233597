<template>
  <div>
    <ResetPasswordForm
      v-if="resetPwActive"
      @passwordResetSent="resetPwActive = false"
    />
    <LoginForm
      v-else
      :error-message="errorMessage"
      @login="data => $emit('login', data)"
    />
    <div
      id="below-form-links"
      class="display-flex justify-content-center"
    >
      <f7-button @click="resetPwActive = !resetPwActive">
        <span v-if="resetPwActive">Back to Login</span>
        <span v-else>Reset Password</span>
      </f7-button>
    </div>
  </div>
</template>
<script>
import LoginForm from './LoginForm';
import ResetPasswordForm from './ResetPasswordForm';
import { mapState } from 'vuex';

export default {
  components: {
    LoginForm,
    ResetPasswordForm,
  },
  props: {
    errorMessage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      resetPwActive: false,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
  },
};
</script>
