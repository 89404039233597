<template>
  <BaseContentCard
    :author="author"
    :date="date"
    :banner-text="bannerText"
    :banner-text-color="item.critical ? '#fff' : '#333'"
    :banner-bg-color="item.critical ? '#EF4422' : '#BBDA6B'"
  >
    <Avatar
      slot="avatar"
      :user-id="item.createdBy"
    />
    <div class="sn-content">
      <SanitizedText :text="item.notes" />
    </div>
    <MenuButton
      v-if="isCordova"
      slot="banner-actions"
      @click="openModal"
    />
  </BaseContentCard>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import BaseContentCard from '@/components/BaseContentCard';
  import Avatar from '@/components/Avatar';
  import MenuButton from '@/components/MenuButton';
  import SanitizedText from '../SanitizedText.vue';

  export default {
    components: { BaseContentCard, Avatar, MenuButton, SanitizedText },
    props: {
      item: {
        type: Object,
        required: true,
      },
    },
    computed: {
      ...mapGetters('users', {
        getUserInStore: 'get',
      }),
      ...mapGetters(['isCordova']),
      user() {
        return this.getUserInStore(this.item.createdBy);
      },
      author() {
        return this.user ? `${this.user.firstName} ${this.user.lastName}` : 'Unknown User';
      },
      date() {
        return new Date(this.item.createdAt);
      },
      bannerText() {
        return `SHIFT NOTE: <strong>${this.item.critical ? 'CRITICAL' : 'NOT CRITICAL'}</strong>`;
      },
    },
    created() {
      this.getUser(this.item.createdBy);
    },
    methods: {
      ...mapActions('users', {
        getUser: 'get',
      }),
      async openModal() {
        const data = Object.assign({}, this.item);
        data.type = 'shiftNote';
        await this.$store.dispatch('setmodifyItemData', data);

        this.$f7.sheet.open('#actionModal');
      },
    },
  };
</script>
<style scoped>
  .sn-content {
    padding: 16px;
  }
</style>
