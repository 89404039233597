import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters('users', {
      getUserById: 'get',
    }),
    filteredParticipants() {
      if (!this.conversation) {
        return [];
      }
      const participants = this.conversation.participants || [];
      return participants.filter((p) => {
        if (p instanceof Object) {
          return p._id !== this.user._id;
        } else {
          return p !== this.user._id;
        }
      });
    },
    title() {
      if (!this.filteredParticipants) return;
      if (this.filteredParticipants.length === 1) {
        if (this.filteredParticipants[0] instanceof Object) {
          return `${this.filteredParticipants[0].firstName} ${this.filteredParticipants[0].lastName}`;
        } else {
          return `${this.getUserById(this.filteredParticipants).firstName} ${
            this.getUserById(this.filteredParticipants).lastName
          }`;
        }
      }
      return this.filteredParticipants.map((p) => p.firstName).join(', ');
    },
    time() {
      return this.conversation.createdAt;
    },
  },
};
