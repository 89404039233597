<template>
  <div class="post fake-post">
    <f7-card class="post-item">
      <f7-card-header class="header headr-topper-post">
        <Avatar
          :user-id="author._id"
          :is-site-admin="true"
        />

        <div class="user">
          <div class="user-name">
            {{ author.firstName + ' ' + author.lastName }}
          </div>
          <div class="info">
            <span class="date">
              <img src="@/assets/images/icons/clock.svg">
              <span v-text="formatDateTime(createdAt)" /></span>
          </div>
        </div>
      </f7-card-header>

      <f7-card-content class="content">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p
          class="content__text"
          v-html="contentHtml"
        />
      </f7-card-content>
    </f7-card>
  </div>
</template>

<script>
  import datesMixin from '@/mixins/dates.js';
  import Avatar from '@/components/Avatar';

  export default {
    components: {
      Avatar,
    },
    mixins: [datesMixin],
    props: {
      createdAt: {
        type: String,
        required: true,
      },
      author: {
        type: Object,
        default: () => ({
          _id: null,
          firstName: 'Navigating Life',
          lastName: 'Admin',
        }),
      },
      headline: {
        type: String,
        required: true,
      },
      body: {
        type: String,
        required: true,
      },
    },
    computed: {
      contentHtml() {
        return `<b>${this.headline}</b>
          <br />
          <br />
          ${this.body}`;
      },
    },
  };
</script>

<style scoped lang="scss">
  .image-container {
    height: 50px;
    width: 50px;
    border: 2px solid color(gray);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 46px;
      width: 46px;
    }
  }

  .post {
    color: color(text-color);
    .post-item {
      margin-bottom: 1.5em;
      display: block;
      border: 0px solid transparent;
      border-radius: 5px;
    }
    .header {
      display: flex;
      justify-content: flex-start;
      &.headr-topper-post {
        border-top: 5px solid color(green);
        border-radius: 5px;
      }
      .user {
        display: flex;
        flex-direction: column;
        margin-left: 1em;
        padding: 5px 0 5px 0;
        .tool {
          position: absolute;
          right: 20px;
          top: 6px;
          color: #333;
          padding: 10px;
        }
      }
      .user-name {
        font-size: 0.95em;
        font-weight: bold;
      }
      .info {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
      }
    }

    .content {
      padding: 0;
    }
    .content__image {
      width: 100%;
    }
    .content__text {
      width: 90%;
      margin: auto;
      padding-bottom: 1em;
      margin-top: 1em;
      word-break: break-word;
    }

    .date,
    .location {
      color: gray;
      font-size: 0.6em;
      display: flex;
      align-items: center;
      padding: 1px;
      img {
        width: 12px;
        height: 12px;
        margin-right: 0.4em;
      }
    }
    .like-list {
      .chip {
        background-color: color(blue);
        color: color(white);
        margin-right: 5px;
      }
    }
    .btn-likes,
    .btn-comments {
      border: none;
      .icon-holder {
        border: 1px solid color(gray);
        border-radius: 100%;
        height: 28px;
        width: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon-holder__icon {
          width: 16px;
          height: 16px;
        }
      }
    }
    .btn-likes {
      .icon-holder__icon {
        color: color(red);
      }
    }
    .post-footer {
      height: 3em;
      margin-top: 1em;
      display: flex;
      align-items: center;
      .likes-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .comments-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .indicator {
        display: flex;
        justify-content: center;
        align-content: center;
        margin-left: -1em;
        border: 1px solid color(light-gray);
        background-color: color(light-gray);
        color: color(text-color);
        margin-top: -6px;
        border-radius: 30px;
        width: 28px;
        height: 19px;
        padding-top: 1px;
        font-size: 0.8em;
      }

      .indicator-like:before {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        left: 65px;
        top: 17px;
        border-style: solid;
        border-width: 4px 5px 4px 0;
        border-color: transparent color(light-gray) transparent transparent;
      }

      .indicator-comment:before {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        right: 44px;
        top: 17px;
        border-style: solid;
        border-width: 4px 5px 4px 0;
        border-color: transparent color(light-gray) transparent transparent;
      }
    }
    .card-footer:before {
      display: none;
    }
  }
  // media layout
  .medias-container {
    width: 90vw;
    @media (min-width: 1024px) {
      width: 63vw;
    }
    @media (min-width: $large_screen_width) {
      max-width: calc(1366px / 2 * 0.9);
      width: 100%;
    }
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    .media-holder {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      .videoPlayIcon {
        position: absolute;
        @media (min-width: 1024px) {
          width: 6vw;
        }
        @media (min-width: $large_screen_width) {
          width: 3vw;
        }
        width: 14vw;
      }
    }
    .media-holder:first-child {
      width: inherit;
      height: 68vw;
      margin-bottom: 1vw;
      @media (min-width: 1024px) {
        height: 47.6vw;
        margin-bottom: 0.7vw;
      }
      @media (min-width: $large_screen_width) {
        height: 360px;
        margin-bottom: 1%;
      }
      .videoPlayIcon {
        width: 17vw;
        @media (min-width: 1024px) {
          width: 10vw;
        }
        @media (min-width: $large_screen_width) {
          width: 7vw;
        }
      }
    }
    .media-holder:nth-child(2),
    .media-holder:nth-child(3),
    .media-holder:nth-child(4) {
      width: 29.3vw;
      margin-left: 1vw;
      height: 22vw;
      @media (min-width: 1024px) {
        width: 20.5vw;
        margin-left: 0.7vw;
        height: 15.4vw;
      }
      @media (min-width: $large_screen_width) {
        width: 32.5%;
        height: 150px;
        margin-left: 1%;
      }
    }
    .media-holder:nth-child(2) {
      margin-left: 0;
    }
    .overlay {
      opacity: 0.75;
      background-color: darken(color(blue), 15%);
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      bottom: 0;
      margin-top: -22vw;
      height: 22vw;
      width: 29.3vw;
      left: 60.6vw;
      @media (min-width: 1024px) {
        margin-top: -15.4vw;
        height: 15.4vw;
        width: 20.5vw;
        left: 42.4vw;
      }
      @media (min-width: $large_screen_width) {
        margin-top: -150px;
        width: 32.5%;
        height: 150px;
        left: 67%;
      }
      .text {
        color: color(white);
        font-size: 2em;
        @media (min-width: 1024px) {
          font-size: 2.5em;
        }
      }
    }
  }
  .two-medias {
    .media-holder:first-child,
    .media-holder:last-child {
      width: 44vw;
      height: 34vw;
      margin-right: 1vw;
      margin-bottom: 0;
      @media (min-width: 1024px) {
        width: 30.8vw;
        height: 23.8vw;
        margin-right: 0.7vw;
      }
      @media (min-width: $large_screen_width) {
        width: 49%;
        height: 200px;
        margin-right: 1%;
      }
    }
    .media-holder:last-child {
      margin-right: 0;
    }
  }
  .three-medias {
    .media-holder:nth-child(2),
    .media-holder:nth-child(3) {
      width: 44vw;
      height: 34vw;
      margin-right: 1vw;
      @media (min-width: 1024px) {
        width: 30.8vw;
        height: 23.8vw;
        margin-right: 0.7vw;
      }
      @media (min-width: $large_screen_width) {
        width: 49%;
        height: 200px;
        margin-right: 1%;
      }
    }
    .media-holder:nth-child(2) {
      margin-right: 0;
    }
  }
  .tagging {
    font-weight: bold;
  }
  .btn-edit-post {
    border: none;
    background: none;
    outline: none;
  }
</style>
