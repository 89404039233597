<template>
  <f7-toolbar
    id="tab-nav"
    tabbar
    bottom-md
    labels
    class="tablet-view-toolbar"
  >
    <f7-link
      v-for="tab in authorizedTabs"
      :key="tab.label"
      tab-link
      :href="tab.route"
      :tab-link-active="currentRouteUrl === tab.route"
      @click="logEvent(`Opened ${tab.label}`)"
    >
      <img :src="tab.imgOnActive">
      <span>{{ tab.label }}</span>
    </f7-link>
  </f7-toolbar>
</template>
<script>
  import { mapState } from 'vuex';
  import analytics from '@/analytics';
  import tabs from '@/mixins/tabs';

  export default {
    mixins: [tabs],
    computed: {
      ...mapState('auth', ['user']),
    },
    methods: {
      logEvent(event) {
        analytics.track({
          userId: this.user._id,
          event,
        });
      },
    },
  };
</script>
<style scoped lang="scss">
  #tab-nav {
    background-color: color(white);
    a {
      color: color(white);
      font-size: 0.82em;
      font-weight: bold;
    }
    .tab-link {
      span {
        text-transform: capitalize;
      }
      img {
        height: 18px;
        width: 18px;
      }
    }
  }
</style>
