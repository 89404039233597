<template>
  <f7-card
    v-if="$can('read', item)"
    class="behavior-item"
  >
    <f7-card-header class="header display-flex justify-content-space-between">
      <div class="display-flex align-items-center">
        <img
          src="@/assets/images/icons/lock.svg"
          class="image-icon-small"
        >
        <span>ONLY VISIBLE TO YOU AND ADMINS</span>
      </div>
      <MenuButton @click="openEditBehaviorModal(item)" />
    </f7-card-header>
    <f7-card-content class="content">
      <div class="time-location">
        <span class="date"><img src="@/assets/images/icons/clock-black.svg">{{
          formatFeedItemDateTime(item.dateTime)
        }}</span>
        <span
          v-if="item.location && item.location.name"
          class="location"
        ><img src="@/assets/images/icons/location-black.svg">{{
          item.location.name
        }}</span>
      </div>
      <div
        v-if="createdBy"
        class="text"
      >
        <span>CREATED BY</span>
        <span class="data--client">
          {{ createdBy.firstName + ' ' + createdBy.lastName }}
        </span>
      </div>
      <div class="text">
        <span>CLIENT</span>
        <span class="data--client">
          {{
            item.clientRef
              ? `${client ? client.firstName + ' ' + client.lastName : ''}`
              : item.client
          }}</span>
      </div>
      <div class="text">
        <span>BEHAVIOR</span>
        <span class="data">
          {{
            item.targetBehavior
              ? targetBehavior
                ? targetBehavior.name
                : ''
              : item.behavior
          }}
        </span>
      </div>
      <div class="text">
        <div class="intensity">
          <span class="intensity-title">INTENSITY</span>
          <span
            v-if="item.intensity === 1"
            class="intensity-data intensity-data-low"
          >
            <span>LOW</span>
          </span>
          <span
            v-else-if="item.intensity === 2"
            class="intensity-data intensity-data-medium"
          >
            <span>MEDIUM</span>
          </span>
          <span
            v-else
            class="intensity-data intensity-data-high"
          >
            <span>HIGH</span>
          </span>
        </div>
        <div class="frequency">
          <span class="frequency-title">FREQUENCY</span>
          <span class="frequency-data">{{ item.frequency }} x</span>
        </div>
      </div>
      <div
        v-if="item.notes.length > 0"
        class="text notes"
      >
        <span class="title">NOTES</span>
        <SanitizedText
          :text="item.notes"
          class="data"
        />
      </div>
    </f7-card-content>
  </f7-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import datesMixin from '@/mixins/dates.js';
import MenuButton from '@/components/MenuButton';
import SanitizedText from '../SanitizedText';
export default {
  components: {
    MenuButton,
    SanitizedText,
  },
  mixins: [datesMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('clients', {
      getClientInStore: 'get',
    }),
    ...mapGetters('targetBehaviors', {
      getTargetBehaviorInStore: 'get',
    }),
    isCordova() {
      return this.$store.getters.isCordova;
    },
    targetBehavior() {
      if (this.item.targetBehavior) {
        return this.getTargetBehaviorInStore(this.item.targetBehavior);
      }
      return this.item.behavior;
    },
    client() {
      if (this.item.client) {
        return this.item.client;
      }

      return this.getClientInStore(this.item.clientRef);
    },
    createdBy() {
      if (this.item.createdByData) {
        return this.item.createdByData;
      }

      return this.getClientInStore(this.item.createdBy);
    },
  },
  methods: {
    openEditBehaviorModal(behavior) {
      const behaviorTypeData = Object.assign({}, behavior);
      behaviorTypeData.type = 'behavior';
      this.$store.commit('setmodifyItemData', behaviorTypeData);
      setTimeout(() => {
        this.$f7.sheet.open('#actionModal');
      }, 100);
    },
  },
};
</script>

<style scoped lang="scss">
.behavior-item {
  margin-bottom: 1.2em;
  display: block;
  border: 0px solid transparent;
  border-radius: 5px;
  color: color(text-color);
  .header {
    border-top: 0px solid transparent;
    border-radius: 5px 5px 0 0;
    background: color(orange);
    min-height: 35px;
    font-size: 0.87em;
    color: color(text-color);

    .image-icon-small {
      margin-right: 0.5em;
      padding-top: 1px;
    }
  }
  .content {
    padding: 0;
    width: 91.5%;
    margin: auto;
    padding-bottom: 1em;
    padding-top: 1em;
    word-break: break-word;
    .text {
      padding: 0.55em 0 0.55em 0;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ddd;
      font-weight: bold;
      font-size: 0.9em;
      .data {
        color: darken(darkgray, 20%);
      }
      .intensity {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        .intensity-title {
          margin-bottom: 0.5em;
        }
        .intensity-data {
          border: 1px solid green;
          width: 5em;
          text-align: center;
          border-radius: 15px;
          border: 0px solid transparent;
          color: color(white);
          padding: 3px 7px 3px 7px;
          font-size: 0.85em;
          &.intensity-data-low {
            background-color: color(green);
          }
          &.intensity-data-medium {
            background-color: color(orange);
          }
          &.intensity-data-high {
            background-color: color(red);
          }
        }
      }
      .frequency {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        .frequency-title {
          margin-bottom: 0.5em;
        }
        .frequency-data {
          margin-bottom: 0.5em;
          color: darken(darkgray, 20%);
        }
      }
    }
    .notes {
      color: darken(darkgray, 20%);
      flex-direction: column;
      .title {
        color: color(text-color);
      }
      .data {
        font-weight: normal;
      }
    }
    .time-location {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.3em;
    }
    .date,
    .location {
      font-size: 0.8em;
      display: flex;
      align-items: center;
      img {
        width: 13px;
        height: 13px;
        margin-right: 0.4em;
      }
    }
    .date {
      min-width: 35%;
    }
  }
}
</style>
