// src/feathers-client.js
import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import auth from '@feathersjs/authentication-client';
import io from 'socket.io-client';
import { iff, discard } from 'feathers-hooks-common';
import feathersVuex from 'feathers-vuex';
import { errorHandler } from './errorHandlerHook';
import store from '../store/index';

const socket = io(process.env.VUE_APP_API_URL, { transports: ['websocket'] });

const feathersClient = feathers()
  .configure(
    socketio(socket, {
      timeout: 30000,
    })
  )
  .configure(
    auth({
      storage: window.localStorage,
    })
  )
  .hooks({
    before: {
      all: [
        // context =>{
        //   console.log(`app.service('${context.path}').${context.method}`); 
        // },
        iff(
          context => ['create', 'update', 'patch'].includes(context.method),
          discard('__id', '__isTemp')
        ),
      ],
    },
    error: {
      all: [
context =>{if(context.error.code=== 408){
  // store.commit('setError', {description: 'The connection timed out. Please try again in a few moments or contact a system administrator for support.', title: 'Error', code: 408}); 
}}

      ],
    },
  });

export default feathersClient;

// Setting up feathers-vuex
const {
  makeServicePlugin,
  makeAuthPlugin,
  BaseModel,
  models,
  FeathersVuex,
} = feathersVuex(feathersClient, {
  serverAlias: 'api', // optional for working with multiple APIs (this is the default value)
  idField: '_id', // Must match the id field in your database table/collection
  whitelist: ['$regex', '$options', '$and'],
  paramsForServer: ['$client', '$aggregate', '$lookup'],
});

export { makeAuthPlugin, makeServicePlugin, BaseModel, models, FeathersVuex };
