import { retry } from '@/helpers/async';
import store from '@/store';

export const connectUser = async user => {
  const { VoxeetSDK } = window;
  if (!VoxeetSDK) return;

  const { accessToken, refreshToken } = await store.dispatch(
    'videoConversationAuthorizations/create',
    {
      type: 'initial',
    }
  );

  await VoxeetSDK.initializeToken(accessToken, async () => {
    const { accessToken } = await store.dispatch(
      'videoConversationAuthorizations/create',
      {
        refreshToken,
        type: 'refresh',
      }
    );

    return accessToken;
  });

  await Promise.all([
    VoxeetSDK.appearMaximized(true),
    VoxeetSDK.defaultBuiltInSpeaker(true),
    VoxeetSDK.defaultVideo(true),
    VoxeetSDK.setAudio3DEnabled(true),
  ]);

  const userInfo = _makeUserInfo(user);
  await VoxeetSDK.connect(userInfo);
};

export const disconnect = async () => {
  const { VoxeetSDK } = window;

  try {
    await VoxeetSDK.disconnect();
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Failed to disconect from Dolbyio', err);
  }
};

export const createConference = async (user, conversationId, users) => {
  const { VoxeetSDK } = window;

  // Create videoConversation
  const videoConversation = await store.dispatch('videoConversations/create', {
    conversation: conversationId,
  });

  // Create conference on Dolbyio
  try {
    const dolbyioConference = await _runFlakeyFunction(user, VoxeetSDK.create, {
      alias: videoConversation.alias,
    });

    // Invite users to conference
    const userInfos = users.map(user => _makeUserInfo(user));
    await VoxeetSDK.invite(dolbyioConference.conferenceId, userInfos);

    return dolbyioConference.conferenceId;
  } catch (err) {
    // Failed to created Dolbyio conference, so remove associated videoConversation
    await store.dispatch('videoConversations/remove', videoConversation._id);

    throw err;
  }
};

export const joinConference = async (user, conferenceId) => {
  const { VoxeetSDK } = window;

  await _runFlakeyFunction(user, VoxeetSDK.join, conferenceId);
};

const _makeUserInfo = user => {
  const { UserInfo } = window;
  const userInfo = new UserInfo(
    user._id,
    `${user.firstName} ${user.lastName}`,
    user.avatarUrl ||
      'https://ucarecdn.com/87659028-3081-4ece-ae0c-f863c28c2030/person_round.png'
  );

  return userInfo;
};

const _runFlakeyFunction = (user, fn, ...params) => {
  // Run a given function 'fn' and retry up to 3 times upon failure.
  //  Before each retry: disconnet & reconnect to Dolbyio
  //  This is a workaround to reduce inconsistent failures of Dolbyio SDK functions

  return retry(3, _retryFlakeyFunction, user, fn, ...params);
};

const _retryFlakeyFunction = async (user, fn, ...params) => {
  try {
    const response = await fn(...params);
    return response;
  } catch (err) {
    await disconnect();
    await connectUser(user);

    throw err;
  }
};
