// Retry an async function fn(...args) a given number of times or until it completes successfully
export const retry = async (maxRetries, fn, ...args) => {
  let retries = 0;
  let done = false;
  let response = null;

  while (!done && retries < maxRetries) {
    retries += 1;

    try {
      /* eslint-disable-next-line no-await-in-loop */
      response = await fn(...args);
      done = true;
    } catch (err) {
      if (retries >= maxRetries) {
        throw err;
      }
    }
  }

  return response;
};
