<template>
  <f7-page class="notifications">
    <f7-navbar class="styled">
      <f7-nav-left v-if="isMobile" back-link="Back"></f7-nav-left>
      <f7-nav-left v-else
        ><f7-link class="icon-only" popup-close
          ><i class="icon icon-back"></i></f7-link
      ></f7-nav-left>
      <f7-nav-title>Notifications</f7-nav-title>
      <f7-nav-right>
        <f7-link
          :icon-f7="allRead ? 'check_round_fill' : 'check_round'"
          :icon-color="allRead ? 'green' : ''"
          @click="markAllRead"
        />
      </f7-nav-right>
    </f7-navbar>
    <NotificationsList />
    <ModifyFeedItemSheetModal />
    <EditItemModal />
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';
  import feathersClient from '@/api/feathers-client';
  import ModifyFeedItemSheetModal from '@/components/socialFeed/ModifyFeedItemSheetModal';
  import EditItemModal from '@/components/EditItemModal';
  import NotificationsList from '@/components/notifications/NotificationsList';
  import analytics from '@/analytics';

  export default {
    components: {
      NotificationsList,
      EditItemModal,
      ModifyFeedItemSheetModal,
    },
    computed: {
      ...mapGetters('users', {
        getUserFromStore: 'get',
      }),
      isMobile() {
        return this.$store.getters.isMobile;
      },
      allRead() {
        return this.$store.state.unreadNotificationCount === 0;
      },

      user() {
        return this.getUserFromStore(this.$store.state.auth.user._id);
      },
    },
    mounted() {
      analytics.track({
        userId: this.$store.state.auth.user._id,
        event: 'Opened notifications',
      });
    },
    methods: {
      markAllRead() {
        feathersClient.service('notifications').patch(
          null,
          { read: true },
          {
            query: {
              read: false,
              subscriber: this.user._id,
            },
          }
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  .np-border--fancy {
    height: 4px;
    width: 100%;
    background: url('../assets/images/nupath-border-bg.svg') repeat-x;
  }
  .notification-list {
    list-style: none;
    margin-top: 0.5rem;
    margin-bottom: 0;
    > li:not(:last-of-type) {
      border-bottom: 1px solid #eee;
    }
  }
  .loadMore {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-size: 14px;
    font-weight: bold;
  }
  .styled {
    height: 57px;
  }
</style>
