<template>
  <div>
    <div class="shortcut-grid">
      <template v-if="!showCustom">
        <button
          v-for="(interval, key) in intervals"
          :key="key"
          :class="{ selected: value.label === interval.label }"
          @click="confirm(interval)"
          v-text="interval.label"
        />
      </template>
      <button
        :class="{ selected: showCustom }"
        @click="toggleCustom"
      >
        Custom
      </button>
    </div>
    <div
      v-if="showCustom"
      class="custom"
    >
      <f7-list class="list no-hairlines-md">
        <f7-row>
          <f7-col>
            <f7-label class="centered-label">
              Start Date<span class="required-field">*</span>
            </f7-label>
            <f7-input
              type="date"
              :value="customInterval.range[0] || ''"
              required
              @input="$set(customInterval.range, 0, $event.target.value)"
            />
          </f7-col>
          <f7-col>
            <f7-label class="centered-label">
              End Date<span class="required-field">*</span>
            </f7-label>
            <f7-input
              type="date"
              :value="customInterval.range[1] || ''"
              required
              @input="$set(customInterval.range, 1, $event.target.value)"
            />
          </f7-col>
        </f7-row>
      </f7-list>
      <f7-button
        class="submit-button"
        type="submit"
        :disabled="!customRangeValid"
        @click="confirm(customInterval)"
      >
        Confirm Date
      </f7-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
const dateFormat = 'YYYY-MM-DD';

export default {
  name: 'DateFeedFilter',
  props: {
    value: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    pickerOptions: {
      type: Object,
      default: () => ({
        formatted: 'MMM DD',
        range: true,
        noShortcuts: false,
        noHeader: true,
        onlyDate: true,
        noValueToCustomElem: true,
        inline: true,
      }),
    },
  },
  data() {
    return {
      showCustom: false,
      customInterval: {
        label: 'Custom',
        range: [],
      },
      intervals: [
        {
          label: 'This Week',
          range: [
            moment()
              .startOf('week')
              .format(dateFormat),
            moment()
              .endOf('week')
              .format(dateFormat),
          ],
        },
        {
          label: 'Last Week',
          range: [
            moment()
              .subtract(1, 'week')
              .startOf('week')
              .format(dateFormat),
            moment()
              .subtract(1, 'week')
              .endOf('week')
              .format(dateFormat),
          ],
        },
        {
          label: 'Last 7 Days',
          range: [
            moment()
              .subtract(7, 'days')
              .format(dateFormat),
            moment().format(dateFormat),
          ],
        },
        {
          label: 'This Month',
          range: [
            moment()
              .startOf('month')
              .format(dateFormat),
            moment()
              .endOf('month')
              .format(dateFormat),
          ],
        },
        {
          label: 'Last Month',
          range: [
            moment()
              .subtract(1, 'month')
              .startOf('month')
              .format(dateFormat),
            moment()
              .subtract(1, 'month')
              .endOf('month')
              .format(dateFormat),
          ],
        },
        {
          label: 'Last 30 Days',
          range: [
            moment()
              .subtract(30, 'days')
              .format(dateFormat),
            moment().format(dateFormat),
          ],
        },
      ],
    };
  },
  computed: {
    customRangeSet() {
      return this.customInterval.range.length === 2;
    },
    customRangeProperlyFormed() {
      return (
        this.customInterval.range.every(date =>
          moment(date, dateFormat).isValid()
        ) &&
        moment(this.customInterval.range[0]).isSameOrBefore(
          this.customInterval.range[1]
        )
      );
    },
    customRangeValid() {
      return this.customRangeSet && this.customRangeProperlyFormed;
    },
  },
  watch: {
    value(newValue) {
      if (Object.keys(newValue).length === 0) {
        this.customInterval = {
          label: 'Custom',
          range: [],
        };
      }
    },
  },
  methods: {
    toggleCustom() {
      this.showCustom = !this.showCustom;
      this.$emit('input', {});
    },
    cancel() {
      this.confirm({ range: [] });
    },
    confirm(interval) {
      if (interval && interval.label !== 'Custom') {
        this.showCustom = false;
      }
      this.$emit('input', interval);
    },
  },
};
</script>
<style lang="scss" scoped>
.page-content__dates {
  padding: 1rem;
}

.shortcut-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.shortcut-grid button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  appearance: none;
  background: #fff;
  border: 2px solid #d3dae3;
  border-radius: 8px;
}

.shortcut-grid button:not(:last-of-type)::before {
  --aspect-ratio: 1 / 1;
  content: '';
  display: inline-block;
  width: 1px;
  height: 0;
  padding-bottom: calc(100% / var(--aspect-ratio));

  @media screen and (min-width: 768px) {
    --aspect-ratio: 1 / 2;
  }
}

.shortcut-grid button.selected {
  background: #d3dae3;
}

.shortcut-grid button:last-of-type {
  grid-column: span 3;
  padding: 2rem;
}

.custom {
  margin-top: 1rem;
}

.submit-button {
  appearance: none;
  border: none;
  text-transform: uppercase;
  border-radius: 5px;
  background-color: color(pink);
  color: color(white);
  font-weight: bold;
  cursor: pointer;
}

.centered-label {
  text-align: center;
}
</style>
