<template>
  <f7-page class="main-container">
    <f7-navbar class="userProfile-nav">
      <f7-nav-left>
        <f7-link v-if="!$store.state.profileUserId" back>
          <f7-icon f7="close" />
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Profile</f7-nav-title>
      <f7-nav-right v-if="!isProfileForCurrentUser">
        <f7-link @click="$refs.actionsGroup.open()">
          <f7-icon class="tool" f7="more" />
        </f7-link>
        <f7-actions ref="actionsGroup" class="actionsGroup">
          <f7-actions-group>
            <f7-actions-button color="red" @click="blockSelectedUser">
              Block this user
            </f7-actions-button>
            <f7-actions-button color="red" @click="reportSelectedUser">
              Report user
            </f7-actions-button>
            <f7-actions-button>Close</f7-actions-button>
          </f7-actions-group>
        </f7-actions>
      </f7-nav-right>
    </f7-navbar>
    <div
      v-if="viewingProfileFor"
      class="profile-wrapper"
      :class="{ 'profile-wrapper--scrolled': isFeedScrolled }"
    >
      <div>
        <div class="image-placeholder">
          <img v-if="avatarUrl" class="profile-avatar" :src="avatarUrl" />
          <img
            v-else
            src="@/assets/images/icons/photo.svg"
            class="image-icon-large"
          />
        </div>

        <div class="name" v-text="fullName" />
        <div v-if="title" class="user-title" v-text="title" />
        <div v-if="roleDisplay" class="mt-2 text-center">
          <b>{{ roleDisplay }}</b>
        </div>

        <div v-if="isCommunityMember">
          <ClientsTags :clients="viewingProfileFor.linkedClients" />
        </div>

        <BadgeList
          class="badges_list"
          :ids="viewingProfileFor.badges"
          :max="10"
          size="sm"
        />

        <f7-block v-if="!isProfileForCurrentUser" class="user-actions">
          <f7-row>
            <f7-col
              v-if="
                userCanCreateConversationWithParticipant(viewingProfileFor._id)
              "
              class="text-center"
            >
              <f7-link
                class="external clickToAction"
                icon-f7="paper_plane"
                @click="openMessages"
              >
                <span>Message</span>
              </f7-link>
            </f7-col>
            <f7-col v-if="viewingProfileFor.phone" class="text-center">
              <f7-link
                class="external clickToAction"
                icon-f7="phone_fill"
                href="#"
                @click="clickToCall(viewingProfileFor.phone)"
              >
                <span>Call</span>
              </f7-link>
            </f7-col>
            <f7-col v-if="viewingProfileFor.email" class="text-center">
              <f7-link
                class="external clickToAction"
                icon-f7="email"
                href="#"
                @click="clickToEmail(viewingProfileFor.email)"
              >
                <span>Email</span>
              </f7-link>
            </f7-col>
          </f7-row>
        </f7-block>
      </div>

      <InfiniteListPage
        v-if="viewingProfileFor"
        ref="userPostsList"
        service="posts"
        :qid="`profile-${viewingProfileFor._id}`"
        :query="postQuery"
        end-of-list-message="Nothing more to see here."
        empty-list-message=""
        :page-size="viewingProfileFor ? 10 : 0"
        @scroll="e => (infiniteListScrollTop = e.srcElement.scrollTop)"
      >
        <template slot="default" slot-scope="{ items }">
          <PostContent v-for="item in items" :key="item._id" :item="item" />
        </template>
      </InfiniteListPage>

      <f7-toolbar
        v-if="isProfileForCurrentUser"
        ref="toolbar"
        :bottom-md="isMobile"
        class="edit-profile-toolbar"
      >
        <f7-link
          icon-f7="edit"
          class="btn-edit-profile"
          @click="$f7.popup.open('#editProfile')"
        >
          Edit Profile & Manage Settings
        </f7-link>
      </f7-toolbar>
    </div>
    <div>
      <EditProfilePopup
        v-if="$can('update', currentUser) && currentUser === viewingProfileFor"
        :user="currentUser"
      />
      <!-- The below need to be clean up as we discussed on Friday Morning -->
      <div v-if="isOnUserProfile">
        <CommentsSheetModal :margin-bottom-zero="true" />
        <LikesSheetModal :margin-bottom-zero="true" />
        <ModifyFeedItemSheetModal />
        <MediaGallery />
        <EditItemModal />
      </div>
    </div>
  </f7-page>
</template>
<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
import { rolesDisplay, serviceRoles } from '@/helpers/user-roles';
import EditProfilePopup from './EditProfilePopup';
import moment from 'moment';
import { clickToCallAndEmail } from '@/mixins/helperFunctions';
import { permissions } from '@/mixins/user';
import feathersClient from '@/api/feathers-client';
import showToast from '@/mixins/showToast';
import InfiniteListPage from '@/components/InfiniteListPage';
import startConversation from '@/mixins/startConversation';
import CommentsSheetModal from '@/components/socialFeed/CommentsSheetModal';
import MediaGallery from '@/components/socialFeed/MediaGallery.vue';
import ModifyFeedItemSheetModal from '@/components/socialFeed/ModifyFeedItemSheetModal';
import EditItemModal from '@/components/EditItemModal';
import LikesSheetModal from '@/components/socialFeed/LikesSheetModal.vue';
import BadgeList from '@/components/BadgeList.vue';
import analytics from '@/analytics';
import { mediaTransformationUrl } from '@/helpers/media/remote';
import PostContent from '@/components/socialFeed/PostContent';
import ClientsTags from './ClientsTags.vue';
import { roleKeys } from '../../helpers/user-roles';

export default {
  components: {
    EditProfilePopup,
    LikesSheetModal,
    CommentsSheetModal,
    ModifyFeedItemSheetModal,
    MediaGallery,
    EditItemModal,
    InfiniteListPage,
    PostContent,
    BadgeList,
    ClientsTags,
  },
  mixins: [permissions, showToast, clickToCallAndEmail, startConversation],
  data() {
    return {
      linkToSendMessage: null,
      sendingVerification: false,
      verificationSent: false,
      userFeedOpen: false,
      showPreloader: false,
      infiniteListScrollTop: 0,
    };
  },
  computed: {
    ...mapState('auth', {
      currentUser: 'user',
    }),
    ...mapGetters('users', {
      getUserInStore: 'get',
    }),
    ...mapGetters(['currentGroup']),
    viewingProfileFor() {
      const currentUser = this.$store.state.profileUserId
        ? this.getUserInStore(this.$store.state.profileUserId)
        : this.getUserInStore(this.$f7route.params.userId);
      return currentUser;
    },
    isFeedScrolled() {
      return this.infiniteListScrollTop > 0;
    },
    fullName() {
      return `${this.viewingProfileFor.firstName} ${this.viewingProfileFor.lastName}`;
    },
    role() {
      if (!this.viewingProfileFor.organizations) return '';
      if (this.viewingProfileFor.role === 'superAdmin')
        return this.viewingProfileFor.role;
      let contextOrganization;
      if (this.currentGroup) {
        contextOrganization = this.viewingProfileFor.organizations.find(
          org => org._id === this.currentGroup.organization
        );
      } else {
        contextOrganization = this.viewingProfileFor.organizations.find(
          org => org.isPrimary
        );
      }

      return contextOrganization ? contextOrganization.role : '';
    },
    isCommunityMember() {
      return this.role === roleKeys.limited || this.role === roleKeys.regular;
    },
    roleDisplay() {
      return rolesDisplay[this.role] || '';
    },
    title() {
      return serviceRoles.includes(this.role)
        ? this.viewingProfileFor.title
        : null;
    },
    isProfileForCurrentUser() {
      return this.currentUser === this.viewingProfileFor;
    },
    currentRoute() {
      return this.$f7router.currentRoute;
    },
    isOnUserProfile() {
      return (
        this.currentRoute.path && this.currentRoute.path.includes('userProfile')
      );
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    avatarUrl() {
      if (!this.viewingProfileFor.avatarUrl) return;

      return mediaTransformationUrl(this.viewingProfileFor.avatarUrl, {
        scale_crop: '300x300/smart_faces',
      });
    },
    postQuery() {
      const newQuery = {
        $sort: { createdAt: -1 },
        hidden: { $ne: true },
        $aggregate: [
          {
            $lookup: {
              from: 'clients',
              localField: 'tags',
              foreignField: '_id',
              as: 'clients',
            },
          },
        ],
      };

      if (!this.isCommunityMember) {
        newQuery.createdBy = this.viewingProfileFor._id;
      } else {
        if (this.viewingProfileFor.linkedClients) {
          newQuery.tags = {
            $in: this.viewingProfileFor.linkedClients.map(client => client._id),
          };
        }
      }

      return newQuery;
    },
  },
  created() {
    this.getUser({
      query: {
        _id: this.$f7route.params.userId,
        $aggregate: [
          {
            $lookup: {
              from: 'clients',
              localField: '_id',
              foreignField: 'linkedUsers',
              as: 'linkedClients',
            },
          },
        ],
      },
    });
  },
  mounted() {
    let trackOptions = {};
    if (this.isProfileForCurrentUser) {
      trackOptions = {
        event: 'Opened own profile',
      };
    } else if (this.viewingProfileFor) {
      trackOptions = {
        event: 'Opened another users profile',
        properties: {
          userId: this.viewingProfileFor._id,
        },
      };
    }
    analytics.track({
      userId: this.$store.state.auth.user._id,
      ...trackOptions,
    });
  },
  methods: {
    ...mapActions('users', {
      getUser: 'find',
    }),
    ...mapMutations(['reset']),
    resendVerification() {
      feathersClient
        .service('authManagement')
        .create({
          action: 'resendVerifySignup',
          value: {
            email: this.user.email,
          },
        })
        .then(() => {
          this.verificationSent = true;
          this.showToast('Please check your email for the verification.', 1500);
          this.sendingVerification = false;
        })
        .catch(() => {
          this.showToast('There was an error submitting your feedback.', 1500);
          this.sendingVerification = false;
        });
    },
    getYear(time) {
      const date = moment(time);
      return date.format('YYYY');
    },
    blockSelectedUser() {
      const text = 'Are you sure you want to block this user?';
      const title = 'Confirmation Required';
      this.$f7.dialog.confirm(text, title, async () => {
        try {
          this.$f7.dialog.preloader();
          await feathersClient.service('users').patch(this.currentUser._id, {
            $addToSet: {
              blocked: this.$f7route.params.userId,
            },
          });
          this.$f7.dialog.close();
          const text_alert =
            'You will not see any more content or receive any messages from this user.';
          const title_alert = 'User blocked successfully';
          this.$f7.dialog.alert(text_alert, title_alert, () => {
            this.reset();
            this.$f7router.navigate('/app/');
          });
        } catch (err) {
          this.$f7.dialog.close();
          this.$f7.dialog.alert(
            'Please try again. If the problem persists, contact support.',
            'Error blocking user'
          );
        }
      });
    },
    reportSelectedUser() {
      this.$f7router.navigate(
        `/app/reportItem/user:${this.$f7route.params.userId}/`
      );
    },
    async openMessages() {
      const conversation = await this.findOrCreateConversation([
        this.viewingProfileFor._id,
      ]);
      if (!this.isMobile) {
        this.$store.commit('setConversationId', conversation._id);
        setTimeout(() => {
          this.$f7.popup.open('#webview-messages');
        }, 300);
      } else {
        this.$f7router.navigate(`/app/conversations/${conversation._id}/`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-title {
  text-align: center;
}
.main-container {
  background: color(bg-color);
  @media (min-width: 1024px) {
    margin-top: 54px;
    padding-bottom: 56px;
  }
  .photo-wrapper {
    text-align: center;
    margin-bottom: 0;
    margin-top: 1.2em;
    img {
      border-radius: 100%;
    }
  }
  .name {
    text-align: center;
    padding: 0.5em 0 0.5em 0;
    font-weight: bold;
    font-size: 1.3em;
  }
  .badges_list {
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
  }
  .member-since {
    background: color(white);
    text-align: center;
    padding: 0.6em 0 0.6em 0;
    font-style: italic;
    font-size: 1.05em;
    font-weight: 500;
    color: darken(color(gray), 10%);
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }
  .userProfileForm {
    margin: 0;
    .name {
      text-align: center;
      width: 100%;
      font-weight: bold;
      font-size: 1.1em;
    }
    .contact {
      background: color(bg-color);
      font-weight: bold;

      .group-title {
        color: color(gray);
      }
    }
    .list-contact {
      width: 92%;
      margin: auto;
      background-color: color(white);
      font-weight: bold;
      border: 1px solid #ddd;
      .item-left {
        @media (max-width: 360px) {
          font-size: 0.8em;
        }
        color: color(text-color);
        margin-left: -1.5em;
      }
      .item-right {
        font-weight: 400;
        font-size: 0.7em;
        margin-right: 1.5em;
      }
      .staff-title {
        text-transform: capitalize;
      }
    }
  }
  .btn-editProfile {
    bottom: 5.2em;
    color: color(text-color);
    background: color(white);
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid darken(color(gray), 25%);
    img {
      margin-right: 0.5em;
    }
    // For Small phones
    @media (max-width: 360px) {
      position: relative;
      bottom: 0;
      margin-bottom: 1.5em;
    }
  }
  .np-border--fancy {
    height: 4px;
    width: 100%;
    background: url('../../assets/images/nupath-border-bg.svg') repeat-x;
    position: absolute;
  }
  .notVerified {
    color: color(white);
    font-weight: bold;
    background-color: color(red);
    line-height: 1.5;
    padding: 5px 10px 5px 10px;
    outline: none;
    border: none;
  }
  .edit-profile-toolbar {
    bottom: 0;
    min-height: 1.06 * $tab_height;
    background-color: color(blue);
    @media (min-width: 1024px) {
      top: calc(100vh - 104px);
    }
    .btn-edit-profile {
      margin: auto;
      font-weight: bold;
    }
  }
}
// Block user button
.actionsGroup {
  width: 96vw;
  touch-action: pan-y;
  left: 2vw !important;
  right: 2vw !important;
  @media (min-width: 1024px) {
    width: 50%;
    margin: auto;
    bottom: 0;
  }
  bottom: 2vw;
  margin: auto;
  height: auto;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 10px;
  .actions-button {
    border-bottom: 1px solid darken(color(gray), 10%);
    background-color: color(white);
    color: color(text-color);
    font-weight: bold;
    &:nth-of-type(3) {
      border-bottom: none;
    }
  }
}

.clickToAction {
  color: color(text-color);
  text-transform: uppercase;
}

.user-actions {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.profile-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .infinite-scroll-content {
    padding-top: 0 !important;
  }

  &--scrolled {
    .image-placeholder {
      width: 50px;
      height: 50px;
    }
  }
}
</style>
