<template>
  <div
    v-if="currentOrganization"
    class="page"
    :class="{ 'page--has-selected-users': selectedUsers.length }"
  >
    <f7-navbar>
      <f7-nav-left>
        <f7-link @click="cancel">
          <f7-icon f7="close" />
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ title }}</f7-nav-title>
      <f7-nav-right>
        <f7-link>
          <f7-button
            fill
            color="green"
            :disabled="!selectedUsers.length"
            @click="confirm"
          >
            {{ confirmButtonText }}
          </f7-button>
        </f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-searchbar
      ref="searchbar"
      @searchbar:disable="resetSearch"
      @input="updateSearchText"
    />
    <div v-if="selectedUsers.length" class="tag-list">
      <div
        v-for="(user, index) in selectedUsers"
        :key="user._id"
        class="chip"
        @click="selectedUsers.splice(index, 1)"
      >
        <div class="chip-label">
          {{ shortName(user) }}
        </div>
        <f7-link class="chip-delete" />
      </div>
    </div>
    <InfiniteListPage
      service="users"
      :query="usersQuery"
      :page-size="10"
      qid="selectUsersList"
      empty-list-message="No users found"
      :end-of-list-message="availableUsersNotice"
    >
      <template slot="default" slot-scope="{ items }">
        <div id="main">
          <f7-list v-if="searchText.length > 0" class="list-row-reverse">
            <f7-list-item
              v-for="user in searchresultUsers"
              :key="user._id"
              class="list-item"
            >
              <label class="item-checkbox item-content">
                <input
                  v-model="selectedUsers"
                  type="checkbox"
                  :value="user"
                  class="searchbar-found__item-checkbox"
                />
                <i class="icon icon-checkbox" />
                <div class="item-inner">
                  <div class="item-title">
                    {{ user.firstName }} {{ user.lastName }}
                  </div>
                </div>
              </label>
            </f7-list-item>
          </f7-list>

          <f7-list v-else class="list-row-reverse">
            <f7-list-item
              v-for="user in items"
              :key="user._id"
              class="list-item"
            >
              <label class="item-checkbox item-content">
                <input
                  v-model="selectedUsers"
                  type="checkbox"
                  :value="user"
                  class="searchbar-found__item-checkbox"
                />
                <i class="icon icon-checkbox" />
                <div class="item-inner">
                  <div class="item-title">
                    {{ user.firstName }} {{ user.lastName }}
                  </div>
                </div>
              </label>
            </f7-list-item>
          </f7-list>
        </div>
      </template>
    </InfiniteListPage>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { debounce } from 'lodash';
import InfiniteListPage from '@/components/InfiniteListPage';

export default {
  components: {
    InfiniteListPage,
  },
  props: {
    title: {
      type: String,
      default: 'Select Users',
    },
    confirmButtonText: {
      type: String,
      default: 'Confirm',
    },
    limitToCurrentGroup: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    clearOnConfirm: {
      type: Boolean,
      default: false,
    },
    excludeRoles: {
      type: Array,
      default: () => [],
    },
    excludeCurrentUser: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      perPage: 10,
      selectedUsers: [],
      totalUserCount: null,
      searchText: '',
      searchresultUsers: [],
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('users', {
      findUsersInStore: 'find',
    }),
    ...mapGetters(['currentGroup', 'currentOrganization']),
    canViewAllOrgUsers() {
      return this.$can('read', {
        modelName: 'users',
        organizations: [
          {
            _id: this.currentOrganization._id,
          },
        ],
      });
    },
    availableUsersNotice() {
      let notice = null;
      if (this.limitToCurrentGroup) {
        notice = 'You can only select users in the current group.';
      } else if (!this.canViewAllOrgUsers) {
        notice =
          'You can only select users that are in the same group(s) as you.';
      }

      return notice;
    },
    moreUsersToLoad() {
      if (this.searchText.length > 0) return false;

      return (
        this.totalUserCount === null || this.users.length < this.totalUserCount
      );
    },
    users() {
      return this.searchText.length > 0
        ? this.searchresultUsers
        : this.findUsersInStore({ paginate: false, query: this.usersQuery })
            .data;
    },
    usersQuery() {
      const query = {
        _id: {},
        $sort: { firstName: 1 },
        organizations: {
          $elemMatch: {
            _id: this.currentOrganization._id,
            role: { $nin: this.excludeRoles },
          },
        },
      };

      if (this.excludeCurrentUser) {
        query._id.$ne = this.user._id;
      }

      if (this.limitToCurrentGroup) {
        query._id.$in = [
          ...this.selectedUserIds,
          ...this.currentGroup.members.map(member => member._id),
        ];
      }

      return query;
    },
    selectedUserIds() {
      return this.selectedUsers.map(user => user._id);
    },
  },
  created() {
    this.searchUsersDebounced = debounce(this.searchUsers, 300);
    this.loadSelectedUsers();
  },
  methods: {
    ...mapActions('users', {
      findUsers: 'find',
    }),

    // Fetch Data
    async searchUsers() {
      if (this.searchText.length === 0) {
        return;
      }

      const { data } = await this.findUsers({
        query: {
          $or: [
            { firstName: { $search: this.searchText } },
            { lastName: { $search: this.searchText } },
          ],
          ...this.usersQuery,
        },
      });

      this.searchresultUsers = data;
    },
    async loadSelectedUsers() {
      const { data } = await this.findUsers({
        query: {
          _id: {
            $in: this.value,
          },
        },
      });

      this.selectedUsers = data;
    },

    // User Actions
    confirm() {
      this.resetSearch();
      this.closePopup();
      this.$emit('input', this.selectedUserIds);

      if (this.clearOnConfirm) {
        this.selectedUsers = [];
      }
    },
    cancel() {
      this.loadSelectedUsers();
      this.resetSearch();
      this.closePopup();
    },

    // Helpers
    resetSearch() {
      this.searchText = '';
      this.$refs.searchbar.disable();
    },
    closePopup() {
      this.$emit('close');
    },

    shortName(user) {
      return `${user.firstName} ${user.lastName.charAt(0).toUpperCase()}`;
    },

    updateSearchText(event) {
      this.searchText = event.target.value.trim();

      this.searchUsersDebounced();
    },
  },
};
</script>

<style lang="scss" scoped>
.inner {
  background: color(bg-color);
}
#main {
  .list-item {
    background: color(white);
    border-bottom: 1px solid #ddd;
    width: 90%;
    margin: auto;
    font-weight: bold;
  }
  .list-group-title {
    background: color(bg-color);
  }
}
.list {
  margin-top: 0;
}
.tag-list {
  margin-top: 0px;
  padding: 0.5em 0 0.5em 1em;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  background: lighten(color(light-blue), 22%);
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 12;
  top: 100px;

  .chip {
    background-color: color(blue);
    color: white;
    margin-right: 10px;
    margin-bottom: 5px;
    width: 29%;
    display: flex;
    justify-content: space-between;
  }
}
.list-row-reverse {
  .item-content {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
  }
}
.np-border--fancy {
  height: 4px;
  width: 100%;
  background: url('../assets/images/nupath-border-bg.svg') repeat-x;
  position: absolute;
  top: 0;
}
.subnavbar {
  border-top: 1px solid color(text-color);
}

.page.page--has-selected-users .tag-list ~ .page-content {
  padding-top: 156px;
}
</style>
