import axios from 'axios';

const myAxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
});

myAxiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('feathers-jwt');
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default myAxiosInstance;
