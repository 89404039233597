<template>
  <SwipeableModal
    v-show="post"
    class="main"
  >
    <div
      slot="toolbar-left"
      class="left"
    >
      <img :src="iconPath">
      <span>{{ title }}</span>
    </div>
    <div
      slot="toolbar-right"
      class="right"
    >
      <f7-link sheet-close>
        <img
          src="@/assets/images/icons/down-arrow.svg"
          height="15"
        >
      </f7-link>
    </div>
    <div slot="modal-content">
      <EmptyState
        v-if="!items.length"
        class="emptyState"
        :empty-message="emptyMessage"
        :empty-title="`No ${service}`"
      />
      <div
        v-if="items.length"
        ref="list"
        class="item-list"
      >
        <f7-button
          v-if="hasMore"
          class="loadMore"
          @click="loadMore"
        >
          Load previous
        </f7-button>
        <slot
          name="list-items"
          :items="items"
        />
      </div>
      <slot name="footer" />
    </div>
  </SwipeableModal>
</template>

<script>
  import { mapState } from 'vuex';
  import { capitalize } from 'lodash';
  import EmptyState from '@/components/EmptyState';
  import SwipeableModal from '@/components/SwipeableModal';
  import { findAllPages } from '@/helpers/pagination';

  export default {
    components: {
      EmptyState,
      SwipeableModal,
    },
    props: {
      service: {
        required: true,
        type: String,
      },
      icon: {
        required: true,
        type: String,
      },
      emptyMessage: {
        type: String,
        default: 'Add one below',
      },
    },
    data() {
      return {
        total: 0,
      };
    },
    computed: {
      ...mapState('auth', ['user']),
      title() {
        return capitalize(this.service);
      },
      iconPath() {
        return require(`@/assets/images/icons/${this.icon}`);
      },
      query() {
        if (!this.post) return {};
        const blockedUsers = this.user.blocked || [];
        return {
          'ref._id': this.post._id,
          hidden: {
            $ne: true,
          },
          createdBy: {
            $nin: blockedUsers,
          },
          $sort: {
            createdAt: 1,
          },
        };
      },
      items() {
        return this.$store.getters[`${this.service}/find`]({ paginate: false, query: this.query })
          .data;
      },
      post() {
        return this.$store.state.postObject;
      },
      hasMore() {
        return this.total > this.items.length && this.total > 10;
      },
      currentRoute() {
        return this.$f7router.currentRoute;
      },
      isOnUserProfile() {
        return this.currentRoute.path.includes('userProfile');
      },
    },
    watch: {
      post(val) {
        if (val) {
          this.init();
        }
      },
    },
    methods: {
      async findItems() {
        return await findAllPages(this.service, this.query);
      },
      async init() {
        const res = await this.findItems();
        this.total = res.total;
        setTimeout(() => {
          this.scrollToBottom();
        }, 100);
      },
      async loadMore() {
        const container = this.$refs.list;
        const scrollPostion = container.scrollHeight;
        await this.findItems();
        container.scrollTop = container.scrollHeight - scrollPostion;
      },
      scrollToBottom() {
        const container = this.$refs.list;
        if (!container) return;
        container.scrollTop = container.scrollHeight;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .item-list {
    overflow: auto;
    height: 45vh;
    margin-bottom: 7.5vh;
  }
  .loadMore {
    text-align: center;
    color: color(text-color);
    font-weight: bold;
    text-transform: none;
  }
</style>
