<template>
  <div
    v-if="images.length + videos.length > 0"
    class="medias-previews"
  >
    <h3 v-if="hasBothMediaTypes">
      PHOTOS
    </h3>
    <Draggable
      v-if="images.length > 0"
      :value="images"
      class="medias-container"
      draggable=".media-holder"
      fallback-tolerance="4"
      :disabled="!editable"
      @input="updateImages"
      @click.self="alert('clicked')"
    >
      <BaseImage
        v-for="(image, index) in imagesData"
        :key="image.thumbnailUrl"
        :src="image.thumbnailUrl"
        class="media-holder mb-1 mr-1"
        @click="openPhotoBrowser(image.url)"
      >
        <div
          v-if="editable"
          class="close-icon-overlay"
          @click="removeImage(index)"
        />
      </BaseImage>
    </Draggable>

    <h3
      v-if="hasBothMediaTypes"
      class="mt-35"
    >
      VIDEOS
    </h3>
    <Draggable
      v-if="videos.length > 0"
      :value="videos"
      class="medias-container"
      draggable=".media-holder"
      fallback-tolerance="4"
      :disabled="!editable"
      @input="updateVideos"
    >
      <div
        v-for="(video, index) in videosData"
        :key="video.url"
        class="media-holder mb-1 mr-1"
        :style="{ backgroundImage: `url(${video.thumbnailUrl})` }"
        @click.self="openPhotoBrowser(video.url)"
      >
        <div
          class="play-icon-overlay"
          @click.self="openPhotoBrowser(video.url)"
        />
        <div
          v-if="editable"
          class="close-icon-overlay"
          @click="removeVideo(index)"
        />
      </div>
    </Draggable>
  </div>
</template>
<script>
import Draggable from 'vuedraggable';
import { imageUriPreview, newVideoUriThumbnail } from '@/helpers/media/local';
const {
  videoThumbnailUrl: remoteVideoThumbnailUrl,
  mediaPreviewUrl,
} = require('@/helpers/media/remote');
import BaseImage from './BaseImage';

export default {
  components: {
    Draggable,
    BaseImage,
  },
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    videos: {
      type: Array,
      default: () => [],
    },
    editable: {
      type: Boolean,
      default: false,
    },
    browsable: {
      type: Boolean,
      default: true,
    },
    remoteSource: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      photoBrowser: null,
      photoBrowserOpen: false,
      videosData: [],
      imagesData: [],
    };
  },
  computed: {
    hasBothMediaTypes() {
      return this.images.length > 0 && this.videos.length > 0;
    },
    photoBrowserMedias() {
      const videoHtmls = this.videosData.map(video => ({
        url: video.url,
        html: `<video playsinline controls width="100%" height="100%" poster="${video.thumbnailUrl}">
                  <source src="${video.url}" type="video/mp4" />
                  <source src="${video.url}" type="video/ogg" />
                  <source src="${video.url}" type="video/webm" />
                </video>`,
      }));

      return [...this.imagesData, ...videoHtmls];
    },
  },
  watch: {
    photoBrowserMedias: {
      immediate: true,
      handler() {
        this.initPhotoBrowser();
      },
    },
    images: {
      immediate: true,
      async handler() {
        if (this.remoteSource) {
          this.imagesData = this.images.map(url => {
            const imageUrl = mediaPreviewUrl(url);

            return { thumbnailUrl: imageUrl, url: imageUrl };
          });
        } else {
          this.imagesData = await Promise.all(
            this.images.map(async item => {
              const extension = item
                .split('.')
                .pop()
                .toLowerCase();

              const url =
                extension === 'heic' ? await imageUriPreview(item) : item;

              return { thumbnailUrl: url, url };
            })
          );
        }
      },
    },
    videos: {
      immediate: true,
      async handler() {
        if (this.remoteSource) {
          this.videosData = this.videos.map(item => ({
            url: item,
            thumbnailUrl: remoteVideoThumbnailUrl(item),
          }));
        } else {
          this.videosData = await Promise.all(
            this.videos.map(async item => ({
              url: item,
              thumbnailUrl: await newVideoUriThumbnail(item),
            }))
          );
        }
      },
    },
  },
  methods: {
    initPhotoBrowser() {
      this.photoBrowser = this.$f7.photoBrowser.create({
        on: {
          open() {
            this.photoBrowserOpen = true;
          },
          closed() {
            this.photoBrowserOpen = false;
          },
        },
        photos: this.photoBrowserMedias,
      });
    },
    openPhotoBrowser(url) {
      if (this.photoBrowserOpen || !this.browsable) return;

      const index = this.photoBrowserMedias.findIndex(item => item.url === url);
      this.photoBrowser.open(index);
    },

    removeImage(index) {
      const images = [...this.images];
      images.splice(index, 1);

      this.updateImages(images);
    },
    removeVideo(index) {
      const videos = [...this.videos];
      videos.splice(index, 1);

      this.updateVideos(videos);
    },

    updateImages(newVal) {
      this.$emit('update:images', newVal);
    },
    updateVideos(newVal) {
      this.$emit('update:videos', newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
.medias-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;

  .media-holder {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: flex;
    position: relative;
    cursor: pointer;

    width: 100px;
    height: 75px;

    @media (min-width: $tablet_width) {
      height: 195px;
      width: 260px;
    }

    &:last-of-type {
      margin-right: 0;
    }

    .play-icon-overlay {
      position: absolute;
      left: 50%;
      top: 50%;

      margin-left: -20px;
      margin-top: -20px;

      width: 40px;
      height: 40px;

      background: url('../../../assets/images/icons/play-rounded-button.svg')
        no-repeat;
      background-size: cover;

      @media (min-width: $tablet_width) {
        height: 50px;
        width: 50px;
      }
    }

    .close-icon-overlay {
      position: absolute;
      top: 2px;
      right: 2px;

      width: 20px;
      height: 20px;

      background: url('../../../assets/images/icons/close-square.svg') no-repeat;
      background-size: cover;

      @media (min-width: $tablet_width) {
        height: 30px;
        width: 30px;
      }
    }
  }
}
</style>
