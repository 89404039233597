export default {
  methods: {
    showToast(text, closeTimeout = 1500, position = 'bottom') {
      return this.$f7.toast.show({
        text,
        closeTimeout,
        position,
      });
    },
  },
};
