<template>
  <div>
    <h1>Whoops, route not found...</h1>
    <p>No match found for {{ currentPath }}</p>
  </div>
</template>
<script>
  export default {
    computed: {
      currentPath() {
        return this.$f7route.url;
      },
    },
  };
</script>
