<template>
  <div v-if="hasCamera || hasMediaPicker">
    <div class="actions">
      <f7-button
        v-if="hasMediaPicker"
        class="cloud-upload"
        :disabled="uploading || mediaLength >= 8"
        @click="openNewMediaPicker('IMAGE')"
      >
        <img src="@/assets/images/icons/photo-upload.svg" class="image-icon" />
        <span>Photos</span>
      </f7-button>
      <f7-button
        v-if="hasMediaPicker"
        class="cloud-upload"
        :disabled="uploading || mediaLength >= 8"
        @click="openNewMediaPicker('VIDEO')"
      >
        <img src="@/assets/images/icons/video-upload.svg" class="image-icon" />
        <span>Video</span>
      </f7-button>
    </div>
    <div class="camera-row my-10">
      <f7-button v-if="hasCamera" class="photo-camera" :disabled="uploading || mediaLength >= 8" @click="openCamera">
        <img src="@/assets/images/icons/camera.svg" class="image-icon" />
        <span>Camera</span>
      </f7-button>
    </div>

    <f7-progressbar v-if="uploading" :infinite="true" class="my-10" />
    <p v-if="errorMessage" class="error-with-upload-file">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import AdvancedImagePicker from 'cordova-plugin-advanced-imagepicker';
import { compressImage } from '@/helpers/media/remote';
export default {
  name: 'MediasInput',
  props: {
    uploading: {
      type: Boolean,
      default: false,
    },
    images: {
      type: Array,
      default: () => [],
    },
    videos: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      errorMessage: null,
    };
  },
  computed: {
    hasCamera() {
      return !!window.Camera;
    },
    mediaLength() {
      return this.images.length + this.videos.length;
    },
    hasMediaPicker() {
      return !!AdvancedImagePicker.present;
    },
  },
  methods: {
    openCamera() {
      this.errorMessage = null;

      const options = {
        quality: 80,
        sourceType: window.Camera.PictureSourceType.CAMERA,
        destinationType: window.Camera.DestinationType.FILE_URI,
        allowEdit: false,
        correctOrientation: true,
        saveToPhotoAlbum: false,
      };

      navigator.camera.getPicture(
        imgUri => this.updateImages([...this.images, imgUri]),
        err => {
          console.log(err);
          this.errorMessage = 'Error adding image from camera. Please try again.';
        },
        options
      );
    },
    openNewMediaPicker(mediaType = 'IMAGE') {
      try {
        this.errorMessage = null;

        const options = {
          mediaType,
          max: 8 - this.mediaLength, //default 40 (Optional)
        };
        const plugin = AdvancedImagePicker || window.AdvancedImagePicker;

        plugin.present(
          options,
          success => {
            const localImages = [];
            const localVideos = [];
            success.forEach(element => {
              if (mediaType.toLowerCase() === 'image') {
                localImages.push(element.src);
              } else localVideos.push(element.src);
            });

            this.updateImages([...this.images, ...localImages]);
            this.updateVideos([...this.videos, ...localVideos]);
          },
          error => {
            console.log(err);
            console.log('error');
            console.error(error);
            this.errorMessage = 'Error adding media from library. Please try again.';
          }
        );
      } catch (err) {
        console.log(err);
        console.log('error');
      }

      console.log('end');
    },
    openMediaPicker() {
      this.errorMessage = null;

      const options = {
        selectMode: 101, //101=picker image and video , 100=image , 102=video
        maxSelectCount: 40, //default 40 (Optional)
        maxSelectSize: 41943040, //40mb (Optional)
      };

      window.MediaPicker.getMedias(
        options,
        localMedias => {
          const localImages = localMedias.filter(item => item.mediaType === 'image').map(item => item.uri);

          const localVideos = localMedias.filter(item => item.mediaType === 'video').map(item => item.uri);

          this.updateImages([...this.images, ...localImages]);
          this.updateVideos([...this.videos, ...localVideos]);
        },
        err => {
          this.errorMessage = 'Error adding media from library. Please try again.';
        }
      );
    },
    updateImages(newVal) {
      this.$emit('update:images', newVal);
    },
    updateVideos(newVal) {
      this.$emit('update:videos', newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.photo-camera,
.cloud-upload {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 48%;
  background: color(white);
  font-weight: bold;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-transform: capitalize;
  cursor: pointer;

  img {
    margin-right: 0.6em;
  }

  @media (max-width: 355px) {
    font-size: 0.7em;
  }
}

.camera-row {
  width: 100%;
  .photo-camera {
    width: 100% !important;
  }
}

.error-with-upload-file {
  color: color(red);
  width: 100%;
  text-align: center;
}
</style>
