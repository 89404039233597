<template>
  <div class="content">
    <f7-icon v-bind="$props" />
    <div
      v-show="count"
      class="indicator"
    >
      {{ count }}
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      count: {
        type: Number,
        default: 0,
      },

      // All potential props from f7-icon https://framework7.io/vue/icon.html
      icon: {
        type: String,
        default: null,
      },
      f7: {
        type: String,
        default: null,
      },
      material: {
        type: String,
        default: null,
      },
      fa: {
        type: String,
        default: null,
      },
      ion: {
        type: String,
        default: null,
      },
      ios: {
        type: String,
        default: null,
      },
      md: {
        type: String,
        default: null,
      },
    },
  };
</script>
<style lang="scss" scoped>
  .content {
    margin-top: -4px;
  }
  .f7-icons {
    font-size: 29px;
    @media (max-width: 320px) {
      font-size: 22px;
    }
  }
  .indicator {
    color: color(white);
    width: 13px;
    height: 13px;
    background: color(red);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.4em;
    font-weight: bold;
    position: absolute;
    top: 11px;
    left: 28px;
    @media (max-width: 360px) {
      top: 16px;
      left: 24px;
      width: 9px;
      height: 9px;
    }
    @media (min-width: 1024px) {
      top: 14px;
      left: 28px;
    }
  }
</style>
