import { LogLevel, PublicClientApplication } from '@azure/msal-browser';
import Vue from 'vue';

const hostUrl = window.location.protocol + '//' + window.location.host;


// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
    authority: `https://login.microsoftonline.com/common`,
    redirectUri: hostUrl, 
    postLogoutRedirectUri: hostUrl,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          // case LogLevel.Error:
          //   console.error(message);
          //   return;
          // case LogLevel.Info:
          //   console.info(message);
          //   return;
          // case LogLevel.Verbose:
          //   console.debug(message);
          //   return;
          // case LogLevel.Warning:
          //   console.warn(message);
          //   break;
          default:
        }
      },
      logLevel: LogLevel.Verbose,
    },
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize()
Vue.prototype.$msal = msalInstance;


export const loginRequest = {
  scopes: ['User.Read'],
};
