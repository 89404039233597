<template>
  <f7-page class="main">
    <GroupInfoContent />
  </f7-page>
</template>
<script>
import GroupInfoContent from '@/components/GroupInfoContent';
export default {
  components: {
    GroupInfoContent,
  },
};
</script>
<style scoped lang="scss">
.main {
  background-color: color(bg-color);
}
</style>
