<template>
  <li
    ref="swipeout"
    class="accordion-item"
    :class="{ swipeout: $can('update', event) }"
    @swipeout:deleted="onDeleted"
    @swipeout:opened="swipeoutOpen = true"
    @swipeout:closed="swipeoutOpen = false"
  >
    <div class="item-content item-link" @click="showDetail = !showDetail">
      <div class="swipeout-content">
        <div class="item-media">
          <span class="date">{{ eventStart.format('D') }}</span>
          <span class="month">{{ eventStart.format('MMM') }}</span>
        </div>
        <div class="item-inner">
          <div class="event-preview">
            <div class="item-title">
              {{ event.title }}
            </div>
            <div class="item-subtitle event-date">
              <span v-if="!event.allDay">
                {{
                  eventStart.format('hh:mm A') +
                    ' - ' +
                    eventEnd.format('hh:mm A')
                }}
              </span>
              <span v-else>All Day</span>
              <span v-if="event.description">
                <img
                  v-if="!showDetail"
                  src="@/assets/images/icons/down-arrow-gray.svg"
                  class="image-icon-small"
                />
                <img
                  v-if="showDetail"
                  src="@/assets/images/icons/up-arrow.svg"
                  class="image-icon-small"
                />
              </span>
            </div>
            <div class="item-subtitle">
              {{ event.location.name }}
            </div>
          </div>
          <!-- Event Desc accordion -->
          <div v-if="event.description" class="accordion-item-content">
            <div class="block">
              <SanitizedText :text="event.description" />
            </div>
          </div>
        </div>
        <div class="display-flex align-items-center mr-2">
          <f7-link v-if="$can('manage', 'events')" @click="toggleSwipeout">
            <f7-icon
              class="cursor-pointer"
              color="gray"
              size="20px"
              f7="gear_fill"
            />
          </f7-link>
        </div>
      </div>
      <div class="swipeout-actions-right">
        <f7-link
          v-if="$can('update', event)"
          class="open-more-actions"
          @click="openEditItemModal"
        >
          Edit
        </f7-link>
        <f7-link
          v-if="$can('create', 'events')"
          class="open-more-actions"
          color="orange"
          @click="duplicate"
        >
          Duplicate
        </f7-link>
        <f7-link
          v-if="$can('delete', event)"
          deleted
          data-confirm-title="Confirmation Required"
          data-confirm="Are you sure to delete this event?"
          class="swipeout-delete"
        >
          Delete
        </f7-link>
      </div>
    </div>
  </li>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import showToastMixin from '@/mixins/showToast';
import moment from 'moment';
import SanitizedText from '../SanitizedText';

export default {
  components: { SanitizedText },
  mixins: [showToastMixin],
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDetail: false,
      swipeoutOpen: false,
      eventStart: moment.utc(this.event.start).local(),
      eventEnd: moment.utc(this.event.end).local(),
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters(['isCordova']),
  },
  methods: {
    ...mapActions('events', {
      removeEvent: 'remove',
      createEvent: 'create',
    }),
    onDeleted() {
      this.removeEvent([this.event._id])
        .then(() => {
          this.showToast('Event deleted Successfully');
        })
        .catch(() => {
          this.showToast('There was an error deleting this event.');
        });
    },
    duplicate() {
      const eventData = {
        ...this.event,
      };

      delete eventData._id;

      this.$f7.swipeout.close(this.$refs.swipeout);

      this.createEvent(eventData)
        .then(() => {
          this.showToast('Event duplicated Successfully');
        })
        .catch(() => {
          this.showToast('There was an error duplicating this event.');
        });
    },
    async openEditItemModal() {
      const eventData = {
        ...this.event,
        type: 'event',
      };

      await this.$store.dispatch('setmodifyItemData', eventData);
      this.$f7.popup.open('.editModal');

      if (this.swipeoutOpen) this.toggleSwipeout();
    },
    toggleSwipeout() {
      if (this.swipeoutOpen) {
        this.$f7.swipeout.close(this.$refs.swipeout);
      } else {
        this.$f7.swipeout.open(this.$refs.swipeout);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.accordion-item {
  margin-top: 0.7em;
  margin-bottom: 0.7em;
}
.item-content {
  padding-left: 0 !important;
  .swipeout-content {
    display: flex;
    width: 94%;
    margin: 0 auto;
    background-color: color(white);
    border: 1px solid color(light-gray);
    border-radius: 10px;
  }
  .item-inner {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 0.5rem 1rem 0.5rem 0 !important;
    background-image: none !important;

    .event-preview {
      width: 100%;

      .item-title {
        color: color(text-color);
        font-weight: bold;
        white-space: normal;
        font-size: 0.92em;
      }
      .item-subtitle {
        color: gray;
        font-size: 0.75em;
        white-space: normal;
      }
      .event-date {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        color: darken(color(gray), 20%);
        font-size: 0.75em;
      }
      .icon-show-detail {
        height: 18px;
        width: 18px;
      }
    }
  }

  .item-media {
    display: flex;
    flex-direction: column;
    justify-content: start !important;
    align-items: center !important;
    .date {
      color: color(blue);
      font-size: 1.4em;
      font-weight: bold;
    }
    .month {
      color: color(gray);
      text-transform: uppercase;
      font-size: 0.95em;
    }
    &:after {
      content: '';
      position: absolute;
      top: 16px;
      bottom: 16px;
      left: 45px;
      border-left: 1px solid color(gray);
    }
  }
}
</style>
