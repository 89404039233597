<template>
  <f7-popup
    class="twofactor-popup"
    :opened="value"
    :close-on-escape="false"
    :close-by-backdrop-click="false"
    @popup:opened="focusInput"
    @popup:closed="cancelLogin"
  >
    <f7-page class="popup-content-centered bg-grey">
      <f7-icon
        f7="lock"
        size="66"
      />
      <BaseFormCard>
        <h3 class="text-align-center">
          Two-Factor Authentication
        </h3>
        <form
          action=""
          method="POST"
          @submit.prevent="validateCode"
          @keyup.enter="validateCode"
        >
          <p>
            Enter the validation code we sent via SMS to your device ({{
              phoneNumber
            }})
          </p>
          <f7-list no-hairlines-md>
            <f7-list-item
              v-if="errorMessage"
              class="error"
            >
              {{
                errorMessage
              }}
            </f7-list-item>
            <!-- We use the html style to take advantage of inputmode prop -->
            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-title item-floating-label">
                  Enter Validation Code
                </div>
                <div class="item-input-wrap">
                  <input
                    id="single-factor-code-text-field"
                    ref="input"
                    v-model="code"
                    autocomplete="one-time-code"
                    type="text"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    autofocus
                    required
                  >
                  <span class="input-clear-button" />
                </div>
              </div>
            </li>
            <f7-button
              large
              fill
              :disabled="loading || !code.trim().length"
              @click="validateCode"
            >
              <span v-if="loading">Verifying...</span>
              <span v-else>Verify Code</span>
            </f7-button>
            <f7-button
              class="margin-top"
              @click="cancelLogin"
            >
              Cancel Login
            </f7-button>
          </f7-list>
        </form>
      </BaseFormCard>
    </f7-page>
  </f7-popup>
</template>
<script>
import feathersClient from '../../../api/feathers-client';
import { mapGetters } from 'vuex';
import BaseFormCard from './BaseFormCard';
export default {
  components: {
    BaseFormCard,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    phoneNumber: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      errorMessage: '',
      code: '',
    };
  },
  computed: {
    ...mapGetters(['loading']),
  },
  methods: {
    reset() {
      this.$store.commit('removeLoadingAction', '2fa-popup');
      this.code = '';
      this.errorMessage = '';
    },
    cancelLogin() {
      this.reset();
      this.$emit('cancelled');
    },
    validateLogin() {
      this.$emit('authenticated');
      this.reset();
    },
    validateCode() {
      this.$store.commit('addLoadingAction', '2fa-popup');
      this.errorMessage = '';
      feathersClient
        .service('2fa')
        .create({ action: 'verifySMSToken', token: this.code.trim() })
        .then(this.validateLogin)
        .catch(err => {
          this.errorMessage =
            'We were not able to validate your two-factor code. Please try logging in again.';
          setTimeout(this.cancelLogin, 1000);
        })
        .finally(() => {
          this.$store.commit('removeLoadingAction', '2fa-popup');
        });
    },
    focusInput() {
      this.$refs.input.focus();
    },
  },
};
</script>
<style lang="scss" scoped>
.bg-grey {
  background-color: color(bg-color);
}
</style>
