<template>
  <div
    ref="page"
    class="page-content infinite-scroll-content"
    infinite
    :infinite-distance="50"
    :infinite-preloader="false"
    @infinite="fetchItems(true)"
  >
    <slot name="header" />
    <slot v-if="items.length" :items="items" />

    <div v-if="!fetching && total === 0" class="h-full">
      <slot name="empty">
        <div v-if="!items.length" class="emptyListMessage text-center text-muted ">
          {{ emptyListMessage }}
        </div>
      </slot>
    </div>

    <div
      v-if="!fetching && endOfListMessage && total > 0 && items.length >= total"
      class="text-center text-muted my-25"
    >
      {{ endOfListMessage }}
    </div>

    <f7-button v-if="!fetching && total > 0 && items.length < total && showLoadMoreButton" @click="fetchItems(true)">
      Load More
    </f7-button>

    <div class="preloader-container">
      <f7-preloader v-if="fetching" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { throttle, isEqual } from 'lodash';
import feathersClient from '@/api/feathers-client';
export default {
  name: 'InfiniteListPage',
  components: {},
  props: {
    service: {
      type: String,
      required: true,
    },
    query: {
      type: Object,
      default: () => ({}),
      validator: prop => Object.keys(prop).every(key => ['$limit', '$skip'].includes(key) === false),
    },
    infiniteScrollEnabled: {
      type: Boolean,
      default: true,
    },
    showLoadMoreButton: {
      type: Boolean,
      default: false,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    emptyListMessage: {
      type: String,
      default: 'List is Empty',
    },
    endOfListMessage: {
      type: String,
      default: null,
    },
    qid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      total: null,
      fetching: false,
    };
  },
  computed: {
    ...mapGetters(['currentGroup']),
    queryId() {
      return this.qid || (this.currentGroup ? `${this.service}-${this.currentGroup._id}` : `init${this.service}`);
    },
    items() {
      if (!this.service) return [];

      const { $limit, $skip, ...query } = this.query;

      const { data } = this.$store.getters[`${this.service}/find`]({
        query,
        qid: this.queryId,
      });

      return data;
    },
  },
  watch: {
    query: {
      deep: true,
      handler(newQuery, oldQuery) {
        const queryChanged = !isEqual(newQuery, oldQuery);
        if (queryChanged) {
          this.resetFetchItems();
        }
      },
    },
    service() {
      this.resetFetchItems();
    },
  },
  mounted() {
    this.$f7.infiniteScroll.create(this.$refs.page);

    this.fetchItems();

    this.$f7.$('.page-content').on('scroll', this.handleScroll);
    // Listen for new posts
    feathersClient.service('posts').on('created', newPost => {
      if (newPost.group === this.currentGroup._id) {
        this.fetchLast();
      }
    });
  },
  beforeDestroy() {
    this.$f7.infiniteScroll.destroy(this.$refs.page);
    this.$f7.$('.page-content').off('scroll', this.handleScroll);
    // remove event listeners when the component is destroyed
    feathersClient.service('posts').removeListener('created');
  },
  methods: {
    resetFetchItems() {
      this.total = null;
      this.fetching = false;
      this.fetchItems(false);
    },
    handleScroll: throttle(function(e) {
      this.$emit('scroll', e);
    }, 50),
    async fetchItems(more = false) {
      if (this.fetching || (this.total !== null && this.items.length >= this.total)) return;
      this.fetching = true;

      this.$store.commit('addLoadingAction', 'fetchItems');

      const query = {
        ...this.query,
        $limit: this.pageSize,
        $skip: more ? this.items.length : 0,
      };

      try {
        const result = await this.$store.dispatch(`${this.service}/find`, {
          query,
          qid: this.queryId,
        });
        console.log(result);

        const { total } = result;

        if (this.fetching) {
          this.total = total;
          this.fetching = false;
        }
      } catch (err) {
        console.log(err);
        this.fetching = false;
      } finally {
        this.$store.commit('removeLoadingAction', 'fetchItems');
      }
    },

    async fetchLast() {
      if (this.fetching || (this.total !== null && this.items.length >= this.total)) return;
      this.fetching = true;
      const query = {
        ...this.query,
        $limit: this.pageSize,
        $skip: 0,
      };

      try {
        const result = await this.$store.dispatch(`${this.service}/find`, {
          query,
          qid: this.queryId + Math.random(),
        });

        const { total } = result;

        if (this.fetching) {
          this.total = total;
          this.fetching = false;
        }
      } catch (err) {
        this.fetching = false;
      }
    },
  },
};
</script>

<style scoped>
.h-full {
  height: calc(100% - 35px);
}
.emptyListMessage {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.preloader-container {
  min-height: 32px;
  text-align: center;
}</style
>import { del } from 'vue';
