import smartcrop from 'smartcrop';
import { getFileEntry } from '@/helpers/filesystem';

export const readFile = fileUri => {
  return new Promise((resolve, reject) => {
    getFileEntry(fileUri)
      .then(fileEntry => {
        fileEntry.file(file => {
          const fileReader = new FileReader();
          fileReader.readAsArrayBuffer(file);

          fileReader.onerror = fileReaderError =>
            reject(`filereader error: ${fileReaderError}`);
          fileReader.onload = () => {
            const imgBlob = new Blob([fileReader.result], { type: file.type });

            resolve(imgBlob);
          };
        });
      })
      .catch(err => {
        console.log(err);
        reject('File URI to upload could not be found');
      });
  });
};



export const imageFilePreview = (imageFile, height = 400, width = 400) => {
  return new Promise((resolve, reject) => {
    if (['image/jpeg', 'image/png', 'image/gif'].includes(imageFile.type)) {
      const reader = new FileReader();

      reader.onload = e => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = () => {
          smartcrop
            .crop(image, {
              width,
              height,
            })
            .then(result => {
              const crop = result.topCrop;
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              canvas.width = width;
              canvas.height = height;
              ctx.drawImage(
                image,
                crop.x,
                crop.y,
                crop.width,
                crop.height,
                0,
                0,
                canvas.width,
                canvas.height
              );

              const dataUrl = canvas.toDataURL(image.type);

              resolve(dataUrl);
            });
        };
      };

      reader.readAsDataURL(imageFile);
    }
  });
};

export const imageUriPreview = uri => {
  const path = uri.replace(/(^\w+:|^)\/\//, '');

  const data = {
    path,
    url: uri,
    mediaType: 'image',
    thumbnailQuality: 100,
  };

  return new Promise((resolve, reject) => {
    window.MediaPicker.extractThumbnail(
      data,
      item => {
        resolve(`data:image/jpeg;base64,${item.thumbnailBase64}`);
      },
      reject
    );
  });
};

export const videoUriThumbnail = videoUri => {
  const path = videoUri.replace(/(^\w+:|^)\/\//, '');

  const data = {
    path,
    url: videoUri,
    mediaType: 'video',
    thumbnailQuality: 50,
  };

  return new Promise((resolve, reject) => {
    window.MediaPicker.extractThumbnail(
      data,
      item => {
        resolve(`data:image/jpeg;base64,${item.thumbnailBase64}`);
      },
      reject
    );
  });
};

export const newVideoUriThumbnail = videoUri => {
  return new Promise((resolve, reject) => {
    window.resolveLocalFileSystemURL(
      videoUri,
      entry => {
        captureThumbnail(entry.toURL(), resolve);
      },
      error => {
        console.error(`Error resolving file entry: ${JSON.stringify(error)}`);
      }
    );
  });
};

// callback(`data:image/jpeg;base64,${base64Data}`);

// function captureThumbnail(videoUrl, resolve) {
//   const video = document.createElement('video');
//   video.src = videoUrl;
//   video.addEventListener('loadedmetadata', () => {
//     const canvas = document.createElement('canvas');
//     canvas.width = video.videoWidth;
//     canvas.height = video.videoHeight;

//     video.addEventListener('loadeddata', () => {
//       canvas
//         .getContext('2d')
//         .drawImage(video, 0, 0, canvas.width, canvas.height);

//       const thumbnail = canvas.toDataURL('image/jpeg');
//       resolve(thumbnail);
//     });
//   });
//   video.load();
// }

function captureThumbnail(videoUrl, resolve) {
  const video = document.createElement('video');
  video.autoplay = true; // Ensure the video is playing
  video.muted = true; // Mute the video to prevent audio interference
  video.src = videoUrl;

  video.addEventListener('loadedmetadata', () => {
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    video.addEventListener('playing', () => {
      canvas
        .getContext('2d')
        .drawImage(video, 0, 0, canvas.width, canvas.height);

      const thumbnail = canvas.toDataURL('image/jpeg');
      resolve(thumbnail);

      // Don't forget to clean up resources after capturing the thumbnail
      video.pause();
      video.src = '';
      video.load();
    });

    video.play(); // Start playing the video to trigger 'playing' event
  });

  video.load();
}
