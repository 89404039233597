import feathersClient, { makeServicePlugin, BaseModel } from '../../api/feathers-client';

class Notification extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }
  static modelName = 'Notification';
}
const servicePath = 'notifications';

const servicePlugin = makeServicePlugin({
  Model: Notification,
  service: feathersClient.service(servicePath),
  servicePath,
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
