<template>
  <div v-if="$can('read', item)" class="post">
    <f7-card class="post-item">
      <f7-card-header class="header headr-topper-post display-flex justify-content-space-between">
        <div class="display-flex align-items-center">
          <Avatar :user-id="item.createdBy" />
          <div class="user">
            <div class="user-name cursor-pointer" @click="author ? openUserProfile(author._id) : null">
              <UserDisplayName :user-id="item.createdBy" />
            </div>
            <!-- Button to delete post -->
            <div class="info">
              <span class="date">
                <img src="@/assets/images/icons/clock.svg" />
                <span v-text="formatFeedItemDateTime(item.createdAt)" />
              </span>
              <span v-if="item.location && item.location.name" class="location">
                <img src="@/assets/images/icons/location-gray.svg" />
                {{ item.location.name }}
              </span>
            </div>
          </div>
        </div>
        <MenuButton @click="openModal(item, 'actionModal')" />
      </f7-card-header>

      <f7-card-content class="content">
        <p class="content__text">
          <SanitizedText :text="item.text" />
          <span v-if="taggedClients.length" class="tagging">
            - with
            <span v-for="(client, index) in taggedClients.slice(0, 3)" :key="client._id">
              {{ `${index !== 0 && taggedClients.length > 1 ? ', ' : ''} ${client.firstName} ${client.lastName}` }}
            </span>
            <f7-link v-if="taggedClients.length > 3" @click="showAllTaggedClients">
              + {{ taggedClients.length - 3 }} more
            </f7-link>
          </span>
        </p>

        <div
          v-if="medias"
          class="medias-container"
          :class="{
            'two-medias': medias.length === 2,
            'three-medias': medias.length === 3,
          }"
        >
          <!-- only load 4 image previews if this post has more than 4 images -->
          <div
            v-for="media in medias.length >= 4 ? medias.slice(0, 4) : medias"
            ref="mediaHolder"
            :key="media.url"
            class="media-holder"
            :style="{
              backgroundImage: 'url(' + media.thumbnailUrl + ')',
              backgroundColor: 'black',
            }"
            @click="openPhotoBrowser(media.url)"
          >
            <img
              v-if="media.type === 'video' && medias.length <= 4"
              class="videoPlayIcon"
              src="@/assets/images/icons/play-rounded-button.svg"
            />
          </div>
          <div v-if="medias.length > 4" class="overlay" @click="openModal(item, 'gallery')">
            <div class="text">{{ medias.length - 4 }} +</div>
          </div>
        </div>
      </f7-card-content>

      <f7-card-footer class="post-footer">
        <div class="likes-wrapper">
          <f7-button class="btn-likes" @click="toggleLike">
            <div class="icon-holder">
              <img v-if="isLiked" class="icon-holder__icon" src="@/assets/images/icons/like-fill.svg" />
              <img v-else class="icon-holder__icon" src="@/assets/images/icons/like.svg" />
            </div>
          </f7-button>
          <a v-if="likesCount" class="indicator indicator-like" @click="openModal(item, 'likesSheetModal')">{{
            likesCount
          }}</a>
        </div>
        <div v-if="$can('read', 'comments')" class="comments-wrapper" @click="openModal(item, 'commentsSheetModal')">
          <f7-button class="btn-comments">
            <div class="icon-holder">
              <img class="icon-holder__icon" src="@/assets/images/icons/comment.svg" />
            </div>
          </f7-button>
          <span v-if="commentsCount" class="indicator indicator-comment">{{ commentsCount }}</span>
        </div>
      </f7-card-footer>

      <template v-if="commentsCount && $can('read', 'comments')">
        <div class="last-comment-wrapper">
          <div v-for="(comment, i) in latestComments" :key="i" class="lastest-comment-box">
            <span class="author"> <UserDisplayName :user-id="comment.author ? comment.author._id : null" />: </span>
            <span>{{ comment.text }}</span>
          </div>
        </div>
        <div
          :style="{
            height: commentsCount > 1 ? '45px' : '25px',
            position: 'relative',
          }"
        >
          <div v-if="commentsCount > 1" class="lastest-comment-box" style="margin: 0px 16px; min-height: 5px;" />
          <div
            class="see-more-comment"
            :style="{
              cursor: 'pointer',
              top: commentsCount > 1 ? '50%' : '25%',
              paddingTop: commentsCount > 1 ? '5px' : '0',
            }"
            @click="openModal(item, 'commentsSheetModal')"
          >
            &darr; see comments &darr;
          </div>
        </div>
      </template>
    </f7-card>

    <TaggedUsersList />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Avatar from '@/components/Avatar';
import datesMixin from '@/mixins/dates.js';
import showToastMixin from '@/mixins/showToast.js';
import { openUserProfile } from '@/mixins/helperFunctions';
import { videoThumbnailUrl, mediaPreviewUrl } from '@/helpers/media/remote';
import TaggedUsersList from '@/components/socialFeed/TaggedUsersList';
import MenuButton from '@/components/MenuButton';
import SanitizedText from '@/components/SanitizedText';
import UserDisplayName from '@/components/UserDisplayName';

export default {
  components: {
    Avatar,
    TaggedUsersList,
    MenuButton,
    SanitizedText,
    UserDisplayName,
  },
  mixins: [datesMixin, showToastMixin, openUserProfile],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      photoBrowser: null,
      likeActionPending: false,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('comments', {
      findCommentsInStore: 'find',
    }),
    ...mapGetters('likes', {
      findLikesInStore: 'find',
    }),
    ...mapGetters('users', {
      findUsersInStore: 'find',
      getUserInStore: 'get',
    }),
    ...mapGetters('clients', {
      findClientsInStore: 'find',
    }),
    isCordova() {
      return this.$store.getters.isCordova;
    },
    medias() {
      const images = this.item.imageUrls.map(url => {
        const imageUrl = mediaPreviewUrl(url);

        return {
          type: 'image',
          url: imageUrl,
          thumbnailUrl: imageUrl,
        };
      });

      let videos = [];
      if (this.item.videoUrls) {
        videos = this.item.videoUrls.map(url => ({
          type: 'video',
          url,
          thumbnailUrl: videoThumbnailUrl(url),
        }));
      }

      return [...images, ...videos];
    },
    photoBrowserMedias() {
      return this.medias.map(media => {
        const mediaData = {};

        if (media.type === 'image') {
          mediaData.url = media.url;
        } else {
          mediaData.html = `
                <video class="video-body" width="100%" playsinline controls poster="${media.thumbnailUrl}">
                  <source src="${media.url}" type="video/mp4"/>
                </video>`;
        }

        return mediaData;
      });
    },
    isLiked() {
      if (!this.user) return;

      const currentUserLiked =
        this.findLikesInStore({
          paginate: false,
          query: { 'ref._id': this.item._id, createdBy: this.user._id },
        }).total > 0;

      return currentUserLiked;
    },
    taggedClients() {
      if (!this.item.clients || this.item.clients.length === 0) return [];
      return this.item.clients;
    },
    author() {
      return this.getUserInStore(this.item.createdBy);
    },
    likesCount() {
      return this.findLikesInStore({
        query: { 'ref._id': this.item._id, $limit: 0 },
      }).total;
    },
    commentsCount() {
      return this.findCommentsInStore({
        query: { 'ref._id': this.item._id, $limit: 0 },
      }).total;
    },
    latestComments() {
      const { data } = this.findCommentsInStore({
        query: {
          'ref._id': this.item._id,
          $limit: 1,
          $sort: { createdAt: -1 },
        },
      });

      return data.map(comment => {
        const author = this.getUserInStore(comment.createdBy);
        return { ...comment, author };
      });
    },
  },
  mounted() {
    this.photoBrowser = this.$f7.photoBrowser.create({
      photos: [],
    });
  },
  beforeDestroy() {
    this.photoBrowser.destroy();
  },
  methods: {
    ...mapActions('likes', {
      createLike: 'create',
      removeLikes: 'remove',
      removeLikeForUser: 'removeLikeForUser',
    }),
    async toggleLike() {
      if (!this.$can('create', 'likes') || this.likeActionPending) {
        this.openModal(this.item, 'likesSheetModal');
        return;
      }

      this.likeActionPending = true;
      if (!this.isLiked) {
        await this.createLike({
          ref: {
            kind: 'post',
            _id: this.item._id,
          },
        });
      } else {
        const params = {
          query: {
            'ref._id': this.item._id,
            createdBy: this.user._id,
          },
        };
        await this.removeLikeForUser({
          refId: this.item._id,
          userId: this.user._id,
        });
      }
      this.likeActionPending = false;
    },
    showAllTaggedClients() {
      // this.$store.commit('setUsersTagged', this.taggedUsers);
      this.$store.commit('setClientsTagged', this.taggedClients);
      setTimeout(() => {
        this.$f7.popup.open('#showAllTaggedClients');
      }, 100);
    },
    async openModal(post, type) {
      if (type === 'actionModal') {
        //clone the post object and give the new post object a type attribute called 'post'
        const postTypeData = Object.assign({}, post);
        postTypeData.type = 'post';
        await this.$store.dispatch('setmodifyItemData', postTypeData);
      } else {
        await this.$store.dispatch('setPostObject', post);
      }

      this.$f7.sheet.open(`#${type}`);
    },
    openPhotoBrowser(url) {
      this.photoBrowser.params.photos = this.photoBrowserMedias;

      const index = window._.findIndex(this.medias, media => {
        return media.url === url;
      });
      this.photoBrowser.open(index);
    },
    showAllTaggedUsers() {
      this.$store.commit('setUsersTagged', this.taggedUsers);
      setTimeout(() => {
        this.$f7.popup.open('#showAllTaggedUsers');
      }, 100);
    },
  },
};
</script>

<style scoped lang="scss">
.last-comment-wrapper {
  display: flex;
  padding: 10px 16px;
  flex-direction: column;
}
.see-more-comment {
  width: 100%;
  background: #fff;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.lastest-comment-box {
  background: rgb(237, 236, 236);
  margin-top: 5px;
  padding: 5px;
  border: 1px solid #cecece;
  border-radius: 4px;
  .author {
    font-weight: bold;
  }
}
.post {
  color: color(text-color);
  .post-item {
    margin-bottom: 1.5em;
    display: block;
    border: 0px solid transparent;
    border-radius: 5px;
  }
  .header {
    &.headr-topper-post {
      border-top: 5px solid color(green);
      border-radius: 5px;
    }
    .user {
      display: flex;
      flex-direction: column;
      margin-left: 1em;
      padding: 5px 0 5px 0;
      .tool {
        position: absolute;
        right: 20px;
        top: 6px;
        color: #333;
        padding: 10px;
      }
    }
    .user-name {
      font-size: 0.95em;
      font-weight: bold;
    }
    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
    }
  }

  .content {
    padding: 0;
  }
  .content__image {
    width: 100%;
  }
  .content__text {
    width: 90%;
    margin: auto;
    padding-bottom: 1em;
    margin-top: 1em;
    word-break: break-word;
  }

  .date,
  .location {
    color: gray;
    font-size: 0.6em;
    display: flex;
    align-items: center;
    padding: 1px;
    img {
      width: 12px;
      height: 12px;
      margin-right: 0.4em;
    }
  }
  .like-list {
    .chip {
      background-color: color(blue);
      color: color(white);
      margin-right: 5px;
    }
  }
  .btn-likes,
  .btn-comments {
    border: none;
    .icon-holder {
      border: 1px solid color(gray);
      border-radius: 100%;
      height: 28px;
      width: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon-holder__icon {
        width: 16px;
        height: 16px;
      }
    }
  }
  .btn-likes {
    .icon-holder__icon {
      color: color(red);
    }
  }
  .post-footer {
    height: 3em;
    margin-top: 1em;
    display: flex;
    align-items: center;
    .likes-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .comments-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .indicator {
      display: flex;
      justify-content: center;
      align-content: center;
      margin-left: -1em;
      border: 1px solid color(light-gray);
      background-color: color(light-gray);
      color: color(text-color);
      margin-top: -6px;
      border-radius: 30px;
      width: 28px;
      height: 19px;
      padding-top: 1px;
      font-size: 0.8em;
    }

    .indicator-like:before {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      left: 65px;
      top: 17px;
      border-style: solid;
      border-width: 4px 5px 4px 0;
      border-color: transparent color(light-gray) transparent transparent;
    }

    .indicator-comment:before {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      right: 44px;
      top: 17px;
      border-style: solid;
      border-width: 4px 5px 4px 0;
      border-color: transparent color(light-gray) transparent transparent;
    }
  }
  .card-footer:before {
    display: none;
  }
}
// media layout
.medias-container {
  width: 90vw;
  @media (min-width: 1024px) {
    width: 63vw;
  }
  @media (min-width: $large_screen_width) {
    max-width: calc(1366px / 2 * 0.9);
    width: 100%;
  }
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  .media-holder {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    .videoPlayIcon {
      position: absolute;
      @media (min-width: 1024px) {
        width: 6vw;
      }
      @media (min-width: $large_screen_width) {
        width: 3vw;
      }
      width: 14vw;
    }
  }
  .media-holder:first-child {
    width: inherit;
    height: 68vw;
    margin-bottom: 1vw;
    @media (min-width: 1024px) {
      height: 47.6vw;
      margin-bottom: 0.7vw;
    }
    @media (min-width: $large_screen_width) {
      height: 360px;
      margin-bottom: 1%;
    }
    .videoPlayIcon {
      width: 17vw;
      @media (min-width: 1024px) {
        width: 10vw;
      }
      @media (min-width: $large_screen_width) {
        width: 7vw;
      }
    }
  }
  .media-holder:nth-child(2),
  .media-holder:nth-child(3),
  .media-holder:nth-child(4) {
    width: 29.3vw;
    margin-left: 1vw;
    height: 22vw;
    @media (min-width: 1024px) {
      width: 20.5vw;
      margin-left: 0.7vw;
      height: 15.4vw;
    }
    @media (min-width: $large_screen_width) {
      width: 32.5%;
      height: 150px;
      margin-left: 1%;
    }
  }
  .media-holder:nth-child(2) {
    margin-left: 0;
  }
  .overlay {
    opacity: 0.75;
    background-color: darken(color(blue), 15%);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 0;
    margin-top: -22vw;
    height: 22vw;
    width: 29.3vw;
    left: 60.6vw;
    @media (min-width: 1024px) {
      margin-top: -15.4vw;
      height: 15.4vw;
      width: 20.5vw;
      left: 42.4vw;
    }
    @media (min-width: $large_screen_width) {
      margin-top: -150px;
      width: 32.5%;
      height: 150px;
      left: 67%;
    }
    .text {
      color: color(white);
      font-size: 2em;
      @media (min-width: 1024px) {
        font-size: 2.5em;
      }
    }
  }
}
.two-medias {
  .media-holder:first-child,
  .media-holder:last-child {
    width: 44vw;
    height: 34vw;
    margin-right: 1vw;
    margin-bottom: 0;
    @media (min-width: 1024px) {
      width: 30.8vw;
      height: 23.8vw;
      margin-right: 0.7vw;
    }
    @media (min-width: $large_screen_width) {
      width: 49%;
      height: 200px;
      margin-right: 1%;
    }
  }
  .media-holder:last-child {
    margin-right: 0;
  }
}
.three-medias {
  .media-holder:nth-child(2),
  .media-holder:nth-child(3) {
    width: 44vw;
    height: 34vw;
    margin-right: 1vw;
    @media (min-width: 1024px) {
      width: 30.8vw;
      height: 23.8vw;
      margin-right: 0.7vw;
    }
    @media (min-width: $large_screen_width) {
      width: 49%;
      height: 200px;
      margin-right: 1%;
    }
  }
  .media-holder:nth-child(2) {
    margin-right: 0;
  }
}
.tagging {
  font-weight: bold;
}
</style>
