<template>
  <f7-page id="login-wrapper" no-navbar no-toolbar no-swipeback>
    <div class="login-page-container">
      <div class="login-content-container">
        <img class="main-logo" src="@/assets/images/NL-App-Logo.png" alt="Navigating Life Logo" />

        <StoredLoginsList v-if="showStoredUsersPrompt" />

        <LoginResetPasswordForms v-else :loading="loading" :error-message="errorMessage" @login="login" />

        <!-- <div class="azure-sso">
          <h2><span> OR </span></h2>
          <div class="azure-sso__buttons">
            <span
              class="azure-sso__container"
              @click="authenticateMsa"
            >
              <img src="@/assets/images/icons/icon-windows.svg">Sign In with
              Azure
            </span>
          </div>
        </div> -->

        <div class="no-account">
          <h2><span>NEW USER?</span></h2>
          <div class="no-account__buttons">
            <span @click="goToWelcomePage"> <img src="@/assets/images/icons/play-arrow.svg" />Take Tour </span>
          </div>
        </div>

        <div class="eula">By using this app, you <a @click="openEula">agree to our EULA</a>.</div>
      </div>
      <TwoFactorPopup
        v-model="twoFAPopupOpen"
        :phone-number="userPhoneNumber"
        @authenticated="loginSuccess"
        @cancelled="cancelLogin"
      />
    </div>
  </f7-page>
</template>
<script>
import { mapMutations, mapActions, mapGetters, mapState } from 'vuex';
import feathersClient from '@/api/feathers-client';
import LoginResetPasswordForms from './components/LoginResetPasswordForms';
import TwoFactorPopup from './components/TwoFactorPopup';
import { logActivity } from '@/helpers/logs';
import StoredLoginsList from './components/StoredLoginsList';

export default {
  name: 'Login',
  components: {
    StoredLoginsList,
    LoginResetPasswordForms,
    TwoFactorPopup,
  },
  props: {
    // Determine whether we came here looking to add a new user account
    addAccount: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: '',
      password: '',
      remember: false,
      errorMessage: '',
      userPhoneNumber: '',
    };
  },
  computed: {
    ...mapGetters(['loading']),
    ...mapGetters('auth', {
      currentUser: 'user',
    }),
    ...mapGetters('accounts', {
      storedUsers: 'stored',
    }),
    firstName() {
      return this.currentUser ? this.currentUser.firstName : '';
    },
    twoFAPopupOpen() {
      return this.$store.state.awaitingTwoFactor;
    },
    showStoredUsersPrompt() {
      if (this.addAccount === true) {
        return false;
      }

      return this.storedUsers.length >= 1;
    },
  },
  created() {
    this.loadStoredUsers();
    this.checkErrors();
  },
  methods: {
    ...mapMutations('auth', {
      clearAuthenticateError: 'clearAuthenticateError',
    }),
    ...mapActions('auth', ['authenticate', 'logout']),
    ...mapActions('accounts', {
      loadStoredUsers: 'loadStored',
      saveUserAccount: 'save',
    }),
    /**
     *
     * Page Navigation Methods
     *
     **/
    goToWelcomePage() {
      this.$f7.views.main.router.navigate('/tour/');
    },
    async authenticateMsa() {
      try {
        window.location.href = `${process.env.VUE_APP_API_URL}/oauth/microsoft`;
        //  window.location.href = 'http://localhost:3030/oauth/microsoft'
      } catch (e) {
        console.log(e);
      }
    },
    openEula() {
      window.open(
        'https://docs.google.com/document/d/e/2PACX-1vTQqJ2KfB8YGhZeB8yhJPjGMg3YNsKkz_8Y2Khv_wncsyNWT-Lrwropw81GSeuQwAfUCje1ksrd7jhn/pub',
        '_system'
      );
    },

    checkErrors() {
      const url = this.$f7.views.main.router.currentRoute.url;
      const ssoErrors = {
        error: {
          message: "Sorry we're having trouble signing you in. Check your login and try again.",
        },
        userNotFound: {
          message: 'Email not found.',
        },
      };
      if (url && url.includes('#error')) {
        const errorValue = url.split('#error=')[1];
        const error = ssoErrors[errorValue] ? ssoErrors[errorValue] : ssoErrors.error;
        this.errorMessage = error.message;
      }
    },

    /**
     *
     * Core Login Methods
     *
     **/
    login({ email, password, remember = false, pin }) {
      this.$store.commit('addLoadingAction', 'login');
      this.email = email;
      this.pin = pin;
      this.remember = remember;
      this.errorMessage = '';
      this.authenticate({ strategy: 'local', email, password })
        .then(({ refreshToken }) => {
          if (this.currentUser.authyId) {
            // User is using 2FA & manually logged in
            this.request2faAuth(refreshToken);
          } else {
            // User not using 2FA
            this.loginSuccess(refreshToken);
          }
        })
        .catch(err => {
          this.clearAuthenticateError();
          if (err.code === 418) return;

          /* eslint-disable-next-line*/
          console.warn(err);

          const errorMap = {
            error: { message: "Sorry we're having trouble signing you in. Check your login and try again." },
            userNotFound: {
              message: 'Email not found.',
            },
            NotAuthenticated: {
              message: 'Incorrect email or password.',
            },
            TokenExpiredError: {
              message: 'Your session has expired. Please log in again.',
            },
            EmailNotVerified: {
              message: "We've sent you a new verification email. Please verify your email address before logging in.",
              callback: () => this.resendVerificationEmail(),
            },
            PasswordExpired: {
              message:
                "Your password has expired based on your organization's policy. An email has been sent to you to reset it.",
              callback: () => this.sendResetEmail(),
            },
          };

          const error = errorMap[err.name];

          if (error) {
            this.errorMessage = error.message;
            if (error.callback) {
              error.callback();
            }
          } else {
            this.errorMessage = 'An error prevented login.';
          }
        })
        .finally(() => {
          this.$store.commit('removeLoadingAction', 'login');
        });
    },

    request2faAuth() {
      this.$store.commit('setAwaitingTwoFactor', true);

      feathersClient
        .service('2fa')
        .create({ action: 'requestSMS' })
        .then(({ cellphone }) => {
          this.userPhoneNumber = cellphone;
        })
        .catch(err => {
          /* eslint-disable-next-line */
          console.warn(err);
        });
    },

    loginSuccess(refreshToken) {
      this.$store.commit('setAwaitingTwoFactor', false);
      logActivity('login');

      if (this.remember) this.storeAuthedUser(refreshToken);

      if (!this.currentUser.acceptedTerms) {
        // this.termsPopupOpen = true;
        this.$store.commit('setTermsPopup', true);
      }
      this.$f7.views.main.router.navigate('/app/', {
        clearPreviousHistory: true,
        reloadAll: true,
      });
    },
    async storeAuthedUser(refreshToken) {
      try {
        await this.saveUserAccount({
          email: this.email,
          token: refreshToken,
          pin: this.pin,
        });
        console.log('saved');
      } catch (err) {
        this.$f7.dialog.alert(
          err.message || 'We were unable to store your account with the information provided.',
          'Error Storing Account'
        );
      }
    },

    async cancelLogin() {
      this.$store.commit('setAwaitingTwoFactor', false);
      await this.logout();
      this.$store.commit('removeLoadingAction', 'login');
    },

    resendVerificationEmail() {
      feathersClient.service('authManagement').create({
        action: 'resendVerifySignup',
        value: { email: this.email.trim() },
      });
    },

    sendResetEmail() {
      feathersClient.service('authManagement').create({
        action: 'sendResetPwd',
        value: { email: this.email.trim() },
      });
    },
  },
};
</script>

<style lang="scss">
#main {
  flex-basis: none !important;
  max-width: none !important;
}

.error {
  color: color(red);
  .item-content .item-inner {
    text-align: center;
  }
}
</style>

<style scoped lang="scss">
#login-wrapper {
  height: 100%;
  background-color: color(bg-color);

  .login-page-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 90%;
    margin: 0 auto;

    .login-content-container {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      .main-logo {
        margin: 0 auto;
        padding: 0 1.5rem;
        max-width: 220px;
        width: 45%;
      }

      .org-logo {
        margin: 0 auto;
        padding: 0 1.5rem;
        max-width: 145px;
        width: 45%;
      }

      .azure-sso,
      .no-account {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 82%;
        h2 {
          letter-spacing: 1px;
          font-size: 16px;
          color: darken(color(gray), 10%);
          width: 100%;
          text-align: center;
          border-bottom: 1px solid darken(color(gray), 10%);
          line-height: 0.1em;
          margin: 10px 0 20px;
          span {
            background: #f2f5f7;
            padding: 0 10px;
          }
        }
        .azure-sso__buttons,
        .no-account__buttons {
          width: 100%;
          max-width: 400px;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          margin-top: 1em;
          @media (max-width: 360px) {
            margin-top: 0;
          }
          span {
            padding: 0.65em 0;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            img {
              height: 14px;
              width: 14px;
              margin-right: 8px;
            }
            a {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          span:first-of-type {
            background-color: darken(color(gray), 10%);
            width: 48%;
            margin-right: 2%;
            font-weight: bold;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            a {
              color: color(text-color);
            }
          }
          span:nth-last-of-type(2) {
            background-color: #f2f5f7;
            width: 10%;
            border-radius: 100%;
            position: absolute;
            max-width: 50px;
            @media (max-width: 360px) {
              width: 11%;
            }
          }
          span:last-of-type {
            background-color: color(blue);
            width: 48%;
            margin-left: 2%;
            font-weight: bold;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            a {
              color: color(white);
            }
          }
        }
      }
    }

    .azure-sso__buttons span {
      width: 100% !important;
      color: white;
    }

    .eula {
      flex: 0 0 25px;
      margin: 0 auto;
      text-align: center;
      padding: 0.5em 0;
    }
  }
}
</style>
