<template>
  <span>
    {{
      user && !user.deletedAt && isUserInGroup
        ? `${user.firstName} ${user.lastName}`
        : 'Navigating Life User'
    }}
  </span>
</template>
<script>
  import { mapGetters } from 'vuex';
  export default {
    props: {
      userId: {
        type: String,
        default: null,
      },
    },
    computed: {
      ...mapGetters('users', {
        getUserInStore: 'get',
      }),
      ...mapGetters(['currentGroup']),
      user() {
        if (!this.userId) return null;
        return this.getUserInStore(this.userId);
      },
      isUserInGroup() {
        return this.currentGroup.members.filter((member) => member._id === this.userId).length > 0;
      },
    },
  };
</script>
