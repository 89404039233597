<template>
  <f7-popup
    id="gallery"
    class="popup-tablet-fullscreen"
  >
    <f7-page class="inner">
      <f7-navbar>
        <div class="np-border--fancy" />
        <div class="navbar-inner sliding">
          <div class="left">
            <f7-link popup-close>
              <i class="icon icon-back" />
            </f7-link>
          </div>
          <div class="title">
            Gallery
          </div>
        </div>
      </f7-navbar>
      <MediasPreviews
        v-if="post"
        class="mx-15 my-15"
        :images="post.imageUrls"
        :videos="post.videoUrls"
        :editable="false"
        :remote-source="true"
      />
    </f7-page>
  </f7-popup>
</template>
<script>
  import MediasPreviews from '@/components/forms/post/MediasPreviews';

  export default {
    components: {
      MediasPreviews,
    },
    computed: {
      post() {
        return this.$store.state.postObject;
      },
    },
  };
</script>

<style lang="scss" scoped>
  #gallery {
    z-index: 12503;
  }
  .inner {
    background: color(bg-color);
  }
  .navbar {
    height: 1.07 * $tab_height;
    .navbar-inner {
      margin-top: 2px;
    }
  }
  .np-border--fancy {
    height: 4px;
    width: 100%;
    background: url('../../assets/images/nupath-border-bg.svg') repeat-x;
    position: absolute;
    top: 0;
  }
</style>
