<template>
  <Modal
    v-body-scroll-lock="disableBodyScroll"
    :modal-title="modalTitle || 'Add Shift Note'"
  >
    <f7-button
      slot="left-button"
      class="link"
      @click="close"
    >
      <i class="icon icon-back" />
    </f7-button>
    <div slot="modal-content">
      <div class="shift-note-form">
        <div class="user-info">
          <div class="user-info__image image-placeholder">
            <img
              v-if="avatarUrl"
              class="profile-avatar"
              :src="avatarUrl"
            >
            <img
              v-else
              src="@/assets/images/icons/photo.svg"
              class="image-icon-large"
            >
          </div>

          <div
            class="user-info__name"
            v-text="fullName"
          />
          <div
            v-if="currentUser.title"
            class="user-info__title"
            v-text="currentUser.title"
          />
          <div
            v-if="currentUser.role"
            class="user-info__role"
            v-text="role"
          />
        </div>
        <f7-list
          inset
          class="no-pad no-hairlines mt-0"
        >
          <f7-list-group class="list-group-info">
            <f7-list-item>
              <img
                slot="media"
                src="@/assets/images/icons/star-blue.svg"
                class="image-icon"
              >
              <span class="item-left">{{ currentGroup.name }}</span>
              <span class="item-right">Group</span>
            </f7-list-item>
            <f7-list-item>
              <img
                slot="media"
                src="@/assets/images/icons/date-picker.svg"
                class="image-icon"
              >
              <span class="item-left">{{ date.toLocaleDateString() }}</span>
              <span class="item-right">Date</span>
            </f7-list-item>
          </f7-list-group>
        </f7-list>
        <f7-list class="no-hairlines">
          <f7-list-item>
            <f7-label>
              Shift Note<span class="required-field"> *</span>
            </f7-label>
            <f7-input
              type="textarea"
              class="note-textarea resizable"
              placeholder="Notes/Comments"
              :value="form.notes"
              required
              @input="form.notes = $event.target.value"
            />
          </f7-list-item>
          <BooleanSelect
            v-model="form.critical"
            label="Critical"
          />
        </f7-list>
      </div>
      <f7-button
        v-if="$store.state.modifyItemData"
        class="btn-primary"
        :disabled="!valid"
        @click="updateCurrentItem('shiftNote', form)"
      >
        Update Note
      </f7-button>
      <f7-button
        v-else
        class="btn-primary"
        :disabled="!valid"
        @click="submit"
      >
        Submit
      </f7-button>
    </div>
  </Modal>
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import analytics from '@/analytics';
import showToast from '@/mixins/showToast';
import { mediaTransformationUrl } from '@/helpers/media/remote';
import { rolesDisplay } from '@/helpers/user-roles';
import BooleanSelect from '@/components/inputs/BooleanSelect';
import Modal from '@/components/Modal';
import editItem from '@/mixins/editItem';

export default {
  components: {
    Modal,
    BooleanSelect,
  },
  mixins: [showToast, editItem],
  props: {
    modalTitle: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      form: {
        date: new Date(),
        notes: '',
        critical: false,
      },
      disableBodyScroll: false,
    };
  },
  computed: {
    ...mapState('auth', {
      currentUser: 'user',
    }),
    ...mapGetters(['currentGroup']),
    valid() {
      return this.form.notes.trim().length > 0;
    },
    date() {
      return new Date(this.form.date);
    },
    avatarUrl() {
      if (!this.currentUser.avatarUrl) return;

      return mediaTransformationUrl(this.currentUser.avatarUrl, {
        scale_crop: '150x150/smart_faces',
      });
    },
    role() {
      return rolesDisplay[this.currentUser.role];
    },
    fullName() {
      return `${this.currentUser.firstName} ${this.currentUser.lastName}`;
    },
  },
  created() {
    if (this.$store.state.modifyItemData) {
      Object.assign(this.form, this.$store.state.modifyItemData);
    }
  },
  methods: {
    ...mapActions('shiftNotes', {
      createShiftNote: 'create',
      updateShiftNote: 'update',
    }),
    ...mapMutations('tabs/data', {
      setActiveDataTabIndex: 'setActiveTabIndex',
    }),
    submit() {
      try {
        this.$store.commit('addLoadingAction', 'save-shift-note');
        const formData = {
          ...this.form,
          group: this.currentGroup._id,
        };
        this.createShiftNote(formData).then(behavior => {
          this.showToast('Shift Note Recorded Successfully');
          this.setActiveDataTabIndex(1);
          this.$f7router.navigate('/app/data/');
          analytics.track({
            userId: this.currentUser._id,
            event: 'Logged shift note',
          });
        });
      } catch (err) {
        this.showToast('There was an error.');
        console.log(err);
      } finally {
        this.$store.commit('removeLoadingAction', 'save-shift-note');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.shift-note-form {
  margin-top: 0;
  padding-top: 1em;
  width: 90%;
  margin: auto;
  background: color(white);
  font-size: 1.1em;
}

.btn-primary {
  @media (max-width: 360px) {
    position: relative;
    margin-top: 1.5em;
  }
}

.list-group-info {
  width: 92%;
  margin: 0 auto;
  ul {
    overflow: hidden;
  }
  li {
    background: color(white);
    border-width: 1px 1px 0 1px;
    border-style: solid;
    border-color: #ddd;
    font-size: 0.9em;

    &:first-of-type {
      border-top-right-radius: 0.5em;
      border-top-left-radius: 0.5em;
    }

    &:last-of-type {
      border-bottom-width: 1px;
      border-bottom-right-radius: 0.5em;
      border-bottom-left-radius: 0.5em;
    }

    .item-left {
      margin-left: -1.2em;
      color: color(text-color);
    }

    .item-right {
      font-weight: 400;
      font-size: 0.7em;
      margin-right: 1.5em;
      text-transform: uppercase;
      opacity: 0.65;
    }
  }
}

.mt-0 {
  margin-top: 0;
}

.user-info {
  text-align: center;
  margin-bottom: 1rem;

  &__image {
    margin-top: 0;
    margin-bottom: 1rem;
    height: 90px;
    width: 90px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__name {
    font-weight: bold;
    font-size: 1.3em;
  }

  &__role {
    font-size: 0.9em;
    font-style: italic;
    color: rgb(167, 167, 167);
  }
}
</style>
