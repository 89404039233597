<template>
  <div class="container">
    <f7-block-title class="mt-1">
      Choose An Account
    </f7-block-title>
    <f7-list class="stored-login-list">
      <StoredLoginsListItem
        v-for="(account, index) in storedAccounts"
        :key="index"
        :stored-account="account"
        @selected="handleStoredAccountSelected"
      />
    </f7-list>
    <button
      class="add-account-btn"
      @click="redirectToLogin"
    >
      <f7-link>
        <span class="add-account-btn__text">Add An Account</span>
        <f7-icon
          class="add-account-btn__icon"
          f7="add"
        />
      </f7-link>
    </button>
    <div class="notice">
      You can store up to 10 of the  most recently used accounts for quick access.
    </div>
    <portal
      v-if="pinDialogOpen"
      to="app"
    >
      <PinForm
        :error="pinErrorMessage"
        @submit="handlePinSubmitted"
        @cancelled="reset"
      />
    </portal>
  </div>
</template>
<script>
  import logout from '@/mixins/logout';
  import { mapState, mapActions, mapGetters } from 'vuex';
  import { logActivity } from '@/helpers/logs';
  import StoredLoginsListItem from './StoredLoginsListItem';
  import PinForm from './PinForm';
  import { InvalidPinError } from '@/store/modules/accounts/errors';

  export default {
    components: { StoredLoginsListItem, PinForm },
    mixins: [logout],
    data() {
      return {
        pinDialogOpen: false,
        pinErrorMessage: '',
        selectedAccount: null,
      };
    },
    computed: {
      ...mapState('auth', {
        currentUser: 'user',
      }),
      ...mapGetters('accounts', {
        storedAccounts: 'stored',
      }),
    },
    created() {
      this.loadStoredAccounts();
    },
    methods: {
      ...mapActions('accounts', {
        loadStoredAccounts: 'loadStored',
        loginStoredAccount: 'login',
        validateAccountWithPin: 'validateWithPin',
      }),
      reset() {
        this.pinDialogOpen = false;
        this.selectedAccount = null;
        this.pinErrorMessage = '';
      },
      showErrorThenRedirectToLogin(title, description, props = {}) {
        return new Promise((resolve) => {
          this.$f7.dialog.alert(description, title, () => {
            this.$f7.views.main.router.navigate('/login/', {
              clearPreviousHistory: true,
              reloadAll: true,
              props,
            });
            resolve();
          });
        });
      },
      handleStoredAccountSelected(account) {
        this.selectedAccount = Object.assign({}, this.selectedAccount, account);
        this.pinDialogOpen = true;
      },
      handlePinSubmitted(pin) {
        this.pinErrorMessage = '';
        const accountWithPin = Object.assign({}, this.selectedAccount, { pin });
        this.validateAccountWithPin(accountWithPin)
          .then(async () => {
            await this.handleStoredAccountLogin(accountWithPin);
            this.reset();
          })
          .catch(this.handleLoginError);
      },
      async handleStoredAccountLogin({ email, tokenId, pin }) {
        try {
          this.$emit('optionSelected');
          await this.$nextTick();
          if (this.currentUser) {
            await this.logout({ redirect: false });
          }
          await this.loginStoredAccount({ email, tokenId, pin });
          this.$f7.views.main.router.navigate('/app/', {
            clearPreviousHistory: true,
            reloadAll: true,
          });
          logActivity('login');
          this.$f7.views.main.router.refreshPage();
        } catch (err) {
          this.handleLoginError(err);
        }
      },
      handleLoginError(err) {
        if (err instanceof InvalidPinError) {
          this.pinErrorMessage = err.message;
        } else {
          this.showErrorThenRedirectToLogin(
            'Error switching account',
            'Ensure you have appropriate biometric access to the device. If the problem persists, contact support.'
          );
        }
      },
      redirectToLogin() {
        try {
          this.$emit('optionSelected');
          this.$nextTick(() => {
            this.logout({ f7RouteOptions: { props: { addAccount: true } } });
          });
        } catch (err) {
          this.showErrorThenRedirectToLogin(
            'Error adding account',
            'If the problem persists, contact support.',
            { addAccount: true }
          );
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .list {
    border-top: 0.5px solid rgba(0, 0, 0, 0.12);
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.12);
    margin-bottom: 0;

    .login-list-item {
      transition: all 0.2s ease-in;

      &:not(:last-child) {
        border-bottom: 0.2px solid rgba(0, 0, 0, 0.12);
      }
    }
  }

  .add-account-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background: rgba(0, 0, 0, 0.05);
    border: none;
    appearance: none;

    &__icon {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #ec6b70;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 1.4em;
      font-weight: bold;
    }

    &__text {
      color: rgba(0, 0, 0, 0.5);
      font-size: 1.3em;
    }
  }

  .notice {
    text-align: center;
    padding: 0 10px;
    color: rgba(0, 0, 0, 0.5);
    font-size: 11px;
    margin-top: 20px;
  }

  .stored-login-list {
    max-height: 45vh;
    overflow: auto;
  }
</style>
