<template>
  <f7-card>
    <div
      v-show="bannerText"
      class="card__banner"
      :style="{ backgroundColor: bannerBgColor, color: bannerTextColor }"
    >
      <span
        class="card__banner__text"
        v-html="bannerText"
      />
      <slot name="banner-actions" />
    </div>
    <f7-card-header class="no-border header">
      <div class="header__left">
        <div class="header__avatar">
          <slot name="avatar" />
        </div>
        <div class="header__info">
          <span class="header__author">{{ author }}</span>
          <span
            v-if="date"
            class="header__date"
          >
            <img
              src="@/assets/images/icons/clock.svg"
              class="header__icon"
            >
            <span v-text="formatFeedItemDateTime(date)" />
          </span>
          <span
            v-if="location"
            class="header__location"
          >
            <img
              src="@/assets/images/icons/location-gray.svg"
              class="header__icon"
            >
            {{ location }}
          </span>
        </div>
      </div>
      <div class="header__right">
        <slot name="header-actions" />
      </div>
    </f7-card-header>
    <f7-card-content :padding="false">
      <slot />
    </f7-card-content>
    <f7-card-footer
      v-if="$slots.footer"
      class="no-border"
    >
      <slot name="footer" />
    </f7-card-footer>
  </f7-card>
</template>
<script>
  import datesMixin from '@/mixins/dates.js';
  import { openUserProfile } from '@/mixins/helperFunctions';

  export default {
    mixins: [datesMixin, openUserProfile],
    props: {
      author: {
        type: String,
        required: true,
      },
      location: {
        type: String,
        required: false,
        default: null,
      },
      date: {
        type: Date,
        required: false,
        default: null,
      },
      bannerText: {
        type: String,
        default: null,
      },
      bannerTextColor: {
        type: String,
        default: '#333333',
      },
      bannerBgColor: {
        type: String,
        default: '#eee',
      },
    },
  };
</script>
<style lang="scss" scoped>
  .card__banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 16px;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 4px 4px 0 0;

    & + .header {
      border-radius: 0;
    }
  }

  .header {
    padding-top: 8px;
    padding-bottom: 8px;

    &__left {
      display: flex;
    }
    &__avatar {
      margin-right: 0.5em;
      width: 50px;
      height: 50px;
    }
    &__author {
      font-size: 0.95em;
      font-weight: bold;
    }
    &__date,
    &__location {
      color: gray;
      font-size: 0.6em;
      display: flex;
      align-items: center;
      padding: 1px;
      img {
        width: 12px;
        height: 12px;
        margin-right: 0.4em;
      }
    }
  }
</style>
