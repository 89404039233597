import jwt_decode from 'jwt-decode';
import { handleBiometricError } from './errors';

const storedStringToTokenObject = (storedString) => {
  const string = storedString.split(':');
  return {
    tokenId: string[0],
    token: string[1],
  };
};

const tokenObjectToStoredString = ({ tokenId, token }) => {
  return `${tokenId}:${token}`;
};

/**
 * @link https://stackoverflow.com/a/1349426
 */
export const generateTokenId = (length = 6) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const loadEncryptedStoredTokens = () => {
  return new Promise((resolve, reject) => {
    if (!('Fingerprint' in window)) {
      reject(
        'Biometric authentication not available. Encrypted tokens and stored accounts only supported on mobile devices'
      );
    }
    window.Fingerprint.loadBiometricSecret(
      {},
      (secret) => {
        try {
          const json = JSON.parse(secret);
          resolve(json.map(storedStringToTokenObject) || []);
        } catch (err) {
          resolve([]);
        }
      },
      handleBiometricError(resolve, reject)
    );
  });
};

export const saveEncryptedStoredTokens = (tokens) => {
  return new Promise((resolve, reject) => {
    if (!('Fingerprint' in window)) {
      reject(
        'Biometric authentication not available. Encrypted tokens and stored accounts only supported on mobile devices'
      );
    }
    window.Fingerprint.registerBiometricSecret(
      {
        title: 'Confirm Biometrics',
        subtitle: 'Confirm access to device for future logins',
        secret: JSON.stringify(tokens.map(tokenObjectToStoredString)),
      },
      resolve,
      handleBiometricError(resolve, reject)
    );
  });
};

/**
 * The JWT returns the expiration time in seconds,
 * so we need to convert it to milliseconds to
 * compare against the current time from Date.now()
 *
 * @param {string} token
 * @returns int
 */
export const getTokenExpiration = async (token) => {
  const decoded = await jwt_decode(token);
  return decoded.exp * 1000;
};
