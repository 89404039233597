<template>
  <f7-navbar id="header-nav">
    <div class="np-border--fancy" />
    <f7-nav-left class="header-nav-group-selector">
      <div>
        <div class="image-container">
          <img src="../static/nl_logo.png">
        </div>
        <div
          v-if="loaded"
          class="group-dropdown-list cursor-pointer"
        >
          <a
            v-if="groups.length > 1"
            class="item-link smart-select smart-select-init"
            data-open-in="popover"
            data-routable-modals="false"
          >
            <span
              v-if="currentOrganization"
              class="organization-name"
              v-text="currentOrganization.name"
            />
            <select
              name="superhero"
              :value="currentGroup._id"
              @change="setCurrentGroup"
            >
              <option
                v-for="group in groups"
                :key="group._id"
                :value="group._id"
                v-text="getGroupDropdownLabel(group)"
              />
            </select>
            <span class="item-content">
              <div class="item-inner">
                <label class="group-name">
                  <span>{{ currentGroup.name }}</span> <f7-icon
                    f7="down"
                    class="group-name-icon"
                  />
                </label>
              </div>
            </span>
          </a>
          <span
            v-else-if="groups.length === 1"
            class="item-link"
          >
            <span class="item-content">
              <span class="organization-name">{{ currentOrganization.name }}</span>
              <div class="item-inner">
                <label class="group-name">{{ currentGroup.name }}</label>
              </div>
            </span>
          </span>
          <span
            v-else
            class="item-link"
          >
            <span class="item-content">
              <div class="item-inner">
                <label class="group-name"> No Groups </label>
              </div>
            </span>
          </span>
        </div>
      </div>
    </f7-nav-left>
    <f7-nav-right
      v-if="loaded"
      class="header-nav-button-list"
    >
      <f7-link
        v-if="$can('create', 'mailer/v2')"
        class="header-nav-button link popup-open"
        popup-open="#feedback-form-popup"
      >
        <f7-icon f7="help" />
      </f7-link>
      <f7-link
        v-if="$can('create', 'conversations')"
        class="header-nav-button"
        href="/app/conversations/"
      >
        <NotificationIcon
          class="header-nav-icon"
          f7="chat"
          :count="unreadConversationCount"
        />
      </f7-link>
      <f7-link
        v-if="isMobile"
        class="header-nav-button"
        href="/app/notifications/"
      >
        <NotificationIcon
          class="header-nav-icon"
          f7="bell"
          :count="unreadNotificationCount"
        />
      </f7-link>
      <div class="user-select__container">
        <UserSelect />
      </div>
    </f7-nav-right>
  </f7-navbar>
</template>
<script>
  import { permissions } from '@/mixins/user';
  import NotificationIcon from './NotificationIcon';
  import { mapState, mapGetters, mapMutations } from 'vuex';
  import UserSelect from './HeaderUserMenu';

  import { mediaTransformationUrl } from '@/helpers/media/remote';

  export default {
    components: {
      NotificationIcon,
      UserSelect,
    },
    mixins: [permissions],
    computed: {
      ...mapState('auth', ['user']),
      ...mapState(['unreadConversationCount', 'unreadNotificationCount']),
      ...mapGetters(['currentOrganization', 'currentGroup']),
      ...mapGetters('groups', {
        findGroupsInStore: 'find',
        getGroupInStore: 'get',
      }),
      ...mapGetters('organizations', {
        getOrganizationInStore: 'get',
      }),
      ...mapGetters('users', {
        getUserInStore: 'get',
      }),
      loaded() {
        return this.groups.length > 0 && this.currentGroup;
      },
      groupsQuery() {
        return {
          'members._id': this.user._id,
          $sort: {
            name: 1,
          },
          $client: {
            collation: { locale: 'en', strength: 1 },
          },
        };
      },
      groups() {
        return this.findGroupsInStore({ paginate: false, query: this.groupsQuery }).data;
      },
      isMobile() {
        return this.$store.getters.isMobile;
      },
      currentUser() {
        return this.getUserInStore(this.user._id);
      },
      avatarUrl() {
        if (!this.currentUser.avatarUrl) return null;

        return mediaTransformationUrl(this.currentUser.avatarUrl, {
          scale_crop: '100x100/smart_faces',
        });
      },
    },
    watch: {
      groups(newVal, oldVal) {
        if (newVal && newVal.length === 0 && oldVal && oldVal.length > 0) {
          // User was removed from all groups, redirect them 'No Groups' notice
          this.$f7.views.main.router.navigate('/app/nogroups/');
        }
      },
    },
    methods: {
      ...mapMutations('tabs/data', { setActiveDataTabIndex: 'setActiveTabIndex' }),
      // eslint-disable-next-line complexity
      setCurrentGroup(event) {
        const groupId = event.target.value;

        let waitForRedirect = false;

        const currentRoutePath = this.$f7.views.main.router.currentRoute.path;
        if (currentRoutePath && currentRoutePath.includes('/data/')) {
          const canReadBehaviors = this.userCanReadBehaviorsInGroup(groupId);
          const canReadShiftNotes = this.userCanReadShiftNotesInGroup(groupId);
          if (!canReadBehaviors && !canReadShiftNotes) {
            // Because of an f7 bug, we need to wait for navigation to complete
            // before we can close the popup or it does not fully close.
            this.$f7.views.main.router.navigate('/app/');
            waitForRedirect = true;
          } else if (!canReadBehaviors) {
            this.setActiveDataTabIndex(1);
          } else {
            this.setActiveDataTabIndex(0);
          }
        }

        if (waitForRedirect) {
          setTimeout(() => {
            this.$f7.popover.close();
          }, 500);
        } else {
          this.$f7.popover.close();
        }

        this.$store.commit('setGroupId', groupId);
      },
      getGroupDropdownLabel(group) {
        const label = group.name;
        if (group.organization === this.currentGroup.organization) {
          return label;
        }

        return `${label} (${group.organizationData.name})`;
      },
    },
  };
</script>
<style scoped lang="scss">
  #header-nav {
    background-color: color(white);
    height: 1.07 * $tab_height;
    color: color(text-color);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5001;
    .header-nav-group-selector {
      margin-left: 0.5em;
      margin-top: 6px;
      div {
        display: flex;
        .image-container {
          height: 32px;
          width: 32px;
          border: 2px solid color(gray);
          border-radius: 100%;
          margin-right: 0.3em;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 28px;
            object-fit: contain;
            aspect-ratio: 1;
          }
        }
        .group-dropdown-list {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          .organization-name {
            font-size: 1.1em;
            font-weight: bold;
          }
          .item-link {
            font-size: 0.6em;
            font-style: italic;
          }
          label {
            cursor: pointer;
          }
        }
      }
    }
    .header-nav-button-list {
      display: flex;
      justify-content: flex-end;
      margin-top: 5px;
      .header-nav-button {
        margin-left: -20px;
        border: none;
        color: color(text-color);
        @media (max-width: 360px) {
          margin-left: -8px;
        }
        @media (max-width: 320px) {
          margin-left: -16px;
        }
      }
      .user-select__container {
        padding-right: 16px;
      }
    }
    i.group-name-icon {
      font-size: 16px;
      margin-left: 0.17em;
    }
  }
  .f7-icons {
    font-size: 29px;
    @media (max-width: 320px) {
      font-size: 22px;
    }
  }
  .np-border--fancy {
    height: 4px;
    width: 100%;
    background: url('../assets/images/nupath-border-bg.svg') repeat-x;
    position: absolute;
    top: 0;
  }
  .group-name {
    display: flex;
    align-items: center;
    i {
      flex-shrink: 0;
    }
    span {
      display: inline-block;
      padding-right: 6px;
      max-width: calc((100vw / 2) - 59px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .profile-avatar {
    border-radius: 50%;
    border: 1.5px solid #b0b0b0;
    padding: 1.5px;
    width: 32px;
    height: 32px;
    @media (max-width: 320px) {
      width: 24px;
      height: 24px;
    }
  }
</style>
