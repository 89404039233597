import moment from 'moment';
import { mapState } from 'vuex';
import { permissions } from './user';

export default {
  mixins: [permissions],
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    formatDateTime(time) {
      const date = moment.isMoment(time) ? time : moment(time);
      if (date.isBefore(moment(), 'year')) {
        return date.format('MMM D YYYY, h:mmA');
      }
      if (date.isBefore(moment().subtract(1, 'week'))) {
        return date.format('MMM D, h:mmA');
      }

      if (date.isBefore(moment().subtract(20, 'hours'))) {
        return date.format('ddd h:mmA');
      }
      return date.fromNow();
    },
    formatDateTimeAbsolute(time) {
      const date = moment.isMoment(time) ? time : moment(time);

      return date.format('MMM D, h:mmA');
    },
    formatFeedItemDateTime(time) {
      return this.userCanViewFeedItemDatetimeAbsolute
        ? this.formatDateTimeAbsolute(time)
        : this.formatDateTime(time);
    },
    formatDurationSeconds(seconds) {
      return moment
        .duration(seconds, 'seconds')
        .humanize(false, { ss: 0, s: 60, m: 60, h: 24, d: 7 });
    },
  },
};
