import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import { serviceRoles } from '../helpers/user-roles';

export const permissions = {
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters(['currentGroup']),
    userCanReadBehaviors() {
      if (!this.currentGroup) return false;

      return this.userCanReadBehaviorsInGroup(this.currentGroup._id);
    },
    userCanReadShiftNotes() {
      if (!this.currentGroup) return false;

      return this.userCanReadShiftNotesInGroup(this.currentGroup._id);
    },
    userCanCreatePostWithoutLocation() {
      if (!this.currentGroup) return false;

      const noLocationPost = {
        modelName: 'posts',
        type: 'post',
        _id: null,
        group: this.currentGroup._id,
        location: null,
      };

      return this.$can('create', noLocationPost);
    },
    userCanFindGeocodeWithLimitRadius() {
      const allowLimitRadiusGeocode = {
        modelName: 'geocode',
        type: 'geocode',
        allowLimitRadius: true,
      };

      return this.$can('find', allowLimitRadiusGeocode);
    },
    userCanReadPostsCreatedBeforeSignupDate() {
      if (!this.currentGroup) return false;

      const oldPost = {
        modelName: 'posts',
        type: 'post',
        _id: null,
        createdAt: moment(this.user.createdAt)
          .subtract(1, 'hour')
          .toISOString(),
        group: this.currentGroup._id,
      };

      return this.$can('read', oldPost);
    },
    userCanViewFeedItemDatetimeAbsolute() {
      if (!this.user) return false;

      return serviceRoles.includes(this.user.role);
    },
  },
  methods: {
    userCanCreateConversationWithParticipant(participantId) {
      const conversationWithParticipant = {
        modelName: 'conversations',
        type: 'conversation',
        participants: [participantId],
      };

      return this.$can('create', conversationWithParticipant);
    },
    userCanReadBehaviorsInGroup(groupId) {
      const behavior = {
        modelName: 'behaviors',
        type: 'behavior',
        group: groupId,
      };
      return this.$can('read', behavior);
    },
    userCanReadShiftNotesInGroup(groupId) {
      const shiftNote = {
        modelName: 'shiftNotes',
        type: 'shiftNote',
        group: groupId,
      };
      return this.$can('read', shiftNote);
    },
  },
};
