/* eslint-disable complexity */
import { STORED_USERS_KEY, getItem, setItem } from '../../../helpers/local-fs';
import { generateTokenId, loadEncryptedStoredTokens, saveEncryptedStoredTokens, getTokenExpiration } from './lib';

import { InvalidPinError, UserNotSavedError } from './errors';

export default {
  namespaced: true,

  state: {
    stored: [],
  },

  getters: {
    stored(state) {
      return state.stored;
    },

    currentUserStored: state => email => {
      return state.stored.find(account => account.email === email);
    },

    isEmailInSavedAccounts: (state, getters) => email => {
      return getters.currentUserStored(email) !== undefined;
    },

    isExpired: (state, getters) => ({ expiration }) => {
      return expiration < Date.now();
    },
  },

  mutations: {
    setStored(state, stored) {
      state.stored = stored;
    },
  },

  actions: {
    async loadStored({ commit }) {
      const stored = (await getItem(STORED_USERS_KEY)) || [];
      console.log(stored);
      const validStored = stored.filter(account => account.expiration > Date.now());
      commit('setStored', validStored);
    },

    async setStored({ commit }, stored) {
      stored = stored.sort((a, b) => b.lastUsedAt - a.lastUsedAt);
      await setItem(STORED_USERS_KEY, stored);
      commit('setStored', stored);
    },

    async remove({ state, dispatch }, email) {
      await dispatch('loadStored');
      const stored = state.stored.filter(account => account.email !== email);
      dispatch('setStored', stored);
    },

    async validateWithPin({ state, dispatch }, { email, pin }) {
      await dispatch('loadStored');
      const account = state.stored.find(account => account.email === email);
      if (!account) {
        throw new UserNotSavedError(`User with email ${email} not found`);
      }
      if (account.pin !== pin) {
        throw new InvalidPinError(`Invalid pin for user with email ${email}`);
      }
      return true;
    },

    async login({ state, dispatch }, { email, tokenId, pin }) {
      await dispatch('loadStored');

      const storedUsers = state.stored;
      pin = parseInt(pin, 10);
      if (isNaN(pin)) {
        throw new InvalidPinError('PIN must be a number');
      }

      const storedUserIdx = storedUsers.findIndex(account => account.email === email && account.tokenId === tokenId);

      if (storedUserIdx === -1) {
        throw new UserNotSavedError('User account not saved');
      } else if (storedUsers[storedUserIdx].pin !== pin) {
        throw new InvalidPinError('Invalid pin');
      }

      const tokens = await loadEncryptedStoredTokens();

      const tokenObj = tokens.find(token => token.tokenId === storedUsers[storedUserIdx].tokenId);

      if (tokenObj === undefined) {
        throw new Error('Token not found in biometric secret');
      }

      return dispatch(
        'auth/authenticate',
        {
          strategy: 'refreshToken',
          token: tokenObj.token,
        },
        { root: true }
      ).then(result => {
        storedUsers[storedUserIdx].lastUsedAt = Date.now();
        dispatch('setStored', storedUsers);
        return result;
      });
    },

    async save({ state, dispatch, getters }, { email, token, pin }) {
      pin = parseInt(pin, 10);
      if (isNaN(pin) || !(pin.toString().length >= 4 && pin.toString().length <= 6)) {
        throw new InvalidPinError('PIN is required and must be a valid number between 4 and 6 digits');
      }

      await dispatch('loadStored');

      const storedAccounts = state.stored;
      const expiration = await getTokenExpiration(token);
      let tokens = [];

      if (storedAccounts.length > 0) {
        tokens = await loadEncryptedStoredTokens();
      }

      const accountData = {
        expiration,
        pin,
        lastUsedAt: Date.now(),
      };

      if (storedAccounts.length === 0 || !getters.isEmailInSavedAccounts(email)) {
        const tokenId = generateTokenId();
        tokens.push({ tokenId, token });
        storedAccounts.push({ email, tokenId, ...accountData });
      } else {
        const { tokenId } = getters.currentUserStored(email);
        const tokenIdx = tokens.findIndex(token => token.tokenId === tokenId);
        const acctIdx = storedAccounts.findIndex(account => account.email === email);

        if (tokenIdx === -1) {
          const newTokenId = generateTokenId();
          tokens.push({ tokenId: newTokenId, token });
          storedAccounts[acctIdx].tokenId = newTokenId;
        } else {
          tokens[tokenIdx].token = token;
        }
        storedAccounts[acctIdx] = { ...storedAccounts[acctIdx], ...accountData };
      }

      if (storedAccounts.length > 10) {
        storedAccounts.sort((a, b) => b.lastUsedAt - a.lastUsedAt).splice(10);
      }

      // Filter out any tokens in encrypted token storage
      // that aren't present in stored accounts
      tokens = tokens.filter(t => storedAccounts.some(a => t.tokenId === a.tokenId));

      try {
        await saveEncryptedStoredTokens(tokens);
        await dispatch('setStored', storedAccounts);
      } catch (err) {
        throw new Error('Failed to save user account');
      }
    },
  },
};
