var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$can('read', _vm.item))?_c('div',{staticClass:"post"},[_c('f7-card',{staticClass:"post-item"},[_c('f7-card-header',{staticClass:"header headr-topper-post display-flex justify-content-space-between"},[_c('div',{staticClass:"display-flex align-items-center"},[_c('Avatar',{attrs:{"user-id":_vm.item.createdBy}}),_c('div',{staticClass:"user"},[_c('div',{staticClass:"user-name cursor-pointer",on:{"click":function($event){_vm.author ? _vm.openUserProfile(_vm.author._id) : null}}},[_c('UserDisplayName',{attrs:{"user-id":_vm.item.createdBy}})],1),_c('div',{staticClass:"info"},[_c('span',{staticClass:"date"},[_c('img',{attrs:{"src":require("@/assets/images/icons/clock.svg")}}),_c('span',{domProps:{"textContent":_vm._s(_vm.formatFeedItemDateTime(_vm.item.createdAt))}})]),(_vm.item.location && _vm.item.location.name)?_c('span',{staticClass:"location"},[_c('img',{attrs:{"src":require("@/assets/images/icons/location-gray.svg")}}),_vm._v(" "+_vm._s(_vm.item.location.name)+" ")]):_vm._e()])])],1),_c('MenuButton',{on:{"click":function($event){return _vm.openModal(_vm.item, 'actionModal')}}})],1),_c('f7-card-content',{staticClass:"content"},[_c('p',{staticClass:"content__text"},[_c('SanitizedText',{attrs:{"text":_vm.item.text}}),(_vm.taggedClients.length)?_c('span',{staticClass:"tagging"},[_vm._v(" - with "),_vm._l((_vm.taggedClients.slice(0, 3)),function(client,index){return _c('span',{key:client._id},[_vm._v(" "+_vm._s(`${index !== 0 && _vm.taggedClients.length > 1 ? ', ' : ''} ${client.firstName} ${client.lastName}`)+" ")])}),(_vm.taggedClients.length > 3)?_c('f7-link',{on:{"click":_vm.showAllTaggedClients}},[_vm._v(" + "+_vm._s(_vm.taggedClients.length - 3)+" more ")]):_vm._e()],2):_vm._e()],1),(_vm.medias)?_c('div',{staticClass:"medias-container",class:{
          'two-medias': _vm.medias.length === 2,
          'three-medias': _vm.medias.length === 3,
        }},[_vm._l((_vm.medias.length >= 4 ? _vm.medias.slice(0, 4) : _vm.medias),function(media){return _c('div',{key:media.url,ref:"mediaHolder",refInFor:true,staticClass:"media-holder",style:({
            backgroundImage: 'url(' + media.thumbnailUrl + ')',
            backgroundColor: 'black',
          }),on:{"click":function($event){return _vm.openPhotoBrowser(media.url)}}},[(media.type === 'video' && _vm.medias.length <= 4)?_c('img',{staticClass:"videoPlayIcon",attrs:{"src":require("@/assets/images/icons/play-rounded-button.svg")}}):_vm._e()])}),(_vm.medias.length > 4)?_c('div',{staticClass:"overlay",on:{"click":function($event){return _vm.openModal(_vm.item, 'gallery')}}},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.medias.length - 4)+" +")])]):_vm._e()],2):_vm._e()]),_c('f7-card-footer',{staticClass:"post-footer"},[_c('div',{staticClass:"likes-wrapper"},[_c('f7-button',{staticClass:"btn-likes",on:{"click":_vm.toggleLike}},[_c('div',{staticClass:"icon-holder"},[(_vm.isLiked)?_c('img',{staticClass:"icon-holder__icon",attrs:{"src":require("@/assets/images/icons/like-fill.svg")}}):_c('img',{staticClass:"icon-holder__icon",attrs:{"src":require("@/assets/images/icons/like.svg")}})])]),(_vm.likesCount)?_c('a',{staticClass:"indicator indicator-like",on:{"click":function($event){return _vm.openModal(_vm.item, 'likesSheetModal')}}},[_vm._v(_vm._s(_vm.likesCount))]):_vm._e()],1),(_vm.$can('read', 'comments'))?_c('div',{staticClass:"comments-wrapper",on:{"click":function($event){return _vm.openModal(_vm.item, 'commentsSheetModal')}}},[_c('f7-button',{staticClass:"btn-comments"},[_c('div',{staticClass:"icon-holder"},[_c('img',{staticClass:"icon-holder__icon",attrs:{"src":require("@/assets/images/icons/comment.svg")}})])]),(_vm.commentsCount)?_c('span',{staticClass:"indicator indicator-comment"},[_vm._v(_vm._s(_vm.commentsCount))]):_vm._e()],1):_vm._e()]),(_vm.commentsCount && _vm.$can('read', 'comments'))?[_c('div',{staticClass:"last-comment-wrapper"},_vm._l((_vm.latestComments),function(comment,i){return _c('div',{key:i,staticClass:"lastest-comment-box"},[_c('span',{staticClass:"author"},[_c('UserDisplayName',{attrs:{"user-id":comment.author ? comment.author._id : null}}),_vm._v(": ")],1),_c('span',[_vm._v(_vm._s(comment.text))])])}),0),_c('div',{style:({
          height: _vm.commentsCount > 1 ? '45px' : '25px',
          position: 'relative',
        })},[(_vm.commentsCount > 1)?_c('div',{staticClass:"lastest-comment-box",staticStyle:{"margin":"0px 16px","min-height":"5px"}}):_vm._e(),_c('div',{staticClass:"see-more-comment",style:({
            cursor: 'pointer',
            top: _vm.commentsCount > 1 ? '50%' : '25%',
            paddingTop: _vm.commentsCount > 1 ? '5px' : '0',
          }),on:{"click":function($event){return _vm.openModal(_vm.item, 'commentsSheetModal')}}},[_vm._v(" ↓ see comments ↓ ")])])]:_vm._e()],2),_c('TaggedUsersList')],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }