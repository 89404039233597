import Vue from 'vue';
import Framework7 from 'framework7/framework7.esm.bundle.js';
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle';
import 'framework7/css/framework7.css';
import './assets/css/fonts.css';
import './assets/css/icons.css';
import './assets/css/custom-framework7-theme.css';
import store from './store';
import ability from './store/ability';
import './assets/css/iosModifications.css';
import App from './App';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueTimeago from 'vue-timeago';
import { abilitiesPlugin } from '@casl/vue';
import VBodyScrollLock from 'v-body-scroll-lock';
import VueCompositionApi from '@vue/composition-api';
import PortalVue from 'portal-vue';
import OneSignalVue from 'onesignal-vue';
import VueObserveVisibility from 'vue-observe-visibility';
import { msalInstance } from './authConfig';

Vue.use(OneSignalVue);
Vue.use(VueObserveVisibility);
Vue.use(VueCompositionApi);

Vue.use(abilitiesPlugin, ability);

Vue.use(PortalVue);

window._ = require('lodash');
window.moment = require('moment');
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
  },
});

Vue.use(VueTimeago, {
  locale: 'en',
});
Vue.use(VBodyScrollLock);

// Init F7 Vue Plugin
Framework7.use(Framework7Vue);

// Init App
new Vue({
  el: '#app',
  beforeMount() {
    this.$OneSignal.init({ appId: process.env.VUE_APP_ONESIGNAL_APP_ID });
    this.$msal = msalInstance; 
  },
  render: (h) => h(App),
  store,
});
