<template>
  <Slide title="Overview">
    <p>
      With real-time sharing within the mobile app, instant messaging, and location check-ins.
      Navigating Life offers the features your community needs.
    </p>

    <ul class="features">
      <li>
        <img src="@/assets/images/sharing.svg">
        <span>Social Features</span>
      </li>
      <li>
        <img src="@/assets/images/clinical.svg">
        <span>Record Clinical Data</span>
      </li>
      <li>
        <img src="@/assets/images/messaging.svg">
        <span>Integrated Messaging</span>
      </li>
      <li>
        <img src="@/assets/images/groupSupport.svg">
        <span>Multiple Group Support</span>
      </li>
      <li>
        <img src="@/assets/images/history.svg">
        <span>Track History</span>
      </li>
      <li>
        <img src="@/assets/images/support.svg">
        <span>Safety and Security</span>
      </li>
    </ul>
  </Slide>
</template>

<script>
  import Slide from '@/components/welcomeSlider/Slide';
  export default {
    components: {
      Slide,
    },
  };
</script>

<style lang="scss" scoped>
  p {
    margin-bottom: 10rem;
  }

  .features {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;

    li {
      flex: 0 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      margin-bottom: 2em;

      @media (max-width: $tablet_width) {
        font-size: 1rem;
      }

      img {
        width: 38px;
        height: 38px;
        margin-bottom: 1.5em;
      }
    }
  }
</style>
