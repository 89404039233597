import UAParser from 'ua-parser-js';

export const getDeviceData = () => {
  let manufacturer;
  let model;
  let platform;
  let version;

  if (window.device) {
    ({ manufacturer, model, platform, version } = window.device);
  } else {
    const uaparser = new UAParser();
    const browser = uaparser.getBrowser();
    const device = uaparser.getDevice();

    manufacturer = device.vendor;
    ({ model } = device);
    platform = `${browser.name} Browser`;
    ({ version } = browser);
  }

  return {
    manufacturer,
    model,
    platform,
    version,
  };
};
