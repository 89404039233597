<template>
  <div class="embeddedTabs page-content">
    <div class="embeddedTabs__toolbar">
      <div class="embeddedTabs__toolbar__inner">
        <button
          v-for="(tab, index) in tabs"
          :key="tab.id"
          class="embeddedTabs__toolbar__link"
          @click="$emit('tabSelected', index)"
        >
          <img
            v-if="tab.icon"
            :src="tab.icon"
          >
          <span>{{ tab.title }}</span>
        </button>
      </div>
      <span
        class="embeddedTabs__toolbar__highlight"
        :style="{
          width: `${100 / tabs.length}%`,
          transform: `translate3d(${100 * activeTabIndex}%, 0, 0)`,
        }"
      />
    </div>
    <div class="embeddedTabs__tabs page-content">
      <slot />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      activeTabIndex: {
        type: Number,
        required: true,
        default: 0,
      },
    },
    data() {
      return {
        tabs: [],
      };
    },
    watch: {
      activeTabIndex(i) {
        this.setActiveTab(i);
      },
    },
    mounted() {
      this.setActiveTab(this.activeTabIndex);
    },
    created() {
      this.tabs = this.$children;
    },
    methods: {
      setActiveTab(i) {
        this.tabs.forEach((tab, index) => {
          tab.isActive = index === i;
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .embeddedTabs {
    &__toolbar {
      background-color: color(blue);
      height: 2.5em;
      margin-top: 1.07 * $tab_height;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 500;

      &__inner {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        box-sizing: border-box;
      }

      &__link {
        flex: 1;
        color: white;
        display: flex;
        flex-direction: row;
        font-size: 0.8em;
        justify-content: center;
        align-items: center;
        appearance: none;
        border: none;
        background: none;
        height: 100%;
        cursor: pointer;
      }

      &__highlight {
        position: absolute;
        height: 3px;
        background-color: color(orange);
        bottom: 0;
        transform: translate3d(0, 0, 0);
        transition: 0.2s all cubic-bezier(0.86, 0, 0.07, 1);
      }
    }
  }

  img {
    height: 15px;
    width: 15px;
    margin-right: 0.6em;
  }
</style>
<style>
  .embeddedTabs .fab {
    position: fixed;
  }

  /* 
   * .page-content wrappers are required for framework7 to handle
   * infinite scrolling in sub-containers... but we don't want to
   * retain any of the applied styling.
   */
  .embeddedTabs.page-content,
  .embeddedTabs .page-content:not(.inner-tab-page-content) {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
</style>
