<template>
  <div
    class="notice"
    :class="{ 'bg-green': loading || isVideoConversationActive }"
  >
    <div
      v-if="loading"
      class="d-flex align-center justify-center text-center py-25"
    >
      <f7-preloader color="white" />
    </div>

    <div
      v-else
      class="notice-content d-flex align-center justify-center"
    >
      <div v-if="isVideoConversationEnded">
        <div class="notice-text-header">
          {{ endedHeaderText }}
        </div>
        <div
          v-if="formattedDate"
          class="notice-text-footer"
        >
          {{ formattedDate }}
        </div>
        <div class="notice-text-footer">
          Duration: {{ duration }}
        </div>
      </div>

      <div
        v-else-if="isVideoConversationActive && !isUserActiveParticipant"
        @click="join"
      >
        <div class="notice-text-header">
          {{ activeHeaderText }}
        </div>
        <div
          v-if="isCordova"
          class="notice-text-active"
        >
          JOIN VIDEO CALL
        </div>
        <div
          v-else
          class="notice-text-active-not-supported"
        >
          Only mobile devices can join video calls.
        </div>
        <div
          v-if="formattedDate"
          class="notice-text-footer"
        >
          {{ formattedDate }}
        </div>
      </div>

      <div
        v-else-if="isVideoConversationActive && isUserActiveParticipant"
        class="py-25"
      >
        <div class="notice-text-header">
          {{ activeHeaderText }}
        </div>
        <div
          v-if="formattedDate"
          class="notice-text-footer"
        >
          {{ formattedDate }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import datesMixin from '@/mixins/dates';

export default {
  mixins: [datesMixin],
  props: {
    videoConversation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formattedDate: null,
      updateFormattedDateInterval: null,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters(['isCordova']),
    ...mapGetters('users', {
      getUserInStore: 'get',
    }),
    isVideoConversationEnded() {
      return !!this.videoConversation.endedAt;
    },
    isVideoConversationActive() {
      return (
        !this.videoConversation.endedAt &&
        this.videoConversation.allParticipants.includes(
          this.videoConversation.createdBy
        )
      );
    },
    loading() {
      return !this.isVideoConversationEnded && !this.isVideoConversationActive;
    },
    isUserActiveParticipant() {
      return this.videoConversation.activeParticipants.includes(this.user._id);
    },
    wasUserEverParticipant() {
      return this.videoConversation.allParticipants.includes(this.user._id);
    },
    isUserCreator() {
      return this.videoConversation.createdBy === this.user._id;
    },
    duration() {
      return this.formatDurationSeconds(this.videoConversation.durationData);
    },
    activeHeaderText() {
      return this.isUserCreator
        ? 'You started a video call'
        : `${this.createdByUser.firstName} invited you to a video call`;
    },
    endedHeaderText() {
      return this.isUserCreator
        ? 'You started a video call'
        : `${this.createdByUser.firstName} started a video call`;
    },
    createdByUser() {
      return this.getUserInStore(this.videoConversation.createdBy);
    },
  },
  created() {
    this.updateFormattedDate();
    this.updateFormattedDateInterval = setInterval(
      this.updateFormattedDate,
      60000
    );
  },
  beforeDestroy() {
    clearInterval(this.updateFormattedDateInterval);
  },
  methods: {
    join() {
      if (this.isCordova)
        this.$emit('join', this.videoConversation.dolbyioConferenceId);
    },
    updateFormattedDate() {
      this.formattedDate = this.formatDateTimeAbsolute(
        this.videoConversation.createdAt
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.notice {
  max-width: 90%;

  margin-left: auto;
  margin-right: auto;

  margin-top: 16px;
  margin-bottom: 16px;

  background-color: gray;

  color: white;

  &__active {
    background-color: #a6d274 !important;
  }

  &-content {
    text-align: center;

    padding: 0.5em 1em 0.5em 1em;
    border-radius: 10px;

    .notice-text {
      &-header {
        font-weight: bold;
        font-size: 0.8em;
      }

      &-active {
        font-weight: bold;
        font-size: 1.4em;
        margin-top: 1em;
        margin-bottom: 1em;
      }

      &-active-unsupported {
        font-size: 1.4em;
        margin-top: 1em;
        margin-bottom: 1em;
      }

      &-footer {
        font-size: 0.65em;
        margin-top: 1em;
      }
    }

    .image-container {
      .avatar-container {
        width: 70px;
      }
    }
  }

  &-text {
    font-size: 0.84em;
    margin-top: 0.4em;
    font-weight: 400;
  }
}

.bg-green {
  background-color: #a6d274 !important;
}

.py-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}
</style>
