<template>
  <div
    v-if="currentOrganization"
    class="page"
    :class="{ 'page--has-selected-clients': selectedClients.length }"
  >
    <f7-navbar>
      <f7-nav-left>
        <f7-link @click="cancel">
          <f7-icon f7="close" />
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ title }}</f7-nav-title>
      <f7-nav-right>
        <f7-link>
          <f7-button
            fill
            color="green"
            @click="confirm"
          >
            {{ confirmButtonText }}
          </f7-button>
        </f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-searchbar
      ref="searchbar"
      @searchbar:disable="resetSearch"
      @input="updateSearchText"
    />
    <div
      v-if="selectedClients.length"
      class="tag-list"
    >
      <div
        v-for="(client, index) in selectedClients"
        :key="client._id"
        class="chip"
        @click="selectedClients.splice(index, 1)"
      >
        <div class="chip-label">
          {{ shortName(client) }}
        </div>
        <f7-link class="chip-delete" />
      </div>
    </div>
    <InfiniteListPage
      service="clients"
      :query="clientsQuery"
      :page-size="10"
      qid="selectClientsList"
      empty-list-message="No clients found"
      :end-of-list-message="availableClientsNotice"
    >
      <template
        slot="default"
        slot-scope="{ items }"
      >
        <div id="main">
          <f7-list class="list-row-reverse">
            <f7-list-item
              v-for="client in searchresultClients"
              :key="client._id"
              class="list-item"
            >
              <label class="item-checkbox item-content">
                <input
                  v-model="selectedClients"
                  type="checkbox"
                  :value="client"
                  class="searchbar-found__item-checkbox"
                >
                <i class="icon icon-checkbox" />
                <div class="item-inner">
                  <div class="item-title">
                    {{ client.firstName }} {{ client.lastName }}
                  </div>
                </div>
              </label>
            </f7-list-item>
          </f7-list>
        </div>
      </template>
    </InfiniteListPage>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { debounce } from 'lodash';
import InfiniteListPage from '@/components/InfiniteListPage';

export default {
  components: {
    InfiniteListPage,
  },
  props: {
    title: {
      type: String,
      default: 'Select Clients',
    },
    confirmButtonText: {
      type: String,
      default: 'Confirm',
    },
    limitToCurrentGroup: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    clearOnConfirm: {
      type: Boolean,
      default: false,
    },
    excludeRoles: {
      type: Array,
      default: () => [],
    },
    excludeCurrentClient: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      perPage: 10,
      selectedClients: [],
      totalClientsCount: null,
      searchText: '',
      searchresultClients: [],
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('clients', {
      findClientsInStore: 'find',
    }),
    ...mapGetters(['currentGroup', 'currentOrganization']),
    //   canViewAllOrgUsers() {
    //     return this.$can('read', {
    //       modelName: 'clients',
    //       organizations: [
    //         {
    //           _id: this.currentOrganization._id,
    //         },
    //       ],
    //     });
    //   },
    availableClientsNotice() {
      let notice = null;
      if (this.limitToCurrentGroup) {
        notice = 'You can only select clients in the current group.';
      }
      //  else if (!this.canViewAllOrgUsers) {
      //   notice = 'You can only select clients that are in the same group(s) as you.';
      // }

      return notice;
    },
    moreClientsToLoad() {
      if (this.searchText.length > 0) return false;

      return (
        this.totalClientsCount === null ||
        this.clients.length < this.totalClientsCount
      );
    },
    clients() {
      return this.searchText.length > 0
        ? this.searchresultClients
        : this.findClientsInStore({ paginate: false, query: this.clientsQuery })
            .data;
    },
    clientsQuery() {
      const query = {
        $sort: { firstName: 1 },
        organization: this.currentOrganization._id,
      };

      if (this.excludeCurrentUser) {
        query._id = { ...query._id, $ne: this.user._id };
      }

      return query;
    },
    selectedClientIds() {
      return this.selectedClients.map(client => client._id);
    },
  },
  created() {
    this.searchClientsDebounced = debounce(this.searchClients, 300);
    this.loadSelectedClients();
    this.searchClients();
  },
  methods: {
    ...mapActions('clients', {
      findClients: 'find',
    }),

    // Fetch Data
    async searchClients() {
      const query = {
        $or: [
          { firstName: { $search: this.searchText } },
          { lastName: { $search: this.searchText } },
        ],
        ...this.clientsQuery,
      };

      const { data } = await this.findClients({
        query,
      });

      this.searchresultClients = data;
    },
    async loadSelectedClients() {
      const { data } = await this.findClients({
        query: {
          _id: {
            $in: this.value,
          },
        },
      });

      this.selectedClients = data;
    },

    // Client Actions
    confirm() {
      this.resetSearch();
      this.closePopup();
      this.$emit('input', this.selectedClientIds);

      if (this.clearOnConfirm) {
        this.selectedClients = [];
      }
    },
    cancel() {
      this.loadSelectedClients();
      this.resetSearch();
      this.closePopup();
    },

    // Helpers
    resetSearch() {
      this.searchText = '';
      this.$refs.searchbar.disable();
    },
    closePopup() {
      this.$emit('close');
    },

    shortName(client) {
      return `${client.firstName} ${client.lastName.charAt(0).toUpperCase()}`;
    },

    updateSearchText(event) {
      this.searchText = event.target.value.trim();

      this.searchClientsDebounced();
    },
  },
};
</script>

<style lang="scss" scoped>
.inner {
  background: color(bg-color);
}
#main {
  .list-item {
    background: color(white);
    border-bottom: 1px solid #ddd;
    width: 90%;
    margin: auto;
    font-weight: bold;
  }
  .list-group-title {
    background: color(bg-color);
  }
}
.list {
  margin-top: 0;
}
.tag-list {
  margin-top: 0px;
  padding: 0.5em 0 0.5em 1em;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  background: lighten(color(light-blue), 22%);
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 12;
  top: 99px;
  box-sizing: border-box;

  .chip {
    background-color: color(blue);
    color: white;
    margin-right: 10px;
    margin-bottom: 5px;
    width: 29%;
    display: flex;
    justify-content: space-between;
  }
}
.list-row-reverse {
  .item-content {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
  }
}
.np-border--fancy {
  height: 4px;
  width: 100%;
  background: url('../assets/images/nupath-border-bg.svg') repeat-x;
  position: absolute;
  top: 0;
}
.subnavbar {
  border-top: 1px solid color(text-color);
}

.page.page--has-selected-clients .tag-list ~ .page-content {
  padding-top: 156px;
}
</style>
