<template>
  <f7-popup
    id="search-by-date"
    :opened="value"
    @popup:closed="closePopup"
  >
    <div class="page">
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click="$emit('input', false)">
            <f7-icon f7="close" />
          </f7-link>
        </f7-nav-left>
        <f7-nav-title>Feed Filters</f7-nav-title>
        <f7-nav-right class="btns-container">
          <f7-link>
            <f7-button
              class="btn-submit"
              fill
              @click="confirm"
            >
              Confirm
            </f7-button>
          </f7-link>
          <f7-link>
            <f7-button
              fill
              @click="filters = {}"
            >
              Clear
            </f7-button>
          </f7-link>
        </f7-nav-right>
      </f7-navbar>
      <div class="page-content">
        <div class="page-content__client">
          <h3>By Client</h3>
          <SearchClients v-model="filters.selectedClient" />
        </div>
        <div class="page-content__dates">
          <h3>By Date Range</h3>
          <DateFeedFilter v-model="filters.dateRange" />
        </div>
      </div>
    </div>
  </f7-popup>
</template>

<script>
import moment from 'moment';
import SearchClients from './SearchClients.vue';
import DateFeedFilter from './DateFeedFilter';

const dateFormat = 'YYYY-MM-DD';

export default {
  name: 'FeedFilters',
  components: { SearchClients, DateFeedFilter },
  props: {
    value: {
      type: Boolean,
      default: true,
      required: true,
    },
    pickerOptions: {
      type: Object,
      default: () => ({
        formatted: 'MMM DD',
        range: true,
        noShortcuts: false,
        noHeader: true,
        onlyDate: true,
        noValueToCustomElem: true,
        inline: true,
      }),
    },
  },
  data() {
    return {
      filters: {},
      oldFilters: {},
    };
  },
  computed: {
    customRangeSet() {
      return this.customInterval.range.length === 2;
    },
    customRangeProperlyFormed() {
      return (
        this.customInterval.range.every(date =>
          moment(date, dateFormat).isValid()
        ) &&
        moment(this.customInterval.range[0]).isSameOrBefore(
          this.customInterval.range[1]
        )
      );
    },
    customRangeValid() {
      return this.customRangeSet && this.customRangeProperlyFormed;
    },
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.oldFilters = { ...this.filters };
      }
    },
  },
  methods: {
    closePopup() {
      this.filters = { ...this.oldFilters };
      this.$emit('input', false);
    },
    confirm(interval) {
      if (interval && interval.label !== 'Custom') {
        this.showCustom = false;
      }
      this.oldFilters = { ...this.filters };
      this.$emit('input', false);
      this.$emit('filter', {
        selectedClient: this.filters.selectedClient,
        selectedInterval: this.filters.dateRange ? this.filters.dateRange : {},
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.page-content__dates {
  padding: 1rem;
}

.shortcut-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.shortcut-grid button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  appearance: none;
  background: #fff;
  border: 2px solid #d3dae3;
  border-radius: 8px;
}

.shortcut-grid button:not(:last-of-type)::before {
  --aspect-ratio: 1 / 1;
  content: '';
  display: inline-block;
  width: 1px;
  height: 0;
  padding-bottom: calc(100% / var(--aspect-ratio));

  @media screen and (min-width: 768px) {
    --aspect-ratio: 1 / 2;
  }
}

.shortcut-grid button.selected {
  background: #d3dae3;
}

.shortcut-grid button:last-of-type {
  grid-column: span 3;
  padding: 2rem;
}

.custom {
  margin-top: 1rem;
}

.submit-button {
  appearance: none;
  border: none;
  text-transform: uppercase;
  border-radius: 5px;
  background-color: color(pink);
  color: color(white);
  font-weight: bold;
  cursor: pointer;
}

.centered-label {
  text-align: center;
}

.btns-container {
  display: grid;
  grid-auto-flow: column;
}

.page-content__dates {
  border-top: solid 1px #dddddd;
  margin: 16px;
  padding: 0 !important;
}

.page-content__client {
  margin: 16px;
}
</style>
