<template>
  <Slide title="Welcome">
    <img
      slot="image"
      src="@/assets/images/NL-Logo-White-Circle.png"
      alt="Navigating Life Logo"
      class="logo"
    >
    <p>
      Navigating Life is a private social networking application designed to create and support
      communities. Take a quick tour to get acquanted.
    </p>

    <WelcomeSliderButton @click="playVideo">
      <img src="@/assets/images/icons/movie.svg">Watch Intro Video
    </WelcomeSliderButton>

    <WelcomeSliderButton
      ghost
      @click="$emit('skipToLogin')"
    >
      Go To Login
    </WelcomeSliderButton>

    <video
      id="welcomeVideo"
      ref="welcomeVideo"
      width="1"
      height="1"
      controls
      @pause="pauseVideo"
      @close="pauseVideo"
      @click="pauseVideo"
    >
      <source
        src="https://ucarecdn.com/865a0656-f140-49b6-adb5-2813b971b926/navlife2021_phoneonly.mp4"
        type="video/mp4"
      >
    </video>
  </Slide>
</template>

<script>
  import WelcomeSliderButton from './WelcomeSliderButton';
  import Slide from '@/components/welcomeSlider/Slide';
  export default {
    components: {
      Slide,
      WelcomeSliderButton,
    },
    methods: {
      playVideo() {
        this.$refs.welcomeVideo.style.display = 'block';
        this.$refs.welcomeVideo.setAttribute('controls', 'controls');
        this.$refs.welcomeVideo.setAttribute('autoplay', 'autoplay');
        this.$refs.welcomeVideo.play();
        this.$refs.welcomeVideo.requestFullscreen();
      },
      pauseVideo() {
        this.$refs.welcomeVideo.style.display = 'none';
        this.$refs.welcomeVideo.pause();
        this.$refs.welcomeVideo.webkitExitFullScreen();
      },
    },
  };
</script>

<style scoped>
  #welcomeVideo {
    position: absolute;
    display: none;
  }

  .logo {
    max-width: 500px;
    margin-bottom: 50px;
  }
</style>
