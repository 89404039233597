import { mapActions, mapGetters } from 'vuex';
import compareVersions from 'compare-versions';
import { getDeviceData } from '@/helpers/device';
import logout from '@/mixins/logout';

export default {
  mixins: [logout],
  created() {
    this.dialog = null;
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
  methods: {
    ...mapActions('versioncompatibility', {
      getVersionCompatibility: 'get',
    }),
    async notifyUpdatedVersion(showRequired = true, showAvailable = true) {
      const { platform } = getDeviceData();
      const versioncompatibility = await this.getVersionCompatibility(1);

      if (!Object.keys(versioncompatibility).includes(platform)) return;

      const platformCompatibility = versioncompatibility[platform];

      const updateRequired =
        showRequired &&
        compareVersions.compare(process.env.VERSION, platformCompatibility.minimumAppVersion, '<');

      const updateAvailable =
        showAvailable &&
        compareVersions.compare(process.env.VERSION, platformCompatibility.latestAppVersion, '<');

      this.createDialog(updateRequired, updateAvailable, platformCompatibility.storeDirectUrl);
    },
    createDialog(updateRequired, updatedAvailable, storeUrl) {
      if (!updateRequired && !updatedAvailable) return;

      const updateButton = {
        text: 'Update',
        bold: true,
        close: false,
        onClick: (dialog, e) => {
          if (updateRequired && this.isAuthenticated) {
            this.logout();
          }

          window.open(storeUrl, '_system');
        },
      };

      const options = updateRequired
        ? {
            title: 'Update Required',
            text: 'An update is required to continue using Navigating Life.',
            closeByBackdropClick: false,
            buttons: [updateButton],
            cssClass: 'update-notice',
          }
        : {
            title: 'Update Available',
            text: 'An updated version of Navigating Life is available.',
            buttons: [
              {
                text: 'Ignore',
                close: true,
              },
              updateButton,
            ],
            cssClass: 'update-notice',
          };

      if (this.dialog !== null) {
        this.dialog.close();
        this.dialog = null;
      }

      this.dialog = this.$f7.dialog.create(options);

      // Delay opening dialog to ensure content is fully rendered
      setTimeout(() => {
        this.dialog.open();
      }, 500);
    },
  },
};
