<template>
  <div>
    <SelectRadioButonsList
      v-if="selectedClient"
      id="selectRadioButonsPopup"
      service="targetBehavior"
      :list-query="query"
      :search-fields="searchFields"
      message="You can only select clients that are assigned to the current group"
      :multiple="multiple"
      :close-on-select="!multiple"
      empty-message="No target behaviors have been assigned to this client. Behaviors can be managed in the Admin Portal."
      @submit="itemSelected"
    >
      <SelectRadioButonsOption
        slot="item"
        slot-scope="{ item }"
        name="targetBehavior"
        :label="item.name"
        :item="item"
        :has-info="
          selectedClient.targetBehaviors &&
            selectedClient.targetBehaviors.length > 0
        "
        :selected="Object.keys(targetBehaviors).includes(item._id)"
        :multiple="multiple"
        @selected="manageTargetBehaviors"
        @show-info="item => (showingInfoPopupFor = item)"
      />
    </SelectRadioButonsList>

    <f7-popup
      id="infoPopup"
      ref="infoPopup"
      :opened="!!showingInfoPopupFor"
      @popup:closed="showingInfoPopupFor = null"
    >
      <f7-page v-if="showingInfoPopupFor">
        <f7-navbar :title="showingInfoPopupFor.name">
          <f7-nav-right>
            <f7-link @click="closeInfoPopup">
              Close
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          <p v-if="behaviorDetail && behaviorDetail.definition">
            {{ behaviorDetail.definition }}
          </p>
          <p v-else>
            No description to display.
          </p>
        </f7-block>
      </f7-page>
    </f7-popup>
  </div>
</template>
<script>
import { has } from 'lodash';
import SelectRadioButonsList from '@/components/SelectRadioButonsList';
import SelectRadioButonsOption from '@/components/SelectRadioButonsOption';

export default {
  components: {
    SelectRadioButonsList,
    SelectRadioButonsOption,
  },
  props: {
    selectedClient: {
      type: Object,
      default: null,
    },
    selectedItem: {
      type: Object,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showingInfoPopupFor: null,
      searchFields: ['name'],
      targetBehaviors: {},
    };
  },
  computed: {
    behaviorDetail() {
      if (!this.showingInfoPopupFor) return;
      return this.selectedClient.targetBehaviors.find(
        behavior => behavior.ref === this.showingInfoPopupFor._id
      );
    },
    query() {
      const query = {
        $sort: { name: 1 },
      };
      if (this.selectedClient.targetBehaviors) {
        const targetBehaviorIds = this.selectedClient.targetBehaviors.map(
          behavior => {
            if (has(behavior, 'ref')) return behavior.ref;
            return behavior;
          }
        );

        query._id = {
          $in: targetBehaviorIds,
        };
      }

      return query;
    },
  },
  methods: {
    manageTargetBehaviors(behavior) {
      const { _id: id } = behavior;
      if (!this.multiple) {
        const newTargetBehaviors = { [id]: behavior };
        this.targetBehaviors = newTargetBehaviors;
        this.$emit('selected', newTargetBehaviors);
        return;
      }
      if (Object.keys(this.targetBehaviors).includes(id)) {
        delete this.targetBehaviors[id];
        this.targetBehaviors = { ...this.targetBehaviors };
      } else {
        this.targetBehaviors = { ...this.targetBehaviors, [id]: behavior };
      }
    },
    itemSelected(item) {
      this.$emit('selected', this.targetBehaviors);
    },
    closeInfoPopup() {
      this.$refs.infoPopup.close();
    },
  },
};
</script>

<style scoped>
#infoPopup {
  z-index: 12604;
}
</style>
