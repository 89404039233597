export const rolesDefinitions = {
  limited: {
    display: 'Supporting Member',
    staffMember: false,
    key: 'limited',
  },
  groupViewer: {
    display: 'Group Viewer',
    staffMember: false,
    key: 'groupViewer',
  },
  regular: {
    display: 'Community Member',
    staffMember: false,
    key: 'regular',
  },
  social: {
    display: 'Social Member',
    staffMember: false,
    key: 'social',
  },
  team: {
    display: 'Restricted Staff Member',
    staffMember: true,
    key: 'team',
  },
  staff: {
    display: 'Staff',
    staffMember: true,
    key: 'staff',
  },
  clinician: {
    display: 'Clinician',
    staffMember: true,
    key: 'clinician',
  },
  groupAdmin: {
    display: 'Group Admin',
    staffMember: true,
    key: 'groupAdmin',
  },
  orgAdmin: {
    display: 'Organization Admin',
    staffMember: true,
    key: 'orgAdmin',
  },
  superAdmin: {
    display: 'Super Admin',
    staffMember: true,
    key: 'superAdmin',
  },
};

const rolesDisplayLocal = {};
for (const key in rolesDefinitions) {
  rolesDisplayLocal[key] = rolesDefinitions[key].display;
}
export const rolesDisplay = rolesDisplayLocal;

export const roles = Object.keys(rolesDisplay).map(value => ({
  text: rolesDisplay[value],
  value,
}));

export const serviceRoles = Object.keys(rolesDefinitions).filter(
  key => rolesDefinitions[key].staffMember
);

export const communityRoles = Object.keys(rolesDefinitions).filter(
  key => rolesDefinitions[key].staffMember === false
);

export const roleKeys = Object.keys(rolesDefinitions).reduce((acc, key) => {
  acc[key] = key;
  return acc;
}, {});
