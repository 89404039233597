import feathersClient, { makeServicePlugin, BaseModel } from '../../api/feathers-client';

class Comment extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }
  static modelName = 'Comment';

  static setupInstance(data, { store, models }) {
    data.createdByData = new models.api.User(data.createdByData);
    return data;
  }
}
const servicePath = 'v2/comments';

const servicePlugin = makeServicePlugin({
  Model: Comment,
  service: feathersClient.service(servicePath),
  servicePath,
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
