/* eslint-disable no-console */
import Vue from 'vue';
import Vuex from 'vuex';
import { merge, find } from 'lodash';
import feathersClient, { FeathersVuex } from '../api/feathers-client';
import { setItem, getItem } from '@/helpers/local-fs';
import { findAllPages } from '@/helpers/pagination';
import { getDeviceData } from '@/helpers/device';
import VuexReset from '@ianwalter/vuex-reset';
import tabs from './modules/tabs';
import accounts from './modules/accounts';
import auth from './store.auth';
import OneSignalVue from 'onesignal-vue';
import debounce from '@/helpers/debounce';

Vue.use(OneSignalVue);
Vue.use(Vuex);
Vue.use(FeathersVuex);

const requireModule = require.context(
  // The path where the service modules live
  './services',
  // Whether to look in subfolders
  false,
  // Only include .js files (prevents duplicate imports`)
  /\.js$/
);
const servicePlugins = requireModule
  .keys()
  .map(modulePath => requireModule(modulePath).default);

export default new Vuex.Store({
  state: {
    error: null,
    currentGroupId: null,
    modifyItemData: null,
    modifyItemDataIsDuplicate: false,
    postObject: null,
    eventObject: null,
    placeId: '',
    clientsTagged: [],
    unreadConversationCount: 0,
    unreadNotificationCount: 0,
    conversationId: null,
    awaitingTwoFactor: false,
    termsPopup: false,
    appInForeground: true,
    device: {
      cordova: !!window.cordova,
      platform: null,
      model: null,
      version: null,
      manufacturer: null,
      size: {
        width:
          window.innerWidth || window.screen.width * window.devicePixelRatio,
        height:
          window.innerHeight || window.screen.height * window.devicePixelRatio,
      },
    },
    appVersion: '',
    biometricType: null,
    biometricAuthAvailable: false,
    loading: [],
  },
  getters: {
    appVersion: state => {
      return state.appVersion;
    },
    isMobile: state => {
      return state.device.size.width < 1024;
    },
    isCordova: state => state.device.cordova,
    isAndroid: state =>
      state.device.platform && state.device.platform.includes('Android'),
    isAuthenticated: state => !state.awaitingTwoFactor && !!state.auth.user,
    isCommunityMember: state =>
      state.auth && state.auth.user && state.auth.user.role === 'regular',
    isAppInForeground: state => state.appInForeground,
    currentOrganization: (state, getters) => {
      if (!getters.currentGroup) return null;

      return getters['organizations/get'](getters.currentGroup.organization);
    },
    currentGroup: (state, getters) => {
      if (!state.currentGroupId) return null;

      return getters['groups/get'](state.currentGroupId);
    },
    currentUserRole: (state, getters) => {
      const { currentOrganization } = getters;
      if (!currentOrganization) return null;

      const userOrganizationObj = this.auth.user.organizations.find(
        org => org._id === currentOrganization._id
      );

      return userOrganizationObj ? userOrganizationObj.role : null;
    },
    biometricTypeLabel(state) {
      if (!state.biometricType) return '';
      if (window.device && window.device.platform === 'iOS') {
        return state.biometricType === 'face' ? 'FaceID' : 'TouchID';
      }
      return state.biometricType === 'finger'
        ? 'fingerprint'
        : state.biometricType;
    },
    loading: state => state.loading.length > 0,
    error: state => state.error,
    loadingActions: state => state.loading,
  },
  mutations: {
    // Keep empty 'reset' mutation for VuexReset plugin
    /* eslint-disable-next-line no-empty-function*/
    reset: () => {},
    setGroupId(state, groupId) {
      setItem('currentGroupId', groupId);
      state.currentGroupId = groupId;
    },
    setmodifyItemData(state, data) {
      state.modifyItemData = data;
    },
    setmodifyItemDataIsDuplicate(state, data) {
      state.modifyItemDataIsDuplicate = data;
    },
    setPostObject(state, data) {
      state.postObject = data;
    },
    setEventObject(state, data) {
      state.eventObject = data;
    },
    setPlaceId(state, data) {
      state.placeId = data;
    },
    setClientsTagged(state, data) {
      state.clientsTagged = data;
    },
    setUnreadConversationCount(state, data) {
      state.unreadConversationCount = data;
    },
    setUnreadNotificationCount(state, data) {
      state.unreadNotificationCount = data;
    },
    setConversationId(state, data) {
      state.conversationId = data;
    },
    updateDeviceData(state, deviceData) {
      merge(state.device, deviceData);
    },
    setAwaitingTwoFactor(state, isAwaiting) {
      state.awaitingTwoFactor = isAwaiting;
    },
    setTermsPopup(state, openState) {
      state.termsPopup = openState;
    },
    setAppInForeground(state, isInForeground) {
      state.appInForeground = isInForeground;
    },
    setBiometricType(state, type) {
      state.biometricType = type;
      state.biometricAuthAvailable = !!type;
    },
    addLoadingAction(state, action) {
      state.loading.push(action);
    },
    removeLoadingAction(state, action) {
      state.loading = state.loading.filter(oldAction => oldAction !== action);
    },
    setError(state, error) {
      state.error = error;
    },
    setAppVersion(state, data) {
      state.appVersion = data;
    },
  },
  actions: {
    setPostObject({ commit }, data) {
      commit('setPostObject', data);
    },
    setEventObject({ commit }, data) {
      commit('setEventObject', data);
    },
    setmodifyItemData({ commit }, data) {
      commit('setmodifyItemData', data);
    },
    setmodifyItemDataIsDuplicate({ commit }, data) {
      commit('setmodifyItemDataIsDuplicate', data);
    },
    updateDeviceData({ commit }) {
      const deviceData = getDeviceData();
      commit('updateDeviceData', deviceData);

      if (!('Fingerprint' in window)) return;
      window.Fingerprint.isAvailable(result => {
        commit('setBiometricType', result);
      });
    },
    async fetchUnreadConversationCount({ commit, state, dispatch }) {
      const { total } = await dispatch('conversations/find', {
        query: {
          $limit: 0,
          participants: state.auth.user._id,
          readBy: { $nin: [state.auth.user._id] },
          lastMessageSentAt: { $ne: null },
        },
      });
      commit('setUnreadConversationCount', total);
    },
    async fetchUnreadNotificationCount({ commit, state }) {
      const { total } = await feathersClient.service('notifications').find({
        query: {
          $limit: 0,
          read: false,
          subscriber: state.auth.user._id,
          label: {
            $ne: null,
          },
        },
      });
      commit('setUnreadNotificationCount', total);
    },
    watchService({ dispatch }, { service, action, delay = 1000 }) {
      const debouncedHandler = debounce(() => dispatch(action), delay);
      feathersClient.service(service).on('created', debouncedHandler);
      feathersClient.service(service).on('patched', debouncedHandler);
    },
    async initGroups({ state, commit }) {
      const [{ data, total }, savedGroupId] = await Promise.all([
        findAllPages('groups', {
          'members._id': state.auth.user._id,
        }),
        getItem('currentGroupId'),
      ]);

      const memberOfSavedGroup = savedGroupId
        ? find(data, ['_id', savedGroupId]) !== undefined
        : false;

      if (savedGroupId && memberOfSavedGroup) {
        commit('setGroupId', savedGroupId);
      } else if (total > 0) {
        commit('setGroupId', data[0]._id);
      } else {
        throw new Error('Could not find any assigned groups');
      }
    },
  },
  plugins: [...servicePlugins, auth, VuexReset()],
  modules: {
    tabs,
    accounts,
  },
});
