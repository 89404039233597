<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <f7-popup
    id="showAllTaggedClients"
    class="popup-tablet-fullscreen"
  >
    <f7-navbar>
      <div class="np-border--fancy" />
      <div class="left">
        <f7-link popup-close>
          <i class="icon icon-back" />
        </f7-link>
      </div>
      <f7-nav-title>Tagged Clients</f7-nav-title>
    </f7-navbar>
    <div class="page-content mt-0">
      <f7-list class="mt-0 p-b">
        <f7-list-item
          v-for="clientTagged in $store.state.clientsTagged"
          :key="clientTagged._id"
          :title="clientTagged.firstName + ' ' + clientTagged.lastName"
          class="taggedClient cursor-pointer"
        >
          <Avatar
            slot="media"
            :user-id="clientTagged._id"
          />
        </f7-list-item>
      </f7-list>
    </div>
  </f7-popup>
</template>

<script>
import Avatar from '@/components/Avatar';
// import { openUserProfile } from '@/mixins/helperFunctions';
export default {
  components: {
    Avatar,
  },
  // mixins: [openUserProfile],
  mixins: [],
  methods: {},
};
</script>

<style lang="scss" scoped>
.mt-0 {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
.p-b {
  padding-bottom: 56px;
}
#showAllTaggedClients {
  //Sheet modal's z-index is 12500 by default in Framework 7
  z-index: 12505;
}
.taggedClient-list {
  margin-top: 0;
}
.taggedClient {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #ddd;
  .wrapper {
    width: 100%;
    .body {
      height: 50px;
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 0.9em;
      .name {
        margin-left: 1em;
      }
    }
  }
}
.np-border--fancy {
  height: 4px;
  width: 100%;
  background: url('../../assets/images/nupath-border-bg.svg') repeat-x;
  position: absolute;
  top: 0;
}
</style>
