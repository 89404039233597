<template>
  <div
    id="user-select-popover"
    class="content header-nav-icon user-select"
  >
    <f7-link
      class="user-select__link"
      popover-open="#userMenuPopover"
      @click="popoverOpen = !popoverOpen"
    >
      <f7-icon
        v-if="!avatarUrl"
        f7="person"
        class="user-select__button__icon"
      />
      <img
        v-else
        class="profile-avatar f7-icons"
        :src="avatarUrl"
        alt=""
      >
    </f7-link>

    <f7-popover
      id="userMenuPopover"
      :opened="popoverOpen"
      @popover:closed="popoverOpen = false"
    >
      <f7-list v-if="popoverOpen">
        <f7-list-item
          v-if="biometricAuthAvailable"
          title="Switch Accounts"
          class="cursor-pointer"
          @click="openAccountSwitchingPopup"
        />
        <f7-list-item
          v-if="$can('read', currentUser)"
          title="View Profile"
          class="cursor-pointer"
          @click="openUserProfile"
        />
        <f7-list-item
          title="Logout"
          class="cursor-pointer"
          @click="logoutUser"
        />
      </f7-list>
    </f7-popover>
    <f7-popup
      v-if="biometricAuthAvailable"
      id="userMenuSelectPopup"
      class="user-select__popup"
      :opened="accountSwitchingPopupOpen"
      @popup:closed="accountSwitchingPopupOpen = false"
    >
      <f7-navbar title="Switch Accounts">
        <f7-nav-right>
          <f7-link popup-close>
            &times;
          </f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-page>
        <StoredLoginsList
          class="mt-3"
          @optionSelected="closePopup"
        />
      </f7-page>
    </f7-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mediaTransformationUrl } from '@/helpers/media/remote';
import logout from '../mixins/logout';
import StoredLoginsList from '../pages/Login/components/StoredLoginsList';

export default {
  name: 'UserSelect',
  components: { StoredLoginsList },
  mixins: [logout],
  data() {
    return {
      accountSwitchingPopupOpen: false,
      popoverOpen: false,
    };
  },
  computed: {
    ...mapState('auth', {
      currentUser: 'user',
    }),
    ...mapState(['biometricAuthAvailable']),
    avatarUrl() {
      if (!this.currentUser.avatarUrl) return null;

      return mediaTransformationUrl(this.currentUser.avatarUrl, {
        scale_crop: '100x100/smart_faces',
      });
    },
  },
  methods: {
    openAccountSwitchingPopup() {
      this.accountSwitchingPopupOpen = true;
      this.popoverOpen = false;
    },
    openUserProfile() {
      this.popoverOpen = false;
      this.$f7.views.main.router.navigate(
        `/app/userProfile/${this.currentUser._id}/`
      );
    },
    logoutUser() {
      this.popoverOpen = false;
      this.$nextTick(() => {
        this.logout();
      });
    },
    closePopup() {
      this.accountSwitchingPopupOpen = false;
    },
  },
};
</script>

<style scoped lang="scss">
.user-select {
  display: flex;

  &__link {
    padding: 0 !important;
    min-width: 10px !important;
  }

  &__button {
    border: none;
    background: none;
    padding: 0;

    &__icon {
      width: auto;
      height: 29px;
      font-size: 29px;
    }
  }

  .list {
    ul li {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__popup {
    border-radius: 5px;
    border-top: 5px solid #379dcb;
  }
  .profile-avatar {
    border-radius: 50%;
    border: 1.5px solid #b0b0b0;
    padding: 1.5px;
    width: 32px;
    height: 32px;
    @media (max-width: 320px) {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
