<template>
  <BaseFakePostContent
    :created-at="user.createdAt"
    headline="Welcome to Navigating Life!"
    :body="bodyText"
  />
</template>
<script>
  import { mapState } from 'vuex';
  import { permissions } from '@/mixins/user';
  import BaseFakePostContent from '@/components/socialFeed/BaseFakePostContent';
  export default {
    components: {
      BaseFakePostContent,
    },
    mixins: [permissions],
    computed: {
      ...mapState('auth', ['user']),
      bodyText() {
        const contactText =
          'Please contact your organization or support if you have any questions.';
        const oldPostsText =
          "You'll only see posts in this group from the date you were added to protect everyone's privacy.";

        return this.userCanReadPostsCreatedBeforeSignupDate
          ? contactText
          : `${oldPostsText} ${contactText}`;
      },
    },
  };
</script>
