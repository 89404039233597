<template>
  <button
    class="btn-edit"
    @click="$emit('click')"
  >
    <f7-icon
      class="tool"
      f7="more"
    />
  </button>
</template>

<script>
  export default {
    name: 'MenuButton',
  };
</script>

<style lang="scss" scoped>
  .btn-edit {
    border: none;
    background: none;
    outline: none;
    width: 25px;
    padding: 0px !important;
    color: currentColor;
  }
</style>
