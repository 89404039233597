<template>
  <svg
    viewBox="0,0,50,50"
    class="avatar"
    @click="handleClick"
  >
    <defs v-if="user && imgUrl">
      <pattern
        :id="instanceId"
        x="0"
        y="0"
        patternUnits="userSpaceOnUse"
        height="50"
        width="50"
      >
        <image
          x="0"
          y="0"
          height="50"
          width="50"
          :xlink:href="imgUrl"
        />
      </pattern>
    </defs>
    <g v-if="isSiteAdmin || !user || (user && user.deletedAt) || !isUserInGroup">
      <circle
        cx="25"
        cy="25"
        r="25"
        fill="#f5f5f5"
      />
      <image
        x="10"
        y="10"
        height="30"
        width="30"
        :href="require('@/assets/images/nl_logo.png')"
      />
    </g>
    <g v-else-if="user">
      <circle
        :fill="user && user.avatar ? `url(#${instanceId})` : color"
        cx="25"
        cy="25"
        r="25"
      />
      <text
        v-if="!imgUrl"
        fill="#FFFFFF"
        font-size="20"
        text-anchor="middle"
        x="25"
        y="33"
      >
        {{ initials }}
      </text>
    </g>
    <circle
      v-if="showIndicator"
      fill="red"
      cx="7"
      cy="7"
      r="5"
    />
  </svg>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { openUserProfile } from '@/mixins/helperFunctions';
  import { mediaTransformationUrl } from '@/helpers/media/remote';
  import { sample } from 'lodash';

  export default {
    mixins: [openUserProfile],
    props: {
      userId: {
        default: null,
        type: String,
      },
      openProfile: {
        type: Boolean,
        default: true,
      },
      showIndicator: {
        type: Boolean,
        default: false,
      },
      isSiteAdmin: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters('users', {
        getUserFromStore: 'get',
      }),
      ...mapGetters(['currentGroup']),
      initials() {
        return ['firstName', 'lastName']
          .map((prop) => (this.user[prop] ? this.user[prop].substr(0, 1) : ''))
          .join('');
      },
      color() {
        const colors = ['#EC6B70', '#379DCB', '#6ABCEA', '#B7506A', '#FAAB19'];
        return sample(colors);
      },
      imgUrl() {
        if (!this.user || !this.user.avatar) {
          return null;
        }
        return mediaTransformationUrl(this.user.avatarUrl, {
          scale_crop: '80x80/smart_faces',
        });
      },
      user() {
        if (!this.userId) return null;
        return this.getUserFromStore(this.userId);
      },
      instanceId() {
        return `${this.userId}-${this._uid}-image`;
      },
      isUserInGroup() {
        return this.currentGroup.members.filter((member) => member._id === this.userId).length > 0;
      },
    },
    methods: {
      handleClick(e) {
        if (this.openProfile && this.user) {
          this.openUserProfile(this.user._id);
        }
      },
    },
  };
</script>
<style scoped lang="scss">
  .avatar {
    width: 100%;
    height: auto;
    max-width: 50px;
  }
  .logo-container {
    border: 2px solid color(gray);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 46px;
      width: 46px;
    }
  }
</style>
