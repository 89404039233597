<template>
  <div class="blurry-background" />
</template>
  <script>
    export default {
        props: {
    error: {
      type: Object,
      default: () => ({
        title: 'Error',
        text: '',
      })},
    },
    data() {
      return {
        currentDialog: null,
      };
    },
    watch:{
      error(){
        this.showError();
      }
    },
    mounted(){
      this.showError();
    },
      methods: {
        showError() {
          if(this.currentDialog && this.currentDialog.opened){
            return;
          }
        this.currentDialog =  this.$f7.dialog.alert(this.error.description, this.error.title || 'Error', () => {
          this.error.callback && this.error.callback();
          this.currentDialog = null;
          this.$store.commit('setError', null);
          });
        },
      },
    };
  </script>
  <style scoped>
    .blurry-background {
      top: 0;
      position: absolute;
      width: 100vw;
      height: 100vh;
      background-color: rgba(255,255,255,0.5);
      overflow: hidden;
      backdrop-filter: blur(5px);
      z-index: 9999;
    }
  </style>
  