<template>
  <f7-page :page-content="false">
    <f7-page-content>
      <f7-navbar>
        <div class="np-border--fancy" />
        <div class="navbar-inner sliding">
          <div class="left">
            <f7-link back>
              <i class="icon icon-back" />
            </f7-link>
          </div>
          <div class="title">
            Post
          </div>
        </div>
      </f7-navbar>

      <PostContent
        v-if="post"
        :item="post"
      />
    </f7-page-content>
    <CommentsSheetModal class="clear-bottom" />
    <LikesSheetModal class="clear-bottom" />
    <MediaGallery />
  </f7-page>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import PostContent from '@/components/socialFeed/PostContent';
  import LikesSheetModal from '@/components/socialFeed/LikesSheetModal.vue';
  import CommentsSheetModal from '@/components/socialFeed/CommentsSheetModal';
  import MediaGallery from '@/components/socialFeed/MediaGallery.vue';

  export default {
    components: {
      PostContent,
      LikesSheetModal,
      CommentsSheetModal,
      MediaGallery,
    },
    computed: {
      ...mapGetters('posts', {
        getPostFromStore: 'get',
      }),
      post() {
        return this.getPostFromStore(this.$f7route.params.postId);
      },
    },
    created() {
      this.getPost(this.$f7route.params.postId);
    },
    methods: {
      ...mapActions('posts', {
        getPost: 'get',
      }),
    },
  };
</script>

<style lang="scss" scoped>
  .clear-bottom {
    margin-bottom: 0 !important;
  }
  .np-border--fancy {
    height: 4px;
    width: 100%;
    background: url('../../assets/images/nupath-border-bg.svg') repeat-x;
    position: absolute;
    top: 0;
  }
</style>
