import feathersClient, { makeServicePlugin, BaseModel } from '../../api/feathers-client';

class VideoConversation extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }
  static modelName = 'VideoConversation';
}

const servicePath = 'videoConversations';

const servicePlugin = makeServicePlugin({
  Model: VideoConversation,
  service: feathersClient.service(servicePath),
  servicePath,
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
