<template>
  <div class="content">
    <a
      v-if="linkTo && !isCommunityMember"
      :href="linkTo"
    ><img src="../assets/images/icons/add.svg"></a>
    <h2>{{ emptyTitle }}</h2>
    <p v-if="emptyMessage && !isCommunityMember">
      {{ emptyMessage }}
    </p>
  </div>
</template>
<script>
  export default {
    props: {
      emptyMessage: {
        type: String,
        default: null,
      },
      emptyTitle: {
        type: String,
        required: true,
      },
      linkTo: {
        type: String,
        default: null,
      },
    },
    computed: {
      isCommunityMember() {
        return this.$store.getters.isCommunityMember;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100% - 180px);
    min-height: 46vh;
    padding-bottom: 7.5vh;
    overflow: hidden;
    text-align: center;
    a {
      img {
        width: 100px;
        height: 50px;
      }
    }
    h2 {
      @media (max-width: 360px) {
        font-size: 1.2em;
      }
    }
    p {
      @media (max-width: 360px) {
        font-size: 0.9em;
      }
    }
  }
</style>
