export default {
  namespaced: true,
  state: {
    activeTabIndex: 0,
  },
  mutations: {
    setActiveTabIndex(state, index) {
      state.activeTabIndex = index;
    },
  },
};
