import { mapState, mapActions } from 'vuex';

export default {
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    ...mapActions('conversations', {
      createConversation: 'create',
      findConversations: 'find',
    }),
    async findOrCreateConversation(userIds) {
      let conversation;

      // We have to sort ids to take advantage of $eq param because order matters
      // https://docs.mongodb.com/manual/reference/operator/query/eq/#match-an-array-value
      userIds = [...userIds, this.user._id].sort();

      const conversations = await this.findConversations({
        query: {
          participants: {
            $eq: userIds,
            $size: userIds.length,
          },
          $limit: 1,
        },
      });

      if (conversations.data.length > 0) {
        [conversation] = conversations.data;
      } else {
        conversation = await this.createConversation({
          participants: userIds,
        });
      }

      return conversation;
    },
  },
};
