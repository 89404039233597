<template>
  <img
    src="@/assets/images/icons/loading.svg"
    :style="style"
    :class="{ spin }"
  >
</template>

<script>
export default {
  name: 'BaseLoadingIcon',
  props: {
    spin: {
      type: Boolean,
      default: true,
    },
    size: {
      type: Number,
      default: 15,
    },
  },
  data() {
    return {
      style: {
        width: `${this.size.toString()}px`,
        height: `${this.size.toString()}px`,
        margin: '0 5px 0',
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.spin {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
