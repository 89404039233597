<template>
  <div class="feedmap-wrapper">
    <div
      v-show="posts.total || events.total"
      class="date-selector"
    >
      <p v-if="selectedDate">
        {{ dateFormat(selectedDate) }}
      </p>
      <div class="control-bar">
        <button
          :disabled="!postsEventsBefore"
          @click="selectedDate = postsEventsBefore.date"
        >
          <img
            v-if="postsEventsBefore"
            src="@/assets/images/icons/left-arrow.svg"
          >
          <img
            v-else
            src="@/assets/images/icons/left-arrow-gray.svg"
          >
        </button>
        <button
          :disabled="!postsEventsAfter"
          @click="selectedDate = postsEventsAfter.date"
        >
          <img
            v-if="postsEventsAfter"
            src="@/assets/images/icons/right-arrow.svg"
          >
          <img
            v-else
            src="@/assets/images/icons/right-arrow-gray.svg"
          >
        </button>
      </div>
    </div>

    <div
      v-if="!loading && postsAndEventsUniqueLocation.length === 0"
      class="empty-state"
    >
      <div class="empty-state-overlay">
        <EmptyState
          empty-message="Please add a post or an event to see on the map"
          empty-title="No post and event added yet"
          link-to="/app/PostForm/"
        />
      </div>
    </div>

    <gmap-map
      v-show="!loading && postsAndEventsUniqueLocation.length > 0"
      id="vue-google-map"
      ref="nuPathMap"
      :center="center"
      :options="mapOptions"
      :zoom="12"
    >
      <!-- Post Marker -->
      <gmap-marker
        v-for="item in postsAndEventsUniqueLocation"
        :key="item._id"
        :position="item.location"
        :icon="postMarkerIcon(item)"
        @click="openItemInMarker(item)"
      />
    </gmap-map>

    <MapMarkerModal />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import MapMarkerModal from '@/components/location/MapMarkerModal';
import EmptyState from '@/components/EmptyState';

import moment from 'moment';
import { uniqBy, debounce } from 'lodash';
import { findAllPages } from '@/helpers/pagination';
import { mediaTransformationUrl } from '@/helpers/media/remote';

export default {
  components: {
    MapMarkerModal,
    EmptyState,
  },
  data() {
    return {
      center: {
        lat: 42.361145,
        lng: -71.057083,
      },
      mapOptions: {
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        zoomControl: true,
        scaleControl: true,
      },
      postsEventsBefore: null,
      postsEventsAfter: null,
      selectedDate: null,
      icon:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAA1CAYAAAAztqkoAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA9JJREFUeNrMWT1MFFEQfrsuCgLmrCi0QK2kUKggWgCJUmmC2hkVrkML9RK10hAilZqAFkJ3+BM70cJKTYDGQCVaaIVcgYlUEn4ElB9nNu/I3jLz3u7bn/AlL3dh3775bubb2ZnBEob4fepwC3zgaoZVDytDbJuDNQlrDNbo/o8/RsPasUKSqoWPTlgdsGoNflcB1jNYQ0C2EBtBIIbeuQmrW8SHHlj9QHQuEkEZyryhx4J4NKsKvaUhh17rE8kjByT7QxEEcnmpNxbO8UZRduI0fDaJXUeObru+PvVdrH0ZF/8+fYDPCR1J1GU2EEEg1yc1RwJJVVy7K+yag4FdtDE7I5af9rpkFUBN5pQEgVyn1Nx2d1ftE3tvP3AJmgIJ/nl4R2wuznNbUJNDJEGZRj5TOQ1DWNkzGMprKm8udXe5EmByZ0MxDdm+i3mKHJKqevQqFnIBzst4I2j7QttC3YGew/D6sfLiiVgdzivJ4HXcR8kFz2XQIvmUeJBMwuVXbpBPaBGbSwtKgqrreC6ez6B7S4MyGY9Qodj3cqzkb5gu1r5OyO/jMt00sST8e5xjjW568mL+UrOrSwKtjvzSQV3dc357GkRyVmU1/Po6sfFrZsso+0B49qxPfXPv9xNEO8sDvdTtHUWC7dTV3W0XmNDUuUY2l+a3EjYf4tI9Re/77TAE2x0Ibz2XVqgHw5+wdQiyB+2gPSLtZBxZyxGvMV5XywP3teRJb0JyLjvZJjh7VF50uCpFRaDi6j1lWPkHZoIMscqeLXYI7JoDO5vgxuzPnU2Q9axK0GmCs2fr3gBpgbNny7aQrIbT8iLaYUovniDi7/vXqRBU2XGwMIS3SYHKh6vDQ/CezCaeqNEO1/VZuh4Ey6Hyy9cT8x7WiivPH7M9iuUp9ae5XdWD75Q1oSlQdwtdZ1RbDrlPsaz/2eYZ+4e4Hxg8D89VAGc5Bds3imCbnMVbF7mi0qhpCnBeT0kelOOHfnU4zur62kBtJ57DpRVPfzxKtZ0Z2XbW6mq8hBr34rymoThUohr3eklSi5hHH0UguUnd6COtoZF2iKQaHr3hepWE8BbInQtTbmWlHtJAQdoLPR8MrMeIKNFd4IJV3pRLQXdswRJ0Rp2UHkndmZT8SeiR1V1oD3r0OCLo/4eEBSbhVlVoQzdNMesxF4RcKA96PKkdrmtADssje9D761Vtggaho2CZWDHUY2DdRW7cDfWYC0vO2IMGegyluzhHH0H0GOnpt6LmC40ejXQX6/BIo8dcFHKxeFChR2PdxepBRo9pVEFmeoQ1LXUZC/4LMAD89rizO0w+BgAAAABJRU5ErkJggg==',
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters(['currentGroup', 'loading']),
    ...mapGetters('posts', {
      findPostsInStore: 'find',
    }),
    ...mapGetters('events', {
      findEventsInStore: 'find',
    }),
    ...mapGetters('users', {
      getUserInStore: 'get',
    }),
    isCommunityMember() {
      return this.$store.getters.isCommunityMember;
    },
    defaultQuery() {
      return {
        group: this.currentGroup._id,
        //Filter out post/event without location
        $and: [{ 'location.name': { $ne: '' } }, { location: { $ne: null } }],
      };
    },
    postQuery() {
      const query = {
        $sort: { createdAt: -1 },
        createdAt: {
          $gte: this.startTimeOfDate(this.selectedDate),
          $lte: this.endTimeOfDate(this.selectedDate),
        },
      };
      return Object.assign({}, this.defaultQuery, query);
    },
    eventQuery() {
      const query = {
        $sort: { start: -1 },
        start: {
          $gte: this.startTimeOfDate(this.selectedDate),
          $lte: this.endTimeOfDate(this.selectedDate),
        },
      };
      return Object.assign({}, this.defaultQuery, query);
    },
    posts() {
      if (!this.currentGroup) return [];

      return this.findPostsInStore({ paginate: false, query: this.postQuery });
    },
    events() {
      if (!this.currentGroup) return [];

      return this.findEventsInStore({
        paginate: false,
        query: this.eventQuery,
      });
    },
    postsAndEventsUniqueLocation() {
      if (!this.currentGroup) return [];

      const items = [...this.posts.data, ...this.events.data].filter(item => {
        return item.location && item.location.lat && item.location.lng;
      });

      const itemsUniqueLocation = uniqBy(
        items,
        item => `${item.location.lat},${item.location.lng}`
      );

      return itemsUniqueLocation;
    },
  },
  watch: {
    //When group changes, re-init posts , events and reset selectedDate
    currentGroup: {
      immediate: true,
      handler() {
        this.$store.commit('addLoadingAction', 'feed-map-view');
        this.getTheSelectedDate();
        this.fetchData();
      },
    },
    // When selectedDate changes, re-init data and re-init bounds
    selectedDate() {
      this.fetchData();
    },
    postsAndEventsUniqueLocation: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.updateBoundsDebounced();
        }
      },
    },
  },
  mounted() {
    this.updateBoundsDebounced = debounce(this.updateBounds, 50);
    this.$store.commit('addLoadingAction', 'feed-map-view');
  },
  beforeDestroy() {
    this.updateBoundsDebounced.cancel();
  },
  methods: {
    ...mapActions('posts', {
      findPosts: 'find',
    }),
    ...mapActions('events', {
      findEvents: 'find',
    }),
    async comparePostDateAndEventDate(postQuery, eventQuery, type) {
      postQuery = Object.assign({}, this.defaultQuery, postQuery, {
        $limit: 1,
      });
      eventQuery = Object.assign({}, this.defaultQuery, eventQuery, {
        $limit: 1,
      });
      let post, event, item;
      await this.findPosts({ query: postQuery }).then(res => {
        if (res.data.length > 0) {
          [post] = res.data;
          post.date = res.data[0].createdAt;
        }
      });
      await this.findEvents({ query: eventQuery }).then(res => {
        if (res.data.length > 0) {
          [event] = res.data;
          event.date = res.data[0].start;
        }
      });
      if (type === 'init') {
        if (event && !post) {
          if (!this.isCommunityMember) {
            this.selectedDate = event.date;
          } else {
            this.selectedDate =
              this.user.createdAt > event.date ? new Date() : event.date;
          }
        } else if (!event && post) {
          if (!this.isCommunityMember) {
            this.selectedDate = post.date;
          } else {
            this.selectedDate =
              this.user.createdAt > post.date ? new Date() : post.date;
          }
        } else {
          this.selectedDate = post.date > event.date ? post.date : event.date;
          if (this.isCommunityMember) {
            this.selectedDate =
              this.user.createdAt > this.selectedDate
                ? new Date()
                : this.selectedDate;
          }
        }
      } else {
        if (!post && !event) {
          item = null;
        } else if (event && !post) {
          if (!this.isCommunityMember) {
            item = event;
          } else {
            item = this.user.createdAt < event.date ? event : null;
          }
        } else if (!event && post) {
          if (!this.isCommunityMember) {
            item = post;
          } else {
            item = this.user.createdAt < post.date ? post : null;
          }
        } else {
          if (!this.isCommunityMember) {
            item = post.date > event.date ? post : event;
          } else {
            const selectedItem = post.date > event.date ? post : event;
            item =
              this.user.createdAt < selectedItem.date ? selectedItem : null;
          }
        }
        if (type === 'before') {
          this.postsEventsBefore = item;
        } else {
          this.postsEventsAfter = item;
        }
      }
    },

    dateBeforeHasPostsOrEvents() {
      const postQuery = {
        $sort: { createdAt: -1 },
        createdAt: {
          $lt: this.startTimeOfDate(this.selectedDate),
        },
      };
      const eventQuery = {
        $sort: { start: -1 },
        start: {
          $lt: this.startTimeOfDate(this.selectedDate),
        },
      };
      this.comparePostDateAndEventDate(postQuery, eventQuery, 'before');
    },

    dateAfterHasPostsOrEvents() {
      const postQuery = {
        $sort: { createdAt: 1 },
        createdAt: {
          $gt: this.endTimeOfDate(this.selectedDate),
        },
      };
      const eventQuery = {
        $sort: { start: 1 },
        start: {
          $gt: this.endTimeOfDate(this.selectedDate),
        },
      };
      this.comparePostDateAndEventDate(postQuery, eventQuery, 'after');
    },

    getTheSelectedDate() {
      const postQuery = {
        $sort: { createdAt: -1 },
      };
      const eventQuery = {
        $sort: { start: -1 },
        start: {
          $lte: this.endTimeOfDate(new Date()),
        },
      };
      this.comparePostDateAndEventDate(postQuery, eventQuery, 'init');
    },

    async openPostInMarker(post) {
      await Promise.all([
        this.$store.dispatch('setEventObject', null),
        this.$store.dispatch('setPostObject', post),
      ]);

      this.$f7.sheet.open('#mapMarkerSheetModal');
    },
    async openEventInMarker(event) {
      await Promise.all([
        this.$store.dispatch('setPostObject', null),
        this.$store.dispatch('setEventObject', event),
      ]);

      this.$f7.sheet.open('#mapMarkerSheetModal');
    },

    openItemInMarker(item) {
      if (item.modelName === 'posts') {
        this.openPostInMarker(item);
      } else {
        this.openEventInMarker(item);
      }
    },
    dateFormat(date) {
      return moment(date).format('MMMM, Do');
    },
    startTimeOfDate(date) {
      return moment(date).startOf('day');
    },
    endTimeOfDate(date) {
      return moment(date).endOf('day');
    },
    async fetchData() {
      await findAllPages('posts', this.postQuery);
      await findAllPages('events', this.eventQuery);
      this.dateAfterHasPostsOrEvents();
      this.dateBeforeHasPostsOrEvents();
      this.$store.commit('removeLoadingAction', 'feed-map-view');
    },
    updateBounds() {
      if (this.postsAndEventsUniqueLocation.length === 0) return;

      this.$gmapApiPromiseLazy().then(() => {
        if (this.postsAndEventsUniqueLocation.length === 1) {
          this.$refs.nuPathMap.$mapObject.setZoom(12);
          this.$refs.nuPathMap.$mapObject.setCenter(
            this.postsAndEventsUniqueLocation[0].location
          );
        } else {
          /* eslint-disable-next-line no-undef */
          const bounds = new google.maps.LatLngBounds();

          this.postsAndEventsUniqueLocation.forEach(item => {
            /* eslint-disable-next-line no-undef */
            bounds.extend(
              new google.maps.LatLng(item.location.lat, item.location.lng)
            );
          });

          this.$nextTick(() => {
            this.$refs.nuPathMap.$mapObject.fitBounds(bounds);
          });
        }
      });
    },
    postMarkerIcon(item) {
      let { icon } = this;

      if (item.modelName === 'posts') {
        if (item.images.length > 0) {
          icon = mediaTransformationUrl(item.imageUrls[0], {
            scale_crop: '45x45/smart',
          });
        } else {
          const user = this.getUserInStore(item.createdBy);

          if (user.avatarUrl) {
            icon = mediaTransformationUrl(user.avatarUrl, {
              scale_crop: '45x45/smart_faces',
            });
          }
        }
      }

      return icon;
    },
  },
};
</script>

<style scoped lang="scss">
.feedmap-wrapper {
  height: 100%;
}
#vue-google-map {
  height: 100%;
  width: 100%;
}
.empty-state {
  height: calc(100vh - 112px);
  width: 100%;
  background-image: url('../../../assets/images/blurMap.jpg');
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  .empty-state-overlay {
    width: 80%;
    margin-top: -56px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: rgba(255, 255, 255, 0.85);
    .content {
      width: 90%;
      height: 32vh;
      min-height: 32vh;
      padding-bottom: 0;
    }
  }
}
.date-selector {
  position: absolute;
  opacity: 0.78;
  background: color(white);
  width: 100%;
  height: 3.2em;
  z-index: 20;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: 1.2em;
    margin-left: 1em;
    font-weight: bold;
  }
  .control-bar {
    display: flex;
    align-items: center;
    button {
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1em;
      background: transparent;
      border: none;
      outline: none;
    }
    img {
      height: 19px;
      width: 19px;
    }
  }
}
</style>
