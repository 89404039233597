<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <span
    class="white-space-pre"
    v-html="sanitizedText"
  />
</template>
<script>
  import DOMPurify from 'dompurify';
  import { convertLinks } from '@/mixins/helperFunctions';
  export default {
    mixins: [convertLinks],
    props: {
      text: {
        type: String,
        required: true,
      },
    },
    computed: {
      sanitizedText() {
        return this.sanitizeInput(this.convertLinks(this.text));
      },
    },
    methods: {
      sanitizeInput(input) {
        return DOMPurify.sanitize(input);
      },
    },
  };
</script>
<style scoped>
  .white-space-pre {
    white-space: pre-line;
  }
</style>
