<template>
  <f7-page
    infinite
    :infinite-distance="50"
    :infinite-preloader="fetching"
    class="select-list-container"
    @infinite="fetchMore"
  >
    <f7-navbar>
      <f7-nav-left>
        <button class="closePopup cursor-pointer" @click="resetAndClose">
          <f7-icon f7="close" />
        </button>
      </f7-nav-left>
      <f7-nav-title>{{ customServiceTitle }}</f7-nav-title>
      <f7-nav-right v-if="multiple">
        <button class="closePopup cursor-pointer" @click="submit">
          <f7-icon f7="checkmark" />
        </button>
      </f7-nav-right>
    </f7-navbar>

    <f7-searchbar
      ref="searchbar"
      @input="e => (searchContent = e.target.value)"
      @click:disable="reset"
    />

    <f7-list
      class="no-hairlines-md radio-list"
      @selected="item => selectItem(item)"
    >
      <p class="text-center">
        <b v-if="items.length === 0">{{ emptyMessage }}</b>
      </p>

      <slot v-for="item in items" name="item" :item="item" />
    </f7-list>
  </f7-page>
</template>
<script>
import { mapGetters } from 'vuex';
import { debounce } from 'lodash';

export default {
  props: {
    service: {
      type: String,
      required: true,
    },
    listQuery: {
      type: Object,
      required: true,
    },
    searchFields: {
      type: Array,
      default: () => [],
    },
    message: {
      type: String,
      default: '',
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    emptyMessage: {
      type: String,
      default: 'No results found',
    },
  },
  data() {
    return {
      skip: 0,
      total: null,
      fetching: false,

      searchContent: '',
      searchResults: [],
    };
  },
  computed: {
    ...mapGetters(['currentGroup']),
    moreItemsExist() {
      return this.total === null || this.items.length < this.total;
    },
    customServiceTitle() {
      return (
        this.service.charAt(0).toUpperCase() +
        this.service.slice(1).replace(/([a-zA-Z])(?=[A-Z])/g, '$1 ')
      );
    },
    suggestedItems() {
      return this.$store.getters[`${this.service}s/find`]({
        paginate: false,
        query: this.listQuery,
      }).data;
    },
    items() {
      return this.searchContent.length > 0
        ? this.searchResults
        : this.suggestedItems;
    },
  },
  watch: {
    searchContent() {
      this.debouncedSearch();
    },
    currentGroup() {
      this.resetFetch();
    },
    listQuery: {
      immediate: true,
      handler(newVal) {
        if (newVal !== null) {
          this.resetFetch();
        }
      },
    },
  },
  created() {
    this.debouncedSearch = debounce(this.searchItems, 300);
  },
  mounted() {
    this.initInfiniteScroll();
  },
  methods: {
    initInfiniteScroll() {
      this.$f7.infiniteScroll.create(
        '.select-list-container .infinite-scroll-content'
      );
    },
    async fetchMore() {
      if (this.fetching || !this.moreItemsExist) return;

      this.fetching = true;

      const query = {
        ...this.listQuery,
        $skip: this.skip,
      };

      try {
        const {
          data,
          total,
        } = await this.$store.dispatch(`${this.service}s/find`, { query });

        this.skip += data.length;
        this.total = total;
        this.fetching = false;
      } catch (err) {
        this.fetching = false;
      }
    },
    closeServicePopup() {
      setTimeout(() => {
        this.$f7.popup.close(`#${this.service}Popup`);
      }, 300);
    },
    selectItem(item) {
      this.$emit('selected', item);

      if (this.closeOnSelect) {
        this.resetAndClose();
      }
    },
    submit() {
      this.$emit('submit');
      this.resetAndClose();
    },
    resetFetch() {
      this.skip = 0;
      this.total = null;
      this.fetching = false;
      this.fetchMore();
    },
    reset() {
      this.searchContent = '';
      this.searchResults = [];
      this.$refs.searchbar.clear();
      this.$refs.searchbar.disable();
    },
    resetAndClose() {
      this.closeServicePopup();
      this.reset();
    },
    searchItems() {
      if (!this.searchContent) return;

      const searchArray = this.searchFields.map(item => {
        return { [item]: { $search: this.searchContent.trim() } };
      });

      const query = {
        ...this.listQuery,
        $or: searchArray,
      };

      this.$store
        .dispatch(`${this.service}s/find`, { query })
        .then(res => (this.searchResults = res.data));
    },
  },
};
</script>

<style lang="scss" scoped>
.select-list-container {
  background: color(bg-color);
  .closePopup {
    padding: 16px !important;
    background-color: transparent;
    border: none;
    &:active,
    &:focus {
      outline: none;
    }
  }
}
.searchbar-main {
  margin-top: 0;
}
.item-content {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
.np-border--fancy {
  height: 4px;
  width: 100%;
  background: url('../assets/images/nupath-border-bg.svg') repeat-x;
  position: absolute;
  top: 0;
}
.subnavbar {
  border-top: 1px solid color(text-color);
}
</style>
<style lang="scss">
.radio-list {
  margin: 0 !important;

  li {
    background: #fff;
    border-bottom: 1px solid #ddd;
    width: 90%;
    margin: auto;
    font-weight: bold;
    list-style: none;
  }
}
</style>
