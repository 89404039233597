<template>
  <InfiniteListPage
    :service="service"
    :query="query"
    :empty-list-message="emptyListMessage"
    end-of-list-message="Nothing more to see here."
  >
    <template slot="header">
      <DataFilters
        :filter-types="filterTypes"
        @usersSelected="updateUserFilter"
        @dateSelected="updateDateRangeFilter"
        @feedFilters="updateFeedFilters"
      />
    </template>
    <template slot="default" slot-scope="{ items }">
      <component :is="contentComponent" v-for="item in items" :key="item._id" :item="item" />
    </template>
    <template slot="empty">
      <slot name="empty" />
    </template>
  </InfiniteListPage>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import InfiniteListPage from '@/components/InfiniteListPage';
import DataFilters from '@/components/dataFeed/DataFilters';
import BehaviorContent from '@/components/dataFeed/BehaviorContent';
import ShiftNoteContent from '@/components/dataFeed/ShiftNoteContent';
import PostContent from '@/components/socialFeed/PostContent';

export default {
  components: {
    InfiniteListPage,
    DataFilters,
    BehaviorContent,
    ShiftNoteContent,
  },
  props: {
    listQuery: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    service: {
      type: String,
      required: true,
      validator: value => ['behaviors', 'shiftNotes', 'posts'].includes(value),
    },
    filterTypes: {
      type: Array,
      default: () => ['users', 'feed'],
      validator: value => value.every(type => ['users', 'feed'].includes(type)),
    },
    dateColumn: {
      type: String,
      default: 'createdAt',
      validator: value => ['createdAt', 'dateTime'].includes(value),
    },
  },
  data() {
    return {
      filters: {
        users: [],
        dateRange: [],
      },
    };
  },
  computed: {
    ...mapGetters(['currentGroup']),
    contentComponent() {
      const serviceToContentComponent = {
        behaviors: BehaviorContent,
        shiftNotes: ShiftNoteContent,
        posts: PostContent,
      };
      return serviceToContentComponent[this.service];
    },
    filtersActive() {
      return this.filters.users.length !== 0 || this.filters.dateRange.length !== 0;
    },
    serviceNameFriendly() {
      return this.service.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase();
    },
    emptyListMessage() {
      return `No ${this.serviceNameFriendly} ${this.filtersActive ? 'to display' : 'yet...'}`;
    },
    query() {
      const query = {
        $sort: { createdAt: -1 },
        group: this.currentGroup._id,
        hidden: { $ne: true },
        ...this.listQuery,
      };
      if (this.filters.users.length > 0) {
        query.createdBy = { $in: this.filters.users };
      }
      if (this.filters.dateRange && this.filters.dateRange.length === 2) {
        query[this.dateColumn] = {
          $gte: moment(this.filters.dateRange[0]).startOf('day'),
          $lte: moment(this.filters.dateRange[1]).endOf('day'),
        };
      }

      if (this.filters.selectedClient) {
        if (this.service === 'behaviors') {
          query.clientRef = this.filters.selectedClient._id;
        } else query.tags = { $in: [this.filters.selectedClient._id] };
      }
      return query;
    },
  },
  methods: {
    clearFilters() {
      this.filters.users = [];
      this.filters.dateRange = [];
    },
    emitFiltersUpdatedEvent() {
      this.$emit('filtersUpdated', this.filters);
    },
    updateUserFilter(users) {
      this.filters.users = users;
      this.emitFiltersUpdatedEvent();
    },
    updateDateRangeFilter(dateRange) {
      this.filters.dateRange = dateRange;
      this.emitFiltersUpdatedEvent();
    },
    updateFeedFilters(newFilters) {
      if (!newFilters) return;
      const dateRange = Array.isArray(newFilters.dateRange) ? [...newFilters.dateRange] : [];
      const updatedFilters = { users: this.filters.users, dateRange };
      if (newFilters.selectedClient) updatedFilters.selectedClient = { ...newFilters.selectedClient };

      this.filters = updatedFilters;
      this.emitFiltersUpdatedEvent();
    },
  },
};
</script>
