<template>
  <div class="badge-list">
    <BaseBadge
      v-for="badge in visibleBadges"
      :key="badge._id"
      :label="badge.label"
      :color="badge.color"
      :size="size"
      class="badge"
    >
      {{ badge.label }}
    </BaseBadge>

    <div
      v-if="hiddenBadgesCount > 0"
      class="hidden-count"
    >
      + {{ hiddenBadgesCount }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseBadge from '@/components/BaseBadge.vue';

export default {
  components: { BaseBadge },
  props: {
    ids: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: 'md',
    },
    max: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters('badges', {
      findBadgesInStore: 'find',
    }),

    badges() {
      return this.findBadgesInStore({
        query: { _id: { $in: this.ids } },
        $sort: { _id: 1 },
      }).data;
    },
    visibleBadges() {
      if (!this.max) return this.badges;

      return this.badges.slice(0, this.max);
    },
    hiddenBadgesCount() {
      if (!this.max) return 0;

      return this.badges.length - this.max;
    },
  },
};
</script>

<style lang="scss">
.badge-list {
  .item_inner {
    display: flex !important;
    justify-content: space-between !important;
  }
}
</style>
<style lang="scss" scoped>
.badge-list {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}
.badge {
  margin: 0.15em;
  white-space: nowrap;
}
.hidden-count {
  margin-left: 0.4em;
  font-size: 0.5em;
}
</style>
