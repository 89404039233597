export default {
  methods: {
    updateCurrentItem(service, formData) {
      if (process.env.NODE_ENV === 'test') {
        formData.createdBy = this.$store.state.modifyItemData.createdBy;
      }
      this.$store
        .dispatch(`${service}s/update`, [this.$store.state.modifyItemData._id, formData])
        .then(() => {
          this.showToast(`${service} updated successfully`, 1500);
          setTimeout(() => {
            this.$f7.popup.close();
            this.$f7.sheet.close();
            this.$store.commit('setmodifyItemData', null);
          }, 500);
        })
        .catch(() => {
          this.showToast(`Error updating ${service}. Please try again.`);
        });
    },
    close() {
      this.$store.commit('setPlaceId', null);
      this.$store.commit('setmodifyItemData', null);
      this.$store.commit('setClientsTagged', []);
      if (!this.modalTitle) {
        this.$f7router.back();
        return;
      }
      this.$f7.popup.close();
      this.$f7.sheet.close();
    },
  },
};
