<template>
  <div
    class="message"
    :class="[isReceived ? 'message-received' : 'message-sent']"
  >
    <div
      v-if="author"
      class="message-content"
    >
      <div class="message-bubble">
        <div class="message-text-header">
          {{ author.firstName }}
        </div>
        <SanitizedText
          class="message-text"
          :text="message.text"
        />
        <div class="message-text-footer">
          {{ formatDateTime(message.createdAt) }}
        </div>
      </div>
      <div class="image-container">
        <div class="avatar-container">
          <Avatar :user-id="authorId" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import SanitizedText from '@/components/SanitizedText';
import Avatar from '@/components/Avatar';
import datesMixin from '@/mixins/dates';

export default {
  components: {
    Avatar,
    SanitizedText,
  },
  mixins: [datesMixin],
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isReceived() {
      return this.authorId !== this.user._id;
    },
    authorId() {
      if (this.message.author._id) {
        return this.message.author._id;
      }
      return this.message.author;
    },
    ...mapState('auth', ['user']),
    ...mapGetters('users', {
      getUserInStore: 'get',
    }),
    author() {
      return this.getUserInStore(this.authorId);
    },
  },
};
</script>

<style lang="scss" scoped>
.message {
  max-width: 100%;
  margin-right: 12px;
  margin-left: 8px;

  &:first-of-type {
    padding-top: 1.5em;
  }

  &-content {
    display: flex;
    align-items: flex-start;

    .message-bubble {
      padding: 0.5em 1em 0.5em 1em;
      border-radius: 10px;

      .message-text-header {
        font-weight: bold;
        font-size: 0.8em;
      }

      .message-text-footer {
        font-size: 0.65em;
        margin-top: 1em;
      }
    }

    .image-container {
      .avatar-container {
        width: 70px;
      }
    }
  }

  &-text {
    font-size: 0.84em;
    margin-top: 0.4em;
    font-weight: 400;
  }

  &-sent {
    .message-content {
      flex-direction: row;
    }

    .image-container:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 15px 0 15px 15px;
      border-color: transparent transparent transparent color(blue);
      position: absolute;
      right: 60px;
      top: 12px;
    }

    .message-bubble {
      background-color: color(blue);
      color: lighten(color(gray), 13%);
      .message-text-header {
        color: color(white);
      }
      .message-text-footer {
        color: color(light-gray);
      }
    }
  }

  &-received {
    a {
      color: black;
    }

    .message-content {
      flex-direction: row-reverse;
    }

    .image-container:before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 15px 15px 15px 0;
      border-color: transparent color(white) transparent transparent;
      position: absolute;
      left: 60px;
      top: 12px;
    }

    .message-text-header {
      color: color(text-color);
    }
  }
}
</style>
