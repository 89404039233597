<template>
  <f7-popup
    class="editModal"
    tablet-fullscreen
  >
    <PostForm
      v-if="service === 'post'"
      :modal-title="modalTitle"
    />
    <BehaviorForm
      v-else-if="service === 'behavior'"
      :modal-title="modalTitle"
    />
    <EventForm
      v-else-if="service === 'event'"
      :modal-title="modalTitle"
    />
    <ShiftNoteForm
      v-else-if="service === 'shiftNote'"
      :modal-title="modalTitle"
    />
  </f7-popup>
</template>

<script>
import { mapState } from 'vuex';
import PostForm from '@/components/forms/post/PostForm';
import BehaviorForm from '@/components/forms/behavior/BehaviorForm';
import EventForm from '@/components/forms/event/EventForm';
import ShiftNoteForm from '@/components/forms/shiftNote/ShiftNoteForm';
export default {
  components: {
    PostForm,
    BehaviorForm,
    EventForm,
    ShiftNoteForm,
  },
  computed: {
    ...mapState(['modifyItemData', 'modifyItemDataIsDuplicate']),
    service() {
      if (!this.modifyItemData) return;

      return this.modifyItemData.type || '';
    },
    actionText() {
      return this.modifyItemDataIsDuplicate ? 'Edit duplicated' : 'Edit';
    },
    serviceText() {
      if (this.service === 'shiftNote') return 'shift note';

      return this.service;
    },
    modalTitle() {
      return `${this.actionText} ${this.serviceText}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.editModal {
  z-index: 12600;
}
</style>
