<template>
  <Modal :modal-title="modalTitle || 'New Behavior'">
    <f7-button slot="left-button" class="link" @click="close">
      <i class="icon icon-back" />
    </f7-button>
    <div slot="modal-content">
      <f7-list class="behavior-form no-hairlines-md">
        <f7-list-item>
          <DateTimeInput
            v-if="$store.state.modifyItemData"
            v-model="form.dateTime"
            :date-time-string="form.dateTime"
            date-label="Occurrence Date"
            time-label="Occurrence Time"
          />
          <DateTimeInput v-else v-model="form.dateTime" date-label="Occurrence Date" time-label="Occurrence Time" />
        </f7-list-item>

        <f7-list-item>
          <LocationSelect
            v-model="form.location"
            title="Select Behavior Location"
            :error="errors.location"
            :error-message="errors.location ? 'Location required to submit' : ''"
          />
        </f7-list-item>

        <li class="item-content item-input">
          <div class="item-inner">
            <div class="item-title item-label" :class="{ 'form-error': errors.clientRef }">
              Client<span class="required-field"> *</span>
            </div>
            <div class="item-input-wrap">
              <f7-link id="autocomplete-clients" class="item-link item-content" @click="addingClients = true">
                <div class="item-inner">
                  <div v-if="selectedClient" class="item-title">
                    {{ selectedClient.firstName + ' ' + selectedClient.lastName }}
                  </div>
                  <div v-else class="item-title item-title--not-selected">
                    Client
                  </div>
                </div>
              </f7-link>
            </div>
          </div>
        </li>

        <li class="item-content item-input">
          <div class="item-inner">
            <div class="item-title item-label" :class="{ 'form-error': errors.targetBehavior }">
              Target Behavior<span class="required-field"> *</span>
            </div>
            <div class="item-input-wrap">
              <f7-link id="autocomplete-clients" class="item-link item-content" @click="openTargetBehaviorModal">
                <div class="item-inner">
                  <!-- <div v-if="selectedTargetBehavior" class="item-title"> -->
                  <div v-if="Object.keys(selectedTargetBehavior).length" class="tag-list">
                    <div v-for="(behavior, index) in Object.values(selectedTargetBehavior)" :key="index" class="chip">
                      <div class="chip-label">
                        {{ behavior.name }}
                      </div>
                    </div>
                  </div>
                  <!-- </div> -->
                  <div v-else-if="selectedClient" class="item-title item-title--not-selected">
                    Choose a behavior
                  </div>
                  <div v-else class="item-title item-title--not-selected">
                    Choose a client first
                  </div>
                </div>
              </f7-link>
            </div>
          </div>
        </li>

        <f7-list-item>
          <f7-label>Notes/Comments</f7-label>
          <f7-input
            type="textarea"
            class="note-textarea resizable"
            placeholder="Notes/Comments"
            :value="form.notes"
            @input="form.notes = $event.target.value"
          />
        </f7-list-item>

        <li class="item-content">
          <div class="item-inner">
            <div class="item-title">
              <div class="item-header">Intensity<span class="required-field"> *</span></div>
            </div>
            <div class="item-after">
              <IntensitySelect v-model="form.intensity" />
            </div>
          </div>
        </li>

        <li v-if="Object.keys(selectedTargetBehavior).length <= 1" class="item-content ">
          <div class="item-inner">
            <div class="item-title">
              <div class="item-header">Frequency<span class="required-field"> *</span></div>
            </div>
            <div class="item-after">
              <f7-stepper
                id="frequency"
                :min="1"
                :max="100"
                :step="1"
                :value="form.frequency"
                @input="form.frequency = parseInt($event.target.value)"
              />
            </div>
          </div>
        </li>
      </f7-list>
      <!-- <f7-button
        v-if="$store.state.modifyItemData && $store.state.modifyItemDataIsDuplicate"
        class="btn-primary"
        :disabled="!valid"
      >
        Submit
      </f7-button> -->
      <f7-button
        v-if="$store.state.modifyItemData"
        class="btn-primary"
        :disabled="!valid"
        @click="updateCurrentItem('behavior')"
      >
        {{ $store.state.modifyItemData ? 'Submit' : 'Update Behavior' }}
      </f7-button>
      <f7-button v-else class="btn-primary" @click="submit">
        Submit
      </f7-button>
      <f7-button class="btn-secondary btn-primary" @click="close">
        Cancel
      </f7-button>

      <f7-popup id="clientPopup" :opened="addingClients" @popup:closed="addingClients = false">
        <ClientSelect :selected-item="selectedClient" @selected="item => onClientSelected(item)" />
      </f7-popup>

      <f7-popup id="targetBehaviorPopup" :opened="addingTargetBehaviors" @popupClosed="addingTargetBehaviors = false">
        <TargetBehaviorSelect
          :selected-client="selectedClient"
          :selected-item="selectedTargetBehavior"
          :multiple="!$store.state.modifyItemData"
          @selected="behavior => onBehaviorSelected(behavior)"
        />
      </f7-popup>
    </div>
  </Modal>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import analytics from '@/analytics';
import LocationSelect from '@/components/inputs/LocationSelect';
import DateTimeInput from '@/components/DateTimeInput';
import showToast from '@/mixins/showToast';
import editItem from '@/mixins/editItem';
import Modal from '@/components/Modal';
import TargetBehaviorSelect from './TargetBehaviorSelect';
import ClientSelect from '@/components/forms/client/ClientSelect';
import IntensitySelect from '@/components/inputs/IntensitySelect';
import feathersClient from '@/api/feathers-client';

export default {
  components: {
    Modal,
    DateTimeInput,
    LocationSelect,
    TargetBehaviorSelect,
    ClientSelect,
    IntensitySelect,
  },
  mixins: [showToast, editItem],
  props: {
    modalTitle: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      form: {
        dateTime: new Date(),
        location: null,
        clientRef: null,
        targetBehavior: null,
        notes: '',
        intensity: 1,
        frequency: 1,
      },
      errors: { location: false, targetBehavior: false, clientRef: false },
      selectedService: null,
      selectedClient: null,
      selectedTargetBehavior: {},
      addingClients: false,
      addingTargetBehaviors: false,
      componentKey: 0,
    };
  },
  computed: {
    ...mapGetters('clients', {
      findClientsInStore: 'find',
      getClientsInStore: 'get',
    }),
    ...mapGetters('targetBehaviors', {
      getTargetBehaviorsInStore: 'get',
    }),
    ...mapGetters(['currentGroup']),
    valid() {
      const behaviorValid = this.form.behavior || this.form.targetBehavior;
      const clientValid = this.form.client || this.form.clientRef;

      return behaviorValid && clientValid && this.form.location;
    },
    location() {
      return this.form.location;
    },
  },
  watch: {
    '$store.state.modifyItemData._id': {
      handler(newValue, oldValue) {
        if (newValue !== this.form._id) {
          this.loadComponent();
        }
      },
    },
    location(value) {
      this.errors.location = !value;
    },
    selectedClient() {
      if (!this.selectedClient) return;
      this.form.clientRef = this.selectedClient._id;
      this.errors.clientRef = false;
      //When client changes, rerender the target behaviors list
      this.forceRerender();
      if (!this.$store.state.modifyItemData) {
        this.selectedTargetBehavior = {};
      }
    },
    selectedTargetBehavior() {
      if (!this.selectedTargetBehavior) {
        this.form.targetBehavior = [];
        return;
      }
      this.errors.targetBehavior = false;
    },
  },
  created() {
    if (this.$store.state.modifyItemData) {
      Object.assign(this.form, this.$store.state.modifyItemData);
      this.selectedClient = this.getClientsInStore(this.$store.state.modifyItemData.clientRef);
      const oldBehavior = this.getTargetBehaviorsInStore(this.$store.state.modifyItemData.targetBehavior);
      this.selectedTargetBehavior = { [oldBehavior._id]: oldBehavior };
    }
  },
  methods: {
    ...mapActions('behaviors', {
      createBehavior: 'create',
      updateBehavior: 'update',
    }),
    ...mapMutations('tabs/data', {
      setActiveDataTabIndex: 'setActiveTabIndex',
    }),
    openTargetBehaviorModal() {
      if (!this.selectedClient) return;
      this.addingTargetBehaviors = true;
    },
    loadComponent() {
      this.form = { ...this.$store.state.modifyItemData };
      this.selectedClient = this.getClientsInStore(this.$store.state.modifyItemData.clientRef);
      const oldBehavior = this.getTargetBehaviorsInStore(this.$store.state.modifyItemData.targetBehavior);
      this.selectedTargetBehavior = { [oldBehavior._id]: oldBehavior };
    },
    updateCurrentItem(type) {
      try {
        const behaviors = Object.values(this.selectedTargetBehavior || {});
        if (!this.selectedTargetBehavior || behaviors.length > 1 || behaviors.length <= 0 || type !== 'behavior') {
          this.showToast('There was an error.');
          return;
        }

        this.$store.commit('addLoadingAction', 'updateCurrentBehavior');
        const [behavior] = behaviors;
        //   Object.values(this.selectedTargetBehavior).forEach(behavior => {
        //   const formData = {
        //     ...this.form,
        //     group: this.currentGroup._id,
        //     targetBehavior: behavior._id,
        //   };
        //   promises.push(this.createBehavior(formData));
        // });
        const formData = {
          ...this.form,
          group: this.currentGroup._id,
          targetBehavior: behavior._id,
        };
        this.updateBehavior([formData._id, formData]).then(() => {
          this.showToast('Behavior Updated Successfully');
          this.close();
          this.$f7router.navigate('/app/data/');
        });
      } catch (err) {
        console.log(err);
        this.showToast('There was an error.');
      } finally {
        this.$store.commit('removeLoadingAction', 'updateCurrentBehavior');
      }
    },
    submit() {
      if (this.validateForm()) return;
      const promises = [];

      Object.values(this.selectedTargetBehavior).forEach(behavior => {
        const formData = {
          ...this.form,
          group: this.currentGroup._id,
          targetBehavior: behavior._id,
        };
        promises.push(formData);
      });

      // const formData = {
      //   ...this.form,
      //   group: this.currentGroup._id,
      // };

      // this.createBehavior(formData)
      // Promise.all(promises)
      feathersClient
        .service('behaviors')
        .create(promises)
        // this.createBehavior(promises, { multi: true })
        .then(result => {
          this.showToast(
            `${
              Object.keys(this.selectedTargetBehavior).length > 1 ? 'Behaviors' : 'Behavior'
            } Recorded Successfully for ${this.selectedClient.firstName} ${this.selectedClient.lastName}`
          );
          this.$store.commit('behaviors/addItems', result);
          this.setActiveDataTabIndex(0);
          this.close();
          // this.$f7router.navigate('/app/data/');
          analytics.track({
            userId: this.$store.state.auth.user._id,
            event: 'Logged behavior',
            // properties: {
            //   hasLocation: !!behavior.location,
            // },
          });
        })
        .catch(err => {
          this.showToast('There was an error.');
          // eslint-disable-next-line no-console
          console.warn(err);
        });
    },
    onClientSelected(client) {
      this.selectedClient = client;
      if (this.$store.state.modifyItemData) {
        this.selectedTargetBehavior = [];
      }
    },
    onBehaviorSelected(behavior) {
      this.selectedTargetBehavior = behavior;
    },
    forceRerender() {
      this.componentKey += 1;
    },
    validateForm() {
      const behaviorValid = Object.keys(this.selectedTargetBehavior).length > 0;
      const clientValid = this.form.client || this.form.clientRef;
      this.errors = {
        targetBehavior: !behaviorValid,
        clientRef: !clientValid,
        location: !this.form.location,
      };

      return Object.values(this.errors).some(value => value);
    },
  },
};
</script>

<style lang="scss" scoped>
.behavior-form {
  margin-top: 0;
  width: 90%;
  margin: auto;
  background: color(white);
  font-size: 1.1em;
  .frequency-selector {
    .item-label {
      margin-bottom: 1em;
    }
  }
  .item-header {
    text-transform: uppercase;
    font-weight: bold;

    color: color(text-color);
  }

  .item-title--not-selected {
    color: lightgray;
  }
  #autocomplete-behaviors,
  #autocomplete-clients {
    .item-inner {
      display: flex;
    }
    .item-title {
      margin-left: -15px;
    }
  }
}

.btn-primary,
.btn-secondary {
  @media (max-width: 360px) {
    position: relative;
    margin-top: 1.5em;
  }
}

.btn-primary {
  bottom: 70px;
}
.btn-secondary {
  bottom: 10px;
}

#clientPopup,
#targetBehaviorPopup {
  z-index: 12602;
}

.form-error {
  color: #ef4422;
}

.chip {
  background-color: color(blue);
}</style
>import { form } from '@segment/analytics-next/dist/types/core/auto-track';
