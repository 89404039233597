class Geolocate {
  constructor() {
    this.GEOLOCATION_OPTIONS = {
      maximumAge: 3600000,
      timeout: 5000,
      enableHighAccuracy: true,
    };
  }

  getCurrentCoordinates() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const coordinates = this._serializeCoordinates(position.coords);

          resolve(coordinates);
        },
        reject,
        this.GEOLOCATION_OPTIONS
      );
    });
  }

  _serializeCoordinates(coords) {
    return {
      latitude: coords.latitude,
      longitude: coords.longitude,
      altitude: coords.altitude,
      accuracy: coords.accuracy,
      altitudeAccuracy: coords.altitudeAccuracy,
      heading: coords.heading,
      speed: coords.speed,
    };
  }
}

export default Geolocate;
