<template>
  <div class="radio-group-pills">
    <span
      v-for="option in options"
      :key="option.name"
      @click="selected(option.value)"
    >
      <input
        :id="option.name"
        type="radio"
        :value="option.value"
        :checked="value === option.value"
      >
      <label :for="option.name">
        {{ option.name }}
      </label>
    </span>
  </div>
</template>

<script>
  export default {
    name: 'IntensitySelect',
    components: {},
    props: {
      value: {
        type: Number,
        default: 1,
      },
    },
    data() {
      return {
        options: [
          {
            name: 'Low',
            value: 1,
          },
          {
            name: 'Medium',
            value: 2,
          },
          {
            name: 'High',
            value: 3,
          },
        ],
      };
    },
    methods: {
      selected(newValue) {
        this.$emit('input', newValue);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .radio-group-pills {
    border: 1px solid #ddd;
    border-radius: 20px;
    display: inline-block;
    margin-bottom: 5px;

    input {
      position: absolute;
      visibility: hidden;
      display: none;

      &:checked + label {
        background: color(orange);
      }
    }

    label {
      cursor: pointer;
      color: color(text-color);
      display: inline-block;
      padding: 2px 12px;
      font-weight: bold;
      border: 0px solid transparent;
      border-radius: 20px;

      @media (max-width: 360px) {
        font-size: 1.2em;
      }
    }
  }
</style>
