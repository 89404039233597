<template>
  <Slide title="Get Started">
    <img
      slot="image"
      src="@/assets/images/get-started.svg"
      alt=""
      class="icon"
    >
    <p>
      Navigating Life is a private network. If you do not have an account and you would like to
      apply. Please complete our pre-application.
    </p>
    <WelcomeSliderButton
      ghost
      @click="$emit('complete')"
    >
      Go to Login
    </WelcomeSliderButton>
  </Slide>
</template>
<script>
  import WelcomeSliderButton from './WelcomeSliderButton';
  import Slide from '@/components/welcomeSlider/Slide';

  export default {
    components: {
      Slide,
      WelcomeSliderButton,
    },
  };
</script>

<style scoped>
  .icon {
    max-width: 150px;
  }
</style>
