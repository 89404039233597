<template>
  <f7-page class="modal">
    <div class="navbar">
      <div class="navbar-inner sliding">
        <div class="left">
          <slot name="left-button" />
        </div>
        <div class="title">
          {{ modalTitle }}
        </div>
      </div>
    </div>
    <div class="content">
      <slot name="modal-content" />
    </div>
  </f7-page>
</template>
<script>
  export default {
    props: {
      modalTitle: {
        type: String,
        required: true,
      },
    },
  };
</script>
<style lang="scss" scoped>
  .modal {
    background: color(bg-color);

    .navbar:before {
      content: '';
      height: 4px;
      width: 100%;
      display: block;
      background: url('../assets/images/nupath-border-bg.svg') repeat-x;
    }

    .content {
      margin-top: 0.8em;
    }
  }
</style>
