import feathersClient, { makeServicePlugin, BaseModel } from '../../api/feathers-client';

class Group extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = 'Group';

  static setupInstance(data, { store, models }) {
    data.organizationData = new models.api.Organization(data.organizationData);
    return data;
  }
}
const servicePath = 'v2/groups';
const servicePlugin = makeServicePlugin({
  Model: Group,
  service: feathersClient.service(servicePath),
  servicePath,
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
