<template>
  <div
    v-if="user"
    class="card-content cursor-pointer"
    @click="openUserProfile(user._id)"
  >
    <Avatar :user-id="userId" />
    <div class="wrapper">
      <div class="body">
        <UserDisplayName :user-id="userId" />
      </div>
    </div>
  </div>
</template>

<script>
  import Avatar from '@/components/Avatar';
  import UserDisplayName from '@/components/UserDisplayName';
  import { openUserProfile } from '@/mixins/helperFunctions';
  import { mapGetters } from 'vuex';
  export default {
    components: {
      Avatar,
      UserDisplayName,
    },
    mixins: [openUserProfile],
    props: {
      userId: {
        type: String,
        required: true,
      },
    },
    computed: {
      ...mapGetters('users', {
        getUserInStore: 'get',
      }),
      user() {
        return this.getUserInStore(this.userId);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .avatar {
    max-height: 50px;
  }
  .card-content {
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid #ddd;
    padding: 0.5em;
    img {
      border: 1px solid transparent;
      border-radius: 100px;
      height: 50px;
      width: 50px;
    }
    .wrapper {
      width: 100%;
      .body {
        padding-left: 1.2em;
        height: 50px;
        display: flex;
        align-items: center;
        font-weight: bold;
      }
    }
  }
</style>
