export class BiometricAuthFailed extends Error {
  constructor(message) {
    super(message);
    this.name = 'BiometricAuthFailed';
  }
}

export class InvalidPinError extends Error {
  constructor(message) {
    super(message);
    this.name = 'InvalidPinError';
  }
}

export class UserNotSavedError extends Error {
  constructor(message) {
    super(message);
    this.name = 'UserNotSavedError';
  }
}

export const handleBiometricError = (resolve, reject) => (err) => {
  switch (err.code) {
    case window.Fingerprint.BIOMETRIC_NO_SECRET_FOUND:
      resolve([]);
      break;
    case window.Fingerprint.BIOMETRIC_AUTHENTICATION_FAILED:
      reject(new BiometricAuthFailed(err));
      break;
    default:
      reject(err);
      break;
  }
};
