import feathersClient, { makeServicePlugin, BaseModel } from '../../api/feathers-client';

class Conversation extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }

  static modelName = 'Conversation';

  static setupInstance(data, { store, models }) {
    data.participantsData = data.participantsData.map(
      (participant) => new models.api.User(participant)
    );
    return data;
  }
}
const servicePath = 'v2/conversations';
const servicePlugin = makeServicePlugin({
  Model: Conversation,
  service: feathersClient.service(servicePath),
  servicePath,
  replaceItems: true,
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
