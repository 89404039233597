<template>
  <div
    ref="container"
    class="container"
  >
    <div
      v-show="loaded"
      class="loading-container"
    >
      <img
        ref="image"
        class="image"
        :src="src"
        @click.self="$emit('click')"
      >
      <slot />
    </div>
    <div
      v-if="!loaded"
      class="loading-container display-flex justify-content-center align-items-center"
    >
      <f7-preloader />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseImage',
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
    };
  },
  mounted() {
    this.$refs.image.onload = () => {
      this.loaded = true;
    };
  },
};
</script>

<style lang="scss" scoped>
.image {
  overflow: hidden;
  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center;
}

.loading-container {
  width: 100%;
  height: 100%;
}
</style>
