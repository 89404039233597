// import anchorme from 'anchorme';
// import * as linkify from 'linkifyjs';
import linkifyStr from 'linkify-string';

export const displayInitials = {
  methods: {
    displayInitials(name) {
      return name.charAt(0).toUpperCase();
    },
  },
};
export const clickToCallAndEmail = {
  methods: {
    clickToCall(number) {
      number = `tel:${number}`;
      window.open(number, '_system');
    },
    clickToEmail(email) {
      email = `mailto:${email}`;
      window.open(email, '_system');
    },
  },
};
export const openUserProfile = {
  methods: {
    async openUserProfile(userId) {
      if (this.$f7.views.main.router.currentRoute.params.userId === userId) {
        // eslint-disable-next-line no-console
        return;
      }
      let storeUser;
      try {
        storeUser = await this.$store.dispatch('users/get', userId);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(`User ${userId} does not exist`);
      }
      if (this.$can('read', storeUser)) {
        //Close nested sheet modal
        this.$f7.sheet.close();
        this.$f7router.navigate(`/app/userProfile/${storeUser._id}/`);
        this.$f7.sheet.close();
      }
    },
  },
};

export const convertLinks = {
  methods: {
    convertLinks(input) {
      const linkedText = linkifyStr(input, {
        attributes: {
          target: '_blank',
          class: 'link external',
        },
      });
      return linkedText;
      // return anchorme(input, {
      //   truncate: [26, 15],
      //   attributes: {
      //     target: '_blank',
      //     class: 'link external',
      //   },
      // });
    },
  },
};
