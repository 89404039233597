import { mapState, mapGetters, mapActions } from 'vuex';
import analytics from '@/analytics';

export default {
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters(['isCordova', 'currentOrganization']),
  },

  methods: {
    ...mapActions('organizations', {
      findOrganizations: 'find',
    }),
    registerServices() {
      if (this.isCordova) {
        this.registerOnesignal();
      }
      this.registerAnalytics();
    },

    registerAnalytics() {
      try {
        analytics.identify({
          userId: this.user._id,
          traits: {
            name: `${this.user.firstName} ${this.user.lastName}`,
            email: this.user.email,
            organization: this.currentOrganization.name,
          },
        });
      } catch (err) {
        // eslint-disable-next-line
        console.log(err);
      }
    },

    async registerOnesignal() {
      const oneSignalUserData = {
        firstName: this.user.firstName,
        email: this.user.email,
        user_id: this.user._id,
      };

      const { data: organizationsData } = await this.findOrganizations({
        query: { _id: this.user.organizations.map(o => o._id) },
      });

      const userPrimaryOrganization = this.user.organizations.find(
        o => o.isPrimary
      );

      const primaryOrganizationData = organizationsData.find(
        o => o._id === userPrimaryOrganization._id
      );

      if (primaryOrganizationData) {
        oneSignalUserData.organization_primary = primaryOrganizationData.name;
        oneSignalUserData.organization_primary_role =
          userPrimaryOrganization.role;
      }

      // Add any non-primary organizations to the user data
      this.user.organizations
        .filter(o => !o.isPrimary)
        .forEach((userOrg, index) => {
          const orgData = organizationsData.find(o => o._id === userOrg._id);
          if (!orgData) {
            return;
          }
          oneSignalUserData[`organization_alt_${index + 1}`] = orgData.name;
          oneSignalUserData[`organization_alt_${index + 1}_role`] =
            userOrg.role;
        });

      window.plugins.OneSignal.setExternalUserId(this.user._id);
      window.plugins.OneSignal.setEmail(this.user.email);
      window.plugins.OneSignal.sendTags(oneSignalUserData);
    },
  },
};
