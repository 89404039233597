<template>
  <Modal :modal-title="modalTitle || 'Create Event'">
    <f7-button
      slot="left-button"
      class="link"
      @click="navigateToEventsPage"
    >
      <i class="icon icon-back" />
    </f7-button>
    <div slot="modal-content">
      <f7-list class="list no-hairlines-md add-event-form">
        <f7-list-item>
          <f7-label>Title<span class="required-field">*</span></f7-label>
          <f7-input
            type="text"
            :value="form.title"
            placeholder="Title"
            required
            @input="form.title = $event.target.value"
          />
        </f7-list-item>

        <f7-list-item>
          <f7-row class="row">
            <f7-col>
              <f7-label>Event Date<span class="required-field">*</span></f7-label>
              <f7-input
                type="date"
                placeholder="date"
                :value="form.date"
                required
                :min="minDate"
                @input="form.date = $event.target.value"
              />
            </f7-col>

            <f7-col>
              <div class="toggle-allDay">
                <f7-label>All Day</f7-label>
                <label class="toggle toggle-init">
                  <input
                    v-model="form.allDay"
                    type="checkbox"
                  >
                  <span class="toggle-icon" />
                </label>
              </div>
            </f7-col>
          </f7-row>
        </f7-list-item>
        <!-- To walking around a weird issue that if you toggle the all day button, the location input get cleared -->
        <f7-list-item
          :class="{ allDayEvent: form.allDay }"
          class="time-selector"
        >
          <f7-row
            v-if="!form.allDay"
            class="row"
          >
            <f7-col>
              <f7-label>Start Time<span class="required-field">*</span></f7-label>
              <f7-input
                ref="startTime"
                type="time"
                :value="form.start"
                step="900"
                required
                @input="form.start = $event.target.value"
              />
            </f7-col>
            <f7-col>
              <f7-label>End Time<span class="required-field">*</span></f7-label>
              <f7-input
                ref="endTime"
                type="time"
                :value="form.end"
                required
                step="900"
                @input="form.end = $event.target.value"
              />
            </f7-col>
          </f7-row>
        </f7-list-item>

        <f7-list-item>
          <LocationSelect
            v-model="form.location"
            title="Select Event Location"
            :auto-select-nearest="true"
          />
        </f7-list-item>

        <f7-list-item>
          <f7-label>Description</f7-label>
          <f7-input
            id="event-desc"
            :value="form.description"
            type="textarea"
            placeholder="Description"
            @input="form.description = $event.target.value"
          />
        </f7-list-item>
      </f7-list>
      <f7-button
        v-if="editing"
        class="btn-primary"
        :disabled="!valid"
        @click="updateCurrentEvent"
      >
        Update Event
      </f7-button>
      <f7-button
        v-else
        class="btn-primary"
        :disabled="!valid"
        @click="addEvent"
      >
        Create Event
      </f7-button>
    </div>
  </Modal>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import LocationSelect from '@/components/inputs/LocationSelect';
  import showToastMixin from '@/mixins/showToast';
  import editItem from '@/mixins/editItem';
  import Modal from '@/components/Modal';
  import moment from 'moment';
  import analytics from '@/analytics';

  export default {
    components: {
      Modal,
      LocationSelect,
    },
    mixins: [showToastMixin, editItem],
    props: {
      modalTitle: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        form: {
          title: null,
          date: null,
          start: null,
          end: null,
          allDay: false,
          location: null,
          description: '',
        },
        minDate: null,
      };
    },
    computed: {
      ...mapGetters(['currentGroup']),
      /* eslint-disable-next-line complexity */
      valid() {
        const timeValid = this.form.allDay || (this.form.start && this.form.end);
        const titleValid = this.form.title && this.form.title.trim().length > 0;

        if (titleValid && this.form.date && timeValid && this.form.location) {
          return true;
        }

        return false;
      },
      editing() {
        return this.$store.state.modifyItemData && this.$store.state.modifyItemData._id;
      },
    },

    mounted() {
      const startObj = moment(this.$store.state.modifyItemData.start).local();
      const endObj = moment(this.$store.state.modifyItemData.end).local();

      this.form.date = startObj.format('YYYY-MM-DD');
      this.form.start = startObj.format('HH:mm');
      this.form.end = endObj.format('HH:mm');
    },
    created() {
      Object.assign(this.form, this.$store.state.modifyItemData || {});
    },
    methods: {
      ...mapActions('events', {
        createEvent: 'create',
        updateEvent: 'update',
      }),
      navigateToEventsPage() {
        if (this.editing) {
          this.$f7.popup.close('.editModal');
        } else {
          this.$f7router.navigate('/app/events/', { animate: false });
        }

        this.$store.dispatch('setmodifyItemData', null);
      },

      prepareForDatabase(date, time) {
        return moment(`${date}T${time}`)
          .utc()
          .format();
      },
      addEvent() {
        this.createEvent({
          title: this.form.title.trim(),
          start: this.prepareForDatabase(this.form.date, this.form.start),
          end: this.prepareForDatabase(this.form.date, this.form.end),
          allDay: this.form.allDay,
          location: {
            name: this.form.location.name,
            lat: this.form.location.lat,
            lng: this.form.location.lng,
          },
          description: this.form.description.trim(),
          group: this.currentGroup._id,
        })
          .then(() => {
            this.showToast('Event added successfully.');
            this.form.title = '';
            this.form.description = '';

            analytics.track({
              userId: this.$store.state.auth.user._id,
              event: 'Added event',
            });

            this.navigateToEventsPage();
          })
          .catch(() => {
            this.showToast('There was an error adding the event.');
          });
      },
      updateCurrentEvent() {
        const formData = {
          ...this.form,
          start: this.prepareForDatabase(this.form.date, this.form.start),
          end: this.prepareForDatabase(this.form.date, this.form.end),
        };

        this.updateCurrentItem('event', formData);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .add-event-form {
    margin-top: 0;
    width: 90%;
    margin: auto;
    background: color(white);
    font-size: 1.15em;
    .toggle-allDay {
      text-align: left;
      margin-left: 1em;
      label {
        margin-right: 0.5em;
      }
    }
    .toggle-init {
      margin-top: 0.5em;
    }
    .required-field {
      font-size: 1.1em;
      margin-left: 3px;
    }
  }
  #event-desc {
    font-size: 0.8em;
  }
  .list li {
    padding: 0.4em;
    @media (max-width: 360px) {
      padding: 0.2em;
    }
  }
  .allDayEvent {
    display: none;
  }
  .row {
    width: 100%;
  }
</style>
