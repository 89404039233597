<template>
  <FeedSheetModal
    id="commentsSheetModal"
    ref="modal"
    service="comments"
    icon="comment.svg"
    :empty-message="$can('create', 'comments') ? 'Add one below' : ''"
    :style="{ 'margin-bottom': marginBottomZero ? 0 : '6px' }"
  >
    <div
      slot="list-items"
      slot-scope="{ items }"
    >
      <Comment
        v-for="item in items"
        :key="item._id"
        :comment="item"
      />
    </div>
    <div
      v-if="$can('create', 'comments')"
      slot="footer"
    >
      <f7-messagebar
        ref="messagebar"
        placeholder="What do you want to say..."
        class="messagebar"
        :value="message"
        @input="message = $event.target.value"
      >
        <f7-link
          slot="inner-end"
          :class="{ 'submit-comment-disabled': loading }"
          class="submit-comment"
          @click="commentOnPost"
        >
          <img
            src="@/assets/images/icons/send.svg"
            class="icon-send"
          >
        </f7-link>
      </f7-messagebar>
    </div>
  </FeedSheetModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Comment from '@/components/socialFeed/Comment';
import FeedSheetModal from '@/components/socialFeed/FeedSheetModal';

export default {
  components: {
    Comment,
    FeedSheetModal,
  },
  props: {
    marginBottomZero: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      message: null,
    };
  },
  computed: {
    ...mapGetters(['loading']),
    post() {
      return this.$store.state.postObject;
    },
  },
  methods: {
    ...mapActions('comments', {
      createComment: 'create',
    }),
    async commentOnPost() {
      if (!this.message) return;
      if (this.message.trim().length === 0) return;
      this.$store.commit('addLoadingAction', 'comment-on-post');
      try {
        await this.createComment([
          {
            text: this.message,
            ref: {
              kind: 'Post',
              _id: this.post._id,
            },
          },
        ]);
        const container = this.$refs.modal.$refs.list;
        setTimeout(() => {
          container.scrollTop = container.scrollHeight;
        }, 0);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      } finally {
        this.message = '';
        this.$store.commit('removeLoadingAction', 'comment-on-post');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.submit-comment-disabled {
  pointer-events: none;
}
.messagebar {
  min-height: 7vh;
  position: absolute;
  display: flex;
  align-items: center;
  border: 8px solid color(blue);
  background: color(blue);
  .icon-send {
    height: 32px;
    width: 32px;
    margin-right: -0.5em;
  }
}
</style>
