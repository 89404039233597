<template>
  <div class="left-sidebar">
    <div class="list links-list">
      <f7-link
        v-for="item in authorizedTabs"
        :key="item.label"
        :href="item.route"
        tab-link
        :tab-link-active="currentRouteUrl === item.route"
      >
        <img
          :src="currentRouteUrl === item.route ? item.imgOnActive : item.img"
        >
        <span v-text="item.label" />
      </f7-link>
    </div>
  </div>
</template>
<script>
import tabs from '@/mixins/tabs';

export default {
  mixins: [tabs],
  computed: {
    currentRouteUrl() {
      return this.$f7.views.main.router.currentRoute.url;
    },
  },
};
</script>
<style lang="scss" scoped>
.list {
  margin: 0;

  &.links-list {
    min-width: 200px;
    a {
      display: flex;
      justify-content: flex-start;
      img {
        width: 18px;
        margin-right: 12px;
      }
    }
  }
}
.tab-link-active {
  background-color: color(blue);
  color: white !important;
}
</style>
