<template>
  <f7-page>
    <f7-navbar class="styled">
      <f7-nav-left>
        <f7-link
          icon-f7="close"
          @click="closeMessages"
        />
      </f7-nav-left>
      <f7-nav-title>{{ title }}</f7-nav-title>
      <f7-nav-right v-if="isMobile">
        <f7-link
          icon-f7="info_round"
          @click="$f7.panel.open('right')"
        />
      </f7-nav-right>
    </f7-navbar>

    <f7-messagebar
      ref="messagebar"
      placeholder="Write a message"
      class="messagebar"
      :value="messageText"
      @input="messageText = $event.target.value"
    >
      <a
        v-if="isCordova"
        slot="inner-start"
        :disabled="loadingVideoConversations"
        class="link"
      >
        <img
          class="icon-video"
          src="@/assets/images/icons/video.svg"
          @click="startVideo(null)"
        >
      </a>
      <img
        slot="send-link"
        src="@/assets/images/icons/send.svg"
        class="icon-send"
        @click="sendMessage"
      >
    </f7-messagebar>

    <f7-button
      v-if="moreItemsExist"
      class="loadMore"
      @click="loadMore"
    >
      Load previous
    </f7-button>

    <div
      v-if="loading"
      class="text-center py-5"
    >
      <f7-preloader />
    </div>

    <f7-messages :scroll-messages="scrollOnNewMessages">
      <div
        v-for="item in items"
        :key="item._id"
      >
        <Message
          v-if="item.modelName === 'messages'"
          :message="item"
        />
        <VideoConversationNotice
          v-else-if="item.modelName === 'videoConversations'"
          :video-conversation="item"
          @join="startVideo"
        />
      </div>
    </f7-messages>

    <f7-panel
      v-if="isMobile && conversationId"
      right
      theme-dark
      class="z-index-top"
    >
      <f7-view>
        <f7-page>
          <f7-block-title class="title-bar">
            <f7-button @click="$f7.panel.close('right')">
              <img
                src="@/assets/images/icons/cancel.svg"
                width="20"
              >
            </f7-button>
            CHAT MEMBERS
          </f7-block-title>
          <f7-list media-list>
            <f7-list-item
              v-for="user in users"
              :key="user._id"
              :title="`${user.firstName} ${user.lastName}`"
              :subtitle="user.title"
            />
          </f7-list>
        </f7-page>
      </f7-view>
    </f7-panel>
  </f7-page>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { orderBy, last } from 'lodash';
import Message from '@/components/chat/Message';
import VideoConversationNotice from '@/components/chat/VideoConversationNotice';
import conversationMixin from '@/mixins/conversations';
import datesMixin from '@/mixins/dates';
import showToast from '@/mixins/showToast';
import { findAllPages } from '@/helpers/pagination';
import {
  createConference as createDolbyioConference,
  joinConference as joinDolbyioConference,
} from '@/helpers/video-call';

export default {
  components: {
    Message,
    VideoConversationNotice,
  },
  mixins: [conversationMixin, datesMixin, showToast],
  props: {
    conversationId: {
      type: String,
      required: true,
    },
    autoStartVideo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      messageText: '',
      infoPanelOpen: false,
      scrollOnNewMessages: false,

      fetchedMessages: false,
      fetchedVideoConversations: false,
      totalItems: null,
      limit: 5,
      loadingMessages: false,
      loadingVideoConversations: false,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('messages', {
      findMessagesInStore: 'find',
    }),
    ...mapGetters('conversations', {
      getConversationFromStore: 'get',
    }),
    ...mapGetters('videoConversations', {
      findVideoConversationsInStore: 'find',
    }),
    ...mapGetters('users', {
      findUsersInStore: 'find',
    }),
    ...mapGetters(['isCordova', 'isMobile']),
    conversation() {
      return this.getConversationFromStore(this.conversationId);
    },
    isConversationRead() {
      if (!this.conversation) return false;

      const readBy = this.conversation.readBy || [];
      return readBy.includes(this.user._id);
    },
    title() {
      if (this.users.length === 0) {
        return '';
      }
      return this.users.length === 1
        ? `${this.users[0].firstName} ${this.users[0].lastName}`
        : this.users.map(u => u.firstName).join(', ');
    },
    users() {
      if (!this.conversation) return [];

      return this.findUsersInStore({
        query: { _id: { $in: this.conversation.participants } },
      }).data;
    },
    query() {
      return {
        $sort: {
          createdAt: -1,
        },
        conversation: this.conversationId,
      };
    },
    items() {
      const items = [...this.messages, ...this.videoConversations];
      return orderBy(items, 'createdAt', 'asc');
    },
    messages() {
      if (!this.conversation) return [];

      return this.findMessagesInStore({ paginate: false, query: this.query })
        .data;
    },
    videoConversations() {
      if (!this.conversation) return [];

      return this.findVideoConversationsInStore({
        paginate: false,
        query: {
          ...this.query,
          dolbyioConferenceId: { $ne: null },
        },
      }).data;
    },
    moreItemsExist() {
      return (
        this.totalItems === null ||
        this.messages.length + this.videoConversations.length < this.totalItems
      );
    },
    oldestStoredMessageDate() {
      return this.fetchedMessages && this.messages.length > 0
        ? this.messages[this.messages.length - 1].createdAt
        : new Date().toISOString();
    },
    oldestStoredVideoConversationDate() {
      return this.fetchedVideoConversations &&
        this.videoConversations.length > 0
        ? this.videoConversations[this.videoConversations.length - 1].createdAt
        : new Date().toISOString();
    },
  },
  watch: {
    items: {
      deep: true,
      handler(newVal, oldVal) {
        if (oldVal && newVal.length > oldVal.length) {
          if (last(newVal)._id !== last(oldVal)._id) {
            this.scrollToEnd();
          }

          this.getFeedItemsTotal();
        }
      },
    },

    conversation: {
      immediate: true,
      async handler(newVal, oldVal) {
        if (!newVal) return;

        this.loadingMessages = false;

        if (newVal !== oldVal) {
          await this.getFeedItemsTotal();

          await this.reloadItems();
          this.scrollToEnd();
        }

        if (this.autoStartVideo) {
          this.autoStartVideo = false;
          const dolbyioConferenceId = await this.getActiveVideoConversationDolbyioConferenceId();

          if (dolbyioConferenceId) {
            await this.startVideo(dolbyioConferenceId);
          } else {
            this.showToast('The selected video call has ended');
          }
        }
      },
    },

    conversationId: {
      immediate: true,
      handler(newVal, oldVal) {
        this.getConversation(this.conversationId);
      },
    },
  },
  methods: {
    ...mapActions('messages', {
      createMessage: 'create',
      findMessages: 'find',
      updateMessages: 'update',
    }),
    ...mapActions('conversations', {
      getConversation: 'get',
      patchConversation: 'patch',
    }),
    ...mapActions('videoConversations', {
      createVideoConversation: 'create',
      findVideoConversations: 'find',
      patchVideoConversation: 'patch',
    }),
    ...mapActions('videoConversationAuthorizations', {
      createVideoConversationAuthorization: 'create',
      getVideoConversationAuthorization: 'get',
    }),

    sendMessage() {
      const message = this.messageText.replace(/\n/g, '<br>').trim();
      if (message.length === 0) return;
      this.createMessage({
        text: message,
        conversation: this.conversationId,
      }).then(() => {
        this.messageText = '';
      });
    },
    markMessagesRead() {
      if (this.isConversationRead) return;

      const readBy = this.conversation.readBy || [];
      readBy.push(this.user._id);
      this.patchConversation([this.conversationId, { readBy }]);
    },
    closeMessages() {
      this.markMessagesRead();
      if (this.isMobile) {
        this.$f7router.back();
      } else {
        this.$f7.popup.close();
      }
    },
    scrollToEnd() {
      this.scrollOnNewMessages = true;
      this.$nextTick(() => {
        this.scrollOnNewMessages = false;
      });
    },
    async startVideo(dolbyioConferenceId = null) {
      if (!this.isCordova || this.loadingVideoConversations) return;

      this.loadingVideoConversations = true;
      const notice = this.showToast('Loading video call', null);

      try {
        if (dolbyioConferenceId === null) {
          // Get active videoConversation from store
          dolbyioConferenceId = await this.getActiveVideoConversationDolbyioConferenceId();

          // Create new videoConverstion & Dolbyio conference if necessary
          if (!dolbyioConferenceId) {
            const otherUsers = this.users.filter(
              user => user._id !== this.user._id
            );
            dolbyioConferenceId = await createDolbyioConference(
              this.user,
              this.conversation._id,
              otherUsers
            );
          }
        }

        // Join the conference
        await joinDolbyioConference(this.user, dolbyioConferenceId);
      } catch (err) {
        console.log(err);
        this.showToast('Error loading the video call. Please try again.', 3000);
      }

      // Delay closing noticing for 1s to ensure it always appears
      setTimeout(() => {
        notice.close();
      }, 1000);

      this.loadingVideoConversations = false;
    },

    // Get active videoConversation's dolbyioConferenceId from store
    // Implemented as method, rather than computed property to ensure we have
    //  the re-computed value when auto starting video
    async getActiveVideoConversationDolbyioConferenceId() {
      const {
        data: [videoConversation],
      } = await this.findVideoConversations({
        query: {
          conversation: this.conversationId,
          dolbyioConferenceId: { $ne: null },
          endedAt: null,
          $sort: { createdAt: -1 },
          $limit: 1,
        },
      });

      if (!videoConversation) return null;

      return videoConversation.dolbyioConferenceId;
    },
    async fetchMessages() {
      const query = {
        ...this.query,
        createdAt: { $lt: this.oldestStoredMessageDate },
        $limit: this.limit,
      };

      const messages = await this.findMessages({ query });

      this.fetchedMessages = true;

      return messages;
    },
    async fetchVideoConversations(createdBefore = null) {
      const query = {
        ...this.query,
        createdAt: { $lt: this.oldestStoredVideoConversationDate },
        dolbyioConferenceId: { $ne: null },
      };

      // If any messages exist, fetch all videoConversations up to the datetime of the oldest message
      // so infinite scroll up to that datetime will be complete
      let videoConversations = [];
      if (createdBefore !== null) {
        query.createdAt.$gte = createdBefore;

        videoConversations = await findAllPages('videoConversations', query);
      } else {
        query.$limit = this.limit;

        videoConversations = await this.findVideoConversations({ query });
      }

      this.fetchedVideoConversations = true;

      return videoConversations;
    },
    async reloadItems() {
      this.fetchedMessages = false;
      this.fetchedVideoConversations = false;

      await this.loadItems();
    },
    async loadItems() {
      const { data } = await this.fetchMessages();

      let createdBefore = null;

      if (data.length > 0) {
        createdBefore = this.messages[this.messages.length - 1].createdAt;
      }

      await this.fetchVideoConversations(createdBefore);
      await this.markMessagesRead();
    },
    async loadMore() {
      if (!this.moreItemsExist || this.items.length === 0 || this.loading)
        return;

      this.loadingMessages = true;

      await this.loadItems();
      this.loadingMessages = false;
    },
    async getFeedItemsTotal() {
      this.totalItems = null;

      const { total: totalMessages } = await this.findMessages({
        query: {
          ...this.query,
          $limit: 0,
        },
      });

      const {
        total: totalVideoConversations,
      } = await this.findVideoConversations({
        query: {
          ...this.query,
          $limit: 0,
        },
      });

      this.totalItems = totalMessages + totalVideoConversations;
    },
  },
};
</script>
<style lang="scss" scoped>
.z-index-top {
  z-index: 12514;
  position: fixed;
}
.messages {
  margin-bottom: 2em;
}

.messagebar {
  .icon-video,
  .icon-send {
    max-height: 80%;
  }
}

.icon-send {
  max-height: 80%;
  width: auto;
}
.messages {
  margin-bottom: 2em;
}
.loadMore {
  text-align: center;
  color: color(text-color);
  font-weight: bold;
  text-transform: none;
}
.title-bar {
  margin: 10px 16px 16px;
  a {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
