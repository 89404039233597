<template>
  <ActionModal>
    <div v-if="modifyItemData && user">
      <div v-if="userCanManage">
        <f7-actions-button
          v-if="isEditable"
          class="btn-edit"
          @click="editItem(modifyItemData)"
        >
          Edit {{ serviceNameFriendly }}
        </f7-actions-button>
        <f7-actions-button
          v-if="isDuplicatable"
          class="btn-duplicate"
          @click="duplicateItem"
        >
          Duplicate {{ serviceNameFriendly }}
        </f7-actions-button>
        <f7-actions-button class="btn-delete" @click="deleteItem">
          Delete {{ serviceNameFriendly }}
        </f7-actions-button>
      </div>

      <f7-actions-button v-if="canCreateFlag" class="btn-report">
        <a :href="`/app/reportItem/${service}:${modifyItemData._id}/`">
          Report {{ serviceNameFriendly }}
        </a>
      </f7-actions-button>
      <f7-actions-button
        class="btn-close sheet-close"
        @click="$store.commit('setmodifyItemData', null)"
      >
        Close
      </f7-actions-button>
    </div>
  </ActionModal>
</template>

<script>
import { mapState } from 'vuex';
import ActionModal from '../ActionModal';
import showToastMixin from '@/mixins/showToast.js';
export default {
  components: {
    ActionModal,
  },
  mixins: [showToastMixin],
  data() {
    return {
      userCanManage: null,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    modifyItemData() {
      return this.$store.state.modifyItemData;
    },
    service() {
      return this.modifyItemData.type;
    },
    serviceNameFriendly() {
      return this.service.replace(/([a-z])([A-Z])/g, '$1 $2');
    },
    canCreateFlag() {
      return (
        this.$can('create', 'flags') &&
        this.user._id !== this.modifyItemData.createdBy &&
        this.service !== 'behavior'
      );
    },
    isEditable() {
      // Don't allow editing of deprecated client property (use clientRef)
      if (this.service === 'behavior' && this.modifyItemData.client) {
        return false;
      }
      return true;
    },
    isDuplicatable() {
      return this.isEditable && this.service === 'behavior';
    },
  },
  watch: {
    async modifyItemData(val) {
      if (!val) return;
      const storeData = await this.$store.getters[`${this.service}s/get`](
        val._id
      );
      this.userCanManage = this.$can('manage', storeData);
    },
  },
  methods: {
    deleteItem() {
      this.$store
        .dispatch(`${this.service}s/remove`, [this.modifyItemData._id])
        .then(() => {
          this.showToast(`${this.serviceNameFriendly} deleted successfully`);
          this.$store.commit('setmodifyItemData', null);
          this.$f7.sheet.close();
        })
        .catch(() => {
          this.showToast(`There was an error deleting this ${this.service}`);
        });
    },
    async editItem(item, isDuplicate = false) {
      await this.$store.dispatch('setmodifyItemData', item);
      await this.$store.dispatch('setmodifyItemDataIsDuplicate', isDuplicate);

      this.$f7.popup.open('.editModal');
    },
    duplicateItem() {
      const clone = Object.assign({}, this.modifyItemData);
      delete clone._id;
      delete clone.createdAt;
      delete clone.updatedAt;

      this.$store
        .dispatch(`${this.service}s/create`, clone)
        .then(cloneObj => {
          console.log(cloneObj);
          this.showToast(`${this.serviceNameFriendly} duplicated successfully`);

          // Immediately edit duplicated behavior
          if (this.service === 'behavior') {
            cloneObj.type = this.service;
            this.editItem(cloneObj, true);
          } else {
            this.$f7.sheet.close();
          }
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err);
          this.showToast(
            `There was an error duplicating this ${this.serviceNameFriendly}`
          );
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-delete,
.btn-report {
  color: color(red);
  border-bottom: 1px solid color(gray);
  font-weight: bold;
  text-transform: capitalize;
}
.btn-edit,
.btn-duplicate {
  color: color(blue);
  border-bottom: 1px solid color(gray);
  font-weight: bold;
  text-transform: capitalize;
}
.btn-close {
  font-weight: bold;
}
</style>
