<template>
  <div
    ref="modal"
    class="sheet-modal swipeableSheetModal"
  >
    <div class="toolbar">
      <div class="toolbar-inner">
        <div class="left">
          <slot name="toolbar-left" />
        </div>
        <div class="right">
          <slot name="toolbar-right" />
        </div>
      </div>
    </div>
    <div class="sheet-modal-inner">
      <slot
        name="modal-content"
        class="modal-content"
      />
    </div>
  </div>
</template>

<script>
  export default {
    mounted() {
      this.$f7.sheet.create({
        el: this.$refs.modal,
      });
    },
  };
</script>

<style lang="scss" scoped>
  .swipeableSheetModal {
    margin: 0 2vw 1.06 * $tab_height 2vw;
    height: auto;
    width: 96vw;
    @media (min-width: $large_screen_width) {
      width: 50vw;
      margin: 0 25vw 1.06 * $tab_height 25vw;
    }
    @media (min-width: $max_view_width) {
      width: 40vw;
      margin: 0 30vw 1.06 * $tab_height 30vw;
    }
    .left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: 0.5em;
      img {
        height: 19px;
        width: 19px;
        margin-right: 1em;
        margin-top: 3px;
      }
      span {
        font-size: 1.2em;
        font-weight: bold;
        @media (max-width: 360px) {
          font-size: 1em;
        }
      }
    }
    .right {
      img {
        height: 15px;
        width: 15px;
        @media (max-width: 360px) {
          margin-top: -12px;
        }
      }
    }
    .toolbar-inner {
      border-bottom: 1px solid darken(color(gray), 10%);
      background-color: color(white);
      color: color(text-color);
    }
  }
</style>
