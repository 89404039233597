import { render, staticRenderFns } from "./NotificationIcon.vue?vue&type=template&id=820d1870&scoped=true"
import script from "./NotificationIcon.vue?vue&type=script&lang=js"
export * from "./NotificationIcon.vue?vue&type=script&lang=js"
import style0 from "./NotificationIcon.vue?vue&type=style&index=0&id=820d1870&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "820d1870",
  null
  
)

export default component.exports