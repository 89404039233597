import localforage from 'localforage';

// Storage Constants
export const USER_HAS_ONBOARDED = 'userOnboarded';
export const STORED_USERS_KEY = 'AciRTiKoD';
export const USER_PREFER_BIOMETRIC_AUTH = 'userPreferBiometricAuth';

const expirationKey = (key) => `_${key}_expiration`;
const getItemExpiration = (key) => localforage.getItem(expirationKey(key));
const removeExpiration = (key) => removeItem(expirationKey(key));

export const setItem = (key, value, expiration = null) => {
  return localforage.setItem(key, value).then(() => {
    if (expiration) {
      localforage.setItem(expirationKey(key), expiration);
    }
  });
};

export const getItem = async (key) => {
  const expiration = getItemExpiration(key);
  if (expiration && expiration < Date.now()) {
    await Promise.all([removeItem(key), removeExpiration(key)]);
  }
  return localforage.getItem(key);
};

export const removeItem = (key) => localforage.removeItem(key);

export const hasItem = (key) => {
  return new Promise((resolve) => {
    getItem(key)
      .then((item) => {
        if (item === null) {
          resolve(false);
        } else {
          resolve(true);
        }
      })
      .catch(() => {
        resolve(false);
      });
  });
};

export const clearStorage = () => {
  const whitelist = [USER_HAS_ONBOARDED, STORED_USERS_KEY];

  return (
    localforage
      .keys()
      // Filter out whitelisted keys
      .then((allKeys) => {
        return allKeys.filter((key) => !whitelist.includes(key));
      })

      // Create removeItem promise for each non-whitelisted key
      .then((blacklistKeys) => {
        return blacklistKeys.map((key) => removeItem(key));
      })

      // Return Promise.all on all removeItem promises
      .then((blacklistPromises) => Promise.all(blacklistPromises))
  );
};
