<template>
  <f7-page class="main">
    <f7-navbar :title="title" back-link="Back" class="styled" />
    <f7-list class="report-reasons">
      <f7-list-item
        v-for="(reportReason, index) in reportReasons"
        :key="index"
        class="report-reason"
        radio
        :title="reportReason"
        name="report-reason"
        @click="setReasonSelected(reportReason)"
      />
    </f7-list>
    <f7-button
      class="btn-primary"
      :disabled="!reason"
      @click="sendEmailToAdmin"
    >
      Submit
    </f7-button>
  </f7-page>
</template>
<script>
import feathersClient from '../../api/feathers-client';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      reportReasons: [
        'Harassment',
        'Hate Speech',
        'Violence',
        'Nudity',
        'Spam',
        'Other',
      ],
      reason: '',
    };
  },
  computed: {
    ...mapGetters(['currentOrganization', 'currentGroup']),
    id() {
      return this.$f7route.params.data.split(':')[1];
    },
    type() {
      return this.$f7route.params.data.split(':')[0];
    },
    model() {
      return this.type.charAt(0).toUpperCase() + this.type.slice(1);
    },
    title() {
      return this.type === 'user'
        ? 'Why are you reporting this user?'
        : 'Report abuse content';
    },
  },
  mounted() {
    this.$f7.sheet.close();
  },
  methods: {
    setReasonSelected(value) {
      this.reason = value;
    },
    goBack() {
      if (this.type === 'user') {
        this.$f7router.navigate('/app/');
      } else this.$f7router.back();
    },
    async createFlag() {
      return feathersClient.service('flags').create({
        ref: {
          kind: this.model,
          _id: this.id,
        },
        organization: this.currentOrganization._id,
        reason: this.reason,
      });
    },
    async createUserReport() {
      return feathersClient.service('userReports').create({
        user: this.id,
        reason: this.reason,
        group: this.currentGroup,
        organization: this.currentOrganization,
      });
    },
    async sendEmailToAdmin() {
      try {
        if (this.type === 'user') {
          await this.createUserReport();
        } else {
          await this.createFlag();
        }
      } catch (err) {
        const text =
          'We have received your report and will review it within 24 hours';
        const title = 'Report Sent Successfully';
        this.$f7.dialog.alert(text, title, () => {
          this.goBack();
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.report-reasons {
  margin-top: 0;
  .report-reason {
    // width: 50%;
    // display: inline-block;
  }
}
</style>
