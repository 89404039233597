<template>
  <span>
    <f7-list-item
      :key="item._id"
      class="list-item"
      :title="label"
      :name="name"
      :checked="selected"
      v-bind="type"
      @click="selectItem"
    >
      <div
        v-if="multiple"
        slot="content-start"
      >
        <i
          :class="{ checked: selected }"
          class="icon icon-checkbox icon-custom"
        />
      </div>
      <template
        v-if="hasInfo"
        slot="media"
      >
        <f7-button
          icon-f7="info"
          @click.stop="showItemInfo"
        />
      </template>
    </f7-list-item>
  </span>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    hasInfo: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    type() {
      return this.multiple ? {} : { radio: true };
    },
  },
  methods: {
    showItemInfo() {
      this.$emit('show-info', this.item);
    },
    selectItem() {
      this.$parent.$emit('selected', this.item);
      this.$emit('selected', this.item);
    },
  },
};
</script>
<style lang="scss" scoped>
.selected {
  background-color: green;
}
.checked {
  background-color: #2196f3;
  border-color: #2196f3;
}
.icon-custom:after {
  width: 18px;
  height: 18px;
  left: -2px;
  top: -2px;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  opacity: 1;
  background: no-repeat center;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20fill%3D'%23ffffff'%20width%3D'24'%20height%3D'24'%20viewBox%3D'0%200%2024%2024'%3E%3Cpath%20d%3D'M9%2016.17L4.83%2012l-1.42%201.41L9%2019%2021%207l-1.41-1.41z'%2F%3E%3C%2Fsvg%3E");
  background-size: 100% auto;
  content: '';
  position: absolute;
}
.icon-custom {
  margin-right: 16px;
}
</style>
