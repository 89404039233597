<template>
  <!-- Only show conversations that are not empty -->
  <f7-list-item
    class="cursor-pointer conversation-list-item"
    :title="title"
    :footer="subtitle"
    :after="sentAtTime"
    @click="$emit('click')"
  >
    <Avatar
      slot="media"
      :user-id="conversationWith ? conversationWith._id : null"
      :show-indicator="!isConversationRead"
      :open-profile="false"
    />
  </f7-list-item>
</template>
<script>
import DOMPurify from 'dompurify';
import { mapState, mapGetters, mapActions } from 'vuex';
import datesMixin from '@/mixins/dates';
import { first } from 'lodash';
import Avatar from '@/components/Avatar';

export default {
  components: {
    Avatar,
  },
  mixins: [datesMixin],
  props: {
    conversation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      refreshPointer: new Date(),
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('messages', {
      findMessagesInStore: 'find',
    }),
    ...mapGetters('conversations', {
      getConversationInStore: 'get',
    }),
    ...mapGetters('users', {
      findUsersInStore: 'find',
    }),
    lastMessage() {
      const { data } = this.findMessagesInStore({
        query: this.lastMessageQuery,
      });

      return data ? data[0] : null;
    },
    sentAtTime() {
      if (!this.conversation.lastMessageSentAt) {
        return '';
      }
      return this.formatDateTime(this.conversation.lastMessageSentAt);
    },
    users() {
      return this.findUsersInStore({
        paginate: false,
        query: {
          _id: {
            $in: this.filteredParticipants,
          },
        },
      }).data;
    },
    filteredParticipants() {
      return this.conversation.participants.filter(p => p !== this.user._id);
    },
    conversationWith() {
      return first(this.users.filter(user => user._id !== this.user._id));
    },
    subtitle() {
      if (!this.lastMessage) return;

      return this.sanitizeInput(this.lastMessage.text);
    },
    title() {
      if (this.users.length === 0) {
        return 'Unknown User(s)';
      }
      return this.users.length === 1
        ? `${this.users[0].firstName} ${this.users[0].lastName}`
        : this.users.map(u => u.firstName).join(', ');
    },
    isConversationRead() {
      const readBy = this.conversation.readBy || [];
      return readBy.includes(this.user._id);
    },
    lastMessageQuery() {
      return {
        $sort: { createdAt: -1 },
        conversation: this.conversation._id,
        $limit: 1,
      };
    },
  },
  created() {
    this.fetchLastMessage();
  },
  methods: {
    ...mapActions('messages', {
      findMessages: 'find',
    }),
    fetchLastMessage() {
      this.findMessages({ query: this.lastMessageQuery });
    },
    sanitizeInput(input) {
      return DOMPurify.sanitize(input, { USE_PROFILES: { html: false } });
    },
  },
};
</script>
<style>
.conversation-list-item .item-footer {
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
