import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '../../api/feathers-client';

class Post extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }
  static modelName = 'Post';

  static setupInstance(data, { store, models }) {
    data.createdByData = new models.api.User(data.createdByData);
    data.commentsData = data.commentsData.map(
      comment => new models.api.Comment(comment)
    );
    data.likesData = data.likesData.map(like => new models.api.Like(like));
    data.tagsData = data.tagsData.map(tag => new models.api.User(tag));
    return data;
  }
}
const servicePath = 'v2/posts';

const servicePlugin = makeServicePlugin({
  Model: Post,
  service: feathersClient.service(servicePath),
  servicePath,
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
