import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions([
      "fetchUnreadConversationCount",
      "fetchUnreadNotificationCount",
      "watchService",
    ]),

    watchUnreadCounts() {
      this.fetchUnreadConversationCount();
      this.fetchUnreadNotificationCount();

      this.watchService({
        service: "notifications",
        action: "fetchUnreadNotificationCount",
      });
      this.watchService({
        service: "v2/conversations",
        action: "fetchUnreadConversationCount",
      });
    },
  },
};
