<template>
  <Modal
    v-body-scroll-lock="disableBodyScroll"
    :modal-title="modalTitle || 'Create Post'"
  >
    <f7-button
      slot="left-button"
      class="link"
      :disabled="isCreatingPost"
      @click="close"
    >
      <i class="icon icon-back" />
    </f7-button>
    <div slot="modal-content">
      <f7-list class="post-form no-hairlines-md">
        <f7-list-item
          class="addPost-textarea"
          :class="{ 'input-error': errors.text }"
        >
          <textarea
            v-model="form.text"
            placeholder="What's on your mind?"
            name="postContent"
            :disabled="isCreatingPost"
            required
          />
        </f7-list-item>
        <f7-list-item
          v-if="errors.text"
          class="error"
        >
          Please fill out this field.
        </f7-list-item>

        <li v-if="!isEditingPost">
          <MediasInput
            class="my-25"
            :videos.sync="localVideos"
            :images.sync="localImages"
            :uploading="isCreatingPost"
          />
        </li>

        <li>
          <!-- eslint-disable vue/valid-v-on -->
          <MediasPreviews
            v-if="isEditingPost"
            class="my-25"
            :videos="form.videoUrls"
            :images="form.imageUrls"
            :remote-source="true"
            :editable="true"
            @update:videos="editVideos"
            @update:images="editImages"
          />
          <!-- eslint-enable vue/valid-v-on -->

          <MediasPreviews
            v-else
            class="my-25"
            :videos.sync="localVideos"
            :images.sync="localImages"
            :remote-source="false"
            :editable="!isCreatingPost"
            :browsable="!isCreatingPost"
          />
        </li>
        <li>
          <LocationSelect
            v-model="form.location"
            title="Select Post Location"
            :display-as-button="true"
            :auto-select-nearest="true"
            :limit-radius="userCanFindGeocodeWithLimitRadius"
          />
        </li>
        <div class="tag-clients">
          <div class="tag-clients__header">
            <img
              src="@/assets/images/icons/client-icon.svg"
              class="tag-clients__icon"
            >
            <div class="tag-clients__info">
              <div class="tag-clients__title-container">
                <span class="tag-clients__title">TAG CLIENTS</span>
                <span class="tag-clients__new-tag">NEW!</span>
              </div>
              <p class="tag-clients__description">
                Introducing Client tagging - you can now tag clients in posts!
              </p>
            </div>
          </div>
          <li class="tag-people">
            <f7-popup
              id="selectClientList"
              :opened="tagsPopupOpen"
              @popup:closed="tagsPopupOpen = false"
            >
              <SelectClientList
                v-if="tagsPopupOpen"
                v-model="form.tags"
                :limit-to-current-group="true"
                :exclude-roles="['limited']"
                @close="tagsPopupOpen = false"
              />
            </f7-popup>
            <f7-link
              class="item-link item-content"
              @click="
                !isCreatingPost
                  ? (tagsPopupOpen = true)
                  : (tagsPopupOpen = false)
              "
            >
              <div class="item-media">
                <img
                  src="@/assets/images/icons/tag.svg"
                  class="image-icon"
                >
              </div>
              <div class="item-inner">
                <div class="item-title">
                  Tag Someone
                </div>
                <span
                  v-if="form.tags.length"
                  class="numOfusers"
                  v-text="form.tags.length"
                />
              </div>
            </f7-link>
          </li>
          <!-- <label class="item-checkbox item-content">
            <input
              v-model="noClients"
              type="checkbox"
              class="searchbar-found__item-checkbox"
            >
            <i class="icon icon-checkbox" />
            <div class="item-inner">
              <div class="item-title">
                there are NO clients in this post
              </div>
            </div>
          </label> -->

          <v-checkbox
            v-model="noClients"
            label="there are NO clients in this post"
          />
        </div>
      </f7-list>

      <f7-button
        class="btn-primary"
        :disabled="!valid || isCreatingPost || loading"
        @click="isEditingPost ? updateCurrentItem('post', form) : submit()"
      >
        {{ isEditingPost ? 'Update Post' : 'Post' }}
      </f7-button>
      <f7-button
        class="btn-secondary"
        :disabled="isCreatingPost || loading"
        @click="close"
      >
        Cancel
      </f7-button>
      <div
        v-if="groupName"
        class="text-align-center mt-5 mb-25"
      >
        You're posting to the group <b v-text="groupName" />.
      </div>
    </div>
  </Modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { permissions } from '@/mixins/user';
import { uploadMediasFromUris, uuidFromMediaUrl } from '@/helpers/media/remote';
import LocationSelect from '@/components/inputs/LocationSelect';
import showToast from '@/mixins/showToast';
import editItem from '@/mixins/editItem';
import Modal from '@/components/Modal';
import SelectClientList from '@/components/SelectClientList';
import analytics from '@/analytics';
import MediasInput from './MediasInput';
import MediasPreviews from './MediasPreviews';
import axiosInstance from '@/api/axios';

export default {
  components: {
    Modal,
    LocationSelect,
    MediasInput,
    MediasPreviews,
    SelectClientList,
  },
  mixins: [permissions, showToast, editItem],
  props: {
    modalTitle: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      form: {
        location: null,
        tags: [],
        text: '',
        images: [],
        videos: [],
        imageUrls: [],
        videoUrls: [],
      },
      errors: {
        text: false,
        location: false,
      },
      localImages: [],
      localVideos: [],
      showGallery: null,
      isCreatingPost: false,
      tagsPopupOpen: false,
      showPhotoBrowserPopup: false,
      hasError: false,
      errorMessage: '',
      file: '',
      disableBodyScroll: false,
      noClients: true,
    };
  },
  computed: {
    ...mapGetters(['isCordova', 'currentGroup', 'loading']),
    groupName() {
      if (!this.currentGroup) return;

      return this.currentGroup.name;
    },
    valid() {
      const textValid = this.form.text.trim().length > 0;

      return textValid;
    },
    isEditingPost() {
      return !!this.$store.state.modifyItemData;
    },
  },
  watch: {
    showGallery(newVal) {
      if (newVal) {
        return this.$f7.popup.open('#gallery');
      } else {
        return this.$f7.popup.close('#gallery');
      }
    },
  },
  created() {
    if (this.isEditingPost) {
      Object.assign(this.form, this.$store.state.modifyItemData);
    }
  },
  methods: {
    ...mapActions('posts', {
      createPost: 'create',
    }),
    async uploadMedias(itemUris = []) {
      if (!itemUris) return;

      const response = await this.uploadFilesToCloudinary(itemUris);
      const mediaPublicIds = response.map(item => item.public_id);

      return mediaPublicIds;
    },
    async uploadImages() {
      this.form.images = await uploadMediasFromUris(this.localImages);
    },
    async uploadVideos() {
      this.form.videos = await uploadMediasFromUris(this.localVideos);
    },
    validate() {
      this.errors.text = !this.valid;

      return Object.values(this.errors).some(value => value);
    },
    async submit() {
      if (this.isCreatingPost || this.validate()) return;
      this.isCreatingPost = true;
      const addingToast = this.showToast('Adding your post...', null, 'top');
      this.$store.commit('addLoadingAction', 'post-form');

      // Upload Media
      try {
        await Promise.all([this.uploadImages(), this.uploadVideos()]);
      } catch (err) {
        console.log(err);
        addingToast.close();
        this.isCreatingPost = false;
        this.showToast('Error uploading media. Please try again.');
        this.$store.commit('removeLoadingAction', 'post-form');
        return;
      }

      // Create post
      try {
        const formData = {
          ...this.form,
          group: this.currentGroup._id,
        };
        delete formData.videoUrls;
        delete formData.imageUrls;

        // const post = await this.createPost(formData);
        const {
          data: { post },
        } = await axiosInstance.post('/posts/create', formData);
        analytics.track({
          userId: this.$store.state.auth.user._id,
          event: 'Added post',
          properties: {
            groupName: this.currentGroup.name,
            hasLocation: !!post.location,
            hasImages: !!post.images.length,
            hasVideo: !!post.videos.length,
            hasTags: !!post.tags.length,
          },
        });

        this.showToast('Post added successfully!');
        this.$f7router.navigate('/app/');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        console.log(err.message);
        this.showToast('Error adding post. Please try again.', 1500);
      } finally {
        addingToast.close();
        this.isCreatingPost = false;
        this.$store.commit('removeLoadingAction', 'post-form');
      }
    },
    tagUsers(users) {
      this.form.tags = users.map(user => user._id);
    },
    editImages(newUrls) {
      this.form.imageUrls = newUrls;
      this.form.images = newUrls.map(url => uuidFromMediaUrl(url));
    },
    editVideos(newUrls) {
      this.form.videoUrls = newUrls;
      this.form.videos = newUrls.map(url => uuidFromMediaUrl(url));
    },
  },
};
</script>

<style lang="scss" scoped>
.error {
  color: color(red);
  .item-content .item-inner {
    text-align: center;
  }
}

.input-error {
  border-color: color(red) !important;
}

.post-form {
  margin-top: 0;
  width: 90%;
  margin: auto;
  .item-content {
    width: 100%;
    font-weight: bold;
    font-size: 0.9em;
    padding-left: 7px;
  }
  @media (max-width: 355px) {
    .item-content {
      font-size: 0.8em;
    }
  }
  .item-media {
    min-width: 0;
  }
  .item-media + .item-inner {
    margin-left: 12px;
  }

  .addPost-textarea {
    border: 1px solid #ddd;
    border-radius: 5px;
    background: color(white);
    textarea {
      min-height: 8em;
      @media (max-width: 355px) {
        min-height: 5em;
      }
      width: 100%;
      height: auto;
      font-size: 1em;
    }
  }
  .tag-people {
    background: color(white);
    padding: 0;
    height: 40px;
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 5px;
    .item-title {
      white-space: normal;
    }
  }
}
.numOfusers {
  border: 1px solid color(red);
  border-radius: 100%;
  height: 16px;
  width: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  color: color(white);
  background-color: color(red);
}
.btn-primary,
.btn-secondary {
  /* -- iphone 5 and extra small phones-- */
  @media (max-width: 360px) {
    position: relative !important;
  }
}

.images--preview {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  .imgView {
    margin-left: 1vw;
    margin-top: 1vw;
    width: 28vw;
    height: 22vw;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    .cameraImage {
      width: 28vw;
      height: 22vw;
    }
    .remove-image {
      top: 0;
      right: 0;
      position: relative;
      img {
        height: 17px;
        width: 17px;
      }
    }
    .cameraImage-remove-image {
      margin-left: -17px;
    }
  }
}
.overlay {
  opacity: 0.75;
  background-color: darken(color(blue), 15%);
  height: 22vw;
  width: 28.3vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 59vw;
  bottom: 0;
  margin-top: -22vw;
  .text {
    color: color(white);
    font-size: 25px;
    @media (min-width: $tablet_width) {
      font-size: 45px;
    }
  }
}
.hide-btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5em;
  .btn-hide {
    padding: 0.5em;
    font-weight: bold;
    font-size: 1em;
    background: color(pink);
    color: color(white);
  }
}

#selectUsersList {
  z-index: 12601;
}
.btn-primary {
  margin-top: 2.5em;
  position: relative;
}

.btn-secondary {
  position: relative;
}

#selectClientList {
  z-index: 12601 !important;
}

.tag-clients {
  padding: 10px;
  display: grid;
  gap: 5px;
  color: #789a15;
  border: 1px solid #c1d979;
  background-color: #f0fad0;
  margin-top: 0.9em;
  @media (max-width: 355px) {
    margin-top: 0.4em;
  }

  &__header {
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
  &__info {
    display: grid;
  }

  &__new-tag {
    background-color: #3a9ecd;
    color: white;
    font-weight: 900;
    padding: 2px 4px;
    font-size: 12px;
    border-radius: 3px;
  }

  &__title {
    font-size: 13px;
    font-weight: bold;
  }
  &__title-container {
    display: grid;
    grid-auto-flow: column;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
  }
  &__description {
    margin: 0 !important;
    font-size: 13px;
  }

  &__icon {
    width: 30px !important;
    height: 30px !important;
  }
}
</style>
