import { render, staticRenderFns } from "./PinForm.vue?vue&type=template&id=476a6352&scoped=true"
import script from "./PinForm.vue?vue&type=script&lang=js"
export * from "./PinForm.vue?vue&type=script&lang=js"
import style0 from "./PinForm.vue?vue&type=style&index=0&id=476a6352&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "476a6352",
  null
  
)

export default component.exports