<template>
  <SwipeableModal id="mapMarkerSheetModal">
    <div
      slot="toolbar-left"
      class="left"
    >
      <img
        src="@/assets/images/icons/location-black.svg"
        class="image-icon"
      >
      <span v-if="sheetModalTitle"> {{ sheetModalTitle }}</span>
    </div>
    <div
      slot="toolbar-right"
      class="right"
    >
      <f7-link
        sheet-close
        @click="clearData"
      >
        <img
          src="@/assets/images/icons/down-arrow.svg"
          height="15"
        >
      </f7-link>
    </div>

    <div
      slot="modal-content"
      class="main"
    >
      <div v-if="$store.state.postObject || $store.state.eventObject">
        <f7-list
          v-if="eventsInSameLocation.length > 0"
          media-list
          class="event-wrapper"
        >
          <Event
            v-for="eventItem in eventsInSameLocation"
            :key="eventItem._id"
            :event="eventItem"
          />
        </f7-list>
        <div v-if="postsInSameLocation.length > 0">
          <PostContent
            v-for="postItem in postsInSameLocation"
            :key="postItem._id"
            :item="postItem"
          />
        </div>
      </div>
    </div>
  </SwipeableModal>
</template>

<script>
  import { mapGetters } from 'vuex';
  import PostContent from '@/components/socialFeed/PostContent';
  import SwipeableModal from '@/components/SwipeableModal';
  import Event from '@/components/event/Event';
  import moment from 'moment';
  import { findAllPages } from '@/helpers/pagination';

  export default {
    components: {
      PostContent,
      SwipeableModal,
      Event,
    },
    computed: {
      ...mapGetters(['currentGroup']),
      ...mapGetters('posts', { findPostsInStore: 'find' }),
      ...mapGetters('events', { findEventsInStore: 'find' }),
      post() {
        return this.$store.state.postObject;
      },
      event() {
        return this.$store.state.eventObject;
      },
      sheetModalTitle() {
        if (!this.post && !this.event) return;
        if (this.post) {
          return this.post.location.name;
        } else {
          return this.event.location.name;
        }
      },
      /* --- For Mutiple post/event in the same location */
      location() {
        if (!this.post && !this.event) return;
        //Return event location if selected location has no post
        if (!this.post && this.event) {
          return this.event.location;
        }
        return this.post.location;
      },
      selectedDate() {
        if (!this.post && !this.event) return;
        if (!this.post && this.event) {
          return this.event.start;
        }
        return this.post.createdAt;
      },
      postsInSameLocation() {
        return this.findPostsInStore({
          paginate: false,
          query: this.postsInSameLocationQuery,
        }).data;
      },
      eventsInSameLocation() {
        return this.findEventsInStore({
          paginate: false,
          query: this.eventsInSameLocationQuery,
        }).data;
      },
      postsInSameLocationQuery() {
        return {
          group: this.currentGroup._id,
          'location.lat': this.location.lat,
          'location.lng': this.location.lng,
          $sort: { createdAt: -1 },
          createdAt: {
            $gte: this.startTimeOfDate(this.selectedDate),
            $lte: this.endTimeOfDate(this.selectedDate),
          },
        };
      },
      eventsInSameLocationQuery() {
        return {
          group: this.currentGroup._id,
          'location.lat': this.location.lat,
          'location.lng': this.location.lng,
          $sort: { createdAt: -1 },
          start: {
            $gte: this.startTimeOfDate(this.selectedDate),
            $lte: this.endTimeOfDate(this.selectedDate),
          },
        };
      },
    },
    watch: {
      post(post) {
        if (post) {
          this.fetchData();
        }
      },
      event(event) {
        if (event) {
          this.fetchData();
        }
      },
    },
    methods: {
      clearData() {
        this.$store.dispatch('setPostObject', null);
        this.$store.dispatch('setEventObject', null);
      },
      fetchData() {
        findAllPages('posts', this.postsInSameLocationQuery);
        findAllPages('events', this.eventsInSameLocationQuery);
      },
      startTimeOfDate(date) {
        return moment(date).startOf('day');
      },
      endTimeOfDate(date) {
        return moment(date).endOf('day');
      },
    },
  };
</script>
<style lang="scss" scoped>
  #mapMarkerSheetModal {
    height: auto;
    max-height: 60vh;
    @media (min-width: 1024px) {
      margin-bottom: 0;
    }
    .main {
      height: auto;
      max-height: 55vh;
      margin-bottom: -1em;
      //for longer phones
      overflow: auto;
    }
    .left {
      span {
        font-size: 0.95em;
      }
    }
  }
  .event-wrapper {
    list-style: none;
    margin-left: -1em;
    margin-top: 0;
  }
</style>
