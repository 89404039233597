import feathersClient, { makeServicePlugin, BaseModel } from '../../api/feathers-client';

class User extends BaseModel {
  static modelName = 'User';

  setupInstance(data, { store, models }) {
    data.organizationsData = data.organizations.map((org) => models.api.Organization.find(org._id));
    data.badges = data.badges.map((badge) => new models.api.Badge(badge));
    return data;
  }
}
const servicePath = 'users';
const servicePlugin = makeServicePlugin({
  Model: User,
  service: feathersClient.service(servicePath),
  servicePath,
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
