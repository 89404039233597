<template>
  <f7-link @click="getCoordinates(true)">
    <f7-icon
      v-if="searching"
      md="material:location_searching"
      size="20px"
      class="pulse"
    />
    <f7-icon
      v-else-if="coordinates"
      md="material:gps_fixed"
      size="20px"
    />
    <f7-icon
      v-else
      md="material:location_disabled"
      size="20px"
    />
  </f7-link>
</template>

<script>
import Geolocate from '@/helpers/geolocate';
import showToast from '@/mixins/showToast';
const geolocate = new Geolocate();

export default {
  name: 'BaseGPSButton',
  mixins: [showToast],
  props: {
    value: {
      type: Object,
      default: null,
    },
    searchOnMounted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searching: false,
      coordinates: this.value,
    };
  },
  computed: {},
  watch: {
    searching(newVal) {
      this.$emit('searchingChange', newVal);
    },
  },
  mounted() {
    if (this.searchOnMounted) {
      this.getCoordinates();
    }
  },
  methods: {
    async getCoordinates(manual = false) {
      this.searching = true;
      try {
        this.coordinates = await geolocate.getCurrentCoordinates();
        this.$emit('input', this.coordinates);
        this.searching = false;
      } catch (err) {
        if (manual) {
          this.showToast('Your location could not be determined');
        }
        this.searching = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pulse {
  animation: pulse 0.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  70% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}
</style>
