<template>
  <f7-row class="row">
    <f7-col>
      <f7-label>{{ dateLabel }}<span class="required-field"> *</span></f7-label>
      <f7-input
        type="date"
        :value="date"
        :required="required"
        @input="date = $event.target.value"
      />
    </f7-col>
    <f7-col>
      <f7-label>{{ timeLabel }}<span class="required-field"> *</span></f7-label>
      <f7-input
        type="time"
        :value="time"
        :required="required"
        @input="time = $event.target.value"
      />
    </f7-col>
  </f7-row>
</template>
<script>
export default {
  props: {
    value: {
      type: [Date, String],
      default: () => new Date(),
    },
    dateTimeString: {
      type: String,
      default: () => window.moment().toISOString(),
    },
    required: {
      type: Boolean,
      default: true,
    },
    dateLabel: {
      type: String,
      default: 'Date',
    },
    timeLabel: {
      type: String,
      default: 'Time',
    },
  },
  data() {
    return {
      date: window.moment(this.dateTimeString).format('YYYY-MM-DD'),
      time: window.moment(this.dateTimeString).format('HH:mm'),
    };
  },
  watch: {
    date() {
      this.$emit('input', this.getCurrentDateObject());
    },
    time() {
      this.$emit('input', this.getCurrentDateObject());
    },
  },

  methods: {
    getCurrentDateObject() {
      return window
        .moment(`${this.date} ${this.time}`, 'YYYY-MM-DD HH:mm')
        .toDate();
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  width: 100%;
}
</style>
