<template>
  <f7-list-item
    class="single-notification cursor-pointer"
    :class="{
      unread: !notification.read,
      webViewSidebarRight: !isMobile,
      'popup-close': !isMobile,
    }"
    @click="viewNotification"
  >
    <div class="notification-type">
      <img
        v-if="parent && parent.imageUrls && parent.imageUrls.length"
        :src="parent.imageUrls[0]"
        class="image-rounded"
      >
      <f7-icon
        v-else
        class="notification-type-icon"
        :class="{ unread: !notification.read }"
        :f7="icon"
      />
    </div>
    <div class="item-content">
      <span v-if="!notification.read" class="indicator"></span>
      <div class="text" :class="{ unread: !notification.read }">{{ text }}</div>
      <div
        class="time"
        :class="{ unread: !notification.read }"
        v-text="formattedTimestamp"
      ></div>
    </div>
  </f7-list-item>
</template>
<script>
  import feathersClient from '@/api/feathers-client';
  import datesMixin from '@/mixins/dates';
  export default {
    name: 'BaseNotification',
    mixins: [datesMixin],
    props: {
      notification: {
        type: Object,
        required: true
      },
      text: {
        type: String,
        required: true
      },
      link: {
        type: String,
        required: false
      },
      parent: {
        type: Object,
        default: {},
      },
      icon: {
        type: String,
        default: 'social_rss_fill'
      }
    },
    computed: {
      isMobile() {
        return this.$store.getters.isMobile;
      },
      formattedTimestamp() {
        const timestamp = this.notification.createdAt || null;
        return this.formatDateTime(timestamp);
      },
    },
    methods: {
      viewNotification(event) {
        feathersClient
          .service('notifications')
          .patch(this.notification._id, { read: true });
        if (this.link) {
          this.$f7.views.main.router.navigate(this.link);
        } else {
          this.$emit('click', event);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .single-notification {
    .notification-type {
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      .f7-icons {
        font-size: 48px;
        color: color(gray);
      }
    }
    .item-content {
      white-space: normal;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .unread {
        font-weight: bold;
        color: color(text-color) !important;
        font-size: 14px;
        &.notification-type-icon {
          font-size: 48px;
          font-weight: normal;
        }
        &.time {
          color: lighten(color(text-color), 30%) !important;
        }
      }
      .text {
        font-size: 13px;
        color: color(gray);
        font-weight: bold;
      }
      .time {
        font-size: 11px;
        color: color(gray);
      }
    }
  }
  .indicator {
    color: color(white);
    width: 12px;
    height: 12px;
    background: color(red);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.6em;
    position: absolute;
    top: 12px;
    left: 38px;
    @media (max-width: 360px) {
      top: 6px;
    }
  }

  .webViewSidebarRight {
    .notification-type .f7-icons {
      font-size: 24px !important;
    }
    .item-content {
      min-height: 35px;
    }
    .notification-type {
      width: 25px;
    }
    .image-rounded {
      width: 25px;
      height: 25px;
    }
    .indicator {
      left: -3px;
      width: 8px;
      height: 8px;
    }
  }
</style>
