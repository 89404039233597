<template>
  <div
    id="actionModal"
    class="sheet-modal"
  >
    <slot />
  </div>
</template>
<script>
  export default {
  mounted() {
      this.initSheetModal();
    },
    methods: {
      initSheetModal() {
        const self = this;
        self.actionModal = self.$f7.sheet.create({
          el: '#actionModal',
          on: {
            closed() {
              self.$store.commit('setmodifyItemData', null);
            },
          },
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  #actionModal {
    margin: 0 2vw 2vw 2vw;
    width: 96vw;
    @media (min-width: $large_screen_width) {
      width: 50vw;
      margin: 0 25vw 10px 25vw;
    }
    @media (min-width: $max_view_width) {
      width: 40vw;
      margin: 0 30vw 10px 30vw;
    }
    touch-action: pan-y;
    height: auto;
    text-align: center;
    border: 1px solid transparent;
    border-radius: 10px;
    .toolbar-inner {
      border-bottom: 1px solid darken(color(gray), 10%);
      background-color: color(white);
      color: color(text-color);
    }
  }
</style>
