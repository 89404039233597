<template>
  <PageTabs
    ref="tabs"
    class="page-collect-data"
    :active-tab-index="activeTabIndex"
    @tabSelected="setActiveTabIndex"
  >
    <Tab
      v-if="$can('read', 'behaviors')"
      id="data-behaviors"
      title="Behaviors"
      :icon="require('@/assets/images/icons/addBehavior.svg')"
    >
      <FilteredListPage
        v-if="currentGroup"
        service="behaviors"
        date-column="dateTime"
      />
      <div v-if="$can('create', 'behaviors')">
        <f7-fab
          v-if="groupHasClients"
          href="/app/BehaviorForm/"
          class="options-button"
        >
          <f7-icon ios="f7:add" md="material:add" />
        </f7-fab>
        <f7-fab v-else class="options-button" @click="openNoClientsDialog">
          <f7-icon ios="f7:add" md="material:add" />
        </f7-fab>
      </div>
    </Tab>
    <Tab
      v-if="$can('read', 'shiftNotes')"
      id="data-shiftNotes"
      title="Shift Notes"
      :icon="require('@/assets/images/icons/addShiftNote.svg')"
    >
      <FilteredListPage v-if="currentGroup" service="shiftNotes" />
      <div v-if="$can('create', 'shiftNotes')">
        <f7-fab href="/app/ShiftNoteForm/" class="options-button">
          <f7-icon ios="f7:add" md="material:add" />
        </f7-fab>
      </div>
    </Tab>
  </PageTabs>
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import PageTabs from '../PageTabs';
import Tab from '../Tab';
import FilteredListPage from '@/components/FilteredListPage';

export default {
  components: {
    FilteredListPage,
    PageTabs,
    Tab,
  },
  computed: {
    ...mapGetters(['currentGroup', 'isCordova']),
    ...mapState('tabs/data', ['activeTabIndex']),
    groupHasClients() {
      if (!this.currentGroup) return false;
      return this.currentGroup.clients && this.currentGroup.clients.length > 0;
    },
  },
  methods: {
    ...mapMutations('tabs/data', ['setActiveTabIndex']),
    openNoClientsDialog() {
      this.$f7.dialog.alert(
        'There are no clients assigned to this group, please contact your organization admin.',
        'Unable to record behavior'
      );
    },
  },
};
</script>
<style scoped lang="scss">
.page-collect-data {
  background: color(bg-color);
}
</style>
