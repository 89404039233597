<template>
  <div class="clients-container">
    <div
      v-for="client of clients"
      :key="client._id"
      class="client"
    >
      <img
        src="@/assets/images/icons/client-icon.svg"
        class="image-icon"
      >
      {{ `${client.firstName} ${client.lastName}` }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    clients: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
};
</script>
<style lang="scss" scoped>
.clients-container {
  margin-top: 15px;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  gap: 15px;
}
.client {
  color: #789a15;
  border: 1px solid #c1d979;
  background-color: #f0fad0;
  padding: 5px;
  display: grid;
  grid-auto-flow: column;
  width: max-content;
  gap: 10px;
  align-items: center;
}
</style>
