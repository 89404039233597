<template>
  <f7-page
    v-if="isAuthenticated"
    :page-content="false"
    class="main"
  >
    <HeaderNavigation v-if="isMobile" />
    <TabBar v-if="isMobile" />

    <f7-tabs
      routable
      class="mobile-view-main"
    >
      <f7-tab
        v-for="tab in tabs"
        :id="tab.id"
        :key="tab.id"
        class="page-content"
      />
    </f7-tabs>

    <CommentsSheetModal :margin-bottom-zero="false" />
    <LikesSheetModal :margin-bottom-zero="false" />
    <MediaGallery />
    <ModifyFeedItemSheetModal />
    <EditItemModal />
    <FormBackForm />
  </f7-page>
</template>
<script>
import { mapGetters } from 'vuex';
import HeaderNavigation from '../components/HeaderNavigation';
import TabBar from '../components/TabBar';
import LikesSheetModal from '@/components/socialFeed/LikesSheetModal.vue';
import CommentsSheetModal from '@/components/socialFeed/CommentsSheetModal';
import MediaGallery from '@/components/socialFeed/MediaGallery.vue';
import ModifyFeedItemSheetModal from '@/components/socialFeed/ModifyFeedItemSheetModal';
import EditItemModal from '@/components/EditItemModal';
import FormBackForm from '@/pages/FeedbackForm';
import tabs from '@/mixins/tabs';

export default {
  components: {
    LikesSheetModal,
    FormBackForm,
    MediaGallery,
    CommentsSheetModal,
    ModifyFeedItemSheetModal,
    EditItemModal,
    HeaderNavigation,
    TabBar,
  },
  mixins: [tabs],
  computed: {
    ...mapGetters(['isMobile', 'isAuthenticated']),
  },
};
</script>
