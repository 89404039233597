import axios from 'axios';
import feathersClient from '@/api/feathers-client';
import { readFile } from './local';
import imageCompression from 'browser-image-compression';
// import { createFFmpeg } from '@ffmpeg/ffmpeg';

// Constants
const UPLOADCARE_BASE_URL = 'https://upload.uploadcare.com/base/';
const UPLOADCARE_VIDEO_CONVERSION_URL = 'https://api.uploadcare.com/convert/video/';

const createFormData = (fileData, options) => {
  const formData = new FormData();
  formData.append('file', fileData);
  formData.append('UPLOADCARE_STORE', '1');
  formData.append('signature', options.signature);
  formData.append('expire', options.expireTimestamp);
  formData.append('UPLOADCARE_PUB_KEY', options.uploadcarePublicKey);
  return formData;
};

export const compressImage = async file => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 800,
    useWebWorker: true,
  };
  try {
    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  } catch (error) {
    console.error('Error compressing image:', error);
  }
};
// export const compressVideo = async file => {
//   const ffmpeg = createFFmpeg({ log: true });
//   await ffmpeg.load();

//   ffmpeg.FS('writeFile', file.name, await fetchFile(file));

//   await ffmpeg.run('-i', file.name, '-vf', 'scale=1280:-2', '-crf', '28', 'output.mp4');

//   const data = ffmpeg.FS('readFile', 'output.mp4');

//   return new File([data.buffer], 'output.mp4', {
//     type: 'video/mp4',
//   });
// };

export const uploadMediasFromUris = async (fileUris = [], secure = true) => {
  const fileUploads = fileUris.map(fileUri => uploadMediaFromUri(fileUri, secure));
  return Promise.all(fileUploads);
};

export const uploadMediaFromUri = async (fileUri, secure = true) => {
  const extension = fileUri
    .split('.')
    .pop()
    .toLowerCase();
  let normalizedFileUri = fileUri;

  if (extension === 'heic') {
    // normalizedFileUri = await convertImageToJpg(fileUri, 80);
  }

  const fileData = await readFile(normalizedFileUri);
  let uploadedMedia = fileData;

  if (fileData.type.includes('image')) {
    uploadedMedia = await compressImage(fileData);
  } else if (fileData.type.includes('video')) {
    // uploadedMedia = await compressVideo(fileData);
  }
  const file = await uploadMediaWithRetry(uploadedMedia, secure);
  return file;
};

export const uploadMediaWithRetry = async (fileData, secure = true, retryCount = 0) => {
  const expireTimestamp = Math.round(new Date().getTime() / 1000) + 900;
  const options = {
    expireTimestamp,
    secure,
    signature: await getSignature(secure, expireTimestamp),
    uploadcarePublicKey: secure
      ? process.env.VUE_APP_UPLOADCARE_SECURE_PUBLIC_KEY
      : process.env.VUE_APP_UPLOADCARE_PUBLIC_PUBLIC_KEY,
  };

  const formData = createFormData(fileData, options);

  try {
    const { data } = await axios.post(UPLOADCARE_BASE_URL, formData);
    console.log(data);
    // await requestThumbnail(data.file);
    return data.file;
  } catch (err) {
    if (retryCount < 3 && isRetryableError(err)) {
      const delay = Math.pow(2, retryCount) * 1000; // Exponential backoff
      console.log(`Retry uploading media - Attempt ${retryCount + 1}, retrying in ${delay}ms`);
      await new Promise(resolve => setTimeout(resolve, delay));
      return uploadMediaWithRetry(fileData, secure, retryCount + 1);
    } else {
      console.error(`Upload failed after retries: ${err}`);
      throw new Error(`Failed to upload media to uploadcare after retries: ${err}`);
    }
  }
};

// Helper function to determine if an error should trigger a retry
const isRetryableError = error => {
  return !error.response || [502, 503, 504].includes(error.response.status); // retry on server errors and network issues
};

async function getSignature(secure, expireTimestamp) {
  const options = { expireTimestamp, secure };
  const { signature } = await feathersClient.service('uploadAuthorizations').create(options);
  return signature;
}

export const mediaTransformationUrl = (secureUrl, transformations = {}) => {
  const transformationsPath = Object.keys(transformations)
    .map(key => `-/${key}/${transformations[key]}/`)
    .join('');
  return appendPathToUrl(secureUrl, transformationsPath);
};

export const videoThumbnailUrl = secureUrl => appendPathToUrl(secureUrl, 'video/-/cut/00.000/00.001/-/thumb/');

export const uuidFromMediaUrl = secureUrl => {
  try {
    const url = new URL(secureUrl);

    // Split the pathname on '/' and find the first non-empty segment as UUID.
    const segments = url.pathname.split('/').filter(segment => segment !== '');
    const uuid = segments.length > 0 ? segments[0] : null;

    return uuid;
  } catch (error) {
    console.error('Error parsing URL:', error.message);
    return null;
  }
};

export const mediaPreviewUrl = secureUrl => {
  const dimensions = calculatePreviewDimensions();
  return mediaTransformationUrl(secureUrl, { preview: `${dimensions.width}x${dimensions.height}`, quality: 'lighter' });
};

export const requestThumbnail = async uuid => {
  const authKey = `Uploadcare.Simple ${process.env.VUE_APP_UPLOADCARE_SECURE_PUBLIC_KEY}:${process.env.VUE_APP_UPLOADCARE_SECURE_PRIVATE_KEY}`;
  await axios.post(
    UPLOADCARE_VIDEO_CONVERSION_URL,
    { paths: [`${uuid}/video/-/cut/00.000/00.001/-/thumb/`], store: 1 },
    { headers: { Authorization: authKey } }
  );
};

const appendPathToUrl = (url, path) => {
  const newUrl = new URL(url);
  newUrl.pathname += path;
  return newUrl.href;
};

const calculatePreviewDimensions = () => {
  const width = Math.min(3000, parseInt(window.screen.width * 1.5));
  const height = Math.min(3000, parseInt(window.screen.height * 1.5));
  return { width, height };
};
