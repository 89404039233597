<template>
  <f7-app id="app" :params="f7params">
    <f7-statusbar />
    <div id="content">
      <HeaderNavigation
        v-if="loaded && isAuthenticated && !isMobile"
        :user="user"
      />

      <div
        v-if="loaded && !isMobile && isAuthenticated"
        class="left-sidebar sidebar"
      >
        <LeftSidebar />
      </div>

      <!-- <div v-if='loaded && !isMobile && isAuthenticated' class='right-sidebar sidebar'>
        <RightSidebar :user='user' />
      </div> -->

      <f7-view
        id="main"
        main
        url="/"
        :push-state="!isCordova"
        :unique-history="!isCordova"
        :animate="isMobile"
      />
      <TermsPopup />
      <ErrorPopup v-if="error" :error="error" />
    </div>
    <f7-routable-modals />
    <portal-target name="app" />
    <TheLoader v-if="loading" />
  </f7-app>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import routes from './routes';
import logout from '@/mixins/logout';
import unreadCounts from '@/mixins/unreadCounts';
import registerServices from '@/mixins/registerServices';
import updateNotice from '@/mixins/updateNotice';
import LeftSidebar from './views/LeftSidebar';
import HeaderNavigation from '@/components/HeaderNavigation';
import { connectUser as connectDolbyioUser } from '@/helpers/video-call';
import TermsPopup from '@/components/TermsPopup';
import ErrorPopup from '@/components/ErrorPopup';
import TheLoader from './components/UI/TheLoader.vue';
import logoutMixin from '@/mixins/logout';

export default {
  components: {
    LeftSidebar,
    HeaderNavigation,
    TermsPopup,
    TheLoader,
    ErrorPopup,
  },
  mixins: [logout, registerServices, unreadCounts, updateNotice],
  data() {
    return {
      f7params: {
        id: 'com.stirlingTechnologies.navigatingLife',
        name: 'Navigating Life',
        theme: 'md',
        routes,
        touch: {
          tapHold: true,
          disableContextMenu: false,
        },
      },
      renewLoginTimeout: null,
      logoutTimeout: null,
      windowWidth: 0,
      loaded: false,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters([
      'isCordova',
      'isMobile',
      'isAuthenticated',
      'loading',
      'error',
    ]),
  },
  watch: {
    isAuthenticated(newVal) {
      if (newVal) {
        this.authenticated();
      }
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    document.addEventListener('pause', this.handleAppInBackground, false);
    document.addEventListener('resume', this.handleAppInForeground, false);

    this.handleResize();
    this.$store.dispatch('updateDeviceData');
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    document.removeEventListener('pause', this.handleAppInBackground, false);
    document.removeEventListener('resume', this.handleAppInForeground, false);
  },
  mounted() {
    this.$f7ready(f7 => {
      document.addEventListener('deviceready', this.deviceReady, false);

      this.loaded = true;
    });
  },
  methods: {
    handleResize() {
      this.$store.commit('updateDeviceData', {
        size: {
          width:
            window.innerWidth || window.screen.width * window.devicePixelRatio,
          height:
            window.innerHeight ||
            window.screen.height * window.devicePixelRatio,
        },
      });
    },
    handleAppInBackground() {
      this.$store.commit('setAppInForeground', false);
    },
    handleAppInForeground() {
      this.$store.commit('setAppInForeground', true);

      this.notifyUpdatedVersion(true, false);
    },
    handleBackButton() {
      if (this.$f7.views.main.router.currentRoute.url === '/login/') {
        this.$f7.dialog.confirm(
          'Do you want to exit this app',
          () => {
            return window.navigator.app.exitApp();
          },
          () => {
            return null;
          }
        );
        return;
      }
      // NOTE: The back button will behave differently depending on circumstance
      // If the side panel is open, close it
      if (document.querySelector('.panel-left.panel-active')) {
        // This will do nothing when the split-view is open
        return this.$f7.panel.close();
      }
      // Close modals
      // @TODO How to handle modals we shouldn't close like a login-screen?
      if (document.querySelector('.modal-in')) {
        return [this.$f7.popup.close(), this.$f7.sheet.close()];
      }
      // If we have a back button, we want it to go back
      if (this.$f7.views.main.router.history.length > 1) {
        return this.$f7.views.main.router.back();
      }
      // If its not on home page , back to home page
      if (this.$f7.views.main.router.currentRoute.url !== '/app/') {
        //Close the video on welcome screen
        this.$refs.welcomeVideo.style.display = 'none';
        return this.$f7.views.main.router.navigate('/app/');
      }
      // Default to closing the app
      this.$f7.dialog.confirm('Do you want to exit this app', () => {
        return window.navigator.app.exitApp();
      });
    },
    initOneSignal() {
      if (!window.plugins || !window.plugins.OneSignal) return;
      window.plugins.OneSignal.setAppId(process.env.VUE_APP_ONESIGNAL_APP_ID);

      window.plugins.OneSignal.promptForPushNotificationsWithUserResponse(
        accepted => {
          // eslint-disable-next-line no-console
        }
      );

      window.plugins.OneSignal.addTrigger('prompt_ios', 'true');
      window.plugins.OneSignal.addTrigger('showPrompt', 'true');
      window.plugins.OneSignal.setNotificationOpenedHandler(jsonData => {
        this.navigateDelayed('/app/notifications/');
      });
    },

    oneSignalNotificationOpened(jsonData) {
      const { location } = jsonData.notification.payload.additionalData;
      if (location === 'messages') {
        this.navigateDelayed('/app/conversations');
      } else if (location === 'notifications') {
        this.navigateDelayed('/app/notifications/');
      } else if (location === 'videoConversation') {
        const { conversationId } = jsonData.notification.payload.additionalData;
        this.navigateDelayed(`/app/conversations/${conversationId}/video/`);
      }
    },
    navigateDelayed(route) {
      // Workaround to support navigation immediately after app is launched from notification link
      setTimeout(() => {
        this.$f7.views.main.router.navigate(route);
      }, 1000);
    },
    linkHandler(e) {
      let href;
      const target = e.target || e.srcElement;
      if (target.tagName === 'A' && target.classList.contains('external')) {
        href = encodeURI(target.getAttribute('href'));
        window.open(
          href,
          '_blank',
          'location=yes,footercolor=#2196f3,toolbarcolor=#2196f3'
        );
        e.preventDefault();
      }
    },
    async deviceReady() {
      document.addEventListener('click', this.linkHandler);
      document.addEventListener('backbutton', this.handleBackButton);
      const version = await cordova.getAppVersion.getVersionNumber();
      this.$store.commit('setAppVersion', version);

      this.$store.dispatch('updateDeviceData');
      this.notifyUpdatedVersion();
    },
    authenticated() {
      try {
        this.$store.commit('addLoadingAction', 'authenticated');
        this.$store
          .dispatch('initGroups')
          .then(() => this.registerServices())
          .catch(err => {
            if (err.code === 408) {
              this.$store.commit('setError', {
                callback: this.logout,
                description:
                  'The connection timed out. Please try again in a few moments or contact a system administrator for support.',
                title: 'Error',
                code: 408,
              });
              return;
            }
            this.$f7.views.main.router.navigate('/app/nogroups/');
          });

        connectDolbyioUser(this.user);
        this.watchUnreadCounts();
        this.initOneSignal();
      } catch (e) {
        console.log(e);
      } finally {
        this.$store.commit('removeLoadingAction', 'authenticated');
      }
    },
  },
};
</script>
<style lang="scss">
.statusbar {
  background: $white;
}

#app {
  font-family: 'Lato', sans-serif;
}

#content {
  display: flex;
  justify-content: center;
  height: 100%;
}

#main {
  order: 2;
  flex-grow: 1;
  flex-basis: 1024px;
  max-width: 1024px;
}

.sidebar {
  flex-shrink: 1;
  flex-basis: 300px;
  padding-top: 56px;

  &.left-sidebar {
    order: 1;
    margin-left: 25px;
  }

  &.right-sidebar {
    order: 3;
    margin-right: 25px;
  }
}
</style>
