export const getFileEntry = fileUri => {
  return new Promise((resolve, reject) => {
    window.resolveLocalFileSystemURL(fileUri, resolve, reject);
  });
};

export const getDirectoryFileEntries = directoryUri => {
  return new Promise((resolve, reject) => {
    getFileEntry(directoryUri)
      .then(directoryEntry => {
        const directoryReader = directoryEntry.createReader();

        directoryReader.readEntries(resolve, reject);
      })
      .catch(reject);
  });
};

export const deleteFileEntry = entry => {
  return new Promise((resolve, reject) => {
    if (entry.isFile) {
      entry.remove(
        // File Deleted
        resolve,

        // Failed to delete file
        reject,

        // File does not exist
        resolve
      );
    } else {
      entry.removeRecursively(resolve, reject);
    }
  });
};

export const deleteDirectoryContents = directoryUri => {
  return new Promise((resolve, reject) => {
    getDirectoryFileEntries(directoryUri)
      .then(fileEntries => {
        const removeEntries = fileEntries.map(fileEntry =>
          deleteFileEntry(fileEntry)
        );

        Promise.all(removeEntries)
          .then(resolve)
          .catch(reject);
      })
      .catch(reject);
  });
};

export const deleteTempDirectory = () => {
  return deleteDirectoryContents(window.cordova.file.tempDirectory);
};
