<template>
  <BaseFormCard id="login-form">
    <form
      autocomplete="on"
      @submit.prevent="login"
      @keyup.enter="login"
    >
      <h3 class="text-align-center">
        Log In
      </h3>
      <f7-list no-hairlines-md>
        <f7-list-item
          v-if="errorMessage"
          class="error"
        >
          {{
            errorMessage
          }}
        </f7-list-item>
        <f7-list-input
          id="user-text-field"
          label="Email"
          type="email"
          autocomplete="username"
          placeholder="you@company.com"
          floating-label
          :value="email"
          autofocus
          validate
          required
          @input="email = $event.target.value"
        >
          <f7-icon
            slot="media"
            f7="person"
          />
        </f7-list-input>
        <f7-list-input
          id="password-text-field"
          label="Password"
          autocomplete="current-password"
          :type="showPassword ? 'text' : 'password'"
          floating-label
          :value="password"
          required
          @input="password = $event.target.value"
        >
          <f7-icon
            slot="media"
            f7="lock_fill"
          />
          <f7-icon
            slot="inner-end"
            :md="showPassword ? 'material:visibility' : 'material:visibility_off'"
            size="20px"
            class="show_pass"
            @click.native="toggleShowPassword"
          />
        </f7-list-input>

        <f7-list-item v-if="biometricAuthAvailable">
          <div class="ml-2 remember-me">
            <input
              id="checkbox"
              v-model="remember"
              type="checkbox"
            >
            <label for="checkbox">Remember Me? (Requires {{ biometricTypeLabel }} access)</label>
          </div>
        </f7-list-item>

        <f7-list-input
          v-if="remember"
          id="pin-field"
          label="Set Pin Number*"
          type="tel"
          floating-label
          :value="pin"
          required
          minlength="4"
          maxlength="6"
          info="PIN must be between 4 and 6 digits"
          @input="pin = $event.target.value"
        >
          <f7-icon
            slot="media"
            f7="number_square_fill"
          />
        </f7-list-input>

        <button
          id="login-button"
          :disabled="loading"
          type="submit"
          class="login-submit-button"
        >
          <span v-if="loading">Loading...</span>
          <span v-else>Login</span>
        </button>
      </f7-list>
    </form>
  </BaseFormCard>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import BaseFormCard from './BaseFormCard';


export default {
  components: {
    BaseFormCard,
  },
  props: {
    errorMessage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      email:
        process.env.NODE_ENV === 'development' ? process.env.VUE_APP_EMAIL : '',
      password:
        process.env.NODE_ENV === 'development'
          ? process.env.VUE_APP_PASSWORD
          : '',
      pin: '',
      remember: false,
      showPassword: false,
    };
  },
  computed: {
    ...mapState(['biometricAuthAvailable']),
    ...mapGetters(['biometricTypeLabel', 'loading']),
  },
  methods: {
    login() {
      this.$emit('login', {
        email: this.email.trim(),
        password: this.password.trim(),
        pin: this.pin.trim(),
        remember: this.remember,
      });
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style scoped lang="scss">
.popover-inner {
  .list {
    ul li {
      transition: all 0.15s ease-in;

      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }

      &:not(:last-child) {
        border-bottom: 0.2px solid rgba(0, 0, 0, 0.12);
      }

      a {
        text-transform: capitalize;
        font-size: 16px;
      }
    }
  }
}

.remember-me {
  font-size: 12px;
  display: flex;
  align-items: center;
  line-height: 1;

  input[type='checkbox'] {
    margin-right: 5px;
  }
}
.show_pass {
  cursor: pointer;
  color: #737373;
  position: absolute;
  right: 20px;
  top: 65%;
  transform: translateY(-65%);
}
#password-text-field .item-inner {
  position: relative; 
}
</style>
