<template>
  <f7-page>
    <f7-link
      class="go-to-login"
      @click="goToLoginPage"
    >
      <i class="icon f7-icons">close_round</i>
    </f7-link>
    <f7-swiper
      id="welcomeSlider"
      pagination
      :navigation="!isMobile"
    >
      <Welcome @skipToLogin="goToLoginPage" />
      <Overview />
      <GetStarted @complete="goToLoginPage" />
    </f7-swiper>
  </f7-page>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Welcome from '@/components/welcomeSlider/Welcome';
  import Overview from '@/components/welcomeSlider/Overview';
  import GetStarted from '@/components/welcomeSlider/GetStarted';
  import { setItem, USER_HAS_ONBOARDED } from '@/helpers/local-fs';
  export default {
    components: {
      Welcome,
      Overview,
      GetStarted,
    },
    computed: {
      ...mapGetters(['isMobile']),
    },
    methods: {
      goToLoginPage() {
        setItem(USER_HAS_ONBOARDED, true).then(() => {
          this.$f7.views.main.router.navigate('/login/');
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  #welcomeSlider {
    height: 100%;
    width: 100%;
    background: color(blue);
    color: color(white);
    position: fixed;
  }

  .go-to-login {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    top: 20px;
    z-index: 50;
    i {
      width: 30px;
      margin-right: 20px;
      color: white;
    }
  }
</style>

<style lang="scss">
  .swiper-button-next {
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 44"%3E%3Cpath d="M27,22L27,22L5,44l-2.1-2.1L22.8,22L2.9,2.1L5,0L27,22L27,22z" fill="white"/%3E%3C/svg%3E') !important;
  }
  .swiper-button-prev {
    background-image: url('data:image/svg+xml, %3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 44"%3E%3Cpath d="M0,22L22,0l2.1,2.1L4.2,22l19.9,19.9L22,44L0,22L0,22L0,22z" fill="white"/%3E%3C/svg%3E') !important;
  }
</style>
