export const rolesDefinitions = {
  limited: {
    display: 'Supporting Member',
    staffMember: false,
    key: 'limited',
  },
  regular: {
    display: 'Community Member',
    staffMember: false,
    key: 'regular',
  },
  social: {
    display: 'Social Member',
    staffMember: false,
    key: 'social',
  },
  team: {
    display: 'Restricted Staff Member',
    staffMember: true,
    key: 'team',
  },
  staff: {
    display: 'Staff',
    staffMember: true,
    key: 'staff',
  },
  clinician: {
    display: 'Clinician',
    staffMember: true,
    key: 'clinician',
  },
  groupAdmin: {
    display: 'Group Admin',
    staffMember: true,
    key: 'groupAdmin',
  },
  orgAdmin: {
    display: 'Organization Admin',
    staffMember: true,
    key: 'orgAdmin',
  },
  superAdmin: {
    display: 'Super Admin',
    staffMember: true,
    key: 'superAdmin',
  },
};

export const roleKeys = Object.keys(rolesDefinitions).reduce((acc, key) => {
  acc[key] = key;
  return acc;
}, {});
