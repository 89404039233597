export const convertHextToRgba = (hex) => {
  return hex.match(/\w\w/g).map((x) => parseInt(x, 16));
};

export const getContrastingColor = (hex) => {
  const rgba = convertHextToRgba(hex);

  /* eslint-disable-next-line no-mixed-operators */
  const brightness = Math.round(0.299 * rgba[0] + 0.587 * rgba[1] + 0.114 * rgba[2]);

  return brightness <= 125 ? 'white' : 'black';
};
