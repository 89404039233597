<template>
  <div class="filter-container">
    <div v-if="filterTypes.includes('users')">
      <button
        type="button"
        class="user-button"
        @click="userPopupOpen = true"
      >
        {{ selectedUsersDisplay }}
        <i
          class="f7-icons"
          style="color:#8c96ab; font-size: 16px"
        >chevron_down</i>
      </button>
      <f7-popup
        id="search-by-user"
        :opened="userPopupOpen"
        @popup:closed="userPopupOpen = false"
      >
        <SelectUsersList
          v-if="userPopupOpen"
          :value="selectedUsers"
          title="Search by user"
          :exclude-roles="excludedRoles"
          :exclude-current-user="false"
          :limit-to-current-group="true"
          @input="handleUserSelection"
          @close="userPopupOpen = false"
        />
      </f7-popup>
    </div>
    <div v-if="filterTypes.includes('feed')">
      <button
        type="button"
        class="date-button title-container"
        @click="datePopupOpen = true"
      >
        <img
          src="@/assets/images/icons/filter.svg"
          class="image-icon-small"
        >
        <span>Feed Filters</span>
        <span
          v-if="title"
          class="filters-title"
        >{{ title }}</span>
      </button>

      <FeedFilters
        v-model="datePopupOpen"
        @filter="handleFiltersSelection"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { communityRoles } from '@/helpers/user-roles';
import SelectUsersList from '@/components/SelectUsersList';
import FeedFilters from '@/components/FeedFilters.vue';

export default {
  components: {
    SelectUsersList,
    // SelectDateRange,
    FeedFilters,
  },
  props: {
    userQuery: {
      type: Object,
      default: () => ({}),
    },
    filterTypes: {
      type: Array,
      default: () => ['users', 'feed'],
      validator: value => value.every(type => ['users', 'feed'].includes(type)),
    },
    excludedRoles: {
      type: Array,
      default: () => communityRoles,
    },
  },
  data: () => ({
    userPopupOpen: false,
    datePopupOpen: false,
    selectedUsers: [],
    selectedInterval: {},
    selectedClient: {},
  }),
  computed: {
    ...mapGetters('users', {
      findUsersInStore: 'find',
    }),
    title() {
      const hasClientFilter = Object.keys(this.selectedClient).length > 0;
      const hasDatefilter = Object.keys(this.selectedInterval).length > 0;
      if (!hasClientFilter && !hasDatefilter) return null;
      let title = ': ';
      const params = [];
      if (hasDatefilter) params.push(this.selectedInterval.label);
      if (hasClientFilter) params.push(this.fullName(this.selectedClient));
      title += params.join('; ');
      return title;
    },
    selectedUsersDisplay() {
      if (this.selectedUsers.length === 0) {
        return 'Select User(s)';
      }

      return this.findUsersInStore({
        query: { _id: { $in: this.selectedUsers } },
      })
        .data.map(user => user.firstName)
        .join(', ');
    },
  },
  methods: {
    fullName(client) {
      if (!client.firstName) return '';
      return `${client.firstName || ''} ${client.lastName || ''}`;
    },
    handleUserSelection(users) {
      this.selectedUsers = users;
      this.$emit('usersSelected', this.selectedUsers);
    },
    handleDateSelection(interval) {
      this.selectedInterval = interval;
      this.$emit('dateSelected', this.selectedInterval.range);
    },
    handleFiltersSelection(filters) {
      const payload = {};
      if (filters.selectedClient) {
        payload.selectedClient = filters.selectedClient;
      }
      if (filters.selectedInterval) {
        payload.dateRange = filters.selectedInterval.range;
      }
      this.selectedInterval = filters.selectedInterval || {};
      this.selectedClient = filters.selectedClient || {};
      this.$emit('feedFilters', payload);
    },
  },
};
</script>
<style scoped>
.title-container {
  display: grid !important;
  grid-auto-flow: column;
  gap: 4px;
  align-items: center;
}
/* Flexbox gap alternative to support older browsers/iOS: https://codepen.io/denobelsoftware/details/KKMpbjg */
.filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -1rem;
}

.filter-container > div {
  margin-left: 1rem;
  padding: 0.5em 0.5rem 0.35rem;
  flex: 1;
  height: 100%;
}

button {
  appearance: none;
  cursor: pointer;
}

.user-button {
  border: none;
  border-bottom: 1px solid rgb(140, 150, 171) !important;
  padding: 10px 0;
  background: none;
  display: flex;
  justify-content: space-between;
}

.date-button {
  display: flex;
  align-items: center;
  border: 1px solid #d3dae3;
  appearance: none;
  border-radius: 8px;
  background: white;
  height: 100%;
  padding: 0.5rem;
  justify-content: center;
  color: #333;
}

.date-button i {
  margin-right: 10px;
  font-size: 1em;
}

.filters-title {
  color: #707070;
  font-weight: bold;
}
</style>
