import { Ability } from '@casl/ability';
const { unpackRules } = require('@casl/ability/extra');
import feathersClient, { BaseModel } from '../api/feathers-client';

const ability = new Ability([], {
  subjectName(subject) {
    if (!subject || typeof subject === 'string') {
      return subject;
    }

    if (subject instanceof BaseModel) {
      return subject.modelName;
    }

    return subject.modelName || subject.constructor.modelName;
  },
});

feathersClient.on('authenticated', (res) => {
  const { rules } = res;
  if (Array.isArray(rules[0])) {
    ability.update(unpackRules(res.rules));
  } else {
    ability.update(res.rules);
  }
});

feathersClient.on('logout', () => {
  ability.update([]);
});

export default ability;
