import GroupTabs from './views/GroupTabs';
import Feed from './pages/tabs/Feed/Feed';
import Events from './pages/tabs/Events/EventsTabs';
import GroupInfo from './pages/tabs/GroupInfo';
import DataTabs from './pages/tabs/Data/DataTabs';
import NotFound from './pages/NotFound';
import Login from './pages/Login/Login';
import store from './store';
import PostForm from './components/forms/post/PostForm';
import EventForm from './components/forms/event/EventForm';
import BehaviorForm from './components/forms/behavior/BehaviorForm';
import Conversations from './pages/Conversations';
import Messages from './pages/Messages';
import Notifications from './pages/Notifications';
import DestinationPost from './components/notifications/DestinationPost';
import UserProfilePage from './components/userProfile/UserProfilePage';
import ReportItem from './components/socialFeed/ReportItem';
import WelcomeSlider from './pages/WelcomeSlider';
import NoGroups from './pages/NoGroups';
import ShiftNoteForm from './components/forms/shiftNote/ShiftNoteForm';

import { getItem, USER_HAS_ONBOARDED } from './helpers/local-fs';

function fetchAuthentication() {
  return new Promise((resolve, reject) => {
    if (!store.getters.isAuthenticated) {
      store
        .dispatch('auth/authenticate')
        .then(() => {
          resolve();
        })
        .catch(() => {
          resolve();
        });
    } else {
      resolve();
    }
  });
}

function requireAuth(routeTo, routeFrom, resolve, reject) {
  const router = this;
  fetchAuthentication().then(() => {
    if (store.getters.isAuthenticated) {
      resolve();
    } else {
      reject();
      router.navigate('/login/');
    }
  });
}

function requireNotAuth(routeTo, routeFrom, resolve, reject) {
  const router = this;
  fetchAuthentication().then(() => {
    if (store.getters.isAuthenticated) {
      reject();
      router.navigate('/app/');
    } else {
      resolve();
    }
  });
}

export default [
  {
    path: '/',
    cache: false,
    async redirect(route, resolve) {
      if (store.getters.isAuthenticated) {
        resolve('/app/');
      } else {
        const userHasOnboarded = await getItem(USER_HAS_ONBOARDED);
        

        if (userHasOnboarded) {
          if(route.url.includes('#error')){
            const errorValue = route.url.split("#error=")[1];
            resolve(`/login/#error=${errorValue}`);
          }
         else resolve('/login/');
        } else {
          resolve('/tour/');
        }
      }
    },
  },
  {
    path: '/login/',
    component: Login,
    beforeEnter: requireNotAuth,
  },
  {
    path: '/tour/',
    component: WelcomeSlider,
    beforeEnter: requireNotAuth,
  },
  {
    path: '/app/nogroups/',
    beforeEnter: requireAuth,
    component: NoGroups,
  },
  {
    path: '/app/',
    beforeEnter: requireAuth,
    component: GroupTabs,
    tabs: [
      {
        path: '/',
        id: 'feed',
        component: Feed,
      },
      {
        path: '/events/',
        id: 'events',
        component: Events,
      },
      {
        path: '/data/',
        id: 'data',
        component: DataTabs,
      },
      {
        path: '/info/',
        id: 'info',
        component: GroupInfo,
      },
    ],
    routes: [
      {
        path: '/conversations/',
        component: Conversations,
        beforeEnter: requireAuth,
      },
      {
        path: '/conversations/:conversationId/',
        component: Messages,
        beforeEnter: requireAuth,
      },
      {
        path: '/conversations/:conversationId/video/',
        component: Messages,
        beforeEnter: requireAuth,
        options: {
          props: {
            autoStartVideo: true,
          },
        },
      },
      {
        path: '/notifications/',
        component: Notifications,
        beforeEnter: requireAuth,
      },
      {
        path: '/PostForm/',
        component: PostForm,
        beforeEnter: requireAuth,
      },
      {
        path: '/PostForm/:placeId',
        component: PostForm,
        beforeEnter: requireAuth,
      },
      {
        path: '/EventForm/',
        component: EventForm,
        beforeEnter: requireAuth,
      },
      {
        path: '/BehaviorForm/',
        component: BehaviorForm,
        beforeEnter: requireAuth,
      },
      {
        path: '/destinationPost/:postId',
        component: DestinationPost,
      },
      {
        path: '/userProfile/:userId/',
        component: UserProfilePage,
        beforeEnter: requireAuth,
      },
      {
        path: '/reportItem/:data/',
        component: ReportItem,
        beforeEnter: requireAuth,
      },
      {
        path: '/noGroups/',
        component: NoGroups,
        beforeEnter: requireAuth,
      },
      {
        path: '/ShiftNoteForm/',
        component: ShiftNoteForm,
        beforeEnter: requireAuth,
      },
    ],
  },
  // 404 page
  {
    path: '(.*)',
    component: NotFound,
  },
];
