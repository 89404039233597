<template>
  <BaseFormCard id="reset-password-form">
    <form @submit.prevent="resetPw">
      <h3 class="text-align-center">
        Reset Password
      </h3>
      <f7-list no-hairlines-md>
        <f7-list-input
          id="user-reset-text-field"
          label="Email"
          autocomplete="username"
          type="email"
          placeholder="you@company.com"
          floating-label
          :value="email"
          autofocus
          validate
          @input="email = $event.target.value"
        >
          <f7-icon slot="media" f7="person" />
        </f7-list-input>
        <button :disabled="loading" type="submit" class="login-submit-button">
          <span v-if="loading">Loading...</span>
          <span v-else>Send Reset Email</span>
        </button>
      </f7-list>
    </form>
  </BaseFormCard>
</template>
<script>
import feathersClient from '@/api/feathers-client';
import BaseFormCard from './BaseFormCard';
import { mapGetters } from 'vuex';

export default {
  components: {
    BaseFormCard,
  },
  data() {
    return {
      errorMessage: '',
      email: '',
    };
  },
  computed: {
    ...mapGetters(['loading']),
  },
  methods: {
    resetPw() {
      if (this.email.trim().length === 0) return;
      this.$store.commit('addLoadingAction', 'reset-password-form');
      feathersClient
        .service('authManagement')
        .create({
          action: 'sendResetPwd',
          value: { email: this.email.trim() },
        })
        .then(() => {
          this.$f7.dialog.alert(
            'An email has been sent to reset your password. Please check your email.',
            'Email Sent',
            () => {
              this.$emit('passwordResetSent');
            }
          );
        })
        .catch(err => {
          console.log(err);
          if (err.message === 'User is not verified.') {
            this.errorMessage =
              'Please follow the link to verify your account in your email before resetting your password.';
            feathersClient.service('authManagement').create({
              action: 'resendVerifySignup',
              value: { email: this.email },
            });
          } else {
            this.errorMessage = 'There was an error processing your reset, please try again.';
          }
          this.$f7.dialog.alert(
            this.errorMessage,
            err.message === 'User is not verified.' ? 'User is not verified' : 'Error'
          );
        })
        .finally(() => {
          this.$store.commit('removeLoadingAction', 'reset-password-form');
        });
    },
  },
};
</script>
<style>
button:disabled {
  opacity: 0.65;
  cursor: not-allowed;
}
</style>
