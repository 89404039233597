import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '../../api/feathers-client';

class Like extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }
  static modelName = 'Like';

  static setupInstance(data, { store, models }) {
    data.createdByData = new models.api.User(data.createdByData);
    return data;
  }
}
const servicePath = 'v2/likes';

const servicePlugin = makeServicePlugin({
  Model: Like,
  service: feathersClient.service(servicePath),
  servicePath,
  actions: {
    removeLikeForUser({ dispatch, getters }, { refId, userId }) {
      const like = getters.list.find(
        like => like.ref._id === refId && like.createdBy === userId
      );
      if (!like) return;
      dispatch('remove', like._id);
    },
  },
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
