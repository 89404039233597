<template>
  <f7-list
    v-if="notifications.length"
    class="notification-list"
  >
    <Notification
      v-for="notification in notifications"
      :key="notification._id"
      :notification="notification"
    />
    <f7-list-item
      v-if="hasMore"
      class="loadMore"
      @click="fetchMore"
    >
      Load Previous
    </f7-list-item>
  </f7-list>
  <EmptyState
    v-else
    empty-title="No notifications yet"
  />
</template>

<script>
  import Notification from '@/components/notifications/Notification';
  import notificationsMixin from '@/mixins/notifications';
  import EmptyState from '@/components/EmptyState';
  import { mapActions } from 'vuex';

  export default {
    name: 'NotificationsList',
    components: {
      Notification,
      EmptyState,
    },
    mixins: [notificationsMixin],
    data() {
      return {
        perPage: 10,
        totalNotifications: null,
        typesToFilter: ['PostAddedToGroup', 'PostCommentedOn'],
      };
    },
    computed: {
      hasMore() {
        return (
          this.totalNotifications !== null &&
          this.notifications.length < this.totalNotifications
        );
      },
      filteredNotifications() {
        return this.notifications.filter(notification =>
          this.typesToFilter.includes(notification.label)
        );
      },
    },
    created() {
      this.fetch();
    },
    methods: {
      ...mapActions('notifications', {
        findNotifications: 'find',
      }),
      fetchMore() {
        this.findNotifications({
          query: {
            ...this.notificationsQuery,
            $skip: this.notifications.length,
            $limit: this.perPage,
          },
        });
      },
      async fetch() {
        const { total } = await this.findNotifications({
          query: {
            ...this.notificationsQuery,
            $limit: this.perPage,
            $sort: {
              createdAt: -1,
            },
          },
        });
        this.totalNotifications = total;
      },
    },
  };
</script>

<style scoped></style>
