<template>
  <div class="page">
    <f7-navbar
      title="Conversations"
      back-link="Back"
    />
    <InfiniteListPage
      service="conversations"
      :query="conversationsQuery"
      qid="conversations"
      :page-size="10"
    >
      <template
        slot="default"
        slot-scope="{ items }"
      >
        <f7-list
          media-list
          class="conversations-list"
        >
          <ConversationListItem
            v-for="item in items"
            :key="item._id"
            :conversation="item"
            @click="openMessages(item._id)"
          />
        </f7-list>
      </template>
    </InfiniteListPage>
    <div class="fab fab-right-bottom add-message">
      <f7-link @click="newConversationPopupOpen = true">
        <f7-icon f7="add" />
      </f7-link>
    </div>
    <f7-popup
      id="add-message-popup"
      :opened="newConversationPopupOpen"
      @popup:closed="newConversationPopupOpen = false"
    >
      <SelectUsersList
        v-if="newConversationPopupOpen"
        title="New conversation"
        confirm-button-text="Create"
        :clear-on-confirm="true"
        :exclude-roles="['limited']"
        @input="initConversation"
        @close="newConversationPopupOpen = false"
      />
    </f7-popup>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ConversationListItem from '@/components/chat/ConversationListItem';
import InfiniteListPage from '@/components/InfiniteListPage';
import SelectUsersList from '@/components/SelectUsersList';
import startConversation from '@/mixins/startConversation';
import analytics from '@/analytics';

export default {
  components: {
    ConversationListItem,
    SelectUsersList,
    InfiniteListPage,
  },
  mixins: [startConversation],
  data() {
    return {
      newConversationPopupOpen: false,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('conversations', {
      findConversationsInStore: 'find',
    }),
    ...mapGetters(['isMobile']),
    conversationsQuery() {
      return {
        $sort: { lastMessageSentAt: -1 },
        participants: this.user._id,
      };
    },
  },
  mounted() {
    analytics.track({
      userId: this.user._id,
      event: 'Opened conversations',
    });
  },
  methods: {
    ...mapActions('conversations', {
      findConversations: 'find',
      createConversation: 'create',
    }),
    openMessages(conversationId) {
      this.$f7router.navigate(`/app/conversations/${conversationId}/`);
    },
    async initConversation(userIds) {
      const conversation = await this.findOrCreateConversation(userIds);
      this.openMessages(conversation._id);
    },
  },
};
</script>

<style scoped lang="scss">
.conversations-list {
  list-style: none;
  margin-top: 0.5em;
  > li:not(:last-of-type) {
    border-bottom: 1px solid #eee;
  }
  @media (min-width: $large_screen_width) {
    margin-bottom: 25px;
  }
}

.add-message {
  .link {
    margin-bottom: -25px;
    position: fixed;
    right: 20px;
    @media (min-width: $large_screen_width) {
      margin-bottom: 50px;
    }
  }
}
</style>
