<template>
  <PageTabs
    :active-tab-index="activeTabIndex"
    @tabSelected="setActiveTabIndex"
  >
    <Tab
      id="feed-inner"
      title="Feed"
      :icon="require('@/assets/images/icons/listing-white.svg')"
      @selected="logEvent('Opened feed list')"
    >
      <FilteredListPage
        v-if="currentGroup"
        ref="list"
        service="posts"
        :filter-types="['feed']"
        :list-query="query"
        @filtersUpdated="setFiltersActive"
      >
        <WelcomePost
          v-if="!filtersActive"
          slot="empty"
        />
      </FilteredListPage>

      <f7-fab
        v-if="shouldShowFab"
        class="options-button"
        href="/app/PostForm/"
      >
        <f7-icon
          ios="f7:add"
          md="material:add"
        />
      </f7-fab>
      <div :class="{ 'bg-blur': isPlusIconClicked }" />
    </Tab>
    <Tab
      id="map-inner"
      title="Map"
      :icon="require('@/assets/images/icons/map.svg')"
      @selected="logEvent('Opened feed map')"
    >
      <MapView v-if="currentGroup" />
    </Tab>
  </PageTabs>
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { logActivity } from '@/helpers/logs';
import analytics from '@/analytics';
import Tab from '../Tab';
import PageTabs from '../PageTabs';
import MapView from './FeedMapView';
import WelcomePost from '@/components/socialFeed/WelcomePost';
import FilteredListPage from '@/components/FilteredListPage';
export default {
  components: {
    Tab,
    PageTabs,
    MapView,
    WelcomePost,
    FilteredListPage,
  },
  data() {
    return {
      isPlusIconClicked: false,
      filtersActive: false,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('tabs/feed', ['activeTabIndex']),
    ...mapGetters(['isCordova', 'currentGroup']),
    shouldShowFab() {
      return this.$can('create', 'posts');
    },
    query() {
      return {
        $aggregate: [
          {
            $lookup: {
              from: 'clients',
              localField: 'tags',
              foreignField: '_id',
              as: 'clients',
            },
          },
        ],
      };
    },
  },
  watch: {
    currentGroup() {
      this.closeFloatingActionButton();
    },
  },
  mounted() {
    logActivity('visitFeed');
  },
  methods: {
    ...mapMutations('tabs/feed', ['setActiveTabIndex']),
    logEvent(event) {
      analytics.track({
        userId: this.user._id,
        event,
      });
    },
    closeFloatingActionButton() {
      this.$f7.fab.close();
      this.isPlusIconClicked = false;
    },
    setFiltersActive(filters) {
      this.filtersActive = Object.values(filters).some(
        filterValues => filterValues && filterValues.length > 0
      );
    },
  },
};
</script>
<style lang="scss" scoped>
#feed-inner {
  overflow: hidden;
}
.bg-blur {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: color(white);
  visibility: visible;
  opacity: 0.7;
  -webkit-transition-duration: 400ms;
  transition-duration: 400ms;
  z-index: 1;
}
</style>
