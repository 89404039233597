import { AnalyticsBrowser } from '@segment/analytics-next'

// @see https://segment.com/docs/sources/server/node/#development
const options = {};
if (process.env.NODE_ENV === 'development') {
  options.flushAt = 1;
}

// const analytics = new Analytics(process.env.VUE_APP_SEGMENT_KEY, options);
const analytics = AnalyticsBrowser.load({ writeKey: process.env.VUE_APP_SEGMENT_KEY })
export default analytics;
