<template>
  <f7-list-item
    checkbox
    class="checkbox"
    :title="label"
    :checked="value"
    @change="changed"
  />
</template>

<script>
  export default {
    name: 'BooleanSelect',
    components: {},
    props: {
      value: {
        type: Boolean,
        default: false,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
    },
    methods: {
      changed(evt) {
        this.$emit('input', evt.target.checked);
      },
    },
  };
</script>
<style scoped>
  .checkbox {
    list-style: none;
  }
</style>
