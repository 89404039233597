<template>
  <f7-popup id="feedback-form-popup" ref="popup">
    <f7-page>
      <f7-navbar>
        <div class="np-border--fancy" />
        <div class="navbar-inner sliding">
          <div class="left">
            <f7-link popup-close>
              <i class="icon icon-back" />
            </f7-link>
          </div>
          <div class="title">
            Navigating Life Feedback
          </div>
        </div>
      </f7-navbar>
      <h3 style="padding: 0 4px">
        Please include as much detail as possible. <br />
        For questions related to your organization, contact them directly.
      </h3>
      <f7-list class="list no-hairlines-md feedbackForm">
        <f7-list-item>
          <f7-label>
            Topic of Discussion<span class="required-field">*</span>
          </f7-label>
          <f7-input
            type="text"
            :value="form.title"
            placeholder="Enter topic here"
            required
            @input="form.title = $event.target.value"
          />
        </f7-list-item>

        <f7-list-item>
          <f7-label>Description<span class="required-field">*</span></f7-label>
          <f7-input
            :value="form.description"
            type="textarea"
            placeholder="Enter description here"
            @input="form.description = $event.target.value"
          />
        </f7-list-item>
        <f7-list-item>
          <f7-label>Type<span class="required-field">*</span></f7-label>
          <f7-input
            type="select"
            :value="form.type"
            placeholder="Please choose..."
            @input="form.type = $event.target.value"
          >
            <option
              v-for="(type, index) in feedbackTypes"
              :key="index"
              :value="type"
            >
              {{ type }}
            </option>
          </f7-input>
        </f7-list-item>
      </f7-list>
      <f7-button
        class="btn-primary"
        :disabled="formInvalid"
        @click="submitFeedback"
      >
        SUBMIT
      </f7-button>
    </f7-page>
  </f7-popup>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import { pick } from 'lodash';
import showToastMixin from '@/mixins/showToast';
import feathersClient from '@/api/feathers-client';
export default {
  mixins: [showToastMixin],
  data() {
    return {
      form: {
        title: null,
        description: null,
        type: null,
        manufacturer: null,
        model: null,
        platform: null,
        version: null,
      },
      feedbackTypes: ['Bug Report', 'Feature Request', 'Other'],
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters(['currentOrganization']),
    appVersion() {
      return this.$store.getters.appVersion;
    },
    formInvalid() {
      if (!this.form.title || !this.form.description) {
        return true;
      }
      if (
        this.form.title.trim().length === 0 ||
        this.form.description.trim().length === 0
      ) {
        return true;
      }
      return false;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
  },
  mounted() {
    document.addEventListener(
      'deviceready',
      () => {
        for (const prop in window.device) {
          if (prop === 'getInfo') continue;
          this.form[prop] = window.device[prop];
        }
      },
      false
    );
  },
  methods: {
    submitFeedback() {
      let content = '';
      for (const key in this.form) {
        content += `${key}: ${this.form[key]}\n`;
      }

      const organization = this.currentOrganization;

      content += `user: ${JSON.stringify(
        pick(this.user, [
          'firstName',
          'lastName',
          'email',
          'phone',
          'organizations',
        ]),
        null,
        '\t'
      )}\n`;
      if (organization) {
        content += `organization: ${organization.name}\n`;
      }
      content += `date: ${window.moment().toString()}\n`;
      content += `version: ${this.appVersion}\n`;

      const email = {
        replyTo: this.user.email,
        subject: this.form.title,
        text: content,
        type: 'feedback',
        organization,
      };

      feathersClient
        .service('mailer/v2')
        .create(email)
        .then(() => {
          this.form.title = '';
          this.form.description = '';
          this.showToast('Thanks for your feedback!');
          this.$refs.popup.close();
        })
        .catch(() => {
          this.showToast('There was an error submitting your feedback.');
        });
    },
  },
};
</script>

<style scoped lang="scss">
h3 {
  text-align: center;
}
.feedbackForm {
  margin-top: 0.5em;
}
.required-field {
  font-size: 1.1em;
  margin-left: 3px;
}
.np-border--fancy {
  height: 4px;
  width: 100%;
  background: url('../assets/images/nupath-border-bg.svg') repeat-x;
  position: absolute;
  top: 0;
}
</style>
