<template>
  <BaseNotification
    :notification="notification"
    :text="text"
    :icon="icon"
    :parent="parent"
    :link="destinationLink"
  />
</template>

<script>
import BaseNotification from '@/components/notifications/BaseNotification';
import { notificationTypes } from '@/lib/notifications/types';
import notificationsMixin from '@/mixins/notifications';

export default {
  components: { BaseNotification },
  mixins: [notificationsMixin],
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  computed: {
    text() {
      return notificationTypes[this.notification.label].text;
    },
  },
};
</script>
