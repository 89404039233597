<template>
  <PageTabs
    :active-tab-index="activeTabIndex"
    @tabSelected="setActiveTabIndex"
  >
    <Tab
      id="calendar-view"
      title="Calendar"
      :icon="require('@/assets/images/icons/calendar-white.svg')"
    >
      <CalendarView @selected-date="onSelectedDate" />
      <CreateEventFab :selected-date="selectedDate" />
    </Tab>
    <Tab
      id="event-view"
      title="Upcoming"
      :icon="require('@/assets/images/icons/listing-white.svg')"
    >
      <InfiniteListPage
        v-if="currentGroup"
        service="events"
        :query="upcomingEventsQuery"
        qid="upcomingEvents"
        :page-size="10"
        empty-list-message="No upcoming events"
        end-of-list-message="No more upcoming events"
      >
        <template
          slot="default"
          slot-scope="{ items }"
        >
          <f7-list
            media-list
            class="event-list"
          >
            <Event
              v-for="event in items"
              :key="event._id"
              :event="event"
            />
          </f7-list>
        </template>
      </InfiniteListPage>
      <CreateEventFab />
    </Tab>
  </PageTabs>
</template>

<script>
  import { mapState, mapMutations, mapGetters } from 'vuex';
  import moment from 'moment';
  import PageTabs from '../PageTabs';
  import Tab from '../Tab';
  import CalendarView from './EventsCalendarView';
  import CreateEventFab from './CreateEventFab';
  import InfiniteListPage from '@/components/InfiniteListPage';
  import Event from '@/components/event/Event';

  export default {
    components: {
      PageTabs,
      Tab,
      CalendarView,
      Event,
      InfiniteListPage,
      CreateEventFab,
    },
    data() {
      return {
        selectedDate: new Date(),
      };
    },
    computed: {
      ...mapState('tabs/events', ['activeTabIndex']),
      ...mapGetters(['currentGroup']),
      upcomingEventsQuery() {
        return {
          group: this.currentGroup._id,
          $sort: { start: 1 },
          start: {
            $gte: moment().startOf('day'),
          },
        };
      },
    },
    methods: {
      ...mapMutations('tabs/events', ['setActiveTabIndex']),
      onSelectedDate(newDate) {
        this.selectedDate = newDate;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .event-list {
    list-style: none;
    margin: 0 0 !important;
    height: 100%;
    .item-content {
      padding-left: 0 !important;
    }
  }
</style>
