<template>
  <f7-list-group v-if="users.length" class="user-list">
    <f7-list-item v-if="title" :title="title" group-title class="user-list__title" />
    <div class="user-list__list">
      <f7-list-item
        v-for="user in users"
        :key="user._id"
        class="user-list__user"
        :class="{
          'no-link': !profile,
        }"
        link="#"
        @click="openUserProfile(user._id)"
      >
        <div class="user-list__userInfo">
          <div class="user-list__userInfo__name">
            {{ user.firstName + ' ' + user.lastName }}
          </div>
          <div v-if="user.title" class="user-list__userInfo__title">
            {{ user.title }}
          </div>
        </div>

        <BadgeList
          :ids="user.badges"
          :max="1"
          size="xs"
          class="user-list__userBadges justify-content-flex-end align-items-center"
        />
      </f7-list-item>
    </div>
  </f7-list-group>
</template>

<script>
import BadgeList from '@/components/BadgeList.vue';
import { openUserProfile } from '@/mixins/helperFunctions';

export default {
  components: { BadgeList },
  mixins: [openUserProfile],
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    profile: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
.user-list__user {
  .item-link,
  .item-content,
  .item-inner {
    width: 100% !important;
  }
}
</style>

<style lang="scss" scoped>
.user-list {
  background: color(bg-color);
  font-weight: bold;

  &__list {
    width: 92%;
    margin: auto;
    background-color: color(white);
    font-weight: bold;
  }

  &__user {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &Info {
      display: flex;
      flex-direction: column;

      &__name {
        color: color(text-color);
      }
      &__title {
        color: color(text-color);
        font-size: 0.7em;
        font-weight: 400;
        text-transform: capitalize;
      }
    }

    &Badges {
      flex: 1 1;
    }
  }
}
</style>

<style lang="scss">
.no-link {
  pointer-events: none;
  & .item-inner {
    background-image: none !important;
  }
}
</style>
