import { mapActions, mapMutations, mapGetters } from 'vuex';
import { clearStorage } from '../helpers/local-fs';
import { deleteTempDirectory } from '../helpers/filesystem';
import { disconnect as disconnectDolbyio } from '@/helpers/video-call';

// @see https://framework7.io/docs/routes.html#route-options
// @see https://framework7.io/docs/view.html#router-api-methods-properties
const routeOptions = {
  history: false, // Don't add the login page to the history
  clearPreviousHistory: true, // Clear the history when navigating to the login page
  reloadAll: true, // Reload all pages when navigating to the login page
};

export default {
  computed: {
    ...mapGetters(['isCordova']),
  },
  methods: {
    ...mapActions('auth', {
      authLogout: 'logout',
    }),
    ...mapActions({
      updateDeviceDataAfterStoreReset: 'updateDeviceData',
    }),
    ...mapMutations({
      resetVuexStore: 'reset',
    }),

    /**
     * Logout a user and redirect to Login page
     */
    async logout({ redirect = '/login/', f7RouteOptions = { props: { addAccount: false } } } = {}) {
      Object.assign(routeOptions, f7RouteOptions);

      try {
        if (this.isCordova) {
          deleteTempDirectory();
        }

        if (redirect) {
          this.$f7.views.main.router.navigate(redirect, routeOptions);
        }

        await Promise.allSettled([this.authLogout(), clearStorage(), disconnectDolbyio(), this.resetVuexStore()]);

        this.updateDeviceDataAfterStoreReset();
      } catch (err) {
        throw new Error('Logout failed', err);
      }
    },
  },
};
