<template>
  <div v-if="currentGroup" id="groupInfo">
    <div>
      <div
        v-if="currentGroupImageUrl && !file"
        class="group-image-previewer"
        :style="{ backgroundImage: `url(${currentGroupImageUrl})` }"
      />
      <div v-if="file" class="group-image-previewer">
        <img id="image0" ref="image0" />
      </div>
      <div v-if="!file && !currentGroupImageUrl" class="group-image-placeholder">
        <img src="@/assets/images/icons/photo.svg" class="image-icon-large" />
      </div>
      <div v-if="$can('update', currentGroup)">
        <f7-button v-if="!file" class="btn-upload-photo" @click="$refs.fileInput.click()">
          Edit
        </f7-button>
        <f7-button v-if="file" class="btn-submit" @click="uploadGroupImage">
          Save
        </f7-button>
        <f7-button v-if="file" class="btn-cancel" @click="groupImageCancelled">
          Cancel
        </f7-button>
        <input ref="fileInput" type="file" accept="image/*" @change="groupImageSelected" />
      </div>
    </div>

    <div v-if="!currentGroup.image" class="no-group-photo">
      No Group Photo Set
    </div>
    <h3 class="text-align-center no-pad">
      {{ currentGroup.name }}
    </h3>
    <f7-list class="no-pad no-hairlines group-contact">
      <f7-list-group v-if="contact" class="contact">
        <f7-list-item title="CONTACT" group-title />
        <f7-list-item class="list-contact">
          <img slot="media" src="@/assets/images/icons/star.svg" class="image-icon" />
          <span class="item-left">
            <div>{{ contact.firstName }} {{ contact.lastName }}</div>
            <div>
              <BadgeList :ids="contact.badges" size="xs" />
            </div>
          </span>
          <span class="item-right">TEAM LEAD</span>
        </f7-list-item>
        <f7-list-item v-if="contact.phone" class="list-contact" @click="clickToCall(contact.phone)">
          <img slot="media" src="@/assets/images/icons/phone.svg" class="image-icon" />
          <span class="item-left link external" v-text="contact.phone" />
          <span class="item-right">PHONE</span>
        </f7-list-item>
        <f7-list-item class="list-contact" @click="clickToEmail(contact.email)">
          <img slot="media" src="@/assets/images/icons/email.svg" class="image-icon" />
          <span class="item-left link external" v-text="contact.email" />
          <span class="item-right">EMAIL</span>
        </f7-list-item>
      </f7-list-group>

      <UserList title="Users" :users="Object.values(users)" />
      <UserList title="Members" :users="Object.values(supportingMembers)" />
      <UserList title="Clients" :users="Object.values(clients)" :profile="false" />
    </f7-list>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { clickToCallAndEmail, openUserProfile } from '@/mixins/helperFunctions';
import showToastMixin from '@/mixins/showToast';
import { imageFilePreview } from '@/helpers/media/local';
import { uploadMedia, mediaPreviewUrl } from '@/helpers/media/remote';
import { uniq, difference } from 'lodash';
import { findAllPages } from '@/helpers/pagination';
import UserList from '@/components/UserList.vue';
import BadgeList from '@/components/BadgeList.vue';
import feathersClient from '@/api/feathers-client';

export default {
  components: { UserList, BadgeList },
  mixins: [clickToCallAndEmail, showToastMixin, openUserProfile],
  data() {
    return {
      file: null,
      supportingMembers: {},
      clients: {},
      users: {},
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('users', {
      findUsersInStore: 'find',
      getUserInStore: 'get',
    }),
    ...mapGetters(['currentGroup']),
    currentGroupImageUrl() {
      if (!this.currentGroup || !this.currentGroup.imageUrl) return;

      return mediaPreviewUrl(this.currentGroup.imageUrl);
    },
    memberIds() {
      return difference(
        this.currentGroup.members.map(member => member._id),
        this.currentGroup.staff
      );
    },
    contact() {
      if (!('contact' in this.currentGroup)) {
        return null;
      }
      return this.getUserInStore(this.currentGroup.contact);
    },
  },
  watch: {
    currentGroup: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.file = null;
          this.fetchUsers();
          this.fetchGroupData();
        }
      },
    },
    async file(newVal) {
      if (!newVal) return;

      this.$refs.image0.src = await imageFilePreview(this.file, 280, 500);
    },
  },
  methods: {
    ...mapActions('groups', {
      patchGroup: 'patch',
    }),
    filterMembers(members = []) {
      return members.reduce(
        (acc, cv) => {
          const organization = cv.organizations.find(org => org._id === this.currentGroup.organization);
          if (!organization) return acc;
          const { role } = organization;
          if (role === 'regular' || role === 'limited') {
            acc.supportingMembers[cv._id] = cv;
          } else {
            acc.users[cv._id] = cv;
          }
          return acc;
        },
        { users: {}, supportingMembers: {} }
      );
    },
    fetchGroupData() {
      try {
        const newQuery = {
          _id: this.currentGroup._id,
          $aggregate: [
            {
              $lookup: {
                from: 'users',
                localField: 'members._id',
                foreignField: '_id',
                as: 'membersData',
              },
            },
            {
              $unwind: {
                path: '$membersData',
              },
            },
            {
              $match: {
                'membersData.deletedAt': null,
              },
            },
            {
              $group: {
                _id: '$_id',
                otherFields: { $first: '$$ROOT' },
                // Other fields you want to preserve
                membersData: { $push: '$membersData' }, // Group back the membersData array
              },
            },
            {
              $replaceRoot: {
                newRoot: {
                  $mergeObjects: ['$otherFields', { membersData: '$membersData' }],
                },
              },
            },
            {
              $lookup: {
                from: 'clients',
                localField: 'clients',
                foreignField: '_id',
                as: 'clientsData',
              },
            },
          ],
        };
        feathersClient
          .service('v2/groups')
          .find({ query: newQuery })
          .then(response => {
            const { data } = response;
            const [res] = data;
            const { supportingMembers, users } = this.filterMembers(res.membersData);
            this.supportingMembers = { ...supportingMembers };
            this.users = users;
            this.clients =
              res.clientsData.reduce((acc, cv) => {
                acc[cv._id] = cv;
                return acc;
              }, {}) || [];
            this.groupData = res;
          });
      } catch (err) {
        console.log(err);
      }
    },
    fetchUsers() {
      const contact = [this.currentGroup.contact] || [];
      const staff = this.currentGroup.staff || [];
      const members = this.currentGroup.members.map(member => member._id) || [];
      const userIds = uniq([...contact, ...staff, ...members]);

      findAllPages('users', {
        _id: {
          $in: userIds,
        },
      });
    },
    async uploadGroupImage() {
      if (!this.file) return;

      this.$store.commit('addLoadingAction', 'group-info-content');

      const imageUuid = await uploadMedia(this.file);

      try {
        await this.patchGroup([
          this.currentGroup._id,
          {
            image: imageUuid,
          },
        ]);
      } catch (err) {
        this.showToast('There was an error in image uploading.');
      }

      this.showToast('Image Uploaded Successfully!');
      this.$store.commit('removeLoadingAction', 'group-info-content');
      this.file = null;
    },
    groupImageSelected(e) {
      [this.file] = this.$refs.fileInput.files;
    },
    groupImageCancelled() {
      this.file = null;
      this.$refs.fileInput.value = null;
    },
  },
};
</script>

<style scoped lang="scss">
#groupInfo {
  background-color: color(bg-color);
  padding-bottom: 2em;
  margin-bottom: 3rem;
  h3 {
    line-height: 2.4em;
  }
  .group-contact {
    margin-top: 0;
    margin-bottom: 2em;
  }
  .list-group-title {
    background-color: color(bg-color);
    span {
      font-weight: bold;
    }
  }

  .contact {
    background: color(bg-color);
    font-weight: bold;
    .group-title {
      color: color(gray);
    }
  }
  .members {
    @media (min-width: 1024px) {
      margin-bottom: 1.5 * $tab-height;
    }
  }

  .list-contact {
    width: 92%;
    margin: auto;
    background-color: color(white);
    font-weight: bold;
    font-size: 0.9em;

    .item-left {
      margin-left: -1.2em;
      color: color(text-color);
    }
    .item-right {
      font-weight: 400;
      font-size: 0.7em;
      margin-right: 1.5em;
    }
  }
}

.btn-upload-photo,
.btn-submit,
.btn-cancel {
  background: color(blue);
  color: color(white);
  text-transform: capitalize;
  margin-top: 10px;
  margin-right: 10px;
  top: 1.07 * $tab-height;
  right: 0;
  height: 2em;
  line-height: 2em;
  width: 5%;
  position: absolute;
}
.btn-cancel {
  right: 5.2em;
}
.no-group-photo {
  background: color(white);
  text-align: center;
  padding: 0.6em 0 0.6em 0;
  font-style: italic;
  font-weight: 500;
  color: darken(color(gray), 10%);
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  font-size: 1.05em;
  margin-top: 0.5em;
}

input[type='file'] {
  position: absolute;
  top: -500px;
}
.group-image-previewer {
  width: 100vw;
  height: 56vw;
  background-size: cover;
  background-position: center center;
  @media (min-width: 1024px) {
    width: 30vw;
    height: 22.5vw;
  }
  @media (min-width: $large_screen_width) {
    width: 100%;
    height: 350px;
  }
  overflow: hidden;
  img {
    width: 100vw;
    height: 56vw;
    @media (min-width: $large_screen_width) {
      width: 100%;
      height: 350px;
    }
  }
}
.group-image-placeholder {
  height: 56vw;
  @media (min-width: 1024px) {
    height: 22.5vw;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 3.5em;
    height: 3.5em;
  }
}

.orgLogo {
  max-width: 240px;
  max-height: 10vh;
  width: auto;
  margin: 1rem auto;

  img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    display: block;
  }
}
</style>
