<template>
  <div class="pin-form-container">
    <div class="pin-form">
      <form @submit.prevent="handleSubmit">
        <div class="pin-form__input-group">
          <label
            for="pin"
            v-text="label"
          />
          <input
            id="pin"
            type="text"
            pattern="\d*"
            inputmode="numeric"
            minlength="4"
            maxlength="6"
            required
            :value="internalValue"
            @keyup="handleChange"
          >
          <div
            v-if="error"
            class="pin-form__error"
          >
            <span v-text="error" />
          </div>
          <div v-else-if="!isValid">
            PIN must be between 4 and 6 digits
          </div>
        </div>
        <div class="pin-form__buttons">
          <button type="submit">
            Confirm
          </button>
          <button
            type="button"
            @click="cancel"
            @keydown.esc="cancel"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      value: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: 'Enter PIN:',
      },
      error: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        internalValue: this.value,
      };
    },
    computed: {
      isValid() {
        if (this.internalValue.length === 0) return true;
        return this.internalValue.length >= 4 && this.internalValue.length <= 6;
      },
    },
    methods: {
      cancel() {
        this.$emit('cancelled');
      },
      handleChange(e) {
        this.internalValue = e.target.value;
        this.$emit('input', this.internalValue);
      },
      handleSubmit() {
        this.$emit('submit', parseInt(this.internalValue));
      },
    },
  };
</script>
<style scoped lang="scss">
  .pin-form-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20000;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pin-form {
    width: 70vw;
    max-width: 600px;

    border-radius: 5px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid #ddd;

    &__input-group {
      margin-bottom: 10px;
      text-align: center;
    }

    input {
      display: block;
      margin: 20px auto;
      width: 50%;
      text-align: center;
      font-weight: bold;
      font-size: 24px;
      border: 2px solid #000;
      padding: 10px;
    }

    &__error {
      color: #f00;
      margin-top: 10px;
    }

    &__buttons {
      display: flex;
      justify-content: center;
      gap: 4px;
      button {
        font-size: 20px;
        background-color: #eee;
        border-radius: 4px;
        border-color: transparent;
        padding: 5px 0;
        appearance: none;
      }

      button[type='submit'] {
        background-color: #ec6b70;
        color: #fff;
        flex: 2 0 60%;
      }
    }
  }
</style>
