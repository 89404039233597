<template>
  <div
    class="badge"
    :style="style"
  >
    {{ label }}
  </div>
</template>

<script>
  import { getContrastingColor } from '@/helpers/colors';

  export default {
    props: {
      label: {
        type: String,
        required: true,
      },
      color: {
        type: String,
        default: '#ffffff',
      },
      size: {
        type: String,
        default: 'md',
      },
    },
    computed: {
      style() {
        return {
          'background-color': this.color,
          color: this.textColor,
          ...this.sizeStyle,
        };
      },
      textColor() {
        return getContrastingColor(this.color);
      },
      sizeStyle() {
        const rules = {};
        switch (this.size) {
          case 'xs': {
            rules.fontSize = '0.6rem';
            break;
          }
          case 'sm': {
            rules.fontSize = '0.8rem';
            break;
          }
          case 'md': {
            rules.fontSize = '1.2rem';
            break;
          }
          case 'lg': {
            rules.fontSize = '1.6rem';
            rules.fontWeight = 'bold';
            break;
          }
        }

        return rules;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .badge {
    border-radius: 25px;
    padding: 0.2rem 0.4rem;
    white-space: nowrap;
    display: inline-block;
    height: 100%;

    max-width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
