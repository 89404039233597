import { permissions } from './user';
export default {
  mixins: [permissions],
  computed: {
    tabs() {
      return [
        {
          label: 'Feed',
          id: 'feed',
          logEvent: 'Opened feed',
          img: this.requireImage('home-black.svg'),
          imgOnActive: this.requireImage('home.svg'),
          route: '/app/',
          userCanView: true,
        },
        {
          label: 'Events',
          id: 'events',
          logEvent: 'Opened events',
          img: this.requireImage('calendar-black.svg'),
          imgOnActive: this.requireImage('calendar.svg'),
          route: '/app/events/',
          userCanView: true,
        },
        {
          label: 'Data',
          id: 'data',
          logEvent: 'Opened data',
          img: this.requireImage('clipboard-minus-black.svg'),
          imgOnActive: this.requireImage('clipboard-minus.svg'),
          route: '/app/data/',
          userCanView: this.userCanReadBehaviors || this.userCanReadShiftNotes,
        },
        {
          label: 'Group Info',
          id: 'info',
          logEvent: 'Opened group info',
          img: this.requireImage('info-black.svg'),
          imgOnActive: this.requireImage('info.svg'),
          route: '/app/info/',
          userCanView: true,
        },
      ];
    },
    currentRouteUrl() {
      return this.$f7.views.main.router.url;
    },
    authorizedTabs() {
      return this.tabs.filter((tab) => tab.userCanView);
    },
  },
  methods: {
    requireImage(fileName) {
      return require(`@/assets/images/icons/${fileName}`);
    },
  },
};
