<template>
  <f7-popup id="editProfile" class="popup-tablet-fullscreen">
    <f7-page class="inner">
      <div class="np-border--fancy" />
      <div class="navbar">
        <div class="navbar-inner sliding">
          <div class="left">
            <f7-button class="link btn-editProfile-close" @click="reset">
              <i class="icon icon-back" />
            </f7-button>
          </div>
          <div class="title">
            Edit Profile & Manage Settings
          </div>
        </div>
      </div>

      <div class="edit-profile__photo">
        <div v-if="user.avatarUrl && !file" class="image-placeholder">
          <img :src="avatarUrl" class="profile-avatar" />
        </div>
        <div v-else-if="file" class="image-placeholder">
          <img ref="avatarPreview" class="profile-avatar" />
        </div>
        <div v-else class="image-placeholder">
          <img src="@/assets/images/icons/photo.svg" class="image-icon-large" />
        </div>

        <f7-button class="upload-photo" @click="$refs.fileInput.click()">
          <span>Edit Photo</span>
        </f7-button>
        <input
          ref="fileInput"
          type="file"
          accept="image/*"
          @change="photoChanged"
        />
      </div>

      <div v-if="!form.avatar" class="no-profile-photo">
        No Profile Photo Set
      </div>
      <f7-list class="editProfileForm no-hairlines-md">
        <f7-list-item>
          <f7-row>
            <f7-col width="50">
              <f7-label>First Name</f7-label>
              <f7-input
                type="text"
                placeholder="First name"
                name="firstName"
                :value="form.firstName"
                required
                validate
                @input="form.firstName = $event.target.value"
              />
            </f7-col>
            <f7-col width="50">
              <f7-label>Last Name</f7-label>
              <f7-input
                type="text"
                placeholder="Last name"
                name="lastName"
                :value="form.lastName"
                required
                validate
                @input="form.lastName = $event.target.value"
              />
            </f7-col>
          </f7-row>
        </f7-list-item>

        <f7-list-item>
          <f7-label>Phone</f7-label>
          <f7-input
            v-mask="'(###) ###-####'"
            type="text"
            name="phone"
            placeholder="Your phone number"
            :value="form.phone"
            @input="form.phone = $event.target.value"
          />
          <small v-if="form.phone !== user.phone && user.authyId"
            >When changing your number you will be automatically un-enrolled in
            2FA. You can re-enable 2FA with the new number.</small
          >
        </f7-list-item>
        <f7-list-item>
          <f7-label>Title</f7-label>
          <f7-input
            type="text"
            placeholder="Your title"
            name="title"
            :value="form.title"
            @focus="isInputOnFocus = true"
            @blur="isInputOnFocus = false"
            @input="form.title = $event.target.value"
          />
        </f7-list-item>
      </f7-list>
      <f7-list class="editProfileForm no-hairlines-md">
        <f7-list-item>
          <f7-label>Email</f7-label>
          <f7-input
            type="email"
            placeholder="Your email"
            :value="form.email"
            required
            validate
            @input="form.email = $event.target.value.trim()"
          />
        </f7-list-item>
        <f7-list-item v-show="form.email !== user.email">
          <f7-label>Password</f7-label>
          <f7-input
            type="password"
            placeholder="Confirm your password"
            :value="form.passwordConfirmation"
            required
            validate
            @input="form.passwordConfirmation = $event.target.value"
          />
        </f7-list-item>
      </f7-list>
      <f7-block-title class="edit-profile__section-title">
        Notification Settings
      </f7-block-title>
      <f7-list class="editProfileForm">
        <template v-for="(notification, type) in notificationTypes">
          <f7-list-item
            v-if="notification.roles.includes(user.role)"
            :key="notification.text"
            class="notification-item"
          >
            <label class="checkbox" :for="notification.text">
              {{ notification.text }}
            </label>
            <input
              :id="notification.text"
              v-model="form.notifications"
              type="checkbox"
              :value="type"
            />
          </f7-list-item>
        </template>
      </f7-list>
      <f7-button v-if="user.authyId" @click="unenrollTwoFactor">
        Un-Enroll in 2FA
      </f7-button>
      <f7-button
        v-else-if="user.phone && !user.authyId"
        :disabled="form.phone !== user.phone"
        @click="enrollTwoFactor"
      >
        Enroll in 2FA
      </f7-button>
      <f7-button @click="sendPasswordResetEmail">
        Reset Password
      </f7-button>
      <f7-button class="btn-secondary" @click="reset">
        Cancel &amp; Go Back
      </f7-button>
      <div class="display-flex justify-content-center my-10 color-gray">
        <b>
          <small>{{ appVersion }}</small>
        </b>
      </div>
      <f7-button
        class="btn-primary"
        :class="{ 'btn-primary--focus': isInputOnFocus && isAndroid }"
        :disabled="!formValid"
        @click="updateUserProfile"
      >
        Update
      </f7-button>
      <div style="margin-bottom: 8rem" />
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapActions } from 'vuex';
import { mask } from 'vue-the-mask';
import showToastMixin from '@/mixins/showToast';
import { forEach } from 'lodash';
import { getDeviceData } from '@/helpers/device';
import { uploadMedia, mediaTransformationUrl } from '@/helpers/media/remote';
import { imageFilePreview } from '@/helpers/media/local';
import feathersClient from '../../api/feathers-client';
import { notificationTypes } from '@/lib/notifications';

const { VERSION } = process.env;

export default {
  directives: {
    mask,
  },
  mixins: [showToastMixin],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        avatar: null,
        title: '',
        passwordConfirmation: '',
        notifications: [],
      },
      file: null,
      isInputOnFocus: false,
      errorMessage: '',
    };
  },
  computed: {
    notificationTypes() {
      return notificationTypes;
    },
    formValid() {
      if (
        this.form.firstName.trim().length > 0 &&
        this.form.lastName.trim().length > 0 &&
        this.form.email.trim().length > 0 &&
        (this.form.email.trim() === this.user.email.trim() ||
          this.form.passwordConfirmation.length > 0)
      ) {
        return true;
      }
      return false;
    },
    isCordova() {
      return this.$store.getters.isCordova;
    },
    isAndroid() {
      return this.$store.getters.isAndroid;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    storeVersion() {
      return this.$store.getters.appVersion || process.env.VUE_APP_VERSION;
    },
    appVersion() {
      const deviceData = getDeviceData();
      const platform =
        deviceData.platform && deviceData.platform.includes('Browser')
          ? 'Web'
          : deviceData.platform;

      return `${platform} App Version ${this.storeVersion}`;
    },
    avatarUrl() {
      if (!this.user.avatarUrl) return;

      return mediaTransformationUrl(this.user.avatarUrl, {
        scale_crop: '300x300/smart_faces',
      });
    },
  },
  watch: {
    async file(newVal) {
      if (!newVal) return;

      this.$refs.avatarPreview.src = await imageFilePreview(newVal);
    },
  },
  mounted() {
    this.reset();
  },
  methods: {
    ...mapActions('users', {
      patchUser: 'patch',
    }),
    testAppVersion() {
      console.log(this.storeVersion);
    },
    async enrollTwoFactor() {
      const confirmed = await new Promise(resolve => {
        this.$f7.dialog.confirm(
          `Can you receive SMS messages at ${this.user.phone}?`,
          'Confirm Phone Number',
          () => resolve(true),
          () => resolve(false)
        );
      });

      if (!confirmed) return;
      const { cellphone } = await feathersClient.service('2fa').create({
        action: 'registerSMS',
      });

      let verificationCode;

      try {
        verificationCode = await new Promise((resolve, reject) => {
          this.$f7.dialog.prompt(
            `Please enter the code we sent via SMS to your phone number (${cellphone}).`,
            '2FA Code Verification',
            code => resolve(code),
            () => reject()
          );
        });
      } catch (err) {
        this.patchUser([this.user._id, { authyId: null }]);
      }

      const isCodeValid = await feathersClient
        .service('2fa')
        .create({ action: 'verifySMSToken', token: verificationCode });
      if (isCodeValid) {
        this.$f7.dialog.alert(
          'You are now enrolled in two-factor authentication. Every time you log in you will be prompted for a code.',
          'Congratulations'
        );
      } else {
        this.$f7.dialog.alert(
          'We were not able to validate your two-factor code. Please try again.',
          'Error Validating Code'
        );
        this.patchUser([this.user._id, { authyId: null }]);
      }
    },
    async unenrollTwoFactor() {
      const confirm = await new Promise(resolve => {
        this.$f7.dialog.confirm(
          'Are you sure you wish to unenroll in two-factor authentication?',
          'Un-Enroll in 2FA?',
          () => resolve(true),
          () => resolve(false)
        );
      });

      if (!confirm) return;
      this.patchUser([this.user._id, { authyId: null }]);
    },
    reset() {
      forEach(this.form, (value, key) => (this.form[key] = this.user[key]));
      this.form.notifications = this.form.notifications || [];
      Object.keys(notificationTypes).forEach(key => {
        if (this.user.enabledNotifications?.includes(key)) {
          this.form.notifications.push(key);
        }
      });
      this.form.passwordConfirmation = '';
      this.$f7.popup.close('#editProfile');
      this.file = null;
    },
    async updateUserProfile() {
      this.$store.commit('addLoadingAction', 'edit-profile-popup');
      if (this.$refs.fileInput.files.length > 0) {
        [this.file] = this.$refs.fileInput.files;
        this.form.avatar = await uploadMedia(this.file);

        this.$refs.fileInput.value = null;
      }

      if (this.form.email !== this.user.email) {
        await this.initiateIdentityChange();
      }

      await this.update();
      this.$store.commit('removeLoadingAction', 'edit-profile-popup');

      return this.showToast('Updated Profile Successfully!');
    },
    // eslint-disable-next-line complexity
    async update() {
      try {
        await this.patchUser([
          this.user._id,
          {
            firstName: this.form.firstName.trim(),
            lastName: this.form.lastName.trim(),
            phone: this.form.phone ? this.form.phone.trim() : '',
            avatar: this.form.avatar,
            title: this.form.title ? this.form.title : '',
            enabledNotifications: this.form.notifications,
          },
        ]);
      } catch (err) {
        this.showToast('There was an error.');
        // eslint-disable-next-line no-console
        console.log(err);
      }

      this.file = null;
    },
    async photoChanged() {
      const avatarUuid = await uploadMedia(this.$refs.fileInput.files[0]);
      [this.file] = this.$refs.fileInput.files;
      this.$refs.fileInput.value = null;
      this.form.avatar = avatarUuid;
    },
    async initiateIdentityChange() {
      if (this.form.email.trim().length === 0) return;

      try {
        await feathersClient.service('authManagement').create({
          action: 'identityChange',
          value: {
            user: { email: this.user.email },
            password: this.form.passwordConfirmation,
            changes: { email: this.form.email },
          },
        });
        this.$f7.dialog.alert(
          `Please check the inbox for ${this.form.email} to confirm this change.`,
          'Confirmation Email Sent'
        );
      } catch (err) {
        let message =
          'Your password could not be confirmed and your email was not updated.';
        if (err.message.includes('password policy')) {
          message =
            "Your existing password does not comply with the current password policy. \
                You must reset your password before you can change your email. \
                If you don't have access to this email, please contact an administrator.";
        } else if (err.message.includes('users service already has a email')) {
          message = 'A user already exists with that email address.';
        }

        this.$f7.dialog.alert(message, 'Error updating email');
      }
    },
    sendPasswordResetEmail() {
      if (this.form.email.trim().length === 0) return;
      this.$store.commit('addLoadingAction', 'edit-profile-popup');
      feathersClient
        .service('authManagement')
        .create({
          action: 'sendResetPwd',
          value: { email: this.form.email.trim() },
        })
        .then(() => {
          this.$f7.dialog.alert(
            'An email has been sent to reset your password. Please check your email.',
            'Email Sent',
            () => {
              this.$emit('passwordResetSent');
            }
          );
        })
        .catch(err => {
          if (err.message === 'User is not verified.') {
            this.errorMessage =
              'Please follow the link to verify your account in your email before resetting your password.';
            feathersClient.service('authManagement').create({
              action: 'resendVerifySignup',
              value: { email: this.form.email.trim() },
            });
          } else {
            this.errorMessage =
              'There was an error processing your reset, please try again.';
          }
          this.$f7.dialog.alert(
            this.errorMessage,
            err.message === 'User is not verified.'
              ? 'User is not verified'
              : 'Error'
          );
        })
        .finally(() => {
          this.$store.commit('removeLoadingAction', 'edit-profile-popup');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.color-gray {
  color: gray;
}

#editProfile {
  //Sheet modal's z-index is 12500 by default in Framework 7
  z-index: 12509;

  .inner {
    background: color(bg-color);

    .editProfileForm {
      width: 90%;
      margin: auto;
      background: color(white);
      margin-top: 1em;
      padding: 1em 0 1em 0;

      .notification-item {
        border-top: #e0e0e0 0.5px solid;
      }
    }

    .edit-profile__photo {
      text-align: center;
      margin-top: 0.5em;
      margin-bottom: 2em;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .edit-profile__section-title {
      width: 90%;
      margin: auto;
      margin-top: 2em;
    }

    .upload-photo {
      background: color(blue);
      color: color(white);
      font-weight: bold;
      text-transform: capitalize;
      border-radius: 8px;
      top: 200px;
      margin-left: auto;
      margin-right: auto;
      height: 2em;
      line-height: 2em;
      position: absolute;
    }

    .no-profile-photo {
      background: color(white);
      text-align: center;
      padding: 0.6em 0 0.6em 0;
      font-style: italic;
      font-weight: 500;
      color: darken(color(gray), 10%);
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      font-size: 1.05em;
      margin-top: 0.5em;
    }

    input[type='file'] {
      position: absolute;
      top: -500px;
    }

    .np-border--fancy {
      height: 4px;
      width: 100%;
      background: url('../../assets/images/nupath-border-bg.svg') repeat-x;
    }

    .image-placeholder {
      img {
        width: 180px;
        height: 180px;
      }

      @media (min-width: 1024px) {
        height: 140px;
        width: 140px;
      }
    }

    .btn-primary--focus {
      position: relative;
      margin-top: 1.5em;
    }
  }
}
</style>
