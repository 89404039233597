import feed from './feed';
import events from './events';
import data from './data';

export default {
  namespaced: true,
  modules: {
    feed,
    events,
    data,
  },
};
