const ACTIVITY_CACHE_KEY = 'activityCache';
import store from '@/store';
import feathersClient from '@/api/feathers-client';

export const logActivity = activity => {
  const appVersion = process.env.VERSION;
  const appName = 'app';

  let activityCache;

  // Check if Cordova native storage is available
  if (window.cordova && window.cordova.platformId !== 'browser') {
    // Use Cordova native storage
    NativeStorage.getItem(
      ACTIVITY_CACHE_KEY,
      data => {
        activityCache = data ? JSON.parse(data) : {};
        processActivity(activity, appVersion, appName, activityCache);
      },
      () => {
        activityCache = {};
        processActivity(activity, appVersion, appName, activityCache);
      }
    );
  } else {
    // Fallback to localStorage
    activityCache = JSON.parse(localStorage.getItem(ACTIVITY_CACHE_KEY)) || {};
    processActivity(activity, appVersion, appName, activityCache);
  }
};

function processActivity(activity, appVersion, appName, activityCache) {
  // Generate a unique key for the activity
  const activityKey = JSON.stringify(activity);

  // Get the current timestamp
  const currentTime = Date.now();

  // If the activity was logged recently (within 15 minutes), return early
  if (
    activityCache[activityKey] &&
    currentTime - activityCache[activityKey] < 15 * 60 * 1000
  ) {
    return;
  }

  // Otherwise, update the cache with the current timestamp
  activityCache[activityKey] = currentTime;

  // Save the updated cache
  if (window.cordova && window.cordova.platformId !== 'browser') {
    // Use Cordova native storage
    NativeStorage.setItem(ACTIVITY_CACHE_KEY, JSON.stringify(activityCache));
  } else {
    // Fallback to localStorage
    localStorage.setItem(ACTIVITY_CACHE_KEY, JSON.stringify(activityCache));
  }

  // Log the activity
  feathersClient.service('devicelogs').create({
    appVersion,
    appName,
    deviceManufacturer: store.state.device.manufacturer,
    deviceModel: store.state.device.model,
    devicePlatform: store.state.device.platform,
    deviceVersion: store.state.device.version,
    activity,
  });
}
