<template>
  <button
    class="welcome__btn"
    :class="{ 'welcome__btn--ghost': ghost }"
    v-on="$listeners"
  >
    <slot />
  </button>
</template>
<script>
  export default {
    props: {
      ghost: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>
<style lang="scss" scoped>
  .welcome__btn {
    cursor: pointer;
    border-radius: 6px;
    font-size: 1em;
    height: 2.5em;
    color: #fff;
    line-height: 1;
    text-transform: capitalize;
    font-weight: bold;
    background-color: color(pink);
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    border: none;
    margin-bottom: 1em;
    transition: 0.2s all ease-in-out;

    &:hover {
      background-color: lighten(color(pink), 5%);
    }

    img {
      height: 50%;
      width: auto;
      margin-right: 0.5em;
    }
  }
  .welcome__btn--ghost {
    background-color: transparent;
    border: 1px solid color(white);
    font-weight: 400;

    &:hover {
      color: color(blue);
      background-color: #fff;
    }
  }
</style>
